// Dayhoot Functions & Scripts
import { getAttractionTrackableExternalUrl } from "../config/apiUrls";

// Dayhoot Config
export async function handleResponse(response) {
  if (!response.ok) console.error(`Can't access the data.`);
  else
    return await response
      .json()
      .catch((e) =>
        console.log(
          "There has been a problem with your fetch operation: " + e.message
        )
      );
}

export async function fetchData(url) {
  const response = await fetch(url);
  return await handleResponse(response);
}

export async function fetchDataWithHeaders({ url, headers }) {
  const response = await fetch(url, {
    method: "get",
    headers: new Headers(headers),
  });
  return await handleResponse(response);
}

export async function handlePostPutResponse(response) {
  if (response.status === 201) {
    return {
      result: !!response.ok,
    };
  } else {
    return {
      response: !!response.ok ? await response.json() : null,
      result: !!response.ok,
    };
  }
}

export async function handleDeleteResponse(response) {
  return {
    result: !!response.ok,
  };
}

export async function postData({ url, data, token }) {
  let requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  if (token) {
    requestOptions.headers = {
      token: token,
      "Content-Type": "application/json",
    };
  }

  const response = await fetch(url, requestOptions);
  return handlePostPutResponse(response);
}

export async function putData({ url, data, token }) {
  let requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  if (token) {
    requestOptions.headers = {
      token: token,
      "Content-Type": "application/json",
    };
  }

  const response = await fetch(url, requestOptions);
  return handlePostPutResponse(response);
}

export async function deleteData({ url, data, token }) {
  let requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  if (token) {
    requestOptions.headers = {
      token: token,
      "Content-Type": "application/json",
    };
  }

  const response = await fetch(url, requestOptions);
  return handleDeleteResponse(response);
}

// Determines whether to use a ? or a & to join filters / sort options
// If we have filters, it should already have a ? at the start, anything afterwards uses & to join url params
export function generateStringJoinCharacter(url) {
  return url.includes("?") ? "&" : "?";
}

// Here we want to take the array of filters, join them together with filterName= and append them onto the current URL
export function appendFilterString(url, filterName, filterArray) {
  const stringJoinCharacter = generateStringJoinCharacter(url);
  let filterString = "";
  filterArray.forEach((filter, i) => {
    filterString +=
      (i === 0 ? stringJoinCharacter : "&") + filterName + "=" + filter;
  });
  return url + filterString;
}

export async function openExternalWebLink(attractionId, attractionUrl) {
  const trackableUrl = await getAttractionTrackableExternalUrl(attractionId);
  const response = await fetch(trackableUrl);

  if (!response.ok) console.log(`Can't track external link.`);
  else window.location.href = attractionUrl;
}
