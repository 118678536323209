import {
  mapInputValuesToFilterArray,
  mapDistanceEnumsToFilters,
} from "../component-functions/filterPanel-core";
import { tryParseNumber, sortArray } from "../scripts/utils";
import {
  filterType,
  attractionsFilterType,
  attractionListSortTypeEnums,
} from "../config/enums";
import {
  FilterList,
  FilterPanelHeader,
} from "../components/filterPanel-components";

export function DistanceFilter({
  filterValue,
  buttonIsOpen,
  buttonContent,
  toggle,
  handleFilterChange,
  classes,
}) {
  return (
    <FilterList
      filterPanel={{
        title: "Distance",
        hasToggle: true,
        type: filterType.Radio,
        label: "Filter Distance",
        selectedFilterValue: filterValue,
        handleFilterChange: event =>
          handleFilterChange(tryParseNumber(event.target.value)),
      }}
      button={{
        handleButtonClick: toggle,
        buttonContent: buttonContent,
      }}
      filters={mapInputValuesToFilterArray({
        arrayToMap: mapDistanceEnumsToFilters(),
        selectedValue: filterValue,
        type: filterType.Radio,
        isToggleOpen: buttonIsOpen,
      })}
      isOpen={buttonIsOpen}
      classes={classes}
    />
  );
}

export function CategoryFilter({
  filterValue,
  categories,
  buttonIsOpen,
  buttonContent,
  category,
  toggle,
  handleFilterChange,
  classes,
}) {
  return (
    <FilterList
      filterPanel={{
        title: attractionsFilterType.Category.displayName,
        hasToggle: true,
        type: filterType.Checkbox,
        label: "Filter Categories",
        selectedFilterValue: filterValue,
        handleFilterChange: categoryName =>
          handleFilterChange(attractionsFilterType.Category, categoryName),
      }}
      button={{
        handleButtonClick: toggle.bind(this),
        buttonContent: buttonContent,
      }}
      filters={mapInputValuesToFilterArray({
        arrayToMap: categories
          .map(c => {
            return {
              key: c.categoryName,
              value: c.categoryName,
              label: c.displayName,
              isPreSelected: c.categoryName === category,
            };
          })
          .sort((a, b) =>
            sortArray(
              a.label,
              b.label,
              attractionListSortTypeEnums.AlphabeticalAsc
            )
          ),
        selectedValue: filterValue,
        type: filterType.Checkbox,
        isToggleOpen: buttonIsOpen,
      })}
      isOpen={buttonIsOpen}
      classes={classes}
    />
  );
}

export function FacilityFilter({
  filterValue,
  facilities,
  buttonIsOpen,
  buttonContent,
  facility,
  toggle,
  handleFilterChange,
  classes,
}) {
  return (
    <FilterList
      filterPanel={{
        title: attractionsFilterType.Facility.displayName,
        hasToggle: true,
        type: filterType.Checkbox,
        label: "Filter Facilities",
        selectedFilterValue: filterValue,
        handleFilterChange: facilityName =>
          handleFilterChange(attractionsFilterType.Facility, facilityName),
      }}
      button={{
        handleButtonClick: toggle.bind(this),
        buttonContent: buttonContent,
      }}
      filters={mapInputValuesToFilterArray({
        arrayToMap: facilities
          .map(f => {
            return {
              key: f.facilityName,
              value: f.facilityName,
              label: f.displayName,
              isPreSelected: f.facilityName === facility,
            };
          })
          .sort((a, b) =>
            sortArray(
              a.label,
              b.label,
              attractionListSortTypeEnums.AlphabeticalAsc
            )
          ),
        selectedValue: filterValue,
        type: filterType.Checkbox,
        isToggleOpen: buttonIsOpen,
      })}
      isOpen={buttonIsOpen}
      classes={classes}
    />
  );
}

export function RegionFilter({
  regions,
  buttonIsOpen,
  filterValue,
  buttonContent,
  region,
  toggle,
  handleFilterChange,
  classes,
}) {
  return (
    <FilterList
      filterPanel={{
        title: attractionsFilterType.Region.displayName,
        hasToggle: true,
        type: filterType.Checkbox,
        label: "Filter Categories",
        selectedFilterValue: filterValue,
        handleFilterChange: regionName =>
          handleFilterChange(attractionsFilterType.Region, regionName),
      }}
      button={{
        handleButtonClick: toggle,
        buttonContent: buttonContent,
      }}
      filters={mapInputValuesToFilterArray({
        arrayToMap: regions
          .map(r => {
            return {
              key: r.regionName,
              value: r.regionName,
              label: r.displayName,
              isPreSelected: r.regionName === region,
            };
          })
          .sort((a, b) =>
            sortArray(
              a.label,
              b.label,
              attractionListSortTypeEnums.AlphabeticalAsc
            )
          ),
        selectedValue: filterValue,
        type: filterType.Checkbox,
        isToggleOpen: buttonIsOpen,
      })}
      isOpen={buttonIsOpen}
      hideToggle={filterValue.length > 0}
      classes={classes}
    />
  );
}

export function FilterPanelContainer({
  filterPanelContainerDisplay,
  filterPanelToggle,
  distanceFilter,
  categoryFilter = "",
  regionFilter = "",
}) {
  return (
    <div
      className="mb-2"
      id="filterContainer"
      style={{ display: filterPanelContainerDisplay }}
    >
      <div className="card">
        <FilterPanelHeader closePanelClick={filterPanelToggle} />
        <div className="card-body">
          <div>
            <DistanceFilter
              filterValue={distanceFilter.filterValue}
              buttonIsOpen={distanceFilter.buttonIsOpen}
              buttonContent={distanceFilter.buttonContent}
              toggle={distanceFilter.toggle}
              handleFilterChange={distanceFilter.handleFilterChange}
            />
            <CategoryFilter
              filterValue={categoryFilter.filterValue}
              categories={categoryFilter.categories}
              buttonIsOpen={categoryFilter.buttonIsOpen}
              buttonContent={categoryFilter.buttonContent}
              category={categoryFilter.category}
              toggle={categoryFilter.toggle}
              handleFilterChange={categoryFilter.handleFilterChange}
            />
            <RegionFilter
              regions={regionFilter.regions}
              buttonIsOpen={regionFilter.buttonIsOpen}
              filterValue={regionFilter.filterValue}
              buttonContent={regionFilter.buttonContent}
              region={regionFilter.region}
              toggle={regionFilter.toggle}
              handleFilterChange={regionFilter.handleFilterChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
