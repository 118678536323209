import { NavLink } from "react-router-dom";
import { AttractionCardContent, AttractionCardContentProps } from ".";
import { openExternalWebLink } from "../../scripts/api-utils";
import { clsx } from "../../scripts/ReactHelpers";

type AttractionCardProps = {
  containerClass?: string;
  openExternalWebLink?: (id?: string, url?: string) => void;
} & AttractionCardContentProps;

const AttractionCard = ({
  attraction,
  cookies,
  containerClass,
}: AttractionCardProps) => {
  const { detailedView, id, links } = attraction;
  const weblink = links?.website || "www.dayhoot.co.uk";
  return (
    <div
      className={clsx(
        containerClass || "col-md-6 col-lg-4",
        "mb-2 attractionItemContainer"
      )}
      id={`attractionContainer${attraction.id}`}
    >
      <div className="h-100 card-edge">
        {detailedView ? (
          <NavLink className="p-0" to={`/attraction/${id}`}>
            <AttractionCardContent attraction={attraction} cookies={cookies} />
          </NavLink>
        ) : (
          <button
            className="attraction-card"
            onClick={() =>
              openExternalWebLink && openExternalWebLink(id, weblink)
            }
          >
            <AttractionCardContent attraction={attraction} cookies={cookies} />
          </button>
        )}
      </div>
    </div>
  );
};
export default AttractionCard;
