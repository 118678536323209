import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import SortIcon from "@material-ui/icons/Sort";
import FilterListIcon from "@material-ui/icons/FilterList";
import CloseIcon from "@material-ui/icons/Close";
import Backdrop from "@material-ui/core/Backdrop";
import {
	attractionListSortTypeEnumList,
	attractionListSortTypeEnums,
	distanceFilterValues,
} from "../config/enums";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { tryParseNumber } from "../scripts/utils";
import {
	CategoryFilter,
	DistanceFilter,
	FacilityFilter,
	RegionFilter,
} from "./allAttractions-components";
import { Badge } from "@material-ui/core";
import ClearAllIcon from "@material-ui/icons/ClearAll";

const drawerWidth = 260;

const useStyles = makeStyles((theme) => {
	return {
		root: {
			display: "fixed",
		},
		appBar: {
			top: "56px",
			[theme.breakpoints.up("md")]: {
				top: "64px",
			},
			transition: theme.transitions.create(["margin", "width"], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			backgroundColor: theme.palette.light.main,
		},
		appBarShift: {
			transition: theme.transitions.create(["margin", "width"], {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		backdrop: {
			zIndex: theme.zIndex.drawer - 1,
			color: "#fff",
		},
		grow: {
			flexGrow: 1,
			marginBottom: theme.spacing(2),
		},
		hide: {
			display: "none",
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
		},
		drawerPaper: {
			width: drawerWidth,
		},
		drawerHeader: {
			display: "flex",
			alignItems: "center",
			// necessary for content to be below app bar
			...theme.mixins.toolbar,
			justifyContent: "flex-end",
			minHeight: "56px !important",
			[theme.breakpoints.up("md")]: {
				minHeight: "64px !important",
			},
		},
		sortDrawerTitleText: {
			margin: theme.spacing(0, 0.5, 0, 2.5),
			fontSize: "1em",
		},
		filterDrawerTitleText: {
			margin: theme.spacing(0, 2.5, 0, 0.5),
			fontSize: "1em",
		},
		contentShift: {
			transition: theme.transitions.create("margin", {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: 0,
		},
		navItem: {
			margin: 0,
			padding: theme.spacing(0.5, 1, 0, 0),
			borderRadius: theme.shape.borderRadius,
		},
		buttonItem: {
			margin: 0,
			padding: theme.spacing(0.5, 1, 1, 0),
			borderRadius: theme.shape.borderRadius,
		},
		navItemText: {
			fontSize: "0.6em",
			color: theme.palette.light.contrastText,
			margin: theme.spacing(0, 0.5, 0, 0.5),
		},
		navItemIcon: {
			fill: theme.palette.light.contrastText,
		},
		radioButtonLabelText: {
			fontSize: "0.8em",
			padding: theme.spacing(0),
		},
		checkboxLabelText: {
			fontSize: "0.8em",
			padding: theme.spacing(0),
		},
	};
});

function SortDrawer({ classes, open, setOpen, sortOptionsDto }) {
	return (
		<Drawer
			className={classes.drawer}
			variant="persistent"
			anchor="left"
			open={open}
			classes={{
				paper: classes.drawerPaper,
			}}
		>
			<div className={classes.drawerHeader}>
				<Typography className={classes.sortDrawerTitleText} variant="h6" aria-label="close" label="close">
					Sort
				</Typography>
				<div className={classes.grow} />
				<IconButton onClick={() => setOpen(false)}>
					<CloseIcon />
				</IconButton>
			</div>
			<Divider />
			<List>
				<ListItem>
					<FormControl component="fieldset">
						<legend className="accessibility-item">
							{sortOptionsDto.sortText}
						</legend>
						<RadioGroup
							aria-label="sortby"
							name="sortby"
							value={sortOptionsDto.sortValue}
							onChange={sortOptionsDto.handleSortChange}
						>
							{attractionListSortTypeEnumList
								.filter(
									(x) => x.value !== attractionListSortTypeEnums.NoSort.value
								)
								.map((input) => {
									const { value, displayName } = input;
									return (
										<FormControlLabel
											key={`sort-${value}`}
											value={value}
											control={<Radio color="primary" />}
											label={
												<Typography className={classes.radioButtonLabelText}>
													{displayName}
												</Typography>
											}
										/>
									);
								})}
						</RadioGroup>
					</FormControl>
				</ListItem>
			</List>
		</Drawer>
	);
}

function FilterDrawer({ classes, open, setOpen, filters, hasSelectedFilters }) {
	return (
		<Drawer
			className={classes.drawer}
			variant="persistent"
			anchor="right"
			open={open}
			classes={{
				paper: classes.drawerPaper,
			}}
			id="filterbar"
		>
			<div className={classes.drawerHeader}>
				<IconButton onClick={() => setOpen(false)} label="Close" aria-label="Close">
					<CloseIcon />
				</IconButton>
				<div className={classes.grow} />
				<Typography className={classes.filterDrawerTitleText} variant="h6">
					Filters
				</Typography>
			</div>
			<Divider />
			<List>
				{hasSelectedFilters ? (
					<>
						<ListItem>
							<IconButton
								className={classes.buttonItem}
								onClick={() => filters.resetFilters()}
							>
								<ClearAllIcon className={classes.navItemIcon}></ClearAllIcon>
								<Typography className={classes.navItemText}>
									Reset All Filters
								</Typography>
							</IconButton>
						</ListItem>
						<Divider />
					</>
				) : null}
				<ListItem>
					<DistanceFilter
						filterValue={filters.distanceFilter.filterValue}
						buttonIsOpen={filters.distanceFilter.buttonIsOpen}
						buttonContent={filters.distanceFilter.buttonContent}
						toggle={filters.distanceFilter.toggle}
						handleFilterChange={filters.distanceFilter.handleFilterChange}
						classes={classes}
					/>
				</ListItem>
				<ListItem>
					<CategoryFilter
						filterValue={filters.categoryFilter.filterValue}
						categories={filters.categoryFilter.categories}
						buttonIsOpen={filters.categoryFilter.buttonIsOpen}
						buttonContent={filters.categoryFilter.buttonContent}
						category={filters.categoryFilter.category}
						toggle={filters.categoryFilter.toggle}
						handleFilterChange={filters.categoryFilter.handleFilterChange}
						classes={classes}
					/>
				</ListItem>
				<ListItem>
					<RegionFilter
						regions={filters.regionFilter.regions}
						buttonIsOpen={filters.regionFilter.buttonIsOpen}
						filterValue={filters.regionFilter.filterValue}
						buttonContent={filters.regionFilter.buttonContent}
						region={filters.regionFilter.region}
						toggle={filters.regionFilter.toggle}
						handleFilterChange={filters.regionFilter.handleFilterChange}
						classes={classes}
					/>
				</ListItem>
				<ListItem>
					<FacilityFilter
						filterValue={filters.facilityFilter.filterValue}
						facilities={filters.facilityFilter.facilities}
						buttonIsOpen={filters.facilityFilter.buttonIsOpen}
						buttonContent={filters.facilityFilter.buttonContent}
						facility={filters.facilityFilter.facility}
						toggle={filters.facilityFilter.toggle}
						handleFilterChange={filters.facilityFilter.handleFilterChange}
						classes={classes}
					/>
				</ListItem>
			</List>
		</Drawer>
	);
}

export default function FilterBar({ hasSearchTerm, sorting, filters }) {
	const classes = useStyles();
	const [sortOpen, setSortOpen] = React.useState(false);
	const [filtersOpen, setFiltersOpen] = React.useState(false);
	const [sortValue, setSortValue] = React.useState(
		sorting && sorting.value
			? sorting.value
			: hasSearchTerm
			? attractionListSortTypeEnums.NoSort.value
			: attractionListSortTypeEnums.DistanceAsc.value
	);

	const sortOptions = attractionListSortTypeEnumList;

	const categoryFilterAmount = filters.categoryFilter.filterValue.length;
	const regionFilterAmount = filters.regionFilter.filterValue.length;
	const facilityFilterAmount = filters.facilityFilter.filterValue.length;
	const distanceFilterAmount =
		filters.distanceFilter.filterValue === distanceFilterValues.AnyDistance.name
			? 0
			: 1;

	const numberOfSelectedFilters =
		categoryFilterAmount + regionFilterAmount + facilityFilterAmount + distanceFilterAmount;
	const hasSelectedFilters = numberOfSelectedFilters > 0;

	const sortOption = sortOptions.find((x) => x.value === sortValue);
	const badgeText = sortOption
		? sortOption.badgeText
		: attractionListSortTypeEnumList.NoSort.badgeText;

	return (
		<div className={classes.root}>
			<AppBar
				position="fixed"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: sortOpen,
				})}
			>
				<Toolbar className="container">
					<Badge badgeContent={badgeText} color="secondary" variant="standard">
						<IconButton
							edge="start"
							className={classes.navItem}
							onClick={() => setSortOpen(!sortOpen)}
						>
							<SortIcon className={classes.navItemIcon} />
							<Typography className={classes.navItemText} variant="h6" noWrap>
								Sort
							</Typography>
						</IconButton>
					</Badge>
					<div className={classes.grow} />
					<Badge
						className={classes.badge}
						badgeContent={numberOfSelectedFilters}
						invisible={!hasSelectedFilters}
						color="secondary"
					>
						<IconButton
							edge="start"
							className={classes.navItem}
							onClick={() => setFiltersOpen(!filtersOpen)}
						>
							<Typography className={classes.navItemText} variant="h6" noWrap>
								Filters
							</Typography>
							<FilterListIcon className={classes.navItemIcon} />
						</IconButton>
					</Badge>
				</Toolbar>
			</AppBar>
			<SortDrawer
				classes={classes}
				open={sortOpen}
				setOpen={setSortOpen.bind(this)}
				sortOptionsDto={{
					sortText: sortOptions.find((x) => x.value === sortValue).displayName,
					sortValue: sortValue,
					handleSortChange: (event) => {
						const tryParsedValue = tryParseNumber(event.target.value);
						setSortValue(tryParsedValue);
						sorting.handleChange(tryParsedValue);
					},
				}}
			/>
			<FilterDrawer
				classes={classes}
				open={filtersOpen}
				setOpen={setFiltersOpen.bind(this)}
				filters={filters}
				hasSelectedFilters={hasSelectedFilters}
			/>
			<Backdrop
				className={classes.backdrop}
				open={sortOpen || filtersOpen}
				onClick={() => {
					if (sortOpen) setSortOpen(false);
					if (filtersOpen) setFiltersOpen(false);
				}}
			/>
			<main
				className={clsx(classes.content, {
					[classes.contentShift]: sortOpen,
				})}
			>
			</main>
		</div>
	);
}
