// Other Stuff
import '../App.scss';

// Package Imports
import React, { Component } from "react";
import { Route } from 'react-router';

// Dayhoot Components
import { PageLoading } from '../components/common-components';

// Pages
import Privacy from '../pages/privacy';
import Cookies from '../pages/cookies';
import Accessibility from'../pages/accessibility';
import Contact from '../pages/contact';
import Settings from'../pages/settings';
import Policies from'../pages/policies';
import about from '../pages/about';
import { InfoNav } from '../components/infoPage-components';

class Info extends Component {

    constructor(props){
        super(props);

        this.state = {
            isPageLoaded: false
        };
    }   

    componentDidMount() {
        this.setState({ isPageLoaded: true });
    }
    
    render(){
        if (this.state.isPageLoaded){
            return (
                <div>    
                    <div id="root"></div>       
                    <div className="container mt-3">
                        <div className="row">
                            <div className="col-12">         
                                <InfoNav activePage={this.props.location.pathname} />
                                <div>
                                    <Route path="/about/" component={about}/>
                                    <Route path="/policies/" exact component={Policies} />
                                    <Route path="/policies/privacy/" component={Privacy}/>
                                    <Route path="/policies/cookies/" component={Cookies}/>
                                    <Route path="/policies/accessibility/" component={Accessibility}/>
                                    <Route path="/contact/" component={Contact}/>
                                    <Route path="/settings/" component={Settings}/>
                                </div>                          
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else return (<PageLoading/>)
    }
}

export default Info;