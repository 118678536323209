// Dayhoot Functions & Scripts
import { getGoogleMapsUrl } from "../scripts/utils";

export function MapPopupContent({data}){
    const { name, region, latitude, longitude } = data;
    const googleMapsUrl = getGoogleMapsUrl({latitude: latitude, longitude: longitude});
    return (
        <div className="text-center">
            <div className="maplabels m-0 wordbreak"><b id="mapLabelName">{name}</b></div>
            <div className="maplabels pb-2 wordbreak" id="mapLabelRegion">{region}</div>
            <div className="maplabels">
                <button className="btn btn-sm btn-success">
                    <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">Get Directions</a>
                </button>
            </div>
        </div>
    )
}