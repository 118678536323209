import { ReactElement } from "react";

type NumberOfDayhootsTextProps = {
  hasSelectedFilters: boolean;
  numberOfResults: number;
  textAlign: "left" | "right" | "none" | "inline-start" | "inline-end";
};

const NumberOfDayhootsText = ({
  hasSelectedFilters,
  numberOfResults,
  textAlign,
}: NumberOfDayhootsTextProps): ReactElement | null => {
  return hasSelectedFilters ? (
    <em
      className="attraction-list-results-text"
      style={{ fontSize: "0.8em", float: textAlign }}
    >
      {numberOfResults} matching Dayhoot{numberOfResults === 1 ? "" : "s"}
    </em>
  ) : null;
};
export default NumberOfDayhootsText;
