import React, { Component } from "react";
import { withCookies } from "react-cookie";
import {
  AdminFiltering,
  AdminTable,
} from "../components/admin-core-components";
import { PageLoading } from "../components/common-components";
import RouterBreadcrumbs from "../components/routerBreadcrumbs";
import { Toolbar } from "../components/toolbar-components";
import { getMatchingPageValueString, setPageTitleText } from "../scripts/utils";
import { renderAdminList } from "../admin-functions/lists";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { NavLink, Redirect } from "react-router-dom";
import { AdminPageConfigs } from "../config/adminPaths";
import { adminObjectTypes } from "../config/enums";

class AdminList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPageLoaded: false,
      results: [],
      metadata: null,
      isAuthenticated: false,
      token: null,
      searchTerm: "",
      page: 1,
      adminListPage: null,
    };
  }

  getAdminListPage() {
    const matchingPageValue = getMatchingPageValueString(
      this.props.location.pathname
    );
    return AdminPageConfigs.find(x => {
      return x.type === adminObjectTypes.List && x.value === matchingPageValue;
    });
  }

  async componentDidMount() {
    const adminListPage = this.getAdminListPage();
    setPageTitleText(`Admin ${adminListPage.label}`);
    this.handleChange({ adminListPage: adminListPage });
    this.setState({ adminListPage: adminListPage });
  }

  async renderData({ adminListPage, searchTerm, page, pageSize, limit }) {
    const { cookies, location } = this.props;
    return await renderAdminList({
      adminListPage: adminListPage,
      listProps: {
        cookies: cookies,
        searchTerm,
        page,
        pageSize,
        limit,
        pathname: location.pathname,
      },
    });
  }

  async handleChange({ adminListPage, searchTerm, page }) {
    const resetPage = !!searchTerm && this.state.page !== 1;
    const { results, metadata, isAuthenticated, token, createLink } =
      await this.renderData({
        adminListPage: adminListPage || this.state.adminListPage,
        searchTerm: searchTerm || this.state.searchTerm,
        page: resetPage ? 1 : page || this.state.page,
      });

    this.setState({
      isPageLoaded: true,
      results: results,
      metadata: metadata,
      isAuthenticated: isAuthenticated,
      token: token,
      page: page || this.state.page,
      createLink: createLink,
    });
  }

  async handlePageChange(event, newPage) {
    this.setState({ isPageLoaded: false });
    this.handleChange({ page: newPage });
  }

  render() {
    if (this.state.isPageLoaded) {
      if (this.state.isAuthenticated) {
        const { adminListPage, metadata, results, createLink } = this.state;
        const { totalPages, currentPage } = metadata;

        return (
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <h1>{adminListPage.label}</h1>
              </div>
              <div className="col-8 col-sm-9 col-md-10 pt-2">
                <RouterBreadcrumbs pathName={this.props.location.pathname} />
              </div>
              <div className="col-4 col-sm-3 col-md-2" style={{textAlign: "right"}}>
                {createLink && createLink !== "/" ? (
                  <NavLink to={createLink}>
                    <button className="btn btn-sm btn-primary">
                      <FontAwesomeIcon icon={faPlus} />
                      <span className="pl-1"><span className="d-none d-sm-inline">Create</span> New</span>
                    </button>
                  </NavLink>
                ) : null}
              </div>
              <div className="col-12 mt-2">
                <div className="card">
                  <div className="card-body">
                    <div>
                      <AdminFiltering
                        label={adminListPage.searchLabel}
                        searchFieldName={adminListPage.searchFieldName}
                        searchPlaceholder={adminListPage.searchPlaceholder}
                        metadata={metadata}
                        handlePageChange={this.handlePageChange.bind(this)}
                        handleDataChange={this.handleChange.bind(this)}
                      />
                      <AdminTable items={results} />
                      <Toolbar
                        numberOfPages={totalPages}
                        currentPage={currentPage}
                        handleChange={this.handlePageChange.bind(this)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else return <Redirect to="/admin" />;
    } else return <PageLoading />;
  }
}

export default withCookies(AdminList);
