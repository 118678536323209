// Package Imports
import React, { Component } from "react";

// Dayhoot Components
import { RegionTileList } from "../components/regions-components";
import { PageLoading } from "../components/common-components";
import { PageTitle } from "../components";

// Dayhoot APIs
import { getFilteredRegions } from "../scripts/api-core";

// Dayhoot Functions & Scripts
import { setPageTitleText } from "../scripts/utils";

// Dayhoot Config
import { regionSortTypes } from "../config/enums";

class Regions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      regions: [],
      isPageLoaded: false,
    };
  }

  async componentDidMount() {
    setPageTitleText("Regions");
    const { results: regions } = await getFilteredRegions({
      sortOptionValue: regionSortTypes.AlphabeticalAsc.value,
    });
    this.setState({
      regions: regions,
      isPageLoaded: true,
    });
  }

  render() {
    const { isPageLoaded, regions } = this.state;
    if (isPageLoaded) {
      return (
        <div>
          <div id="root"></div>
          <div className="container mt-3">
            <PageTitle headingId={"regionHeader"} headingText={"Regions"} />
            <RegionTileList regions={regions} />
          </div>
        </div>
      );
    } else return <PageLoading />;
  }
}

export default Regions;
