import { ReactElement } from "react";
import { Carousel, CarouselItem } from "../../components";
import { WebConfig } from "../../config/webconfig";

const FeaturedBannerCarousel = (): ReactElement | null => {
  return WebConfig.enableFeaturedBanner ? (
    <Carousel>
      <CarouselItem banner="social" />
      <CarouselItem banner="museums" />
      <CarouselItem banner="adventuresAndExperiences" />
      <CarouselItem banner="aquariums" />
      <CarouselItem banner="themeparks" />
      <CarouselItem banner="mailingList" />
      <CarouselItem banner="zoos" />
    </Carousel>
  ) : null;
};
export default FeaturedBannerCarousel;
