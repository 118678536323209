// Package Imports
import { Modal } from "react-bootstrap";

// Fonts & Icons
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ModalFooter({onHide}){
    return (
      <Modal.Footer>
        <button className="btn btn-primary btn-sm mr-auto" onClick={onHide}>
          <FontAwesomeIcon className="mr-2" icon={faTimes} />
          <span>Close</span>
        </button>
      </Modal.Footer>
    )
}
  
export function DayhootStandardModal(props){
    const { headerContent, bodyContent, ...data } = props.data;
  
    const modalHeader = headerContent ? (
      <Modal.Header closeButton>
        <Modal.Title>{headerContent}</Modal.Title>
      </Modal.Header>
    ) : null;
    
    return (
      <Modal {...data} size="lg">
        {modalHeader}
        <Modal.Body>
          <div className="modal-container">
          {bodyContent || 'Modal Body'}
          </div>
        </Modal.Body>
        <ModalFooter onHide={data.onHide} />
      </Modal>
    )
  }
  
  export function DayhootVerticallyCenteredModal(props) {
    const { headerContent, bodyContent, ...data } = props.data;
      return (
        <Modal
          {...data}
          size="lg"
          aria-labelledby="contained-modal-title-vtop"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vtop">
                {headerContent || 'Modal Heading'}
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {bodyContent || 'Modal Body'}
          </Modal.Body>
          <ModalFooter onHide={data.onHide} />
        </Modal>
      );
    }