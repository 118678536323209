// Package Imports
import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { isEmptyObject } from "jquery";

// Dayhoot Config
import {
  attractionsFilterType,
  distanceFilterValueList,
  distanceFilterValues,
  attractionListSortTypeEnums,
} from "../../config/enums";
import { WebConfig } from "../../config/webconfig";

// Dayhoot Functions & Scripts
import { updateFilterSelection } from "../../component-functions/filterPanel-core";
import {
  getAllCategories,
  getAllFacilities,
  getAllRegions,
  getFilteredAttractions,
} from "../../scripts/api-core";
import {
  convertMilesToKilometers,
  setPageTitleText,
} from "../../scripts/utils";
import {
  hasOnlyPreSelectFilterSelected,
  prepareFilters,
  toggleFilterPanelContainer,
  swapButton,
  getHasSelectedFilters,
  setTitleText,
  getAttractionListUrlQueryStringParams,
  getPreSelectedCategoryName,
  getPreSelectedRegionName,
  handleChangePageQueryUrl,
  handleSortOptionChangeQueryUrl,
  handleWithinMilesChangeQueryUrl,
  handleFilterChangeQueryUrl,
  getFilterValues,
  setPageRenderData,
  tryGetPreSelectValue,
  getSelectedFilters,
  getPreSelectedFacilityName,
} from "../../component-functions/allAttractions-core";

// Dayhoot Components
import { PageLoading } from "../../components/common-components";
import { Toolbar } from "../../components/toolbar-components";
import { FilterPanelContainer } from "../../components/allAttractions-components";

// Fonts & Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import FilterBar from "../../components/attractionList-filterbar";
import { AttractionsBody, AttractionsHeaderSection } from ".";

class Attractions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPageLoaded: false,
      cookies: props.cookies.getAll(),
      title: "",
      pageHeader: "attractionsPageHeader",
      // Page Data
      attractions: [],
      categories: [],
      regions: [],
      // Pages
      currentPage: 1,
      numberOfPages: 1,
      numberOfResults: 0,
      // Filters (General)
      isFiltersOpen: false,
      hasSelectedFilters: false,
      preSelect: null,
      queryStringParams: getAttractionListUrlQueryStringParams(
        this.props.location.search
      ),
      searchTerm: null,
      // Distance Filter
      withinMiles: null,
      distanceFilterValue: distanceFilterValues.AnyDistance.name,
      distanceButtonContent: <FontAwesomeIcon icon={faChevronDown} />,
      distanceButtonIsOpen: false,
      // Category Filter
      categoryFilterValues: [],
      categoryButtonContent: <FontAwesomeIcon icon={faChevronDown} />,
      categoryButtonIsOpen: false,
      // Region Filter
      regionFilterValues: [],
      regionButtonContent: <FontAwesomeIcon icon={faChevronDown} />,
      regionButtonIsOpen: false,
      // Facility Filter
      facilityFilterValues: [],
      facilityButtonContent: <FontAwesomeIcon icon={faChevronDown} />,
      facilityButtonIsOpen: false,
      // Sort
      sortOptionValue: attractionListSortTypeEnums.DistanceAsc.value,
      // Style Classes
      attractionsListContainerClass: `col-sm-6 col-lg-4 col-xl-4 mb-2 attractionItemContainer`,
      filterPanelContainerDisplay: "none",
    };
    this.convertMilesToKilometers = convertMilesToKilometers.bind(this);
    this.updateFilterSelection = updateFilterSelection.bind(this);
  }

  async componentDidMount() {
    setPageTitleText("Attractions");
    this.renderData({});
  }

  async componentDidUpdate(prevProps) {
    const { resetFilters, updateQueryString } = setPageRenderData({
      prevProps: prevProps,
      currProps: this.props,
    });

    if (resetFilters || updateQueryString) {
      this.setState({ isPageLoaded: false });
      this.renderData({
        resetFilters: resetFilters,
        updateQueryString: updateQueryString,
      });
    }
  }

  async renderData({ resetFilters, updateQueryString }) {
    const { categoryFilterValues, regionFilterValues, facilityFilterValues } =
      this.state;

    const preSelect = this.props.match.params;
    const queryStringUrl = this.props.location.search;
    let queryStringParams = this.state.queryStringParams;

    if (updateQueryString)
      queryStringParams = getAttractionListUrlQueryStringParams(queryStringUrl);

    const preSelectedCategory = tryGetPreSelectValue({
      preSelect: preSelect,
      type: attractionsFilterType.Category,
    });

    const preSelectedRegion = tryGetPreSelectValue({
      preSelect: preSelect,
      type: attractionsFilterType.Region,
    });

    const preSelectedFacility = tryGetPreSelectValue({
      preSelect: preSelect,
      type: attractionsFilterType.Facility,
    });

    const { results: allFacilities } = await getAllFacilities();
    const { results: categories } = await getAllCategories();
    const { results: regions } = await getAllRegions();

    // This function will take the preselect values and URL values to apply
    //    the necessary filters for the attraction list
    const { mappedFilters, selectedFilters } = await prepareFilters({
      preSelect: preSelect,
      pageData: {
        categories: categories,
        regions: regions,
        facilities: allFacilities,
      },
      filtersToSelect: {
        categoryFilters: getFilterValues({
          preSelectValue: preSelectedCategory,
          urlValues: queryStringParams.category,
          resetFilters: resetFilters,
        }),
        regionFilters: getFilterValues({
          preSelectValue: preSelectedRegion,
          urlValues: queryStringParams.region,
          resetFilters: resetFilters,
        }),
        facilityFilters: getFilterValues({
          preSelectValue: preSelectedFacility,
          urlValues: queryStringParams.facility,
          resetFilters: resetFilters,
        }),
      },
      selectedFilters: {
        categoryFilterValues: getSelectedFilters({
          filterValues: categoryFilterValues,
          preSelectedFilter: preSelectedCategory,
          resetFilters: resetFilters,
        }),
        regionFilterValues: getSelectedFilters({
          filterValues: regionFilterValues,
          preSelectedFilter: preSelectedRegion,
          resetFilters: resetFilters,
        }),
        facilityFilterValues: getSelectedFilters({
          filterValues: facilityFilterValues,
          preSelectedFilter: preSelectedFacility,
          resetFilters: resetFilters,
        }),
      },
    });

    const hasSearchTerm =
      !isEmptyObject(preSelect) && !isEmptyObject(preSelect.search);
    // TODO: we probably want to use the URL method going forward but this atleast covers
    //       it if not going the 'proper' way
    let tempSearchTerm = hasSearchTerm
      ? preSelect.search
      : queryStringParams.search;

    let sortOptionValue = WebConfig.defaultAttractionListSort;
    if (queryStringParams.sortOptionValue != null) {
      sortOptionValue = queryStringParams.sortOptionValue;
    } else if (hasSearchTerm) {
      sortOptionValue = attractionListSortTypeEnums.NoSort.value;
    }
    // First we check the URL for this value and if one isn't set, then we take the default
    const distanceFilterValue = queryStringParams.withinMiles
      ? distanceFilterValueList.find(
          (x) => x.value === queryStringParams.withinMiles
        )
      : null;

    const { attractions, metadata } = await getFilteredAttractions({
      categories: mappedFilters.categories,
      regions: mappedFilters.regions,
      facilities: mappedFilters.facilities,
      page: queryStringParams.page,
      withinMiles: queryStringParams.withinMiles,
      cookies: this.props.cookies,
      sortOptionValue: sortOptionValue,
      excludeId: null,
      groupId: null,
      coordinates: null,
      pageSize: null,
      searchTerm: tempSearchTerm,
    });

    this.setState({
      isPageLoaded: true,
      title: setTitleText({
        filters: mappedFilters,
        hasAttractions: attractions.length > 0,
        category: getPreSelectedCategoryName(preSelect, mappedFilters),
        region: getPreSelectedRegionName(preSelect, mappedFilters),
        facility: getPreSelectedFacilityName(preSelect, mappedFilters),
        search: tempSearchTerm,
      }),
      // Page Data
      attractions: attractions,
      categories: mappedFilters.categories,
      regions: mappedFilters.regions,
      facilities: mappedFilters.facilities,
      // Pages
      currentPage: metadata.currentPage,
      numberOfPages: metadata.totalPages,
      numberOfResults: metadata.totalResults,
      // Filters (General)
      searchTerm: preSelect.search,
      queryStringParams: queryStringParams,
      hasSelectedFilters: getHasSelectedFilters({
        distanceFilterValue: distanceFilterValue,
        selectedFilters: selectedFilters,
      }),
      distanceFilterValue: distanceFilterValue
        ? distanceFilterValue.name
        : distanceFilterValues.AnyDistance.name,
      categoryFilterValues: selectedFilters.categories,
      regionFilterValues: selectedFilters.regions,
      facilityFilterValues: selectedFilters.facilities,
      // Sort
      sortOptionValue: sortOptionValue,
    });
  }

  toggleDistance() {
    this.setState({
      distanceButtonContent: swapButton(this.state.distanceButtonIsOpen),
      distanceButtonIsOpen: !this.state.distanceButtonIsOpen,
    });

    if (!this.state.distanceButtonIsOpen) {
      if (this.state.categoryButtonIsOpen) this.toggleCategory();
      if (this.state.regionButtonIsOpen) this.toggleRegion();
      if (this.state.facilityButtonIsOpen) this.toggleFacility();
    }
  }

  toggleCategory() {
    this.setState({
      categoryButtonContent: swapButton(this.state.categoryButtonIsOpen),
      categoryButtonIsOpen: !this.state.categoryButtonIsOpen,
    });

    if (!this.state.categoryButtonIsOpen) {
      if (this.state.distanceButtonIsOpen) this.toggleDistance();
      if (this.state.regionButtonIsOpen) this.toggleRegion();
      if (this.state.facilityButtonIsOpen) this.toggleFacility();
    }
  }

  toggleRegion() {
    this.setState({
      regionButtonContent: swapButton(this.state.regionButtonIsOpen),
      regionButtonIsOpen: !this.state.regionButtonIsOpen,
    });

    if (!this.state.regionButtonIsOpen) {
      if (this.state.distanceButtonIsOpen) this.toggleDistance();
      if (this.state.categoryButtonIsOpen) this.toggleCategory();
      if (this.state.facilityButtonIsOpen) this.toggleFacility();
    }
  }

  toggleFacility() {
    this.setState({
      facilityButtonContent: swapButton(this.state.facilityButtonIsOpen),
      facilityButtonIsOpen: !this.state.facilityButtonIsOpen,
    });

    if (!this.state.facilityButtonIsOpen) {
      if (this.state.distanceButtonIsOpen) this.toggleDistance();
      if (this.state.regionButtonIsOpen) this.toggleRegion();
      if (this.state.categoryButtonIsOpen) this.toggleCategory();
    }
  }

  // Only currently used by reset all filters
  async tryCloseAllFilters() {
    if (this.state.distanceButtonIsOpen) this.toggleDistance();
    if (this.state.categoryButtonIsOpen) this.toggleCategory();
    if (this.state.regionButtonIsOpen) this.toggleRegion();
    if (this.state.facilityButtonIsOpen) this.toggleFacility();
  }

  // This removes all filters that have been selected in the page by the user
  //    the exceptions here being the ones passed on pre-select
  async tryResetFilters() {
    // We only want to show the page loading if there's anything to reset
    if (!isEmptyObject(this.props.location.search))
      this.setState({ isPageLoaded: false });
    this.props.history.push(this.props.location.pathname);
  }

  async handleFilterChange(filterType, filterValue) {
    this.setState({ isPageLoaded: false });

    const updatedQueryString = handleFilterChangeQueryUrl({
      queryStringParams: this.state.queryStringParams,
      filterType: filterType,
      filterValue: filterValue,
      categoryFilterValues: this.state.categoryFilterValues,
      regionFilterValues: this.state.regionFilterValues,
      facilityFilterValues: this.state.facilityFilterValues,
      preSelect: this.props.match.params,
    });

    this.props.history.push(this.props.location.pathname + updatedQueryString);
  }

  async handleWithinMilesChange(newValue) {
    this.setState({ isPageLoaded: false });

    const withinMilesEnum = distanceFilterValueList.find(
      (x) => x.name === newValue
    );

    const updatedQueryString = handleWithinMilesChangeQueryUrl({
      queryStringParams: this.state.queryStringParams,
      withinMiles: withinMilesEnum.value,
      preSelect: this.props.match.params,
    });

    this.props.history.push(this.props.location.pathname + updatedQueryString);
  }

  async handlePageChange(event, newPage) {
    this.setState({ isPageLoaded: false });

    const updatedQueryString = handleChangePageQueryUrl({
      queryStringParams: this.state.queryStringParams,
      page: newPage,
      preSelect: this.props.match.params,
    });

    this.props.history.push(this.props.location.pathname + updatedQueryString);
  }

  async handleSortOptionChange(newValue) {
    this.setState({ isPageLoaded: false });

    const updatedQueryString = handleSortOptionChangeQueryUrl({
      queryStringParams: this.state.queryStringParams,
      sortOptionValue: newValue,
      preSelect: this.props.match.params,
    });

    this.props.history.push(this.props.location.pathname + updatedQueryString);
  }

  // Toggles the filter panel open and closed and changes the attraction containers to accommodate the panel showing / hiding
  filterPanelToggle() {
    this.setState(toggleFilterPanelContainer(this.state.isFiltersOpen));
  }

  render() {
    const {
      isPageLoaded,
      title,
      pageHeader,
      // Pages
      currentPage,
      numberOfPages,
      numberOfResults,
      // Page Data
      attractions,
      categories,
      regions,
      facilities,
      // Filters (General)
      isFiltersOpen,
      hasSelectedFilters,
      searchTerm,
      // Distance Filter
      distanceFilterValue,
      distanceButtonIsOpen,
      distanceButtonContent,
      // Category Filter
      categoryFilterValues,
      categoryButtonIsOpen,
      categoryButtonContent,
      // Region Filter
      regionFilterValues,
      regionButtonIsOpen,
      regionButtonContent,
      // Facility Filter
      facilityFilterValues,
      facilityButtonIsOpen,
      facilityButtonContent,
      // Sort
      sortOptionValue,
      // Style Classes
      attractionsListContainerClass,
      filterPanelContainerDisplay,
    } = this.state;

    if (isPageLoaded) {
      const preSelect = this.props.match.params;

      return (
        <>
          <FilterBar
            hasSearchTerm={!isEmptyObject(searchTerm)}
            sorting={{
              value: sortOptionValue,
              handleChange: this.handleSortOptionChange.bind(this),
            }}
            filters={{
              resetFilters: this.tryResetFilters.bind(this),
              hasOnlyPreSelectFilterSelected: hasOnlyPreSelectFilterSelected,
              filterPanelContainerDisplay: filterPanelContainerDisplay,
              filterPanelToggle: this.filterPanelToggle.bind(this),
              distanceFilter: {
                filterValue: distanceFilterValue,
                buttonIsOpen: distanceButtonIsOpen,
                buttonContent: distanceButtonContent,
                toggle: this.toggleDistance.bind(this),
                handleFilterChange: this.handleWithinMilesChange.bind(this),
              },
              categoryFilter: {
                categories: categories,
                filterValue: categoryFilterValues,
                buttonIsOpen: categoryButtonIsOpen,
                buttonContent: categoryButtonContent,
                category: preSelect.category,
                toggle: this.toggleCategory.bind(this),
                handleFilterChange: this.handleFilterChange.bind(this),
              },
              regionFilter: {
                regions: regions,
                filterValue: regionFilterValues,
                buttonIsOpen: regionButtonIsOpen,
                buttonContent: regionButtonContent,
                region: preSelect.region,
                toggle: this.toggleRegion.bind(this),
                handleFilterChange: this.handleFilterChange.bind(this),
              },
              facilityFilter: {
                facilities: facilities,
                filterValue: facilityFilterValues,
                buttonIsOpen: facilityButtonIsOpen,
                buttonContent: facilityButtonContent,
                facility: preSelect.facility,
                toggle: this.toggleFacility.bind(this),
                handleFilterChange: this.handleFilterChange.bind(this),
              },
            }}
          />
          <div className="container" style={{ marginTop: "150px" }}>
            <AttractionsHeaderSection
              pageHeader={pageHeader}
              title={title}
              hasSelectedFilters={hasSelectedFilters}
              numberOfResults={numberOfResults}
              sortOptionValue={sortOptionValue}
              cookies={this.props.cookies}
            />
            <AttractionsBody
              filterPanelContainer={
                <FilterPanelContainer
                  filterPanelContainerDisplay={filterPanelContainerDisplay}
                  filterPanelToggle={this.filterPanelToggle.bind(this)}
                  distanceFilter={{
                    filterValue: distanceFilterValue,
                    buttonIsOpen: distanceButtonIsOpen,
                    buttonContent: distanceButtonContent,
                    toggle: this.toggleDistance.bind(this),
                    handleFilterChange: this.handleWithinMilesChange.bind(this),
                  }}
                  categoryFilter={{
                    categories: categories,
                    filterValue: categoryFilterValues,
                    buttonIsOpen: categoryButtonIsOpen,
                    buttonContent: categoryButtonContent,
                    category: preSelect.category,
                    toggle: this.toggleCategory.bind(this),
                    handleFilterChange: this.handleFilterChange.bind(this),
                  }}
                  regionFilter={{
                    regions: regions,
                    filterValue: regionFilterValues,
                    buttonIsOpen: regionButtonIsOpen,
                    buttonContent: regionButtonContent,
                    region: preSelect.region,
                    toggle: this.toggleRegion.bind(this),
                    handleFilterChange: this.handleFilterChange.bind(this),
                  }}
                  facilityFilter={{
                    facilities: facilities,
                    filterValue: facilityFilterValues,
                    buttonIsOpen: facilityButtonIsOpen,
                    buttonContent: facilityButtonContent,
                    facility: preSelect.facility,
                    toggle: this.toggleFacility.bind(this),
                    handleFilterChange: this.handleFilterChange.bind(this),
                  }}
                />
              }
              isFiltersOpen={isFiltersOpen}
              numberOfResults={numberOfResults}
              attractions={attractions}
              attractionsListContainerClass={attractionsListContainerClass}
              cookies={this.props.cookies}
              hasSelectedFilters={hasSelectedFilters}
              searchTerm={searchTerm}
            />
            <Toolbar
              numberOfPages={numberOfPages}
              handleChange={this.handlePageChange.bind(this)}
              currentPage={currentPage}
            />
          </div>
        </>
      );
    } else
      return (
        <PageLoading loadingMessage={"Fetching some incredible Dayhoots..."} />
      );
  }
}

export default withCookies(Attractions);
