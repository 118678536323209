import { stepTypes, wizardStateEnums } from "../config/manageAttraction-config";

export function getWizardState(stepType, stateType) {
  return stepType === stepTypes.PastStep
    ? wizardStateEnums.Complete
    : stepType === stepTypes.CurrentStep
    ? stateType
    : stepType === stepTypes.ConfirmStep
    ? wizardStateEnums.ConfirmLocked
    : wizardStateEnums.Incomplete;
}

export function getValidationStateType(isValid, hasErrors) {
  return isValid
    ? wizardStateEnums.Complete
    : !isValid && !hasErrors
    ? wizardStateEnums.InProgress
    : wizardStateEnums.Invalid;
}

export function getStepType(userStep, elementStep) {
  return userStep > elementStep
    ? stepTypes.PastStep
    : userStep === elementStep
    ? stepTypes.CurrentStep
    : elementStep === userStep + 1
    ? stepTypes.NextStep
    : stepTypes.FutureStep;
}

export function getMainCategoryDropdownOptions(selectedCategories) {
  let options = [
    {
      categoryName: "N/A",
      categoryPageTitle: "Choose...",
    },
  ];
  if (selectedCategories) {
    selectedCategories.forEach(sc => options.push(sc));
  }
  return options;
}

export function updateSelectedCategories({ checked, val, selectedCategories }) {
  if (!checked) {
    selectedCategories.splice(selectedCategories.indexOf(val), 1);
  } else selectedCategories.push(val);
  return selectedCategories;
}

export function updateSelectedFacilities({
  checked,
  val,
  selectedFacilities,
  updateFacilities
}) {
  if (!checked) {
    const index = selectedFacilities
      .map(sf => sf.facilityName)
      .indexOf(val.facilityName);
    selectedFacilities.splice(index, 1);
  } else selectedFacilities.push(val);
  updateFacilities(selectedFacilities);
  return selectedFacilities;
}

export function getFacilityValue(facility, attraction) {
  if (attraction.facilities.some(f => f === facility.id)) return "YES";
  else if (attraction.facilityadvisories.some(f => f === facility.id))
    return "PARTIAL";
  else if (attraction.unavailablefacilities.some(f => f === facility.id))
    return "NO";
  else if (attraction.unknownfacilities.some(f => f === facility.id))
    return "N/A";
  else return "N/A";
}
