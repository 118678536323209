// Package Imports
import React from "react";

// Dayhoot Functions & Scripts
import { citiesList } from "../config/citiesList";
import { updateGaDisableFlag } from "../scripts/dh-analytics";

import {
  cookieDictionary,
  getCookie,
  setCookie,
  deleteCookie,
} from "../storage/cookies";

// Dayhoot Components
import { CitiesDropdownList } from "../components/settings-components";

export function toggleLocationPopupElement() {
  const locationElement = document.getElementById("locationPopup");
  if (locationElement)
    locationElement.style.display === "none"
      ? (locationElement.style.display = "block")
      : (locationElement.style.display = "none");
}

export function getSettingsConfig(data) {
  const { values, handleFormChange, citiesList } = data;
  const cookiesAreDisabled = values.acceptedPersonalCookies === false;
  return {
    cookieSettingsDto: {
      personalCookiesDto: {
        id: "acceptedPersonalCookies",
        labelText: "Dayhoot personalisation and settings preferences",
        accessibilityText: "Accept Personalisation Cookies",
        value: values.acceptedPersonalCookies,
        onChange: handleFormChange,
        defaultChecked: values.acceptedPersonalCookies,
      },
      analyticsCookiesDto: {
        id: "acceptedAnalyticsCookies",
        labelText: "Analytics and performance statistics (Third-party)",
        accessibilityText: "Accept Analytics Cookies",
        value: values.acceptedAnalyticCookies,
        onChange: handleFormChange,
        defaultChecked: values.acceptedAnalyticCookies,
      },
      advertCookies: {
        id: "acceptedAdvertCookies",
        labelText: "Advertisments and tailored content (Third-party)",
        accessibilityText: "Accept Advertisment Cookies",
        value: values.acceptedAdvertCookies,
        onChange: handleFormChange,
        defaultChecked: values.acceptedAdvertCookies,
      },
    },
    locationSettingsDto: {
      defaultUserCookie: {
        useGeolocationData: values.useGeolocationData,
        userLocation: values.userLocation,
        userDefaultLocation: values.defaultUserLocation,
        userLatitude: values.userLatitude,
        userLongitude: values.userLongitude,
        titleText: "What location data am I using?",
      },
      useGeolocationCookie: {
        id: "useGeolocation",
        labelText: "Use precise location data from Geolocation where available",
        accessibilityText: "Accept Advertisment Cookies",
        value: values.useGeolocationData,
        onChange: handleFormChange,
        defaultChecked: values.useGeolocationData,
      },
      locationDropdownDto: {
        titleText:
          "Set a city to use as the default user location when Geolocation is not enabled:",
        dropdownList: (
          <CitiesDropdownList
            data={{
              values: values,
              citiesList: citiesList,
              handleFormChange: handleFormChange,
            }}
          />
        ),
      },
    },
    personalisationSettingsDto: {
      values: values,
      handleFormChange: handleFormChange,
      cookiesAreDisabled: cookiesAreDisabled,
      darkModeCookieDto: {
        id: "darkMode",
        labelText: "Enable the dark mode colour scheme",
        accessibilityText: "Enable Dark Mode",
        value: values.darkMode,
        onChange: handleFormChange,
        defaultChecked: values.darkMode,
        disabled: cookiesAreDisabled,
      },
    },
  };
}

export function getDefaultUserCityIndexFromCityList(props) {
  const { citiesList, defaultUserLocation } = props;
  return citiesList.map((c) => c.cityName).indexOf(defaultUserLocation);
}

export function getValuesFromCookies(cookies) {
  const userAcknowledgedCookies = getCookie(
    cookies,
    cookieDictionary.CookiePolicyAcknowledged.key
  );

  const userAcceptedSettingsCookies = getCookie(
    cookies,
    cookieDictionary.ApplicationCookiesAndLocalStorageUseageAccepted.key
  );

  const userAcceptedAnalyticCookies = getCookie(
    cookies,
    cookieDictionary.AnalyticsCookieUseageAccepted.key
  );

  const userAcceptedAdvertCookies = getCookie(
    cookies,
    cookieDictionary.AdvertisingCookieUseageAccepted.key
  );

  const userUseGeolocationData = getCookie(
    cookies,
    cookieDictionary.SettingShouldUseGeolocation.key
  );

  const userDefaultLocation = getCookie(
    cookies,
    cookieDictionary.SettingDefaultUserLocationName.key
  );

  const userLocation = getCookie(
    cookies,
    cookieDictionary.SettingUserLocationName.key
  );

  const userLatitude = getCookie(
    cookies,
    cookieDictionary.SettingUserLocationLatitude.key
  );

  const userLongitude = getCookie(
    cookies,
    cookieDictionary.SettingUserLocationLongitude.key
  );

  const userDistanceUnitKm = getCookie(
    cookies,
    cookieDictionary.SettingUserDistanceUnitKm.key
  );

  return {
    acknowledgedCookies: userAcknowledgedCookies,
    useGeolocationData: userUseGeolocationData,
    defaultUserLocation: userDefaultLocation,
    acceptedPersonalCookies: userAcceptedSettingsCookies,
    acceptedAnalyticCookies: userAcceptedAnalyticCookies,
    acceptedAdvertCookies: userAcceptedAdvertCookies,
    distanceUnitKm: userDistanceUnitKm,
    userLocation: userLocation,
    userLatitude: userLatitude,
    userLongitude: userLongitude,
  };
}

export function tryRemovePersonalisationCookies(values, cookies) {
  if (
    window.confirm(
      "This will delete all personalisation cookies stored by Dayhoot and remove your settings, do you wish to continue?"
    )
  ) {
    values.acceptedPersonalCookies = false;
    deleteCookie(cookies, cookieDictionary.CookiePolicyAcknowledged.key);
    deleteCookie(
      cookies,
      cookieDictionary.ApplicationCookiesAndLocalStorageUseageAccepted.key
    );
    deleteCookie(cookies, cookieDictionary.AdvertisingCookieUseageAccepted.key);
    deleteCookie(cookies, cookieDictionary.AnalyticsCookieUseageAccepted.key);
    deleteCookie(cookies, cookieDictionary.SettingShouldUseGeolocation.key);
    deleteCookie(cookies, cookieDictionary.SettingDefaultUserLocationName.key);
    deleteCookie(cookies, cookieDictionary.SettingUserLocationName.key);
    deleteCookie(cookies, cookieDictionary.SettingUserLocationLatitude.key);
    deleteCookie(cookies, cookieDictionary.SettingUserLocationLongitude.key);
    deleteCookie(cookies, cookieDictionary.SettingUserDistanceUnitKm.key);
  }
  return values;
}

export function trySetPersonalisationCookies(values, cookies) {
  values.acceptedPersonalCookies = true;
  setCookie(
    cookies,
    cookieDictionary.ApplicationCookiesAndLocalStorageUseageAccepted.key,
    true,
    cookieDictionary.ApplicationCookiesAndLocalStorageUseageAccepted
      .validForMonths
  );
  const cookieElement = document.getElementById("cookiesNotice");
  if (cookieElement != null) cookieElement.style.display = "none";
  return values;
}

export function setPersonalisationCookies(values, cookies) {
  if (values.acceptedPersonalCookies) {
    return tryRemovePersonalisationCookies(values, cookies);
  } else if (!values.acceptedPersonalCookies) {
    return trySetPersonalisationCookies(values, cookies);
  }
}

export function setAnalyticsCookies(values, cookies) {
  values.acceptedAnalyticCookies = !values.acceptedAnalyticCookies;
  setCookie(
    cookies,
    cookieDictionary.AnalyticsCookieUseageAccepted.key,
    values.acceptedAnalyticCookies,
    cookieDictionary.AnalyticsCookieUseageAccepted.validForMonths
  );
  updateGaDisableFlag(values.acceptedAnalyticCookies);
  return values;
}

export function setAdvertCookies(values, cookies) {
  values.acceptedAdvertCookies = !values.acceptedAdvertCookies;
  setCookie(
    cookies,
    cookieDictionary.AdvertisingCookieUseageAccepted.key,
    values.acceptedAdvertCookies,
    cookieDictionary.AdvertisingCookieUseageAccepted.validForMonths
  );
  return values;
}

export function setGeolocationCookies(values, cookies) {
  values.useGeolocationData = !values.useGeolocationData;
  setCookie(
    cookies,
    cookieDictionary.SettingShouldUseGeolocation.key,
    values.useGeolocationData,
    cookieDictionary.SettingShouldUseGeolocation.validForMonths
  );
  return values;
}

export function setLocationCookies(values, cookies, id) {
  const cityDetails = citiesList[id];
  values.defaultUserLocation = cityDetails.cityName.toString();

  setCookie(
    cookies,
    cookieDictionary.SettingDefaultUserLocationName.key,
    values.defaultUserLocation,
    cookieDictionary.SettingDefaultUserLocationName.validForMonths
  );

  setCookie(
    cookies,
    cookieDictionary.SettingUserLocationName.key,
    values.defaultUserLocation,
    cookieDictionary.SettingUserLocationName.validForMonths
  );

  setCookie(
    cookies,
    cookieDictionary.SettingUserLocationLongitude.key,
    cityDetails.longitude,
    cookieDictionary.SettingUserLocationLongitude.validForMonths
  );

  setCookie(
    cookies,
    cookieDictionary.SettingUserLocationLatitude.key,
    cityDetails.latitude,
    cookieDictionary.SettingUserLocationLatitude.validForMonths
  );

  return values;
}

export function setDistanceCookies(values, cookies) {
  values.distanceUnitKm = !values.distanceUnitKm;
  setCookie(
    cookies,
    cookieDictionary.SettingUserDistanceUnitKm.key,
    values.distanceUnitKm,
    cookieDictionary.SettingUserDistanceUnitKm.validForMonths
  );
  return values;
}

export function getToggleGeolocationAlertText(props) {
  const {
    useGeolocationData,
    userLatitude,
    defautlLocationDetails,
    userLongitude,
    userLocation,
    userDefaultLocation,
  } = props;

  let geolocationText;

  if (
    useGeolocationData &&
    userLatitude === defautlLocationDetails.latitude &&
    userLongitude === defautlLocationDetails.longitude
  )
    geolocationText =
      "You have disabled geolocation data or it is not supported";
  else if (!useGeolocationData)
    geolocationText =
      "You have turned off geolocation data in the Dayhoot settings";
  else if (useGeolocationData && userLocation !== userDefaultLocation)
    geolocationText =
      "You are using the precise geolocation data of your device";

  return geolocationText;
}
