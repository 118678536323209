// Package Imports
import React, { Component } from "react";
import { withCookies } from "react-cookie";

// Dayhoot Functions & Scripts
import { setPageTitleText } from "../scripts/utils";
import { getAllAttractionGroups } from "../scripts/api-core";

// Dayhoot Components
import { PageLoading } from "../components/common-components";
import { AttractionGroupTileList } from "../components/attractionGroup-components";
import { PageTitle } from "../components";

class Groups extends Component {
  state = {
    AttractionGroups: {},
    isPageLoaded: false,
    cookies: this.props.cookies.getAll(),
  };

  async componentDidMount() {
    const { attractionGroupsTileData } = await this.renderMyData();
    this.setState({
      attractionGroupTiles: attractionGroupsTileData.results,
      isPageLoaded: true,
    });
    setPageTitleText("Attraction Groups");
  }

  async renderMyData() {
    return {
      attractionGroupsTileData: await getAllAttractionGroups({}),
    };
  }

  render() {
    const { isPageLoaded, attractionGroupTiles } = this.state;
    if (isPageLoaded) {
      return (
        <div>
          <div id="root"></div>
          <div className="container mt-3 apply-ellipsis-3 wordbreak">
            <PageTitle
              headingId={"attractionGroupsTitle"}
              headingText={"Attraction Groups"}
            />
            <AttractionGroupTileList groups={attractionGroupTiles} />
          </div>
        </div>
      );
    } else return <PageLoading />;
  }
}

export default withCookies(Groups);
