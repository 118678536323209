import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { ReactElement } from "react";

type ExpandedListItemClickableProps = {
  icon: any;
  id: string;
  onClick: () => void;
  text: string;
};

const ExpandedListItemClickable = ({
  icon,
  id,
  text,
  onClick,
}: ExpandedListItemClickableProps): ReactElement => {
  return (
    <>
      <ListItem button key={text} onClick={onClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    </>
  );
};
export default ExpandedListItemClickable;
