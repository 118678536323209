// Dayhoot Config
import { attractionTabs } from "./enums";

export const attractionTabList = [
    attractionTabs.Details, 
    attractionTabs.Map, 
    attractionTabs.Discover, 
    attractionTabs.Back
];

export function getDefaultTabs(){
    return attractionTabList.map((tab, i) => {
        tab.isVisible = i === 0;
        return tab;
    });
}  