import { cookieDictionary, getCookie } from "../storage/cookies";

// Dayhoot Config
import { AnalyticsConfig } from "../config/webconfig";

export function setupGoogleAnalytics(cookies) {
  const userAcceptedGA = getCookie(
    cookies,
    cookieDictionary.AnalyticsCookieUseageAccepted.key
  );
  updateGaDisableFlag(userAcceptedGA);
  window.gtag("js", new Date());
  window.gtag("config", AnalyticsConfig.measurementId);
}

export function updateGaDisableFlag(analyticsEnabled) {
  if (analyticsEnabled) {
    window["ga-disable-" + AnalyticsConfig.measurementId] = false;
  } else {
    window["ga-disable-" + AnalyticsConfig.measurementId] = true;
  }
}
