import "../../App.scss";
import { Component } from "react";
import { withCookies } from "react-cookie";
import { isMobile, isTablet } from "react-device-detect";
import { PageLoading } from "../../components/common-components";
import { EmailBanner } from ".";
import {
  getAllAttractionGroups,
  getFilteredCategories,
  getFilteredRegions,
  getFilteredAttractions,
  getFeaturedBanner,
} from "../../scripts/api-core";
import { handleCoordinates, setPageTitleText } from "../../scripts/utils";
import { WebConfig } from "../../config/webconfig";
import {
  attractionListSortTypeEnums,
  categorySortTypes,
} from "../../config/enums";
import {
  AttractionFinder,
  FeaturedBannerCarousel,
  AttractionShowcase,
  SubmissionsBanner,
} from ".";
import { Grid } from "@mui/material";
import { cookieDictionary, getCookie } from "../../storage/cookies";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      isPageLoaded: false,
      noOfAttractions: 0,
      closestAttractions: [],
      popularAttractions: [],
      categories: [],
      regions: [],
      attractionGroupTiles: [],
      featuredBanner: null,
      cookies: props.cookies.getAll(),
    };
  }

  async componentDidMount() {
    this.setState({ isPageLoaded: false });
    const {
      noOfAttractions,
      closestAttractionData,
      popularAttractionData,
      categoryData,
      regionData,
      attractionGroupTileData,
      featuredBannerData,
    } = await this.renderMyData();

    this.setState({
      noOfAttractions: noOfAttractions,
      closestAttractions: closestAttractionData.attractions,
      popularAttractions: popularAttractionData.attractions,
      categories: categoryData,
      regions: regionData,
      attractionGroupTiles: attractionGroupTileData,
      featuredBanner: featuredBannerData,
      isPageLoaded: true,
    });

    setPageTitleText("");
  }

  async renderMyData() {
    const userLatitude = getCookie(
      this.props.cookies,
      cookieDictionary.SettingUserLocationLatitude.key
    );
    const userLongitude = getCookie(
      this.props.cookies,
      cookieDictionary.SettingUserLocationLongitude.key
    );
    const coordinates = handleCoordinates({
      latitude: userLatitude,
      longitude: userLongitude,
    });
    const { results: featuredBanners } = await getFeaturedBanner();

    const { results: categoryData } = await getFilteredCategories({
      sortOptionValue: categorySortTypes.Rank.value,
    });

    const { results: regionData } = await getFilteredRegions({
      coordinates: coordinates,
    });

    const { results: attractionGroupTileData } = await getAllAttractionGroups();

    const { metadata } = await await getFilteredAttractions({});
    const isLandscape = window.matchMedia("(orientation: portrait)").matches;

    return {
      noOfAttractions: metadata.totalResults,
      closestAttractionData: await getFilteredAttractions({
        coordinates: coordinates,
        pageSize: (isMobile || isTablet) && isLandscape ? 2 : 3,
      }),
      popularAttractionData: await getFilteredAttractions({
        sortOptionValue: attractionListSortTypeEnums.MostPopular.value,
        pageSize: (isMobile || isTablet) && isLandscape ? 2 : 3,
      }),
      categoryData: categoryData,
      regionData: regionData,
      attractionGroupTileData: attractionGroupTileData,
      featuredBannerData:
        featuredBanners && featuredBanners.length > 0
          ? featuredBanners[0]
          : null,
    };
  }

  render() {
    const {
      categories,
      closestAttractions,
      isPageLoaded,
      noOfAttractions,
      popularAttractions,
      regions,
    } = this.state;

    const { cookies } = this.props;

    if (isPageLoaded) {
      const userLocation =
        getCookie(cookies, cookieDictionary.SettingUserLocationName.key) ||
        WebConfig.defaultLocation;

      return (
        <>
          <div id="root"></div>
          <div
            className="attractionFinderContainer mb-3"
            style={{ marginTop: "-26px" }}
          >
            <div className="pt-3 pb-3 container">
              <div className="row">
                <div className="col-12">
                  <Grid
                    container
                    spacing={2}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item xs={12} lg={4}>
                          <AttractionFinder
                            noOfAttractions={noOfAttractions}
                            categories={categories}
                            regions={regions}
                          />
                        </Grid>
                        <Grid item xs={12} lg={8}>
                          <FeaturedBannerCarousel />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <AttractionShowcase
                attractions={closestAttractions}
                cookies={cookies}
                title={`Dayhoots close to ${userLocation}...`}
                to="/attractions/"
              />
            </Grid>
            {WebConfig.enableEmailBanner && (
              <Grid item>
                <EmailBanner />
              </Grid>
            )}
            <Grid item>
              <AttractionShowcase
                attractions={popularAttractions}
                cookies={cookies}
                title="Popular Dayhoots in the UK"
                to={`/attractions?sort=${attractionListSortTypeEnums.MostPopular.value}`}
              />
            </Grid>
            <Grid item>
              <SubmissionsBanner />
            </Grid>
          </Grid>
        </>
      );
    } else return <PageLoading />;
  }
}

export default withCookies(Home);
