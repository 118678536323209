import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => {
	return {
		root: {
			width: "100%",
			"& > * + *": {
				marginTop: theme.spacing(2),
			},
			color: "#fff !important",
			fill: "#fff !important",
		},
        alert: {
			color: "#fff !important",
			fill: "#fff !important",
        },
		message: {
			color: "#fff !important",
			fill: "#fff !important",
		},
	};
});

export default function CustomSnackbar({
	open,
	setOpen,
	severity,
	message,
	autoHideDuration,
}) {
	const classes = useStyles();

	const handleClose = (event, reason) => {
		if (reason === "clickaway") return;
		setOpen(false);
	};

	return (
		<div className={classes.root}>
			<Snackbar
				open={open}
				autoHideDuration={autoHideDuration || 6000}
				onClose={handleClose}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
			>
				<Alert className={classes.alert} onClose={handleClose} severity={severity}>
					<span className={classes.message}>{message}</span>
				</Alert>
			</Snackbar>
		</div>
	);
}
