import { AttractionCardFooter, AttractionCardImage } from ".";

export interface AttractionCardContentProps {
  attraction: any;
  cookies: any;
}

const AttractionCardContent = ({
  attraction,
  cookies,
}: AttractionCardContentProps) => {
  const { description, links, name } = attraction;
  return (
    <>
      <div className="card-image">
        <AttractionCardImage
          imageUrl={links?.mainImage}
          imageDescription={description}
        />
      </div>
      <div className="container mt-2 mb-2">
        <div className="row">
          <div className="col-12">
            <div className="h5 mb-2 wordbreak">{name}</div>
          </div>
          <div className="col-12">
            <AttractionCardFooter attraction={attraction} cookies={cookies} />
          </div>
        </div>
      </div>
    </>
  );
};
export default AttractionCardContent;
