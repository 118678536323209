import { useEffect } from "react";

export default function useStyle(css: string | { use: () => void; unuse: () => void }): void {
  useEffect(() => {
    let style: HTMLStyleElement;

    if (typeof css === "string") {
      style = document.createElement("style");
      style.textContent = css;

      document.head.appendChild(style);
    } else if (typeof css.use === "function") {
      css.use();
    }

    return () => {
      if (typeof css === "string") {
        document.head.removeChild(style);
      } else if (typeof css.unuse === "function") {
        css.unuse();
      }
    };
  }, [css]);
}
