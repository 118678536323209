import React from "react";
import { Button, Form, FormControl, InputGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {
  faBan,
  faEdit,
  faExclamationCircle,
  faLink,
  faTrash,
  faSearch,
  faTimes,
  faCalendar,
  faCheckCircle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format, differenceInDays } from "date-fns";

import { Toolbar } from "../components/toolbar-components";
import { InputLabel } from "@material-ui/core";

export function AdminBanner({ config }) {
  return (
    <NavLink to={config.link}>
      <div className="mb-3">
        <div
          className={`pt-4 pb-4 ${config.backgroundClass}`}
          style={{ textAlign: "center", borderRadius: "1rem" }}
        >
          <FontAwesomeIcon
            className=" text-white"
            icon={config.icon}
            style={{ fontSize: "1.3rem" }}
          />
          <b className="h1 text-white pl-1" style={{ fontSize: "1.3rem" }}>
            &nbsp;{config.label}
          </b>
        </div>
      </div>
    </NavLink>
  );
}

export function AdminSection({ config }) {
  return (
    <div>
      {config.sections.map(s => {
        return (
          <div className="row" key={s.label}>
            <div className="col-12">
              <h2>{s.label}</h2>
              <hr className="mt-2 mb-2" />
            </div>
            {s.links.map(l => {
              return (
                <div className="col-md-4" key={l.label}>
                  <AdminBanner config={l} />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

function getPostStatus(hasPosted, x) {
  let postStatus = {
    style: "text-purple",
    icon: faInfoCircle,
  };

  if (hasPosted) {
    const dateDiff = differenceInDays(new Date(), new Date(x.lastPosted));
    
    if (dateDiff < 365) {
      postStatus = {
        style: "text-green",
        icon: faCheckCircle,
      };
    } else {
      postStatus = {
        style: "text-orange",
        icon: faExclamationCircle,
      };
    }
  }
  return postStatus;
}

export function AdminTable({ items }) {
  if (items.length > 0) {
    return (
      <table className="table table-striped mt-3">
        <thead className="background-green text-white">
          <tr style={{ borderTop: "none" }}>
            <th>Name</th>
            <th style={{ textAlign: "right" }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {items.map((x, i) => {
            const hasPosted = !!x.lastPosted && x.lastPosted !== "N/A";
            const postStatus = getPostStatus(hasPosted, x);

            return (
              <tr key={i}>
                <td
                  style={{
                    verticalAlign: "middle",
                    width: "70%",
                  }}
                >
                  <p className="mb-0">
                    <b className="wordbreak">{x.name}</b>
                  </p>
                  {!!x.additionalText ? (
                    <p className="mb-0">
                      <em className="wordbreak">{x.additionalText}</em>
                    </p>
                  ) : null}
                  {!!x.lastPosted ? (
                    <p
                      className={`mb-0 mt-2 ${postStatus.style}`}
                      style={{ fontSize: "0.8em" }}
                    >
                      <FontAwesomeIcon icon={postStatus.icon} />
                      <em className="pl-1 wordbreak">
                        Last posted about on{" "}
                        {hasPosted
                          ? format(new Date(x.lastPosted), "MM/dd/yyyy")
                          : "N/A"}
                      </em>
                    </p>
                  ) : null}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    verticalAlign: "middle",
                    width: "60%",
                  }}
                >
                  {x.manageSocialLink && x.manageSocialLink !== "/" ? (
                    <NavLink
                      to={x.manageSocialLink}
                      className="btn btn-sm btn-outline-primary mr-1"
                      style={{ minWidth: "2.2rem" }}
                    >
                      <FontAwesomeIcon icon={faCalendar} />
                      <span className="ml-1 d-none d-md-inline">
                        Manage Social
                      </span>
                    </NavLink>
                  ) : null}
                  {x.viewLink !== "/" ? (
                    <NavLink
                      to={x.viewLink}
                      className="btn btn-sm btn-primary"
                      style={{ minWidth: "2.2rem" }}
                    >
                      <FontAwesomeIcon icon={faLink} />
                      <span className="ml-1 d-none d-md-inline">View</span>
                    </NavLink>
                  ) : null}
                  {x.editLink !== "/" && x.enableEdit ? (
                    <NavLink
                      to={x.editLink}
                      className="ml-1 btn btn-sm btn-primary"
                      style={{ minWidth: "2.2rem" }}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                      <span className="ml-1 d-none d-md-inline">Edit</span>
                    </NavLink>
                  ) : null}
                  {x.deactivateLink !== "/" ? (
                    <NavLink
                      to={x.deactivateLink}
                      className="ml-1 btn btn-sm btn-warning"
                      style={{ minWidth: "2.2rem" }}
                    >
                      <FontAwesomeIcon icon={faBan} />
                      <span className="ml-1 d-none d-md-inline">
                        Deactivate
                      </span>
                    </NavLink>
                  ) : null}
                  {x.deleteLink !== "/" ? (
                    <NavLink
                      to={x.deleteLink}
                      className="ml-1 btn btn-sm btn-danger"
                      style={{ minWidth: "2.2rem" }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                      <span className="ml-1 d-none d-md-inline">Delete</span>
                    </NavLink>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  } else {
    return (
      <div className="mt-2 alert alert-info">
        <FontAwesomeIcon icon={faExclamationCircle} />
        <span className="ml-1">There are no matching results.</span>
      </div>
    );
  }
}

export function AdminFiltering({
  searchPlaceholder,
  metadata,
  handlePageChange,
  handleDataChange,
}) {
  const { totalPages, currentPage } = metadata;
  const [searchInputValue, setSearchInputValue] = React.useState("");
  const hasSearchValue = searchInputValue !== "";

  return (
    <div className="row">
      <div className="col-sm-6">
        <div className="d-flex flex-row align-items-center">
          <div className="ml-auto mr-auto">
            <Toolbar
              numberOfPages={totalPages}
              currentPage={currentPage}
              handleChange={handlePageChange}
            />
          </div>
        </div>
      </div>
      <div className="col-sm-6">
        <div className="d-flex flex-column">
          <div className="p-0">
            <Form
              // This stops the page from refreshing on enter
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              <InputLabel hidden={true}>Search</InputLabel>
              <InputGroup>
                <FormControl
                  type="text"
                  placeholder={searchPlaceholder || "Search..."}
                  id="searchBox"
                  name="searchBox"
                  value={searchInputValue}
                  onChange={e => setSearchInputValue(e.target.value)}
                  style={{ borderRight: "none" }}
                  onKeyDown={val => {
                    if (val.keyCode === 13 && searchInputValue) {
                      handleDataChange({
                        searchTerm: searchInputValue,
                      });
                    }
                  }}

                  // onClick={() =>
                  // 	handleDataChange({
                  // 		searchTerm: searchInputValue,
                  // 	})
                  // }
                />
                {hasSearchValue ? (
                  <InputGroup.Append>
                    <Button
                      variant="white"
                      onClick={() => {
                        setSearchInputValue("");
                        handleDataChange({
                          searchTerm: "",
                        });
                      }}
                      className="clear-input-button"
                    >
                      <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                    </Button>
                  </InputGroup.Append>
                ) : null}
                <InputGroup.Append>
                  <Button
                    variant="primary"
                    onClick={() =>
                      handleDataChange({
                        searchTerm: searchInputValue,
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
