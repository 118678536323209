import { ReactElement, useState } from "react";
import { DayhootStandardModal } from "../modal";
import ChangeLocation from "../../pages/changeLocation";
import Settings from "../../pages/settings";
import { AppBar, Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { NavbarLogo } from "../navbar-components";
import { DesktopMenu, MenuDrawer, MobileMenu } from ".";

type PrimarySearchAppBarProps = { isUsingGeolocation: boolean; cookies: any };

const useStyles = makeStyles((theme) => {
  return {
    grow: {
      flexGrow: 1,
      marginBottom: theme.spacing(2),
    },
  };
});

type AnchorState = {
  top: boolean;
  left: boolean;
  bottom: boolean;
  right: boolean;
};

type AnchorTypes = "top" | "right" | "bottom" | "left";

const PrimarySearchAppBar = ({
  isUsingGeolocation,
  cookies,
}: PrimarySearchAppBarProps): ReactElement => {
  const classes = useStyles();

  const [state, setState] = useState<AnchorState>({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor: AnchorTypes, open: boolean) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    )
      return;

    setState({ ...state, [anchor]: open });
  };

  const [searchText, setSearchText] = useState<string>("");
  const [locationModalShow, setLocationModalShow] = useState(false);
  const [settingsModalShow, setSettingsModalShow] = useState(false);

  return (
    <>
      <div className={classes.grow}>
        <AppBar position="fixed">
          <Toolbar className="container">
            <MenuDrawer
              toggleDrawer={toggleDrawer}
              state={state}
              searchText={searchText}
              setSearchText={setSearchText}
              cookies={cookies}
            />
            <Typography variant="h6" noWrap>
              <NavbarLogo />
            </Typography>
            <DesktopMenu
              locationModalShow={locationModalShow}
              setLocationModalShow={setLocationModalShow.bind(this)}
              isUsingGeolocation={isUsingGeolocation}
              settingsModalShow={settingsModalShow}
              setSettingsModalShow={setSettingsModalShow.bind(this)}
              toggleDrawer={toggleDrawer}
              drawerState={state["top"]}
              searchText={searchText}
              setSearchText={setSearchText.bind(this)}
            />
            <MobileMenu
              locationModalShow={locationModalShow}
              setLocationModalShow={setLocationModalShow.bind(this)}
              isUsingGeolocation={isUsingGeolocation}
              settingsModalShow={settingsModalShow}
              setSettingsModalShow={setSettingsModalShow.bind(this)}
              toggleDrawer={toggleDrawer}
              drawerState={state["top"]}
            />
          </Toolbar>
        </AppBar>
      </div>
      <DayhootStandardModal
        data={{
          id: "locationSelectionModal",
          show: locationModalShow,
          onHide: () => setLocationModalShow(false),
          headerContent: "Change Location",
          bodyContent: <ChangeLocation />,
        }}
      />
      <DayhootStandardModal
        data={{
          id: "settingsModal",
          show: settingsModalShow,
          onHide: () => setSettingsModalShow(false),
          headerContent: "Settings",
          bodyContent: <Settings isInModal={true} />,
        }}
      />
    </>
  );
};
export default PrimarySearchAppBar;
