import React, { Component } from 'react';
import 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.scss';

export default class App extends Component  {

  render(){
      return (<div id="root"></div>)
  }
}
