// Package Imports
import React, { Component } from "react";
import { withCookies } from "react-cookie";

// Dayhoot Functions & Scripts
import { setPageTitleText } from "../scripts/utils";
import { citiesList } from "../config/citiesList";
import {
  getSettingsConfig,
  setAdvertCookies,
  setAnalyticsCookies,
  setDistanceCookies,
  setGeolocationCookies,
  setLocationCookies,
  setPersonalisationCookies,
  getValuesFromCookies,
} from "../component-functions/settings-core";

// Dayhoot Components
import { SettingsBody } from "../components/settings-components";
import { InfoPageBodyContent } from "../components/infoPage-components";
import { PageLoading } from "../components/common-components";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPageLoaded: false,
      cookies: props.cookies.getAll(),
      citiesList: citiesList,
      values: getValuesFromCookies(props.cookies),
      isInModal: props.isInModal != null && props.isInModal,
    };

    this.handleFormChange = this.handleFormChange.bind(this);
  }

  componentDidMount() {
    this.setState({ isPageLoaded: true });
    setPageTitleText("Settings");
  }

  //This is called when one of the inputs is changed, checks to see which one and updates settigns accordingly
  handleFormChange(event) {
    this.setState({ isPageLoaded: false });

    const { id, value } = event.target;
    let { values } = this.state;
    const { cookies } = this.props;

    switch (id) {
      case "acceptedPersonalCookies":
        values = setPersonalisationCookies(values, cookies);
        window.location.reload();
        break;
      case "acceptedAnalyticsCookies":
        values = setAnalyticsCookies(values, cookies);
        break;
      case "acceptedAdvertCookies":
        values = setAdvertCookies(values, cookies);
        break;
      case "useGeolocation":
        values = setGeolocationCookies(values, cookies);
        window.location.reload();
        break;
      case "citySelector":
        values = setLocationCookies(values, cookies, value);
        window.location.reload();
        break;
      case "distanceUnitKm":
        values = setDistanceCookies(values, cookies);
        break;
      default:
        break;
    }

    this.setState({
      values: values,
      isPageLoaded: true,
    });
  }

  render() {
    const { values, isInModal, citiesList, isPageLoaded } = this.state;
    if (isPageLoaded) {
      const {
        cookieSettingsDto,
        locationSettingsDto,
        personalisationSettingsDto,
      } = getSettingsConfig({
        values: values,
        handleFormChange: this.handleFormChange,
        citiesList: citiesList,
      });

      // If it's in a modal, we don't want the wrapping container
      if (isInModal) {
        return (
          <SettingsBody
            cookieSettingsDto={cookieSettingsDto}
            locationSettingsDto={locationSettingsDto}
            personalisationSettingsDto={personalisationSettingsDto}
          />
        );
      } else {
        const bodyHtml = (
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-left p-3">
                <div className="pb-0">
                  <SettingsBody
                    cookieSettingsDto={cookieSettingsDto}
                    locationSettingsDto={locationSettingsDto}
                    personalisationSettingsDto={personalisationSettingsDto}
                  />
                </div>
              </div>
            </div>
          </div>
        );
        return (
          <InfoPageBodyContent
            id={"settings"}
            title={"Dayhoot Settings"}
            bodyHtml={bodyHtml}
          />
        );
      }
    } else return <PageLoading />;
  }
}

export default withCookies(Settings);
