import { faFortAwesome } from "@fortawesome/free-brands-svg-icons";
import {
  faChartPie,
  faEdit,
  faEnvelope,
  faFlag,
  faInbox,
  faMapMarkedAlt,
  faObjectGroup,
  faPlus,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faWheelchair,
} from "@fortawesome/free-solid-svg-icons";
import { getMatchingPageValueString } from "../scripts/utils";
import { adminAreas, adminObjectTypes } from "./enums";

export const AdminLinks = {
  Attractions: {
    Root: "/admin/attractions",
    All: "/admin/attractions/all",
    Active: "/admin/attractions/active",
    Inactive: "/admin/attractions/inactive",
    View: "/attraction",
    Create: "/attraction/create",
    Edit: "/attraction/edit",
    ManageSocial: "/admin/manageSocial"
  },
  AttractionSubmissions: {
    Root: "/admin/attractionsubmissions",
    All: "/admin/attractionsubmissions/all",
    Rejected: "/admin/attractionsubmissions/rejected",
    AwaitingApproval: "/admin/attractionsubmissions/awaitingapproval",
    Create: "/attraction/submit",
    Edit: "/attraction/editsubmission",
    View: "/attraction",
  },
  Categories: {
    Root: "/admin/categories",
    All: "/admin/categories/all",
    View: "/categories",
    Create: "/admin/categories/create",
    Edit: "/admin/categories/edit",
    Deactivate: "/",
    Delete: "/",
  },
  Regions: {
    Root: "/admin/regions",
    All: "/admin/regions/all",
    View: "/regions",
    Create: "/admin/regions/create",
    Edit: "/admin/regions/edit",
    Deactivate: "/",
    Delete: "/",
  },
  AttractionGroups: {
    Root: "/admin/attractiongroups",
    All: "/admin/attractiongroups/all",
    Create: "/admin/attractiongroups/create",
    Edit: "/admin/attractiongroups/edit",
    //Delete: "/admin/attractiongroups/delete"
  },
  Facilities: {
    Root: "/admin/facilities",
    All: "/admin/facilities/all",
    Create: "/admin/facilities/create",
    Edit: "/admin/facilities/edit",
    Delete: "/admin/facilities/delete",
  },
};

export const AdminPageConfigs = [
  // Attraction Lists
  {
    type: adminObjectTypes.List,
    adminArea: adminAreas.Attractions,
    link: AdminLinks.Attractions.All,
    value: getMatchingPageValueString(AdminLinks.Attractions.All),
    label: "All Attractions",
    backgroundClass: "background-green",
    icon: faFortAwesome,
    isBreadcrumbClickable: true,
    // Admin List Props
    searchLabel: "Attraction",
    searchFieldName: "Name",
    searchPlaceholder: "e.g. Chester Zoo",
  },
  {
    type: adminObjectTypes.List,
    adminArea: adminAreas.Attractions,
    link: AdminLinks.Attractions.Inactive,
    value: getMatchingPageValueString(AdminLinks.Attractions.Inactive),
    label: "Inactive Attractions",
    backgroundClass: "background-red",
    icon: faThumbsDown,
    isBreadcrumbClickable: true,
    // Admin List Props
    searchLabel: "Attraction",
    searchFieldName: "Name",
    searchPlaceholder: "e.g. Chester Zoo",
  },
  {
    type: adminObjectTypes.List,
    adminArea: adminAreas.Attractions,
    link: AdminLinks.Attractions.Active,
    value: getMatchingPageValueString(AdminLinks.Attractions.Active),
    label: "Active Attractions",
    backgroundClass: "background-green",
    icon: faThumbsUp,
    isBreadcrumbClickable: true,
    // Admin List Props
    searchLabel: "Attraction",
    searchFieldName: "Name",
    searchPlaceholder: "e.g. Chester Zoo",
  },
  {
    type: adminObjectTypes.Manage,
    adminArea: adminAreas.Attractions,
    link: AdminLinks.Attractions.Create,
    value: getMatchingPageValueString(AdminLinks.Attractions.Create),
    label: "Create Attraction",
    backgroundClass: "background-green",
    icon: faPlus,
    isBreadcrumbClickable: true,
  },
  // Attraction Submission Lists
  {
    type: adminObjectTypes.List,
    adminArea: adminAreas.AttractionSubmissions,
    link: AdminLinks.AttractionSubmissions.All,
    value: getMatchingPageValueString(AdminLinks.AttractionSubmissions.All),
    label: "All Submissions",
    backgroundClass: "background-purple",
    icon: faInbox,
    isBreadcrumbClickable: true,
    // Admin List Props
    searchLabel: "Submission",
    searchFieldName: "Name",
    searchPlaceholder: "e.g. Chester Zoo",
  },
  {
    type: adminObjectTypes.List,
    adminArea: adminAreas.AttractionSubmissions,
    link: AdminLinks.AttractionSubmissions.Rejected,
    value: getMatchingPageValueString(
      AdminLinks.AttractionSubmissions.Rejected
    ),
    label: "All Rejected",
    backgroundClass: "background-red",
    icon: faThumbsDown,
    isBreadcrumbClickable: true,
    // Admin List Props
    searchLabel: "Submission",
    searchFieldName: "Name",
    searchPlaceholder: "e.g. Chester Zoo",
  },
  {
    type: adminObjectTypes.List,
    adminArea: adminAreas.AttractionSubmissions,
    link: AdminLinks.AttractionSubmissions.AwaitingApproval,
    value: getMatchingPageValueString(
      AdminLinks.AttractionSubmissions.AwaitingApproval
    ),
    label: "All Awaiting Approval",
    backgroundClass: "background-orange",
    icon: faFlag,
    isBreadcrumbClickable: true,
    // Admin List Props
    searchLabel: "Submission",
    searchFieldName: "Name",
    searchPlaceholder: "e.g. Chester Zoo",
  },
  {
    type: adminObjectTypes.Manage,
    adminArea: adminAreas.AttractionSubmissions,
    link: AdminLinks.AttractionSubmissions.Create,
    value: getMatchingPageValueString(AdminLinks.AttractionSubmissions.Create),
    label: "Submit Attraction",
    backgroundClass: "background-green",
    icon: faPlus,
    isBreadcrumbClickable: true,
  },
  {
    type: adminObjectTypes.List,
    adminArea: adminAreas.Categories,
    link: AdminLinks.Categories.All,
    value: getMatchingPageValueString(AdminLinks.Categories.All),
    label: "All Categories",
    backgroundClass: "background-red",
    icon: faChartPie,
    isBreadcrumbClickable: true,
    // Admin List Props
    searchLabel: "Category",
    searchFieldName: "Name",
    searchPlaceholder: "e.g. Experience",
  },
  {
    type: adminObjectTypes.Manage,
    adminArea: adminAreas.Categories,
    link: AdminLinks.Categories.Create,
    value: getMatchingPageValueString(AdminLinks.Categories.Create),
    label: "Create Category",
    backgroundClass: "background-green",
    icon: faPlus,
    isBreadcrumbClickable: true,
  },
  {
    type: adminObjectTypes.Manage,
    adminArea: adminAreas.Categories,
    link: AdminLinks.Categories.Edit,
    isTileHidden: true,
    value: getMatchingPageValueString(AdminLinks.Categories.Edit),
    label: "Edit Category",
    backgroundClass: "background-orange",
    icon: faEdit,
    isBreadcrumbClickable: false,
  },
  {
    type: adminObjectTypes.Manage,
    adminArea: adminAreas.Categories,
    link: AdminLinks.Categories.Delete,
    isTileHidden: true,
    value: getMatchingPageValueString(AdminLinks.Categories.Delete),
    label: "Delete Category",
    backgroundClass: "background-red",
    icon: faTimes,
    isBreadcrumbClickable: false,
  },
  {
    type: adminObjectTypes.List,
    adminArea: adminAreas.Regions,
    link: AdminLinks.Regions.All,
    value: getMatchingPageValueString(AdminLinks.Regions.All),
    label: "All Regions",
    backgroundClass: "background-darkgrey",
    icon: faMapMarkedAlt,
    isBreadcrumbClickable: true,
    // Admin List Props
    searchLabel: "Region",
    searchFieldName: "Name",
    searchPlaceholder: "e.g. Yorkshire",
  },
  {
    type: adminObjectTypes.Manage,
    adminArea: adminAreas.Regions,
    link: AdminLinks.Regions.Create,
    value: getMatchingPageValueString(AdminLinks.Regions.All),
    label: "Create Region",
    backgroundClass: "background-green",
    icon: faPlus,
    isBreadcrumbClickable: false,
  },
  {
    type: adminObjectTypes.Manage,
    adminArea: adminAreas.Regions,
    link: AdminLinks.Regions.Edit,
    value: getMatchingPageValueString(AdminLinks.Regions.All),
    label: "Edit Region",
    backgroundClass: "background-orange",
    icon: faEdit,
    isBreadcrumbClickable: false,
    isTileHidden: true,
  },
  {
    type: adminObjectTypes.List,
    adminArea: adminAreas.AttractionGroups,
    link: AdminLinks.AttractionGroups.All,
    value: getMatchingPageValueString(AdminLinks.AttractionGroups.All),
    label: "All Attraction Groups",
    backgroundClass: "background-purple",
    icon: faObjectGroup,
    isBreadcrumbClickable: true,
    // Admin List Props
    searchLabel: "Attraction Group",
    searchFieldName: "Name",
    searchPlaceholder: "e.g. Merlin",
  },
  {
    type: adminObjectTypes.Manage,
    adminArea: adminAreas.AttractionGroups,
    link: AdminLinks.AttractionGroups.Create,
    value: getMatchingPageValueString(AdminLinks.AttractionGroups.Create),
    label: "Create Group",
    backgroundClass: "background-green",
    icon: faPlus,
    isBreadcrumbClickable: true,
  },
  {
    type: adminObjectTypes.Manage,
    adminArea: adminAreas.AttractionGroups,
    link: AdminLinks.AttractionGroups.Edit,
    isTileHidden: true,
    value: getMatchingPageValueString(AdminLinks.AttractionGroups.Edit),
    label: "Edit Group",
    backgroundClass: "background-orange",
    icon: faEdit,
    isBreadcrumbClickable: false,
  },
  {
    type: adminObjectTypes.List,
    adminArea: adminAreas.Facilities,
    link: AdminLinks.Facilities.All,
    value: getMatchingPageValueString(AdminLinks.Facilities.All),
    label: "All Facilities",
    backgroundClass: "background-blue",
    icon: faWheelchair,
    isBreadcrumbClickable: true,
    // Admin List Props
    searchLabel: "Facility",
    searchFieldName: "Name",
    searchPlaceholder: "e.g. Toilets",
  },
  {
    type: adminObjectTypes.Manage,
    adminArea: adminAreas.Facilities,
    link: AdminLinks.Facilities.Create,
    value: getMatchingPageValueString(AdminLinks.Facilities.Create),
    label: "Create Facility",
    backgroundClass: "background-green",
    icon: faPlus,
    isBreadcrumbClickable: true,
  },
  {
    type: adminObjectTypes.Manage,
    adminArea: adminAreas.Facilities,
    link: AdminLinks.Facilities.Edit,
    isTileHidden: true,
    value: getMatchingPageValueString(AdminLinks.Facilities.Edit),
    label: "Edit Facility",
    backgroundClass: "background-orange",
    icon: faEdit,
    isBreadcrumbClickable: false,
  },
  {
    type: adminObjectTypes.Manage,
    adminArea: adminAreas.Facilities,
    link: AdminLinks.Facilities.Delete,
    isTileHidden: true,
    value: getMatchingPageValueString(AdminLinks.Facilities.Delete),
    label: "Delete Facility",
    backgroundClass: "background-red",
    icon: faTimes,
    isBreadcrumbClickable: false,
  },
];

export const AdminAreaConfigs = [
  {
    type: adminObjectTypes.Root,
    adminArea: adminAreas.Attractions,
    link: AdminLinks.Attractions.Root,
    value: getMatchingPageValueString(AdminLinks.Attractions.Root),
    label: "Attractions",
    backgroundClass: "background-green",
    icon: faFortAwesome,
    isBreadcrumbClickable: true,
    sections: [
      {
        label: "Attractions",
        links: AdminPageConfigs.filter((x) => {
          return (
            x.type === adminObjectTypes.List &&
            x.adminArea === adminAreas.Attractions
          );
        }),
      },
      {
        label: "Manage Attractions",
        links: AdminPageConfigs.filter((x) => {
          return (
            x.type === adminObjectTypes.Manage &&
            x.adminArea === adminAreas.Attractions &&
            !x.isTileHidden
          );
        }),
      },
    ],
  },
  {
    type: adminObjectTypes.Root,
    adminArea: adminAreas.AttractionSubmissions,
    link: AdminLinks.AttractionSubmissions.Root,
    value: getMatchingPageValueString(AdminLinks.AttractionSubmissions.Root),
    label: "Attraction Submissions",
    backgroundClass: "background-green",
    icon: faEnvelope,
    isBreadcrumbClickable: true,
    sections: [
      {
        label: "Submissions",
        links: AdminPageConfigs.filter((x) => {
          return (
            x.type === adminObjectTypes.List &&
            x.adminArea === adminAreas.AttractionSubmissions
          );
        }),
      },
      {
        label: "Manage Submissions",
        links: AdminPageConfigs.filter((x) => {
          return (
            x.type === adminObjectTypes.Manage &&
            x.adminArea === adminAreas.AttractionSubmissions &&
            !x.isTileHidden
          );
        }),
      },
    ],
  },
  {
    type: adminObjectTypes.Root,
    adminArea: adminAreas.Categories,
    link: AdminLinks.Categories.Root,
    value: getMatchingPageValueString(AdminLinks.Categories.Root),
    label: "Categories",
    backgroundClass: "background-red",
    icon: faChartPie,
    isBreadcrumbClickable: true,
    sections: [
      {
        label: "Categories",
        links: AdminPageConfigs.filter((x) => {
          return (
            x.type === adminObjectTypes.List &&
            x.adminArea === adminAreas.Categories
          );
        }),
      },
      {
        label: "Manage Categories",
        links: AdminPageConfigs.filter((x) => {
          return (
            x.type === adminObjectTypes.Manage &&
            x.adminArea === adminAreas.Categories &&
            !x.isTileHidden
          );
        }),
      },
    ],
  },
  {
    type: adminObjectTypes.Root,
    adminArea: adminAreas.Regions,
    link: AdminLinks.Regions.Root,
    value: getMatchingPageValueString(AdminLinks.Regions.Root),
    label: "Regions",
    backgroundClass: "background-darkgrey",
    icon: faMapMarkedAlt,
    isBreadcrumbClickable: true,
    sections: [
      {
        label: "Regions",
        links: AdminPageConfigs.filter((x) => {
          return (
            x.type === adminObjectTypes.List &&
            x.adminArea === adminAreas.Regions
          );
        }),
      },
      {
        label: "Manage Regions",
        links: AdminPageConfigs.filter((x) => {
          return (
            x.type === adminObjectTypes.Manage &&
            x.adminArea === adminAreas.Regions &&
            !x.isTileHidden
          );
        }),
      },
    ],
  },
  {
    type: adminObjectTypes.Root,
    adminArea: adminAreas.AttractionGroups,
    link: AdminLinks.AttractionGroups.Root,
    value: getMatchingPageValueString(AdminLinks.AttractionGroups.Root),
    label: "Attraction Groups",
    backgroundClass: "background-purple",
    icon: faObjectGroup,
    isBreadcrumbClickable: true,
    sections: [
      {
        label: "Attraction Groups",
        links: AdminPageConfigs.filter((x) => {
          return (
            x.type === adminObjectTypes.List &&
            x.adminArea === adminAreas.AttractionGroups
          );
        }),
      },
      {
        label: "Manage Attraction Groups",
        links: AdminPageConfigs.filter((x) => {
          return (
            x.type === adminObjectTypes.Manage &&
            x.adminArea === adminAreas.AttractionGroups &&
            !x.isTileHidden
          );
        }),
      },
    ],
  },
  {
    type: adminObjectTypes.Root,
    adminArea: adminAreas.Facilities,
    link: AdminLinks.Facilities.Root,
    value: getMatchingPageValueString(AdminLinks.Facilities.Root),
    label: "Facilities",
    backgroundClass: "background-blue",
    icon: faWheelchair,
    isBreadcrumbClickable: true,
    sections: [
      {
        label: "Facilities",
        links: AdminPageConfigs.filter((x) => {
          return (
            x.type === adminObjectTypes.List &&
            x.adminArea === adminAreas.Facilities
          );
        }),
      },
      {
        label: "Manage Facilities",
        links: AdminPageConfigs.filter((x) => {
          return (
            x.type === adminObjectTypes.Manage &&
            x.adminArea === adminAreas.Facilities &&
            !x.isTileHidden
          );
        }),
      },
    ],
  },
];
