// Package Imports
import { NavLink } from "react-router-dom";
import { attractionListSortTypeEnums } from "../config/enums";

export function AttractionLinkList({ attractions }) {
  return attractions.map((attraction, i) => {
    return (
      <li key={attraction.id}>
        <NavLink
          className="p-0"
          key={attraction.id}
          title={attraction.id}
          to={`/attraction/${attraction.id}`}
          exact
        >
          <b className="hyperlink wordbreak">{attraction.name}</b>
        </NavLink>
      </li>
    );
  });
}

export function CategoryLinkList({ categories }) {
  return categories.map((category, i) => {
    return (
      <li key={category.categoryName}>
        <NavLink
          className="p-0"
          key={category.categoryName}
          title={category.categoryName}
          to={`/categories/${category.categoryName}`}
          exact
        >
          <b className="hyperlink wordbreak">{category.displayName}</b>
        </NavLink>
      </li>
    );
  });
}

export function RegionLinkList({ regions }) {
  return regions.map((region, i) => {
    return (
      <li key={region.regionName}>
        <NavLink
          className="p-0"
          key={region.regionName}
          title={region.regionName}
          to={`/regions/${region.regionName}?sort=${attractionListSortTypeEnums.MostPopular.value}`}
          exact
        >
          <b className="hyperlink wordbreak">{region.displayName}</b>
        </NavLink>
      </li>
    );
  });
}

export function AttractionGroupLinkList({ groups }) {
  return groups.map((group, i) => {
    return (
      <li key={group.groupId}>
        <NavLink
          className="p-0"
          key={group.groupId}
          title={group.displayName}
          to={`/group/${group.groupId}`}
          exact
        >
          <b className="hyperlink wordbreak">{group.displayName}</b>
        </NavLink>
      </li>
    );
  });
}

export function FacilityLinkList({ facilities }) {
  return facilities.map(({ facilityName, displayName }, i) => {
    return (
      <li key={facilityName}>
        <NavLink
          className="p-0"
          key={facilityName}
          title={displayName}
          to={`/facilities/${facilityName}`}
          exact
        >
          <b className="hyperlink wordbreak">{displayName}</b>
        </NavLink>
      </li>
    );
  });
}
