// Package Imports
import React from "react";
import {
	FormControlLabel,
	Radio,
	Checkbox,
	withStyles,
    Typography,
    makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => {
    return {
		radioButtonLabelText: {
			fontSize: "0.8em",
			padding: theme.spacing(0),
		},
		checkboxLabelText: {
			fontSize: "0.8em",
			padding: theme.spacing(0),
		},
    }
});

export function RadioButton({ value, selectedValue, label, isOpen }) {
	const classes = useStyles();
	const isSelected = value === selectedValue;
	return isSelected || isOpen ? (
		<FormControlLabel
			className="radio-btn"
			value={value}
			control={<Radio color="primary" />}
			label={
                <Typography className={classes.radioButtonLabelText}>
                    {label}
                </Typography>}
		/>
	) : null;
}

export function CheckboxInput({
	id,
	label,
	isDisabled,
	handleChange,
	isOpen,
	value,
	selectedValue,
}) {
	const classes = useStyles();
	let shouldCheck = false;
	if (selectedValue != null) {
		shouldCheck = Array.isArray(selectedValue)
			? selectedValue.includes(value)
			: selectedValue === value;
	}
	const [checkboxFilterValue, setCheckboxFilterValue] =
		React.useState(shouldCheck);

	const handleCheckboxChangeAction = (event, newValue) => {
		setCheckboxFilterValue(newValue);
		handleChange(id, newValue);
	};

	// This sets the custom styling for our own checkbox
	const CustomCheckbox = withStyles({
		root: {
			color: "rgba(21, 160, 132, 1)",
			"&$checked": { color: "rgba(21, 160, 132, 1)" },
			"&$disabled": { color: "rgba(21, 160, 132, 0.5)" },
		},
		checked: {},
		disabled: {},
	})((props) => <Checkbox color="primary" {...props} />);

	// I've pulled this out to cleanly pass in the values before applying it to the label
	const checkboxInput = (
		<CustomCheckbox
			key={id + "checkbox"}
			checked={checkboxFilterValue}
			disabled={isDisabled}
			onChange={handleCheckboxChangeAction}
		/>
	);

	// Then we return the label dom object with the checkbox in it as the control
	return isOpen || checkboxFilterValue ? (
		<FormControlLabel
			control={checkboxInput}
			name={id}
			label={<Typography className={classes.checkboxLabelText}>{label}</Typography>}
			disabled={isDisabled}
		/>
	) : null;
}
