// Package Imports
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withCookies } from "react-cookie";

// Dayhoot Components
import { PageLoading } from "../components/common-components";
import { PageTitle } from "../components";

// Dayhoot Functions & Scripts
import { setPageTitleText } from "../scripts/utils";
import {
  getEveryAttraction,
  getAllCategories,
  getAllRegions,
  getAllAttractionGroups,
  getAllFacilities,
} from "../scripts/api-core";
import {
  AttractionGroupLinkList,
  AttractionLinkList,
  CategoryLinkList,
  RegionLinkList,
  FacilityLinkList
} from "../components/sitemap-components";

class SiteMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attractions: [],
      metadata: {},
      categories: [],
      regions: [],
      attractionGroups: [],
      isPageLoaded: false,
    };
  }

  async componentDidMount() {
    const { metadata, attractions } = await getEveryAttraction();

    const { results: categories } = await getAllCategories();
    const { results: regions } = await getAllRegions();
    const { results: attractionGroups } = await getAllAttractionGroups();
    const { results: facilities } = await getAllFacilities();

    this.setState({
      attractions: attractions,
      metadata: metadata,
      categories: categories,
      regions: regions,
      attractionGroups: attractionGroups,
      facilities: facilities,
      isPageLoaded: true,
    });
    setPageTitleText("Site Map");
  }

  render() {
    const { isPageLoaded } = this.state;
    if (isPageLoaded) {
      return (
        <div>
          <div id="root"></div>
          <div className="container mt-3">
            <div className="row">
              <div className="col-12">
                <PageTitle
                  headingId={"siteMapPageHeading"}
                  headingText={"Site Map"}
                />
              </div>
              <div className="col-md-3">
                <PageTitle
                  subHeadingId={"dayhootPagesSubHeading"}
                  subHeadingText={"Dayhoot Pages"}
                />
                <ul style={{ paddingLeft: "1.2rem" }}>
                  <li>
                    <NavLink
                      className="p-0"
                      key="attractions"
                      title="attractions"
                      to="/attractions/"
                      exact
                    >
                      <b className="hyperlink">Attractions</b>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="p-0"
                      key="regions"
                      title="regions"
                      to="/regions/"
                      exact
                    >
                      <b className="hyperlink">Regions</b>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="p-0"
                      key="categories"
                      title="categories"
                      to="/categories/"
                      exact
                    >
                      <b className="hyperlink">Categories</b>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="p-0"
                      key="about"
                      title="about"
                      to="/about/"
                      exact
                    >
                      <b className="hyperlink">About</b>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="p-0"
                      key="policies"
                      title="policies"
                      to="/policies/"
                      exact
                    >
                      <b className="hyperlink">Policies</b>
                    </NavLink>
                  </li>
                  <li style={{ listStyleType: "none" }}>
                    <ul className="pl-3">
                      <li style={{ listStyleType: "disc" }}>
                        <NavLink
                          className="p-0"
                          key="privacy"
                          title="privacy"
                          to="/policies/privacy/"
                          exact
                        >
                          <b className="hyperlink">Privacy</b>
                        </NavLink>
                      </li>
                      <li style={{ listStyleType: "disc" }}>
                        <NavLink
                          className="p-0"
                          key="cookies"
                          title="cookies"
                          to="/policies/cookies/"
                          exact
                        >
                          <b className="hyperlink">Cookies</b>
                        </NavLink>
                      </li>
                      <li style={{ listStyleType: "disc" }}>
                        <NavLink
                          className="p-0"
                          key="accessibility"
                          title="accessibility"
                          to="/policies/accessibility/"
                          exact
                        >
                          <b className="hyperlink">Accessibility</b>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <NavLink
                      className="p-0"
                      key="contact"
                      title="contact"
                      to="/contact/"
                      exact
                    >
                      <b className="hyperlink">Contact</b>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="p-0"
                      key="settings"
                      title="settings"
                      to="/settings/"
                      exact
                    >
                      <b className="hyperlink">Settings</b>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="p-0"
                      key="sitemap"
                      title="sitemap"
                      to="/sitemap/"
                      exact
                    >
                      <b className="hyperlink">Site Map</b>
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="col-md-3">
                <div className="d-flex flex-row align-items-center">
                  <div className="p-0">
                    <PageTitle
                      subHeadingId={"attractionsSubHeading"}
                      subHeadingText={"Attractions"}
                    />
                  </div>
                  <div className="pl-2">
                    <span
                      className="badge badge-info"
                      style={{ marginBottom: "0.75rem" }}
                    >
                      {this.state.metadata.totalResults}
                    </span>
                  </div>
                </div>
                <ul style={{ paddingLeft: "1.2rem" }}>
                  <AttractionLinkList attractions={this.state.attractions} />
                </ul>
              </div>
              <div className="col-md-3">
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex flex-row align-items-center">
                      <div className="p-0">
                        <PageTitle
                          subHeadingId={"regionsSubHeading"}
                          subHeadingText={"Regions"}
                        />
                      </div>
                      <div className="pl-2">
                        <span
                          className="badge badge-info"
                          style={{ marginBottom: "0.75rem" }}
                        >
                          {this.state.regions.length}
                        </span>
                      </div>
                    </div>
                    <ul style={{ paddingLeft: "1.2rem" }}>
                      <RegionLinkList regions={this.state.regions} />
                    </ul>
                  </div>
                  <div className="col-12">
                    <div className="d-flex flex-row align-items-center">
                      <div className="p-0">
                        <PageTitle
                          subHeadingId={"categoriesSubHeading"}
                          subHeadingText={"Categories"}
                        />
                      </div>
                      <div className="pl-2">
                        <span
                          className="badge badge-info"
                          style={{ marginBottom: "0.75rem" }}
                        >
                          {this.state.categories.length}
                        </span>
                      </div>
                    </div>
                    <ul style={{ paddingLeft: "1.2rem" }}>
                      <CategoryLinkList categories={this.state.categories} />
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex flex-row align-items-center">
                      <div className="p-0">
                        <PageTitle
                          subHeadingId={"attractionGroupSubHeading"}
                          subHeadingText={"Groups"}
                        />
                      </div>
                      <div className="pl-2">
                        <span
                          className="badge badge-info"
                          style={{ marginBottom: "0.75rem" }}
                        >
                          {this.state.attractionGroups.length}
                        </span>
                      </div>
                    </div>
                    <ul style={{ paddingLeft: "1.2rem" }}>
                      <AttractionGroupLinkList
                        groups={this.state.attractionGroups}
                      />
                    </ul>
                  </div>
                  <div className="col-12">
                    <div className="d-flex flex-row align-items-center">
                      <div className="p-0">
                        <PageTitle
                          subHeadingId={"facilitySubHeading"}
                          subHeadingText={"Facilities"}
                        />
                      </div>
                      <div className="pl-2">
                        <span
                          className="badge badge-info"
                          style={{ marginBottom: "0.75rem" }}
                        >
                          {this.state.facilities.length}
                        </span>
                      </div>
                    </div>
                    <ul style={{ paddingLeft: "1.2rem" }}>
                      <FacilityLinkList
                        facilities={this.state.facilities}
                      />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else return <PageLoading />;
  }
}

export default withCookies(SiteMap);
