// Package Imports
import { isMobile, isTablet } from "react-device-detect";

export function calculateAttractionGroupTileColSize(groupLimit) {
	switch (groupLimit) {
		case 1:
			return "col-12";
		case 2:
			return "col-md-6";
		case 3:
		default:
			return "col-md-6 col-xl-4";
	}
}

export function calculateAttractionGroupLimit(limit) {
	if (isMobile) return 1;
	else if (isTablet) return 2 < limit ? 2 : limit;
	else return limit;
}