import aquarium from '../img/categoryTiles/aquarium.jpg';
import arenas from '../img/categoryTiles/arenas.jpg';
import castles from '../img/categoryTiles/castles.jpg';
import entertainment from '../img/categoryTiles/entertainment.jpg';
import experience from '../img/categoryTiles/experience.jpg';
import historic from '../img/categoryTiles/historic.jpg';
import museum from '../img/categoryTiles/museum.jpg';
import nationalparks from '../img/categoryTiles/nationalparks.jpg';
import nature from '../img/categoryTiles/nature.jpg';
import outdoors from '../img/categoryTiles/outdoors.jpg';
import racing from '../img/categoryTiles/racing.jpg';
import railways from '../img/categoryTiles/railways.jpg';
import sports from '../img/categoryTiles/sports.jpg';
import stadiums from '../img/categoryTiles/stadiums.jpg';
import themepark from '../img/categoryTiles/themepark.jpg';
import tour from '../img/categoryTiles/tour.jpg';
import theatres from '../img/categoryTiles/theatres.jpg';
import woods from '../img/categoryTiles/woods.jpg';
import zoo from '../img/categoryTiles/zoo.jpg';

// Mappings for all icons to their category name
export const categoryTileList = [
    { name: 'aquarium', tile: aquarium},
    { name: 'arenas', tile: arenas},
    { name: 'castles', tile: castles},
    { name: 'entertainment', tile: entertainment},
    { name: 'experience', tile: experience},
    { name: 'historic', tile: historic},
    { name: 'museum', tile: museum},
    { name: 'nationalparks', tile: nationalparks},
    { name: 'nature', tile: nature},
    { name: 'outdooractivity', tile: outdoors},
    { name: 'racing', tile: racing},
    { name: 'railways', tile: railways},
    { name: 'sports', tile: sports},
    { name: 'stadiums', tile: stadiums},
    { name: 'themepark', tile: themepark},
    { name: 'tours', tile: tour},
    { name: 'theatres', tile: theatres},
    { name: 'woods', tile: woods},
    { name: 'zoo', tile: zoo}
];