import { Dispatch, ReactElement, SetStateAction } from "react";
import { useHistory } from "react-router-dom";
import { SearchButton } from ".";
import { fade, makeStyles } from "@material-ui/core/styles";
import { InputBase } from "@mui/material";

type SearchBoxProps = {
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
};

const useStyles = makeStyles((theme) => {
  return {
    search: {
      marginTop: "0.5em",
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(1),
      marginLeft: 0,
      width: "100%",
      height: "2.5em",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(0.5),
        width: "auto",
      },
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    input: {
      color: theme.palette.primary.contrastText,
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: theme.spacing(3),
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  };
});

const SearchBox = ({ searchText, setSearchText }: SearchBoxProps): ReactElement => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <>
      <div className={classes.search}>
        <InputBase
          placeholder="Search…"
          className={classes.input}
          value={searchText}
          inputProps={{ "aria-label": "search" }}
          onChange={(val) => setSearchText(val.target.value)}
          onKeyDown={(val) => {
            if (val.keyCode === 13 && searchText) {
              history.push(`/search/${searchText}`);
              setSearchText("");
            }
          }}
        />
      </div>
      <SearchButton searchText={searchText} />
    </>
  );
};
export default SearchBox;
