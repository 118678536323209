import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { NavLink, Prompt } from "react-router-dom";
import {
  createAttraction,
  createSubmission,
  getAttraction,
  getFilteredCategories,
  getFilteredRegions,
  getGoogleLocationPopulatedAttraction,
  getSubmittedAttraction,
  updateAttraction,
  updateSubmission,
} from "../scripts/api-core";
import { attractionEditSaveType, categorySortTypes } from "../config/enums";
import { PageLoading } from "../components/common-components";
import {
  AttractionNameContainer,
  AttractionImageContainer,
  AttractionDescriptionContainer,
  ContactDetailsEditorContext,
  AttractionContactDetailsContainer,
  LocationDetailsEditorContext,
  AttractionAddressContainer,
  AttractionMapContainer,
  AttractionFacilitiesContainer,
  FacilityDetailsEditorContext,
  CategoryDetailsEditorContext,
  AttractionRelatedDayhootsContainer,
  AttractionTagContainer,
  AttractionRatingsContainer,
  RatingDetailsEditorContext,
  AttractionGooglePlaceIdContainer,
  ManagementBar,
  AttractionSocialMediaLinksContainer,
} from "../components/attraction-edit-components";
import {
  getSanitisedFacilities,
  mapAttractionToProperties,
  mapPropertiesToAttraction,
  setupPagePermissions,
} from "../component-functions/attraction-edit-core";
import {
  faExclamationTriangle,
  faInfoCircle,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomSnackbar from "../components/snackbars";
import { SetupMap } from "../component-functions/map-core";
import Button from "react-bootstrap/Button";
import { setPageTitleText, sortByAz } from "../scripts/utils";
import { isInputValid } from "../scripts/validation";
import { attractionEditValidationConfig as validationConfig } from "../config/manageAttraction-config";
import { getAdminAuthenticationDetails } from "../scripts/admin-auth";

class AttractionEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPageLoaded: false,
      isInvalidSubmission: false,
      pagePermissions: null,
      isDirty: false,

      showSuccessSnackbar: false,
      showWarningSnackbar: false,
      showErrorSnackbar: false,
      successSnackbarMessages: [],
      warningSnackbarMessages: [],
      errorSnackbarMessages: [],

      // Editor Contexts
      isViewingRatingsSelect: false,
      isViewingContactSelect: false,
      isViewingLocationSelect: false,
      isViewingFacilitySelect: false,
      isViewingCategorySelect: false,
      isViewingRegionSelect: false,
      isEditingName: false,
      isEditingGooglePlaceId: false,
      isEditingImageUrl: false,
      isEditingDescription: false,

      // Additional Data
      allCategories: [],
      allRegions: [],
      allFacilities: [],

      // Attraction Object
      id: null,
      name: "",
      nameErrors: [],
      description: "",
      descriptionErrors: [],
      googlePlaceId: "",
      googlePlaceIdErrors: [],
      hvt: false, // high value target
      hvtErrors: [],
      isUserSubmitted: false,
      isApproved: false,
      isRejected: false,
      isAwaitingAdminReview: false,
      isLive: false,
      detailedView: false,
      latitude: "",
      latitudeErrors: [],
      longitude: "",
      longitudeErrors: [],
      locationDescription: "",
      locationDescriptionErrors: [],
      address1: "",
      address1Errors: [],
      address2: "",
      address2Errors: [],
      town: "",
      townErrors: [],
      postCode: "",
      postCodeErrors: [],
      country: "",
      countryErrors: [],
      region: null,
      regionErrors: [],
      attractionGroups: [],
      attractionGroupsErrors: [],
      facilities: [],
      facilitiesErrors: [], // not set up since it can't have errors right now
      rating: null,
      ratingErrors: [],
      price: null,
      priceErrors: [],
      hitCount: 0,
      mainCategory: null,
      mainCategoryErrors: [],
      categories: [],
      categoriesErrors: [],
      phoneNumber: "",
      phoneNumberErrors: [],
      emailAddress: "",
      emailAddressErrors: [],
      imageUrl: "",
      imageUrlErrors: [],
      linkUrl: "",
      linkUrlErrors: [],
      twitterUsername: "",
      twitterUsernameErrors: [],
      facebookUsername: "",
      facebookUsernameErrors: [],
      instagramUsername: "",
      instagramUsernameErrors: [],
      startState: null,
      noMatchingAttraction: false,
      noMatchingSubmission: false,
      isFacilitiesSaving: false,
    };
  }

  updateErrors(newVal) {
    newVal.forEach(({ name, errors }) => {
      this.setState({
        [`${name}Errors`]: errors,
      });
    });
  }

  async handleGetAttraction() {
    const attractionId = this.props.match.params.attractionId;
    const attraction = await getAttraction({ id: attractionId });
    if (attraction) {
      const mappedAttraction = await mapAttractionToProperties({ attraction });
      this.setState(mappedAttraction);
      return mappedAttraction;
    } else {
      this.setState({
        noMatchingAttraction: true,
      });
      return undefined;
    }
  }

  async handleGetSubmission() {
    const submissionId = this.props.match.params.submissionId;
    const submission = await getSubmittedAttraction({ id: submissionId });
    if (submission) {
      const mappedSubmission = await mapAttractionToProperties({
        attraction: submission,
      });
      this.setState(mappedSubmission);
      return mappedSubmission;
    } else {
      this.setState({
        noMatchingSubmission: true,
      });
      return undefined;
    }
  }

  async renderData({ isInitialize }) {
    let pagePermissions;
    let attraction;
    if (isInitialize) {
      pagePermissions = setupPagePermissions({
        pathname: this.props.location.pathname,
        cookies: this.props.cookies,
      });
      if (pagePermissions.isView) attraction = await this.handleGetAttraction();
      if (pagePermissions.isEdit) attraction = await this.handleGetAttraction();
      if (pagePermissions.isEditSubmission)
        attraction = await this.handleGetSubmission();

      const { results: allCategories } = await getFilteredCategories({
        sortOptionValue: categorySortTypes.AlphabeticalAsc.value,
      });

      const { results: allRegions } = await getFilteredRegions({
        sortOptionValue: categorySortTypes.AlphabeticalAsc.value,
      });

      this.setState({
        allCategories: allCategories,
        allRegions: allRegions,
        allFacilities: await getSanitisedFacilities({
          selectedFacilities: this.state.facilities,
        }),
        pagePermissions: pagePermissions,
        isPageLoaded: true,
      });

      this.setState({
        startState: this.state,
      });
    } else {
      attraction = {
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        name: this.state.name,
      };
      pagePermissions = this.state.pagePermissions;
    }

    if (attraction) {
      SetupMap({
        latitude: attraction.latitude,
        longitude: attraction.longitude,
        name: attraction.name,
      });
    }
  }

  async componentDidMount() {
    await this.renderData({ isInitialize: true });
  }

  async componentDidUpdate(prevProps) {
    const { attractionId: currAttractionId, submissionId: currSubmissionId } =
      this.props.match.params;
    const { attractionId: prevAttractionId, submissionId: prevSubmissionId } =
      prevProps.match.params;

    const hasAttractionIdChanged = currAttractionId !== prevAttractionId;
    const hasSubmissionIdChanged = currSubmissionId !== prevSubmissionId;

    const { pathName: currPathname } = this.props.location;
    const { pathName: prevPathname } = prevProps.location;
    const hasPathChanged = currPathname !== prevPathname;

    const reloadPage =
      hasAttractionIdChanged || hasSubmissionIdChanged || hasPathChanged;

    if (reloadPage) {
      this.setState({
        isPageLoaded: false,
      });

      await this.renderData({
        isInitialize: reloadPage,
        isPageLoaded: false,
      });
    }
  }

  async callSave({ attraction, type }) {
    const { pagePermissions, name, categories, mainCategory, region } =
      this.state;
    const { isCreate, isEdit, isSubmit, isEditSubmission } = pagePermissions;

    const nameErrors = isInputValid(validationConfig, "name", name);
    const categoryErrors = isInputValid(
      validationConfig,
      "categories",
      categories
    );
    const mainCategoryErrors = isInputValid(
      validationConfig,
      "mainCategory",
      mainCategory
    );
    const regionErrors = isInputValid(validationConfig, "region", region);

    const isValid =
      nameErrors.length === 0 &&
      categoryErrors.length === 0 &&
      mainCategoryErrors.length === 0 &&
      regionErrors.length === 0;

    if (isValid) {
      const {
        SaveChanges,
        Deactivate,
        Reactivate,
        RejectSubmission,
        ApproveSubmission,
      } = attractionEditSaveType;

      const { token } = getAdminAuthenticationDetails(this.props.cookies);

      const shouldUpdateOnSave =
        type === SaveChanges || type === Deactivate || type === Reactivate;

      if (isCreate) {
        return await createAttraction(attraction, token);
      } else if (isEdit && shouldUpdateOnSave) {
        return await updateAttraction(attraction, token);
      } else if (isSubmit) {
        return await createSubmission(attraction);
      } else if (isEditSubmission) {
        if (type === RejectSubmission || type === ApproveSubmission) {
          return await updateSubmission(attraction, token);
        } else if (type === SaveChanges) {
          return await updateSubmission(attraction);
        } else {
          this.setState({
            errorSnackbarMessages: ["This attraction could not be saved."],
            showErrorSnackbar: true,
          });
        }
      } else {
        this.setState({
          errorSnackbarMessages: ["This attraction could not be saved."],
          showErrorSnackbar: true,
        });
      }
    } else {
      this.setState({
        nameErrors,
        categoryErrors,
        mainCategoryErrors,
        regionErrors,
      });
      this.setState({
        errorSnackbarMessages: [
          "This attraction could not be saved. Please ensure the name, region and category fields are complete before continuing.",
        ],
        showErrorSnackbar: true,
      });
    }
  }

  showCallResponse({ response, successMessage, successAction }) {
    if (response.id) {
      this.setState({
        successSnackbarMessages: [successMessage || "Saved successfully."],
        showSuccessSnackbar: true,
      });
      setTimeout(successAction, 150);
    } else {
      this.setState({
        errorSnackbarMessages: ["This attraction could not be saved."],
        showErrorSnackbar: true,
      });
    }
  }

  isModelDirty() {
    const startState = this.state.startState;
    const isNameDirty = startState.name !== this.state.name;
    const isDescriptionDirty =
      startState.description !== this.state.description;
    const isGooglePlaceIdDirty =
      startState.googlePlaceId !== this.state.googlePlaceId;
    const isLatitudeDirty = startState.latitude !== this.state.latitude;
    const isLongitudeDirty = startState.longitude !== this.state.longitude;
    const isAddress1Dirty = startState.address1 !== this.state.address1;
    const isAddress2Dirty = startState.address2 !== this.state.address2;
    const isTownDirty = startState.town !== this.state.town;
    const isPostCodeDirty = startState.postCode !== this.state.postCode;
    const isCountryDirty = startState.country !== this.state.country;
    const isRegionDirty = startState.region !== this.state.region;
    const isAttractionGroupsDirty =
      startState.attractionGroups !== this.state.attractionGroups;
    const isFacilitiesDirty = startState.facilities !== this.state.facilities;
    const isRatingDirty = startState.rating !== this.state.rating;
    const isPriceDirty = startState.price !== this.state.price;
    const isMainCategoryDirty =
      startState.mainCategory !== this.state.mainCategory;
    const isCategoriesDirty = startState.categories !== this.state.categories;
    const isPhoneNumberDirty =
      startState.phoneNumber !== this.state.phoneNumber;
    const isEmailAddressDirty =
      startState.emailAddress !== this.state.emailAddress;
    const isImageUrlDirty = startState.imageUrl !== this.state.imageUrl;
    const isLinkUrlDirty = startState.linkUrl !== this.state.linkUrl;
    const isTwitterUsernameDirty =
      startState.twitterUsername !== this.state.twitterUsername;
    const isFacebookUsernameDirty =
      startState.facebookUsername !== this.state.facebookUsername;
    const isInstagramUsernameDirty =
      startState.instagramUsername !== this.state.instagramUsername;
    const isDirty =
      isNameDirty ||
      isDescriptionDirty ||
      isGooglePlaceIdDirty ||
      isLatitudeDirty ||
      isLongitudeDirty ||
      isAddress1Dirty ||
      isAddress2Dirty ||
      isTownDirty ||
      isPostCodeDirty ||
      isCountryDirty ||
      isRegionDirty ||
      isAttractionGroupsDirty ||
      isFacilitiesDirty ||
      isRatingDirty ||
      isPriceDirty ||
      isMainCategoryDirty ||
      isCategoriesDirty ||
      isPhoneNumberDirty ||
      isEmailAddressDirty ||
      isImageUrlDirty ||
      isLinkUrlDirty ||
      isTwitterUsernameDirty ||
      isFacebookUsernameDirty ||
      isInstagramUsernameDirty;
    return {
      response: isDirty,
      responseMessage: isDirty
        ? "One or more properties have changed since the last save. Please save changes before proceeding."
        : "",
    };
  }

  async loadGooglePlaceData() {
    const isGooglePlaceIdValid =
      this.state.googlePlaceId && this.state.googlePlaceId !== "";
    const { token } = getAdminAuthenticationDetails(this.props.cookies);
    const isModelDirty = this.isModelDirty();
    if (isModelDirty.response === true) {
      this.setState({
        warningSnackbarMessages: [isModelDirty.responseMessage],
        showWarningSnackbar: true,
      });
    } else if (isGooglePlaceIdValid) {
      const response = await getGoogleLocationPopulatedAttraction({
        attractionId: this.state.id,
        token: token,
      });
      this.showCallResponse({
        response: response,
        successMessage: "Google Place location details populated successfully.",
        successAction: () => window.location.reload(),
      });
    } else {
      this.setState({
        errorSnackbarMessages: [
          "Please ensure a valid Google Place Id has been saved.",
        ],
        showErrorSnackbar: true,
      });
    }
  }

  async saveChanges() {
    const type = attractionEditSaveType.SaveChanges;
    const attraction = mapPropertiesToAttraction({
      props: this.state,
      type: type,
    });

    const response = await this.callSave({ attraction, type });
    if (response) {
      const { isCreate, isSubmit } = this.state.pagePermissions;
      this.showCallResponse({
        response: response,
        successMessage: `${isCreate ? "Created" : "Updated"} successfully.`,
        successAction: () => {
          const pathnames = this.props.location.pathname
            .split("/")
            .filter((x) => x);
          if (isCreate)
            this.props.history.push(`/${pathnames[0]}/${response.id}`);
          if (isSubmit)
            this.props.history.push(
              `/${pathnames[0]}/editsubmission/${response.id}`
            );
          else window.location.reload();
        },
      });
    }
  }

  async deactivateAttraction() {
    const type = attractionEditSaveType.Deactivate;
    const attraction = mapPropertiesToAttraction({
      props: this.state,
      type: type,
    });

    this.showCallResponse({
      response: await this.callSave({ attraction, type }),
      successMessage: "Deactivated successfully.",
      successAction: () => window.location.reload(),
    });
  }

  async reactivateAttraction() {
    const type = attractionEditSaveType.Reactivate;
    const attraction = mapPropertiesToAttraction({
      props: this.state,
      type: type,
    });

    this.showCallResponse({
      response: await this.callSave({ attraction, type }),
      successMessage: "Reactivated successfully.",
      successAction: () => window.location.reload(),
    });
  }

  async rejectSubmission() {
    const type = attractionEditSaveType.RejectSubmission;
    const attraction = mapPropertiesToAttraction({
      props: this.state,
      type: type,
    });

    this.showCallResponse({
      response: await this.callSave({ attraction, type }),
      successMessage: "Rejected successfully.",
      successAction: () => {
        this.props.history.push(`/admin/attractionsubmissions`);
      },
    });
  }

  async approveSubmission() {
    const type = attractionEditSaveType.ApproveSubmission;
    const attraction = mapPropertiesToAttraction({
      props: this.state,
      type: type,
    });

    const response = await this.callSave({ attraction, type });

    this.showCallResponse({
      response: response,
      successMessage: "Approved successfully.",
      successAction: () => {
        // this.props.history.push(`/attraction/${response.id}`);
        window.location = `/attraction/${response.id}`;
      },
    });
  }

  render() {
    const { isPageLoaded, noMatchingAttraction, noMatchingSubmission } =
      this.state;
    if (isPageLoaded) {
      if (noMatchingAttraction || noMatchingSubmission) {
        return (
          <div className="container">
            <div className="alert alert-danger">
              <div className="d-flex flex-row">
                <div className="p-0">
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                </div>
                <div className="pl-2">
                  <span>
                    No matching{" "}
                    {noMatchingAttraction ? "Attraction" : "Submission"} could
                    be found.{" "}
                  </span>
                  <span
                    className="hyperlink text-red"
                    onClick={() => this.props.history.goBack()}
                  >
                    Please go back
                  </span>
                  <span> and try again.</span>
                </div>
              </div>
            </div>
          </div>
        );
      }

      const {
        isViewingCategorySelect,
        isViewingContactSelect,
        isViewingFacilitySelect,
        isViewingLocationSelect,
        isViewingRatingsSelect,
        isViewingRegionSelect,
        isEditingName,
        isEditingImageUrl,
        isEditingGooglePlaceId,
        isEditingDescription,

        id,
        name,
        description,
        address1,
        address2,
        town,
        postCode,
        country,
        linkUrl,
        phoneNumber,
        emailAddress,
        facebookUsername,
        twitterUsername,
        instagramUsername,
        facilities,
        allFacilities,
        mainCategory,
        categories,
        allCategories,
        region,
        allRegions,
        price,
        rating,
        imageUrl,
        latitude,
        longitude,
        pagePermissions,
        isLive,
        isApproved,
        isAwaitingAdminReview,
        googlePlaceId,
        startState,
        isFacilitiesSaving,
        isDirty,
      } = this.state;

      const hasUrl = linkUrl && linkUrl !== "";
      const hasPhoneNumber = phoneNumber && phoneNumber !== "";
      const hasEmailAddress = emailAddress && emailAddress !== "";
      const hasFacebookUsername = facebookUsername && facebookUsername !== "";
      const hasTwitterUsername = twitterUsername && twitterUsername !== "";
      const hasInstagramUsername =
        instagramUsername && instagramUsername !== "";
      const hasNoContactDetails =
        !hasUrl && !hasPhoneNumber && !hasEmailAddress;
      const hasNoSocialMediaLinks =
        !hasFacebookUsername && !hasTwitterUsername && !hasInstagramUsername;

      const hasValidAddress1 = address1 && address1 !== "";
      const hasValidAddress2 = address2 && address2 !== "";
      const hasValidTown = town && town !== "";
      const hasValidPostCode = postCode && postCode !== "";
      const hasValidCountry = country && country !== "";
      const hasNoAddress =
        !hasValidAddress1 &&
        !hasValidAddress2 &&
        !hasValidTown &&
        !hasValidPostCode &&
        !hasValidCountry;

      const isInlineEditing =
        isEditingName ||
        isEditingImageUrl ||
        isEditingGooglePlaceId ||
        isEditingDescription;

      const isInEditorView =
        isViewingCategorySelect ||
        isViewingContactSelect ||
        isViewingFacilitySelect ||
        isViewingLocationSelect ||
        isViewingRatingsSelect ||
        isViewingRegionSelect;

      setPageTitleText(name);
      return (
        <>
          {!isInEditorView &&
          !pagePermissions.isView &&
          pagePermissions.isManaging ? (
            <ManagementBar
              pagePermissions={pagePermissions}
              isApproved={isApproved}
              isAwaitingAdminReview={isAwaitingAdminReview}
              isLive={isLive}
              deactivateAttraction={this.deactivateAttraction.bind(this)}
              reactivateAttraction={this.reactivateAttraction.bind(this)}
              rejectSubmission={this.rejectSubmission.bind(this)}
              approveSubmission={this.approveSubmission.bind(this)}
              loadGooglePlaceData={this.loadGooglePlaceData.bind(this)}
              saveChanges={this.saveChanges.bind(this)}
            />
          ) : null}

          <div
            className="container"
            style={{
              paddingTop:
                !isInEditorView &&
                !pagePermissions.isView &&
                pagePermissions.isManaging
                  ? "70px"
                  : "",
            }}
          >
            <div className="row">
              <div className="col-12">
                {pagePermissions.isView & pagePermissions.isAdmin ? (
                  <Button
                    size="sm"
                    href={"edit/" + id}
                    style={{ float: "right" }}
                    variant="warning"
                  >
                    <FontAwesomeIcon icon={faEdit} />
                    &nbsp;Edit Attraction
                  </Button>
                ) : null}
                <AttractionNameContainer
                  attraction={{
                    name: name,
                    update: (newVal) => {
                      this.setState({
                        name: newVal,
                        isDirty: startState.name !== newVal,
                      });
                    },
                  }}
                  pagePermissions={pagePermissions}
                  updateErrors={this.updateErrors.bind(this)}
                  isInEditorView={isInEditorView}
                  reRenderMap={(newName) => {
                    const mapLabelName =
                      document.getElementById("mapLabelName");
                    if (mapLabelName) {
                      mapLabelName.innerHTML = newName;
                      SetupMap({
                        latitude: latitude,
                        longitude: longitude,
                        name: newName,
                      });
                    }
                  }}
                  startState={startState}
                  isEditing={isEditingName}
                  setIsEditing={(newVal) =>
                    this.setState({ isEditingName: newVal })
                  }
                />
              </div>
              {isFacilitiesSaving ? (
                <PageLoading loadingMessage="Facilities are being selected. Please wait to be returned to the attraction..." />
              ) : (
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      {pagePermissions.isEditSubmission ? (
                        <div className="alert alert-success">
                          <div className="d-flex flex-row align-items-top">
                            <div className="p-0">
                              <FontAwesomeIcon icon={faInfoCircle} />
                            </div>
                            <div className="pl-2">
                              <span className="h6">
                                This Attraction Submission ID is{" "}
                                <span style={{ textDecoration: "underline" }}>
                                  {id}
                                </span>
                                . Please keep a record of this ID.
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {/* Edit Contexts */}
                      {isViewingContactSelect ? (
                        <ContactDetailsEditorContext
                          website={{
                            url: linkUrl,
                            update: (newVal) => {
                              this.setState({
                                linkUrl: newVal,
                                isDirty: startState.linkUrl !== newVal,
                              });
                            },
                          }}
                          phone={{
                            phoneNumber: phoneNumber,
                            update: (newVal) => {
                              this.setState({
                                phoneNumber: newVal,
                                isDirty: startState.phoneNumber !== newVal,
                              });
                            },
                          }}
                          email={{
                            emailAddress: emailAddress,
                            update: (newVal) => {
                              this.setState({
                                emailAddress: newVal,
                                isDirty: startState.emailAddress !== newVal,
                              });
                            },
                          }}
                          facebook={{
                            username: facebookUsername,
                            update: (newVal) => {
                              this.setState({
                                facebookUsername: newVal,
                                isDirty: startState.facebookUsername !== newVal,
                              });
                            },
                          }}
                          twitter={{
                            username: twitterUsername,
                            update: (newVal) => {
                              this.setState({
                                twitterUsername: newVal,
                                isDirty: startState.twitterUsername !== newVal,
                              });
                            },
                          }}
                          instagram={{
                            username: instagramUsername,
                            update: (newVal) => {
                              this.setState({
                                instagramUsername: newVal,
                                isDirty:
                                  startState.instagramUsername !== newVal,
                              });
                            },
                          }}
                          isEditing={isViewingContactSelect}
                          setIsEditing={(newVal) => {
                            this.setState({
                              isViewingContactSelect: newVal,
                            });
                          }}
                          pagePermissions={pagePermissions}
                          updateErrors={this.updateErrors.bind(this)}
                          startState={startState}
                        />
                      ) : null}
                      {isViewingFacilitySelect ? (
                        <FacilityDetailsEditorContext
                          update={(name, newVal) => {
                            this.setState({
                              [name]: newVal,
                            });
                          }}
                          allFacilities={allFacilities}
                          isEditing={isViewingFacilitySelect}
                          setIsEditing={(newVal) => {
                            this.setState({
                              isViewingFacilitySelect: newVal,
                              isDirty: startState.facilities !== newVal,
                            });
                          }}
                          setIsSaving={(newVal) => {
                            this.setState({
                              isFacilitiesSaving: newVal,
                            });
                          }}
                        />
                      ) : null}
                      {isViewingCategorySelect ? (
                        <CategoryDetailsEditorContext
                          updateMainCategory={(newVal) => {
                            this.setState({
                              mainCategory: newVal,
                              isDirty: startState.mainCategory !== newVal,
                            });
                          }}
                          updateCategories={(newVal) => {
                            this.setState({
                              categories: newVal,
                              isDirty: startState.categories !== newVal,
                            });
                          }}
                          allCategories={allCategories}
                          categories={categories}
                          mainCategory={mainCategory}
                          isEditing={isViewingCategorySelect}
                          setIsEditing={(newVal) => {
                            this.setState({
                              isViewingCategorySelect: newVal,
                            });
                          }}
                          pagePermissions={pagePermissions}
                          updateErrors={this.updateErrors.bind(this)}
                        />
                      ) : null}
                      {isViewingLocationSelect ? (
                        <LocationDetailsEditorContext
                          address1={{
                            value: address1,
                            update: (newVal) => {
                              this.setState({
                                address1: newVal,
                                isDirty: startState.address1 !== newVal,
                              });
                            },
                          }}
                          address2={{
                            value: address2,
                            update: (newVal) => {
                              this.setState({
                                address2: newVal,
                                isDirty: startState.address2 !== newVal,
                              });
                            },
                          }}
                          town={{
                            value: town,
                            update: (newVal) => {
                              this.setState({
                                town: newVal,
                                isDirty: startState.town !== newVal,
                              });
                            },
                          }}
                          postCode={{
                            value: postCode,
                            update: (newVal) => {
                              this.setState({
                                postCode: newVal,
                                isDirty: startState.postCode !== newVal,
                              });
                            },
                          }}
                          country={{
                            value: country,
                            update: (newVal) => {
                              this.setState({
                                country: newVal,
                                isDirty: startState.country !== newVal,
                              });
                            },
                          }}
                          latitude={{
                            value: latitude,
                            update: (newVal) => {
                              this.setState({
                                latitude: newVal,
                                isDirty: startState.latitude !== newVal,
                              });
                            },
                          }}
                          longitude={{
                            value: longitude,
                            update: (newVal) => {
                              this.setState({
                                longitude: newVal,
                                isDirty: startState.longitude !== newVal,
                              });
                            },
                          }}
                          allRegions={allRegions}
                          region={region}
                          setAddress={(newVal) => {
                            const {
                              address1,
                              address2,
                              town,
                              postCode,
                              country,
                            } = newVal;
                            this.setState({
                              address1,
                              address2,
                              town,
                              postCode,
                              country,
                            });
                          }}
                          setCoordinates={(newVal) => {
                            const { latitude, longitude } = newVal;
                            this.setState({
                              latitude,
                              longitude,
                            });
                          }}
                          setRegion={(newVal) => {
                            this.setState({
                              region: newVal,
                              isDirty: startState.region !== newVal,
                            });
                          }}
                          isEditing={isViewingLocationSelect}
                          setIsEditing={() => {
                            this.setState({
                              isViewingLocationSelect: !isViewingLocationSelect,
                            });
                          }}
                          pagePermissions={pagePermissions}
                          updateErrors={this.updateErrors.bind(this)}
                          startState={startState}
                        />
                      ) : null}
                      {isViewingRatingsSelect ? (
                        <RatingDetailsEditorContext
                          attraction={{
                            name: name,
                            price: price,
                            rating: rating,
                          }}
                          setPrice={(newVal) => {
                            this.setState({
                              price: newVal,
                              isDirty: startState.price !== newVal,
                            });
                          }}
                          isEditing={isViewingRatingsSelect}
                          setIsEditing={(newVal) => {
                            this.setState({
                              isViewingRatingsSelect: !isViewingRatingsSelect,
                            });
                          }}
                          pagePermissions={pagePermissions}
                          updateErrors={this.updateErrors.bind(this)}
                          startState={startState}
                        />
                      ) : null}
                      {/* End of Edit Contexts */}
                      {/* Read Context */}
                      {pagePermissions.isEdit & !pagePermissions.isAdmin ? (
                        <div className="alert alert-warning">
                          <div className="d-flex flex-row align-items-center">
                            <div className="p-0">
                              <FontAwesomeIcon icon={faInfoCircle} />
                            </div>
                            <div className="pl-1">
                              <span>
                                You are not logged in. Please{" "}
                                <NavLink to="/admin">Log-in</NavLink> to edit
                                this attraction.
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {isInEditorView ? null : (
                        <div className="row">
                          <div className="col-md-6">
                            <AttractionImageContainer
                              attraction={{
                                imageUrl: imageUrl,
                                update: (newVal) => {
                                  this.setState({
                                    imageUrl: newVal,
                                    isDirty: startState.imageUrl !== newVal,
                                  });
                                },
                              }}
                              pagePermissions={pagePermissions}
                              updateErrors={this.updateErrors.bind(this)}
                              isEditing={isEditingImageUrl}
                              setIsEditing={(newVal) =>
                                this.setState({ isEditingImageUrl: newVal })
                              }
                            />
                          </div>
                          <div className="col-md-6">
                            {pagePermissions.isAdmin ? (
                              <AttractionGooglePlaceIdContainer
                                attraction={{
                                  googlePlaceId: googlePlaceId,
                                  update: (newVal) => {
                                    this.setState({
                                      googlePlaceId: newVal,
                                      isDirty:
                                        startState.googlePlaceId !== newVal,
                                    });
                                  },
                                }}
                                pagePermissions={pagePermissions}
                                updateErrors={this.updateErrors.bind(this)}
                                isInEditorView={isInEditorView}
                                isEditing={isEditingGooglePlaceId}
                                setIsEditing={(newVal) =>
                                  this.setState({
                                    isEditingGooglePlaceId: newVal,
                                  })
                                }
                              />
                            ) : null}
                            <AttractionRatingsContainer
                              attraction={{
                                name: name,
                                price: price,
                                rating: rating,
                              }}
                              isEditing={isViewingRatingsSelect}
                              setIsEditing={() => {
                                this.setState({
                                  isViewingRatingsSelect:
                                    !isViewingRatingsSelect,
                                });
                              }}
                              pagePermissions={pagePermissions}
                              disableEdit={isInlineEditing}
                            />
                            <AttractionDescriptionContainer
                              attraction={{
                                name: name,
                                description: description,
                                update: (newVal) => {
                                  this.setState({
                                    description: newVal,
                                    isDirty: startState.description !== newVal,
                                  });
                                },
                              }}
                              pagePermissions={pagePermissions}
                              url={linkUrl}
                              phoneNumber={phoneNumber}
                              emailAddress={emailAddress}
                              facebookUsername={facebookUsername}
                              twitterUsername={twitterUsername}
                              instagramUsername={instagramUsername}
                              isEditing={isEditingDescription}
                              setIsEditing={(newVal) =>
                                this.setState({ isEditingDescription: newVal })
                              }
                              updateErrors={this.updateErrors.bind(this)}
                            />
                          </div>
                          <div className="col-md-4 mt-3">
                            <AttractionAddressContainer
                              attraction={{
                                name: name,
                              }}
                              address={{
                                address1: address1,
                                address2: address2,
                                town: town,
                                postCode: postCode,
                                country: country,
                              }}
                              isEditing={isViewingLocationSelect}
                              setIsEditing={(newVal) => {
                                this.setState({
                                  isViewingLocationSelect: newVal,
                                });
                              }}
                              pagePermissions={pagePermissions}
                              updateErrors={this.updateErrors.bind(this)}
                              disableEdit={isInlineEditing}
                            />
                          </div>
                          {(!pagePermissions.isView &&
                            pagePermissions.isManaging) ||
                          !hasNoContactDetails ? (
                            <div className="col-md-4 mt-3">
                              <AttractionContactDetailsContainer
                                url={linkUrl}
                                phoneNumber={phoneNumber}
                                emailAddress={emailAddress}
                                isEditing={isViewingContactSelect}
                                setIsEditing={() => {
                                  this.setState({
                                    isViewingContactSelect:
                                      !isViewingContactSelect,
                                  });
                                }}
                                pagePermissions={pagePermissions}
                                updateErrors={this.updateErrors.bind(this)}
                                disableEdit={isInlineEditing}
                              />
                            </div>
                          ) : null}
                          {(!pagePermissions.isView &&
                            pagePermissions.isManaging) ||
                          !hasNoSocialMediaLinks ? (
                            <div className="col-md-4 mt-3">
                              <AttractionSocialMediaLinksContainer
                                facebookUsername={facebookUsername}
                                twitterUsername={twitterUsername}
                                instagramUsername={instagramUsername}
                                isEditing={isViewingContactSelect}
                                setIsEditing={() => {
                                  this.setState({
                                    isViewingContactSelect:
                                      !isViewingContactSelect,
                                  });
                                }}
                                pagePermissions={pagePermissions}
                                updateErrors={this.updateErrors.bind(this)}
                                disableEdit={isInlineEditing}
                              />
                            </div>
                          ) : null}
                          <div className="col-12 mt-3">
                            <AttractionFacilitiesContainer
                              facilities={facilities}
                              allFacilities={allFacilities.sort((a, b) =>
                                sortByAz(a.displayName, b.displayName)
                              )}
                              isEditing={isViewingFacilitySelect}
                              setIsEditing={(newVal) => {
                                this.setState({
                                  isViewingFacilitySelect: newVal,
                                });
                              }}
                              pagePermissions={pagePermissions}
                              updateErrors={this.updateErrors.bind(this)}
                              disableEdit={isInlineEditing}
                            />
                          </div>
                          {hasNoAddress ? null : (
                            <div className="col-12 mt-3">
                              <AttractionMapContainer
                                attraction={{
                                  name: name,
                                }}
                                name={name}
                                address={{
                                  address1: address1,
                                  address2: address2,
                                  town: town,
                                  postCode: postCode,
                                  country: country,
                                }}
                                coordinates={{
                                  latitude: latitude,
                                  longitude: longitude,
                                }}
                                isEditing={isViewingLocationSelect}
                                setIsEditing={(newVal) => {
                                  this.setState({
                                    isViewingLocationSelect: newVal,
                                  });
                                }}
                                pagePermissions={pagePermissions}
                                updateErrors={this.updateErrors.bind(this)}
                                setupMap={() => {
                                  SetupMap({
                                    latitude: latitude,
                                    longitude: longitude,
                                    name: name,
                                  });
                                }}
                                disableEdit={isInlineEditing}
                              />
                            </div>
                          )}
                          <div className="col-12 mt-3">
                            <AttractionRelatedDayhootsContainer
                              attraction={{
                                id,
                                name,
                              }}
                              mainCategory={mainCategory}
                              isEditing={isViewingCategorySelect}
                              setIsEditing={(newVal) => {
                                this.setState({
                                  isViewingCategorySelect: newVal,
                                });
                              }}
                              cookies={this.props.cookies}
                              pagePermissions={pagePermissions}
                              updateErrors={this.updateErrors.bind(this)}
                              disableEdit={isInlineEditing}
                            />
                          </div>
                          <div className="col-12 mt-3">
                            <AttractionTagContainer
                              allCategories={allCategories}
                              categories={categories}
                              isEditing={isViewingCategorySelect}
                              setIsEditing={(newVal) => {
                                this.setState({
                                  isViewingCategorySelect: newVal,
                                });
                              }}
                              pagePermissions={pagePermissions}
                              updateErrors={this.updateErrors.bind(this)}
                              disableEdit={isInlineEditing}
                            />
                          </div>
                        </div>
                      )}
                      {/* End of Read Context */}
                    </div>
                  </div>
                </div>
              )}
              <CustomSnackbar
                open={this.state.showSuccessSnackbar}
                setOpen={() =>
                  this.setState({
                    showSuccessSnackbar: !this.state.showSuccessSnackbar,
                  })
                }
                severity={"success"}
                message={this.state.successSnackbarMessages}
                autoHideDuration={3000}
              />
              <CustomSnackbar
                open={this.state.showWarningSnackbar}
                setOpen={() =>
                  this.setState({
                    showWarningSnackbar: !this.state.showWarningSnackbar,
                  })
                }
                severity={"warning"}
                message={this.state.warningSnackbarMessages}
                autoHideDuration={3000}
              />
              <CustomSnackbar
                open={this.state.showErrorSnackbar}
                setOpen={() =>
                  this.setState({
                    showErrorSnackbar: !this.state.showErrorSnackbar,
                  })
                }
                severity={"error"}
                message={this.state.errorSnackbarMessages}
                autoHideDuration={3000}
              />
              <Prompt
                when={isDirty && isPageLoaded}
                message="Are you sure you want to leave? Don't forget to save your changes."
              />
            </div>
          </div>
        </>
      );
    } else return <PageLoading />;
  }
}

export default withCookies(AttractionEdit);
