// Dayhoot Config
import { citiesList } from "./citiesList";
import { distanceFilterValues, attractionListSortTypeEnums } from "./enums";

const appProperties = require("../app-properties.json");

const defaultLocation = citiesList.find((x) => x.cityName === "London");

export const WebConfig = {
  enviroment: appProperties.env,
  defaultCoordinates: {
    latitude: defaultLocation.latitude,
    longitude: defaultLocation.longitude,
  },
  defaultLocation: defaultLocation.cityName,
  defaultAttractionListSort: attractionListSortTypeEnums.DistanceAsc.value,
  defaultFilterDistance: distanceFilterValues.AnyDistance.name,
  enableDistanceMeasurementToggle: false,
  enabledPersonalisationOptions: false,
  enableDarkModeToggle: false,
  enableFeaturedBanner: true,
  enableEmailBanner: true,
  isInHoldingPageMode: false,
  numberOfCategoriesToShow: 19,
  numberOfRegionsToShow: 13,
  // This determines whether the user can change their location from the navbar search
  enableChangeableSearchLocation: true,
  numberOfAttractionGroupsToShowOnHomepage: 2,
  infoEmailAddress: "info@dayhoot.co.uk",
  facebookUrl: "https://www.facebook.com/Dayhootuk",
  instagramUrl: "https://www.instagram.com/dayhootuk/",
  twitterUrl: "https://twitter.com/DayhootUK",
};

export const AnalyticsConfig = {
  measurementId: appProperties.analytics.measurementId,
};

export const CookieDefaults = {
  sessionCookieDefault: {
    userLocation: WebConfig.defaultLocation,
    userLatitude: WebConfig.defaultCoordinates.latitude,
    userLongitude: WebConfig.defaultCoordinates.longitude,
    userUseGeolocationData: false,
    userDefaultLocation: WebConfig.defaultLocation, // This is in both cookies, (when updated change in both if allowed in other, check both)
    setDate: Date.now(),
  },
  cookiesCookieDefault: {
    userAcceptedSettingsCookies: false,
    userAcceptedAnalyticCookies: false,
    userAcceptedAdvertCookies: false,
    setDate: Date.now(),
  },
  settingsCookieDefault: {
    userDistanceUnitKm: false,
    userDarkMode: false,
    userDefaultLocation: WebConfig.defaultLocation,
    setDate: Date.now(),
  },
};
