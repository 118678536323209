// Package Imports
import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { Route } from "react-router";

// Dayhoot Components
import { PageLoading } from "../components/common-components";
import { AttractionBody } from "../components/attraction-components";

// Dayhoot Functions & Scripts
import {
  getGoogleMapsUrl,
  multiLineOverflows,
  setPageTitleText,
} from "../scripts/utils";
import { getIconFromCategoryName } from "../component-functions/categories-core";
import { SetupMap } from "../component-functions/map-core";

// Dayhoot Config
import { getDefaultTabs } from "../config/attractionTabs";

// APIs
import { getAttraction, getCategoryAttractions } from "../scripts/api-core";
import ErrorPage from "../pages/error404";
import RouterBreadcrumbs from "../components/routerBreadcrumbs";

class Attraction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      cookies: props.cookies.getAll(),
      attractionId: this.props.match.params.attractionId,
      attraction: {},
      attractions: [], // This is only populated from similar attractions based on the main category
      isPageLoaded: false,
      isValidAttraction: false,
      tabs: getDefaultTabs(),
      showDetailsModal: false,
    };

    this.goBack = props.history.goBack.bind(this);
  }

  async componentDidMount() {
    const { attractionId } = this.state;

    await this.renderData({ attractionId: attractionId });

    this.setState({
      isDetailsOverflow: multiLineOverflows(
        document.getElementById("description")
      ),
    });
  }

  // This is useful when changing between options from the navbar. For example categories/themepark to categories/zoo without going elsewhere
  // We want to re-render the attractions and categories when doing this
  checkIfPageParamsHaveChanged(prevProps) {
    return (
      prevProps.match.params.attractionId !==
      this.props.match.params.attractionId
    );
  }

  async componentDidUpdate(prevProps) {
    if (this.checkIfPageParamsHaveChanged(prevProps)) {
      this.setState({
        isPageLoaded: false,
        tabs: getDefaultTabs(),
      });

      await this.renderData({
        attractionId: this.props.match.params.attractionId,
      });
    }
  }

  async buildAttraction(id) {
    if (!id) {
      console.error("There was no attraction ID provided.");
      return;
    }

    const attraction = await getAttraction({ id: id });
    if (attraction) {
      attraction.mapUrl = getGoogleMapsUrl(attraction.location.coordinates);
      attraction.mainCategoryIcon = getIconFromCategoryName(
        attraction.mainCategory.categoryName,
        this.state.darkMode
      );
      return attraction;
    } else {
      console.error("There was an invalid attraction ID provided.");
      return;
    }
  }

  async renderData({ attractionId }) {
    const attraction = await this.buildAttraction(attractionId);
    if (attraction) {
      const data = await getCategoryAttractions({
        name: attraction.mainCategory.categoryName,
        excludeId: attractionId,
        limit: 6,
      });

      this.setState({
        data: data,
        attraction: attraction,
        attractions: data.attractions,
        isValidAttraction: true,
        isPageLoaded: true,
      });

      // This is on a slight time delay as the elements need to exist before the map can render
      setTimeout(() => {
        const { name, location } = attraction;
        const { latitude, longitude } = location.coordinates;
        const zoomLevel = 13; // TODO some centrally stored zoom levels as enums? Scale is 0-20
        SetupMap(
          "attractionMap",
          [latitude, longitude],
          zoomLevel,
          name,
          location.description
        );
      }, 100);

      setPageTitleText(attraction.name);
    } else {
      this.setState({
        isValidAttraction: false,
        isPageLoaded: true,
      });
    }
  }

  setShowDetailsModal(showValue) {
    this.setState({ showDetailsModal: showValue });
  }

  render() {
    if (this.state.isPageLoaded) {
      const pathName = this.props.location.pathname;
      const isAdminRoute = pathName.includes("admin/");

      if (this.state.isValidAttraction) {
        return (
          <div>
            <div id="root"></div>
            <div className="container mt-3">
              <div className="row">
                {isAdminRoute ? (
                  <div className="col-12">
                    <RouterBreadcrumbs
                      pathName={pathName}
                      pageItemLabel={this.state.attraction.name}
                    />
                  </div>
                ) : null}
                <div className="col-12">
                  <AttractionBody
                    cookies={this.props.cookies}
                    attraction={this.state.attraction}
                    attractions={this.state.attractions}
                    isDetailsOverflow={this.state.isDetailsOverflow}
                    showDetailsModal={this.state.showDetailsModal}
                    setShowDetailsModal={this.setShowDetailsModal.bind(this)}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return <Route component={ErrorPage} />;
      }
    } else {
      return (
        <PageLoading loadingMessage={"Fetching an incredible Dayhoot..."} />
      );
    }
  }
}

export default withCookies(Attraction);
