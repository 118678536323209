import React from "react";
import { Form } from "react-bootstrap";

export function FacilityDetails({ displayName, onChange }) {
  return (
    <Form.Group>
      <Form.Label>Display Name *</Form.Label>
      <Form.Control
        type="text"
        placeholder={"e.g. Disabled Toilets"}
        id="displayName"
        name="displayName"
        value={displayName.value}
        onChange={e => onChange("displayName", e.target.value)}
        isValid={displayName.isDirty && displayName.errors.length === 0}
        isInvalid={displayName.isDirty && displayName.errors.length > 0}
      />
      {displayName.isDirty && displayName.errors.length > 0 ? (
        <Form.Control.Feedback type="invalid">
          {displayName.errors}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );
}