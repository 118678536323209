import { SetId } from "../../scripts/utils";

type PageTitleProps = {
  headingId?: string;
  headingText?: string;
  subHeadingId?: string;
  subHeadingText?: string;
  isCentered?: boolean;
};

const PageTitle = ({
  headingId,
  headingText,
  subHeadingId,
  subHeadingText,
  isCentered,
}: PageTitleProps) => {
  return (
    <div className="row">
      <div
        className="col-12"
        style={{ textAlign: isCentered ? "center" : "left" }}
      >
        {headingText && (
          <h1
            className="text-break"
            id={SetId({ id: headingId || "example-heading-id" })}
          >
            {headingText}
          </h1>
        )}
        {subHeadingText && (
          <h2
            className="text-break"
            id={SetId({ id: subHeadingId || "example-heading-id" })}
          >
            {subHeadingText}
          </h2>
        )}
      </div>
    </div>
  );
};

export default PageTitle;
