import { Dispatch, ReactElement, SetStateAction } from "react";
import { IconButton, LocationSettingIcon } from ".";

type LocationButtonProps = {
  isUsingGeolocation: boolean;
  modalShow: boolean;
  setModalShow: Dispatch<SetStateAction<boolean>>;
};

const LocationButton = ({
  isUsingGeolocation,
  modalShow,
  setModalShow,
}: LocationButtonProps): ReactElement => {
  return (
    <IconButton
      edge="end"
      aria-label="Location Selection Window"
      aria-controls={"locationSelectionModal"}
      aria-haspopup="true"
      onClick={() => setModalShow(!modalShow)}
      color="inherit"
    >
      <LocationSettingIcon isUsingGeolocation={isUsingGeolocation} />
    </IconButton>
  );
};
export default LocationButton;
