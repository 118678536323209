import {
  faCheckCircle,
  faExclamationCircle,
  faLock,
  faMinusCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { setValidation } from "../scripts/validation";

export const wizardStepEnums = {
  BasicDetails: {
    value: 0,
    name: "Basic Details",
  },

  ContactInformation: {
    value: 1,
    name: "Contact Information",
  },

  Categories: {
    value: 2,
    name: "Categories",
  },

  Facilities: {
    value: 3,
    name: "Facilities",
  },

  Location: {
    value: 4,
    name: "Location",
  },

  Confirmation: {
    value: 5,
    name: "Confirmation",
  },
};

export const wizardSteps = [
  wizardStepEnums.BasicDetails,
  wizardStepEnums.Location,
  wizardStepEnums.ContactInformation,
  wizardStepEnums.Facilities,
  wizardStepEnums.Categories,
  wizardStepEnums.Confirmation,
];

export const wizardStateEnums = {
  Incomplete: {
    value: 0,
    text: "Incomplete",
    colour: "#ebf0f1",
    icon: faMinusCircle,
  },
  Complete: {
    value: 1,
    text: "Complete",
    colour: "#15a084",
    icon: faCheckCircle,
  },
  InProgress: {
    value: 2,
    text: "In Progress",
    colour: "#e67e21",
    icon: faSpinner,
  },
  ConfirmLocked: {
    value: 3,
    text: "Complete all steps before Confirming Changes",
    colour: "#ebf0f1",
    icon: faLock,
  },
  Invalid: {
    value: 4,
    text: "Fix issues before proceeding",
    colour: "#e67e21",
    icon: faExclamationCircle,
  },
};

export const stepTypes = {
  PastStep: 0,
  CurrentStep: 1,
  NextStep: 2,
  FutureStep: 3,
};

export const categoryValidationConfig = {
  categoryPageTitle: setValidation({
    isRequired: true,
  }),

  displayName: setValidation({
    isRequired: true,
  }),

  categoryName: setValidation({
    isRequired: true,
    isLowerCase: true,
    allowWhiteSpace: false,
  }),

  rank: setValidation({
    isRequired: true,
  }),

  displayColor: setValidation({
    isRequired: true,
  }),
};

export const regionValidationConfig = {
  displayName: setValidation({
    isRequired: true,
  }),
  regionName: setValidation({
    isRequired: true,
    isLowerCase: true,
    allowWhiteSpace: false,
  }),

  // Need latitude and longitude validation sorting below

  latitude: setValidation({
    isRequired: true,
  }),

  longitude: setValidation({
    isRequired: true,
  }),
};

export const featuredBannerValidationConfig = {
  title: setValidation({
    isRequired: true,
    maxLength: 80,
  }),

  id: setValidation({
    isRequired: true,
  }),

  bodyText: setValidation({
    isRequired: true,
  }),

  textColour: setValidation({
    isRequired: true,
  }),

  backgroundColour: setValidation({
    isRequired: true,
  }),

  imageUrl: setValidation({
    isRequired: true,
  }),

  buttonName: setValidation({
    isRequired: true,
  }),

  buttonUrl: setValidation({
    isRequired: true,
  }),

  buttonText: setValidation({
    isRequired: true,
    maxLength: 16,
  }),

  TextColour: setValidation({
    isRequired: true,
  }),
};

export const attractionGroupValidationConfig = {
  groupId: setValidation({
    isRequired: true,
    isLowerCase: true,
    allowWhiteSpace: false,
  }),
  displayName: setValidation({
    isRequired: true,
    maxLength: 32,
  }),
  websiteUrl: setValidation({
    isRequired: true,
    type: "url",
  }),
  mainCategory: setValidation({
    isRequired: true,
  }),
  description: setValidation({
    isRequired: true,
    maxLength: 750,
  }),
  // These aren't currently used but i'll leave it here incase we change our mind
  primaryColour: setValidation({
    isRequired: true,
    maxLength: 7,
  }),
  secondaryColour: setValidation({
    isRequired: true,
    maxLength: 7,
  }),
  textColour: setValidation({
    isRequired: true,
    maxLength: 7,
  }),
};

export const facilityValidationConfig = {
  facilityName: setValidation({
    isRequired: true,
    isLowerCase: true,
    allowWhiteSpace: false,
  }),
  displayName: setValidation({
    isRequired: true,
  }),
};

export const attractionValidationConfig = {
  latitude: setValidation({}),
  longitude: setValidation({}),
  region: setValidation({
    isRequired: true,
  }),
  address1: setValidation({
    maxLength: 120,
  }),
  address2: setValidation({
    maxLength: 120,
  }),
  country: setValidation({
    maxLength: 80,
  }),
  postCode: setValidation({
    maxLength: 10,
  }),
  facilities: setValidation({
    type: "array",
  }),
  facilityadvisories: setValidation({
    type: "array",
  }),
  unknownfacilities: setValidation({
    type: "array",
  }),
  unavailablefacilities: setValidation({
    type: "array",
  }),
  rating: setValidation({}),
  phone: setValidation({
    type: "phone",
  }),
  email: setValidation({
    type: "email",
  }),
  categories: setValidation({
    isRequired: true,
    type: "array",
  }),
  mainCategory: setValidation({
    isRequired: true,
  }),
  name: setValidation({
    isRequired: true,
    maxLength: 80,
  }),
  description: setValidation({
    isRequired: true,
    maxLength: 1000,
  }),
  imageUrl: setValidation({
    type: "url",
  }),
  linkUrl: setValidation({
    type: "url",
  }),
  price: setValidation({
    isRequired: true,
  }),
  socialAccount: setValidation({
    allowWhiteSpace: false,
  }),
};

export const attractionEditValidationConfig = {
  latitude: setValidation({}),
  longitude: setValidation({}),
  region: setValidation({
    isRequired: true,
  }),
  address1: setValidation({
    maxLength: 120,
  }),
  address2: setValidation({
    maxLength: 120,
  }),
  town: setValidation({
    maxLength: 120,
  }),
  postCode: setValidation({
    maxLength: 10,
  }),
  country: setValidation({
    maxLength: 80,
  }),
  facilities: setValidation({
    type: "array",
  }),
  facilityName: setValidation({}),
  rating: setValidation({}),
  phoneNumber: setValidation({
    type: "phone",
  }),
  emailAddress: setValidation({
    type: "email",
  }),
  categories: setValidation({
    isRequired: true,
    type: "array",
  }),
  mainCategory: setValidation({
    isRequired: true,
  }),
  name: setValidation({
    isRequired: true,
    maxLength: 80,
  }),
  description: setValidation({
    isRequired: true,
    maxLength: 1000,
  }),
  imageUrl: setValidation({
    type: "url",
  }),
  linkUrl: setValidation({
    type: "url",
  }),
  price: setValidation({
    isRequired: true,
  }),
  socialAccount: setValidation({
    allowWhiteSpace: false,
  }),
  googlePlaceId: setValidation({ maxLength: 100 }),
};
