import React from "react";
import { Form } from "react-bootstrap";

export function NameSection({
  displayName,
  regionName,
  latitude,
  longitude,
  onChange,
  isEdit,
}) {
  return (
    <div className="row">
      <div className="col-md-6">
        <Form.Group>
          <Form.Label>Display Name *</Form.Label>
          <Form.Control
            type="text"
            placeholder={"e.g. London"}
            id="displayName"
            name="displayName"
            value={displayName.value}
            onChange={(e) => onChange("displayName", e.target.value)}
            isValid={displayName.isDirty && displayName.errors.length === 0}
            isInvalid={displayName.isDirty && displayName.errors.length > 0}
          />
          {displayName.isDirty && displayName.errors.length > 0 ? (
            <Form.Control.Feedback type="invalid">
              {displayName.errors}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>
      </div>
      <div className="col-md-6">
        <Form.Group>
          <Form.Label>Name *</Form.Label>
          <Form.Control
            type="text"
            placeholder={"e.g. london"}
            id="regionName"
            name="regionName"
            value={regionName.value}
            readOnly={true}
            onChange={(e) => onChange("regionName", e.target.value)}
            isValid={regionName.isDirty && regionName.errors.length === 0}
            isInvalid={regionName.isDirty && regionName.errors.length > 0}
          />
          {regionName.isDirty && regionName.errors.length > 0 ? (
            <Form.Control.Feedback type="invalid">
              {regionName.errors}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>
      </div>
      <div className="col-md-6">
        <Form.Group>
          <Form.Label>Latitude *</Form.Label>
          <Form.Control
            type="text"
            placeholder={"e.g. 51.50744410036797"}
            id="latitude"
            name="latitude"
            value={latitude.value}
            readOnly={false}
            onChange={(e) => onChange("latitude", e.target.value)}
            isValid={latitude.isDirty && latitude.errors.length === 0}
            isInvalid={latitude.isDirty && latitude.errors.length > 0}
          />
          {latitude.isDirty && latitude.errors.length > 0 ? (
            <Form.Control.Feedback type="invalid">
              {latitude.errors}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>
      </div>

      <div className="col-md-6">
        <Form.Group>
          <Form.Label>Longitude *</Form.Label>
          <Form.Control
            type="text"
            placeholder={"e.g. -0.12775035022110884"}
            id="longitude"
            name="longitude"
            value={longitude.value}
            readOnly={false}
            onChange={(e) => onChange("longitude", e.target.value)}
            isValid={longitude.isDirty && longitude.errors.length === 0}
            isInvalid={longitude.isDirty && longitude.errors.length > 0}
          />
          {longitude.isDirty && longitude.errors.length > 0 ? (
            <Form.Control.Feedback type="invalid">
              {longitude.errors}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>
      </div>
    </div>
  );
}
