// Package Imports
import React from "react";
import { NavLink } from "react-router-dom";

// Fonts & Icons
import {
	faCookieBite,
	faShieldAlt,
	faUniversalAccess,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Dayhoot Functions & Scripts
import { policyUpdateDate } from "../component-functions/infoPage-core";
import { getUuid } from "../scripts/utils";

// Dayhoot Config
import { infoPageList } from "../config/infoPages";
import { policiesList } from "../config/policiesList";
import { Button } from "./common-components";
import { PageTitle } from "../components";

export function Policies() {
	return (
		<div className="container">
			<div className="page-margin">
				<div className="row">
					<div className="col-md-12 text-left p-3 px-3">
						<div className="pt-3 pb-0">
							<div className="px-0 pt-3 px-sm-3 px-md-0 px-lg-5">
								<div className="row">
									<div className="col-12 col-sm-6 col-md-4 text-center h5">
										<button className="btn">
											<div>
												<NavLink
													key="privacy"
													title="privacy"
													to="/policies/privacy/"
													exact
												>
													<FontAwesomeIcon
														className="contact-icon"
														icon={faShieldAlt}
													/>
													<p className="font-weight-bold pt-1">
														Privacy Policy
														<br />({policyUpdateDate.privacy})
													</p>
												</NavLink>
											</div>
										</button>
									</div>
									<div className="col-12 col-sm-6 col-md-4 text-center h5">
										<button className="btn">
											<div>
												<NavLink
													key="cookies"
													title="cookies"
													to="/policies/cookies/"
													exact
												>
													<FontAwesomeIcon
														className="contact-icon"
														icon={faCookieBite}
													/>
													<p className="font-weight-bold pt-1">
														Cookies Policy
														<br />({policyUpdateDate.cookies})
													</p>
												</NavLink>
											</div>
										</button>
									</div>
									<div className="col-12 col-sm-6 col-md-4 text-center h5">
										<button className="btn">
											<div>
												<NavLink
													key="accessibility"
													title="accessibility"
													to="/policies/accessibility/"
													exact
												>
													<FontAwesomeIcon
														className="contact-icon"
														icon={faUniversalAccess}
													/>
													<p className="font-weight-bold pt-1">
														Accessibility Policy
														<br />({policyUpdateDate.accessibility})
													</p>
												</NavLink>
											</div>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export function PrivacyPolicy() {
	return (
		<div className="container">
			<div className="page-margin">
				<div className="row">
					<div className="col-md-12 text-left p-3">
						<div className="pb-0">
							<div>
								<PoliciesDropDown page="privacy" />
								<p className="pt-3">Last updated: {policyUpdateDate.cookies}</p>
							</div>
							<div>
								<p className="h5 pt-3 pb-1">1. About Your Privacy</p>
								<p>
									The Dayhoot project team believe you should know what information we
									collect about you when you visit our site. We also think it is
									important to give you easily accessible controls to ensure you
									can best choose how these data are used and shared. This
									policy will outline what, how, and why we collect data as well
									as explain the privacy options you have.
								</p>
							</div>
							<div>
								<p className="h5 pt-3 pb-1">2. Information We Collect</p>
								<p>
									The information collected when you visit and use Dayhoot is
									used to provide you with our services. You may choose not to
									provide this information or request it be deleted, but this
									may result in the services we provide becoming limited or not
									function as intended.
								</p>
								<p>
									<b>Data Collected Automatically:</b> These data may be
									collected when you choose to visit Dayhoot.
								</p>
								<div className="pb-3">
									<table className="table table-bordered table-responsive">
										<thead>
											<tr>
												<th style={{ width: "20%" }}>Data Type</th>
												<th style={{ width: "50%" }}>More Information</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Log and Usage Data</td>
												<td>
													We may log information including your IP address,
													device type, browser type and operating system. URLs
													submitted that are generated from your search terms
													may also be logged. These data are deleted after 100
													days.
												</td>
											</tr>
											<tr>
												<td>Location Data</td>
												<td>
													With your consent, we may receive and use information
													about your specific location collected using the
													Geolocation function.
												</td>
											</tr>
											<tr>
												<td>Cookie Data</td>
												<td>
													We may store and use your browser cookies information.
													These data are used to improve your experience and
													give you personalised content. For more information
													view our cookie policy.
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<p>
									<b>Data Provided by You:</b> These data may be collected
									through the choices you make while using Dayhoot.
								</p>
								<div className="pb-3">
									<table className="table table-bordered table-responsive">
										<thead>
											<tr>
												<th style={{ width: "20%" }}>Data Type</th>
												<th style={{ width: "50%" }}>More Information</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Other Information</td>
												<td>
													You may provide information to us by completing a form
													or by providing your details through contacting us.
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<p>
									<b>Data Collected by Third Parties:</b> These data are
									collected through our opt-in third party services.
								</p>
								<div className="pb-3">
									<table className="table table-bordered table-responsive">
										<thead>
											<tr>
												<th style={{ width: "20%" }}>Data Type</th>
												<th style={{ width: "50%" }}>More Information</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Advertisement and Analytical Services</td>
												<td>
													We use third-party ad providers to show advertisements
													that are relevant to you. These third parties may
													collect information that identifies your browser
													including your web requests, IP address browser type,
													browser language, as well as the date and time of the
													request. These third parties may also store cookies on
													your browser and for more information view our cookie
													policy.
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div>
								<p className="h5 pt-3 pb-1">3. Use of Your Information</p>
								<p>We may use the data you provide to us to:</p>
								<ul>
									<li className="pb-1">
										Provide our service including to perform maintenance and
										make improvements.
									</li>
									<li className="pb-1">
										Provide you with things relevant to you.
									</li>
									<li className="pb-1">
										Provide customisation and personalisation on the site.
									</li>
									<li className="pb-1">
										Present you with customised content based on your geographic
										location.
									</li>
									<li className="pb-1">
										Deliver targeted advertisement content relevant to you.
									</li>
									<li className="pb-1">
										Provide technical support and customer service for our site.
									</li>
									<li className="pb-1">
										Monitor, analyse, and identify trends in the usage of the
										site.
									</li>
									<li className="pb-1">
										To respond to your contact requests.{" "}
									</li>
									<li className="pb-1">
										To allow us to fulfil our legal and compliance obligations.
									</li>
								</ul>
							</div>
							<div>
								<p className="h5 pt-3 pb-1">4. Sharing of Your Information</p>
								<p>
									Depending on how you use our service, we may occasionally
									share your information with our service providers. We do this
									to provide you with relevant results based on your location
									and search terms as well as a named current location from your
									precise location data using the{" "}
									<a href="https://www.bigdatacloud.com/geocoding-apis/free-reverse-geocode-to-city-api">
										<b>reverse geocode API</b>
									</a>{" "}
									provided by BigDataCloud.
								</p>
							</div>
							<div>
								<p className="h5 pt-3 pb-1">5. Your choices</p>
								<p>
									You can make choices in order to control the data that is
									collected and used when using our services and some examples
									of actions you can take are listed below.
								</p>
								<div className="pb-3">
									<table className="table table-bordered table-responsive">
										<thead>
											<tr>
												<th style={{ width: "20%" }}>Data Type</th>
												<th style={{ width: "50%" }}>More Information</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Controlling the use of Cookies</td>
												<td>
													While most browsers accept cookies by default, they
													often offer the option to reject all or specific
													cookies. For more information on how to control
													cookies view our{" "}
													<a href="/policies/cookies/">
														<b>cookies policy</b>
													</a>
													.
												</td>
											</tr>
											<tr>
												<td>
													Controlling advertisements and analytical services
												</td>
												<td>
													The third parties we partner with to provide
													advertisement and analytical services offer privacy
													solutions to control the data you share with them. For
													Google Analytics you can use the{" "}
													<a href="https://tools.google.com/dlpage/gaoptout">
														<b>Opt-out browser Add-on</b>
													</a>{" "}
													while for Google Adsense you can change your
													preferences on their{" "}
													<a href="https://adssettings.google.com/">
														<b>ads settings page</b>
													</a>
													.
												</td>
											</tr>
											<tr>
												<td>Controlling Your Location information</td>
												<td>
													When you visit Dayhoot you may be asked if you wish to
													provide precise location data from your device. If you
													consent to this but wish to change your choice you can
													update this directly through your browser or device
													privacy settings. You can also disable the use of
													precise location data through our{" "}
													<a href="/settings/">
														<b>settings page</b>
													</a>{" "}
													where we also provide you with alternative location
													options.
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div>
								<p className="h5 pt-3 pb-1">6. Protecting Your Information</p>
								<p>
									We take appropriate measures to ensure that your personal
									information is protected to prevent theft, misuse or
									unauthorised access of the data. For example, your information
									is transferred using HTTPS and any data stored as cookies by
									your browser is encrypted. We also regularly ensure any
									collected data is deleted except in cases where this data must
									be retained for business purposes or as required by law.
								</p>
							</div>
							<div>
								<p className="h5 pt-3 pb-1">7. Policy Changes</p>
								<p>
									We may update this policy and our other policies from time to
									time, in which cases the revision date will be updated.
								</p>
							</div>
							<div>
								<p className="h5 pt-3 pb-1">8. Contact</p>
								<p>
									We take your privacy seriously and you can{" "}
									<a href="/contact/">
										<b>contact us</b>
									</a>{" "}
									with any questions, comments, requestions or complains
									regarding your information and we will respond as soon as we
									can, but within 30 days. Alternatively, you can also get in
									touch with us at info@dayhoot.co.uk. You can also contact your local data
									protection authorities.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export function AccessibilityPolicy() {
	return (
		<div className="container">
			<div className="page-margin">
				<div className="row">
					<div className="col-md-12 text-left p-3">
						<div className="pb-0">
							<div>
								<PoliciesDropDown page="accessibility" />
								<p className="pt-3">
									Last updated: {policyUpdateDate.accessibility}
								</p>
							</div>
							<div>
								<p className="h5 pt-3">1. About Accessibility</p>
								<p>
									The Dayhoot Project is designed to be accessible and usable by
									everyone including people that have disabilities or
									impairments. When we were designing and building our site, we
									considered the additional requirements that may be needed to
									make our site suitable for anyone who wishes to use it and for
									this, we aim to work towards web accessibility standards.
								</p>
							</div>
							<div>
								<p className="h5 pt-3">2. Actions We Have Taken</p>
								<p>
									Some examples of design choices and feature implements to
									allow for our site to be accessible are listed below:
								</p>
								<ul>
									<li className="pb-1">
										Ensured page structural elements are correct and present,
										including headings, navigations and footers.
									</li>
									<li className="pb-1">
										Implemented Accessible Rich Internet Application (WAI-ARIA)
										features and labels.
									</li>
									<li className="pb-1">
										Ensured colour contrast ratios between elements and text are
										acceptable for accessibility standards.
									</li>
									<li className="pb-1">
										Included descriptive alternative text for images.
									</li>
								</ul>
							</div>
							<div>
								<p className="h5 pt-3">3. Feedback</p>
								<p>
									We acknowledge that we may not be aware of issues
									some people may have when using our site. Therefore, we value
									all suggestions and feedback on things that we could implement
									to improve our site and we ask that you{" "}
									<a href="/contact/">
										<b>contact us</b>
									</a>
									.
								</p>
							</div>
							<div>
								<p className="h5 pt-3">4. Available Resources</p>
								<p>
									Below are some external links to useful web accessibility
									resources:
								</p>
								<ul>
									<li className="pb-1">
										<a href="https://www.w3.org/WAI/">
											<b> W3C Web Accessibility Initiative (WAI)</b>
										</a>
									</li>
									<li className="pb-1">
										<a href="https://www.w3.org/WAI/standards-guidelines/wcag/">
											<b>
												{" "}
												Web Content Accessibility Guideline (WCAG) standards
											</b>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export function InfoPageBodyContent({ id, title, bodyHtml = "Content" }) {
	return (
		<div className="infoBody">
			<div className="col-md-12 pb-3">
				<div className="content pt-3">
					<PageTitle headingId={id} headingText={title} isCentered={true} />
					<div>{bodyHtml}</div>
				</div>
			</div>
		</div>
	);
}

function InfoNavItem({ data }) {
	const { activePage, page } = data;
	return (
		<NavLink to={page.to} key={page.key}>
			<Button
				data={{
					id: `button-${getUuid()}`,
					buttonClass: `pageNav pageNavButton ${
						activePage === page.to ? " activeTab" : ""
					}`,
					buttonClick: () => null,
					buttonText: page.content,
					title: page.title,
				}}
			/>
		</NavLink>
	);
}

function InfoNavItems({ activePage }) {
	return infoPageList.map((page) => {
		return (
			<InfoNavItem
				key={page.key}
				data={{ activePage: activePage, page: page }}
			/>
		);
	});
}

export function InfoNav({ activePage }) {
	return (
		<div className="col-12 p-0">
			<div className="d-flex">
				<div className="p-0">
					<InfoNavItems activePage={activePage} />
				</div>
			</div>
		</div>
	);
}

export function PoliciesDropDown(activePage) {
	const { title } = policiesList.find(
		(value) => value.pageName === activePage.page
	);
	return (
		<div className="dropdown">
			<button
				className="btn btn-bordered dropdown-toggle"
				type="button"
				id="dropdownMenuButton"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
			>
				{title}
			</button>
			<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
				<NavLink
					className="dropdown-item"
					key="accessibility"
					title="accessibility"
					to="/policies/accessibility/"
					exact
				>
					Accessibility Policy
				</NavLink>
				<NavLink
					className="dropdown-item"
					key="cookies"
					title="cookies"
					to="/policies/cookies/"
					exact
				>
					Cookies Policy
				</NavLink>
				<NavLink
					className="dropdown-item"
					key="privacy"
					title="privacy"
					to="/policies/privacy/"
					exact
				>
					Privacy Policy
				</NavLink>
			</div>
		</div>
	);
}
