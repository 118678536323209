import { Component } from "react";
import { withCookies } from "react-cookie";
import {
  getAttractionGroup,
  getFilteredAttractions,
} from "../scripts/api-core";
import { PageLoading } from "../components/common-components";
import { setPageTitleText } from "../scripts/utils";
import { attractionListSortTypeEnums } from "../config/enums";
import { AttractionGroupHeaderContainer } from "../components/attractionGroup-components";
import { AttractionsList } from "../components";

class AttractionGroup extends Component {
  constructor(props) {
    super(props);
    const { groupId } = this.props.match.params;

    this.state = {
      groupId: groupId,
      group: {},
      attractions: [],
      metadata: {},
      isPageLoaded: false,
    };
  }

  async componentDidMount() {
    const { groupId } = this.state;

    const { group, attractions } = await this.renderData(groupId);
    setPageTitleText(group.displayName);

    this.setState({
      group: group,
      attractions: attractions,
      isPageLoaded: true,
    });
  }

  async componentDidUpdate(prevProps) {
    const prevId = prevProps.match.params.groupId;
    const currentId = this.props.match.params.groupId;

    if (this.checkIfPageParamsHaveChanged(prevId, currentId)) {
      this.setState({ isPageLoaded: false });

      const { group, attractions } = await this.renderData(currentId);

      setPageTitleText(group.displayName);

      this.setState({
        group: group,
        attractions: attractions,
        isPageLoaded: true,
      });
    }
  }

  checkIfPageParamsHaveChanged(prevId, currentId) {
    return prevId !== currentId;
  }

  async renderData(groupId) {
    if (groupId) {
      const attractionGroup = await getAttractionGroup(groupId);
      const { attractions, metadata } = await getFilteredAttractions({
        groupId: groupId,
        sortOptionValue: attractionListSortTypeEnums.AlphabeticalAsc.value,
        pageSize: 1000,
      });

      return {
        metadata: metadata,
        group: attractionGroup,
        attractions: attractions,
      };
    } else {
      console.error("No Group Id has been provided");
    }
  }

  render() {
    const { isPageLoaded, group, attractions } = this.state;
    if (isPageLoaded) {
      return (
        <div>
          <div id="root"></div>
          <div className="container">
            <div className="row mb-2">
              <AttractionGroupHeaderContainer
                group={group}
                colWidth={"col-12"}
                hideAttractionsButton={true}
              />
            </div>
            <div className="row">
              <div className="col-12">
                <AttractionsList
                  attractions={attractions}
                  cookies={this.props.cookies}
                  isAttractionPage={true}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else return <PageLoading />;
  }
}

export default withCookies(AttractionGroup);
