// Other Stuff
import "../App.scss";

// Package Imports
import React, { Component } from "react";
import { withCookies } from "react-cookie";
import LazyLoad from "react-lazyload";

// Dayhoot Components
import { PageLoading } from "../components/common-components";

// Dayhoot Functions & Scripts
import { setPageTitleText } from "../scripts/utils";

import HootLadBroke from "../img/logos/hoot-green-broke.png";
import { AddAttractionBanner } from "../components/manageAttraction-components";

class ErrorPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPageLoaded: false,
		};
	}

	async componentDidMount() {
		this.setState({
			isPageLoaded: true,
		});

		setPageTitleText("Error 404");
	}

	render() {
		const { isPageLoaded } = this.state;
		if (isPageLoaded) {
			return (
				<div>
					<div className="container">
						<p className="h1 text-center">Hoot, we have a problem...</p>

						<p className="h1 text-center">Error 404 - Page Not Found</p>
						<br />
						<LazyLoad once={true}>
							<img
								src={HootLadBroke}
								className="rounded mx-auto d-block"
								alt="Dayhoot Owl Icon"
							></img>
						</LazyLoad>
						<br />
						<p className="h3 text-center">
							If you think there's a error, why not{" "}
							<a href="mailto:info@dayhoot.co.uk" className="hyperlink">
								contact us
							</a>{" "}
							on{" "}
							<a href="mailto:info@dayhoot.co.uk" className="hyperlink">
								info@dayhoot.co.uk
							</a>
						</p>
						<hr className="mb-3" />
						<p className="h3 text-center">
							If you were looking for a Dayhoot that's not on our website yet,
							why not submit it here and we'll get it added for you?
						</p>
						<AddAttractionBanner />
					</div>
				</div>
			);
		} else {
			return <PageLoading />;
		}
	}
}

export default withCookies(ErrorPage);
