// Dayhoot Config
import { distanceFilterValues } from "./enums";

export const distanceFilterList = [ 
    distanceFilterValues.AnyDistance, 
    distanceFilterValues.WithinOne, 
    distanceFilterValues.WithinFive, 
    distanceFilterValues.WithinTen, 
    distanceFilterValues.WithinTwentyFive,  
    distanceFilterValues.WithinFifty 
];