// Package Imports
import Leaflet from "leaflet";
import "leaflet/dist/leaflet.css";
import { renderToString } from "react-dom/server";

// Dayhoot Components
import { MapPopupContent } from "../components/map-components";

// Images
import pinGreen from "../img/icons/pin_green.png";

export function SetupMap(
  mapId,
  coords,
  zoomLevel,
  attractionName,
  attractionRegion
) {
  const mapElement = document.getElementById(mapId);
  if (mapElement) {
    // If the _leaflet_id is null, the map needs initializing
    const doesMapNeedInitializing = !mapElement._leaflet_id;

    // Variable for putting in a custom pin, add pin method needs to be changes further down
    var pinIcon = Leaflet.icon({
      iconUrl: pinGreen,
      iconSize: [50, 50], // size of the icon
      iconAnchor: [25, 45], // point of the icon which will correspond to marker's location
      popupAnchor: [0, -45], // point from which the popup should open relative to the iconAnchor
    });

    if (doesMapNeedInitializing) {
      // We get the map object here
      var map = Leaflet.map(mapId);

      // Set the default pin icon to show (can be overridden by above var to custom image)
      delete Leaflet.Icon.Default.prototype._getIconUrl;
      Leaflet.Icon.Default.mergeOptions({
        iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
        iconUrl: require("leaflet/dist/images/marker-icon.png"),
        shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
      });

      // We set the map properties here
      map.setView(coords, zoomLevel);

      // Render the map within the container here
      Leaflet.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        id: "mapbox/streets-v11",
      }).addTo(map);

      // Add a pin to the map and open the popup with the text
      Leaflet.marker([coords[0], coords[1]], { icon: pinIcon })
        .addTo(map)
        .bindPopup(
          renderToString(
            <MapPopupContent
              data={{
                name: attractionName,
                region: attractionRegion,
                latitude: coords[0],
                longitude: coords[1],
              }}
            />
          )
        )
        .openPopup();

      // And then readjust the map's size once loaded here
      map.invalidateSize();
    }
  } else {
    setTimeout(() => SetupMap(mapId, coords, 13, attractionName, attractionRegion), 200);
  }
}
