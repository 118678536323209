import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Form } from "react-bootstrap";
import { ChromePicker } from "react-color";

export function NameSection({ groupId, displayName, onChange, isEdit }) {
  return (
    <div className="row">
      <div className="col-md-6">
        <Form.Group>
          <Form.Label>Display Name *</Form.Label>
          <Form.Control
            type="text"
            placeholder={"e.g. Go Ape"}
            id="displayName"
            name="displayName"
            value={displayName.value}
            onChange={e => onChange("displayName", e.target.value)}
            isValid={displayName.isDirty && displayName.errors.length === 0}
            isInvalid={displayName.isDirty && displayName.errors.length > 0}
          />
          {displayName.isDirty && displayName.errors.length > 0 ? (
            <Form.Control.Feedback type="invalid">
              {displayName.errors}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>
      </div>
      <div className="col-md-6">
        <Form.Group>
          <Form.Label>Name *</Form.Label>
          <Form.Control
            type="text"
            placeholder={"e.g. goape"}
            id="groupId"
            name="groupId"
            value={groupId.value}
            readOnly={true}
            onChange={e => onChange("groupId", e.target.value)}
            isValid={groupId.isDirty && groupId.errors.length === 0}
            isInvalid={groupId.isDirty && groupId.errors.length > 0}
          />
          {groupId.isDirty && groupId.errors.length > 0 ? (
            <Form.Control.Feedback type="invalid">
              {groupId.errors}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>
      </div>
    </div>
  );
}

export function DescriptorInput({ description, onChange }) {
  return (
    <Form.Group>
      <Form.Label>Description *</Form.Label>
      <Form.Control
        as="textarea"
        rows={2}
        placeholder="e.g. Go Ape has lots of outdoor activity centres across the UK"
        id="description"
        name="description"
        value={description.value}
        onChange={e => onChange("description", e.target.value)}
        isValid={description.isDirty && description.errors.length === 0}
        isInvalid={description.isDirty && description.errors.length > 0}
      />
      {description.isDirty && description.errors.length > 0 ? (
        <Form.Control.Feedback type="invalid">
          {description.errors}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );
}

export function WebsiteInput({ websiteUrl, onChange }) {
  return (
    <Form.Group>
      <Form.Label>Main Website URL *</Form.Label>
      <Form.Control
        type="text"
        placeholder="Website URL"
        id="websiteUrl"
        value={websiteUrl.value}
        onChange={e => onChange("websiteUrl", e.target.value)}
        isValid={websiteUrl.isDirty && websiteUrl.errors.length === 0}
        isInvalid={websiteUrl.isDirty && websiteUrl.errors.length > 0}
      />
      {websiteUrl.isDirty && websiteUrl.errors.length > 0 ? (
        <Form.Control.Feedback type="invalid">
          {websiteUrl.errors}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );
}

export function MainCategoryInput({ mainCategory, options, onChange }) {
  return (
    <div className="row">
      <div className="col-12">
        <div>
          <Form.Label>
            <span>Main Category *</span>
          </Form.Label>
          <Form.Control
            as="select"
            value={mainCategory.value}
            onChange={e => onChange("mainCategory", e.target.value)}
            style={{
              display: options.length > 1 ? "block" : "none",
            }}
            id="category-mainCategory"
            isValid={mainCategory.isDirty && mainCategory.errors.length === 0}
            isInvalid={mainCategory.isDirty && mainCategory.errors.length > 0}
          >
            {options.map(c => (
              <option
                key={c.categoryName}
                value={c.categoryName}
                id={c.categoryName}
              >
                {c.categoryPageTitle}
              </option>
            ))}
          </Form.Control>
          <div
            className="alert alert-warning"
            style={{
              display: options.length < 2 ? "block" : "none",
            }}
          >
            <div className="d-flex flex-row">
              <div className="p-0">
                <FontAwesomeIcon icon={faInfoCircle} />
              </div>
              <div className="pl-2">
                <span>
                  Select at least one category before selecting a main category.
                </span>
              </div>
            </div>
          </div>
          {mainCategory.isDirty && mainCategory.errors.length > 0 ? (
            <span className="forced-invalid-feedback">
              {mainCategory.errors}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export function BrandingPicker({
  primaryColour,
  secondaryColour,
  textColour,
  isEdit,
  onChange,
}) {
  return (
    <div className="row">
      <div className="col-md-4 mt-3">
        <p className="mb-2">
          <b>Primary Colour *</b>
        </p>
        <ChromePicker
          color={primaryColour.value}
          onChange={val => onChange("primaryColour", val.hex)}
          width={"100%"}
        />
        {!isEdit && !primaryColour.isDirty ? (
          <p className="pt-2">
            <em className="text-red">No Colour Selected</em>
          </p>
        ) : null}
      </div>
      <div className="col-md-4 mt-3">
        <p className="mb-2">
          <b>Secondary Colour *</b>
        </p>
        <ChromePicker
          color={secondaryColour.value}
          onChange={val => onChange("secondaryColour", val.hex)}
          width={"100%"}
        />
        {!isEdit && !secondaryColour.isDirty ? (
          <p className="pt-2">
            <em className="text-red">No Colour Selected</em>
          </p>
        ) : null}
      </div>
      <div className="col-md-4 mt-3">
        <p className="mb-2">
          <b>Text Colour *</b>
        </p>
        <ChromePicker
          color={textColour.value}
          onChange={val => onChange("textColour", val.hex)}
          width={"100%"}
        />
        {!isEdit && !textColour.isDirty ? (
          <p className="pt-2">
            <em className="text-red">No Colour Selected</em>
          </p>
        ) : null}
      </div>
    </div>
  );
}
