import { AttractionsList } from "../../components";

type AttractionsProps = {
  filterPanelContainer: any;
  isFiltersOpen?: boolean;
  attractions: any;
  attractionsListContainerClass: string;
  cookies: any;
  hasSelectedFilters?: boolean;
  searchTerm?: string;
  numberOfResults: number;
};

const AttractionsBody = ({
  filterPanelContainer,
  isFiltersOpen,
  attractionsListContainerClass,
  ...attractionsListProps
}: AttractionsProps) => {
  return (
    <div className="row attract-pagin-spacer">
      <div className="col-md-12 d-lg-none">{filterPanelContainer}</div>
      <div
        className={isFiltersOpen ? "col-md-8" : "col-md-12"}
        id="attractionContainer"
      >
        <AttractionsList
          {...attractionsListProps}
          containerClass={attractionsListContainerClass}
        />
      </div>
      <div className="col-md-4 d-none d-lg-block">{filterPanelContainer}</div>
    </div>
  );
};
export default AttractionsBody;
