import { AttractionCard } from "..";
import { NoResults } from "../common-components";

type AttractionsListProps = {
  attractions: Array<any>;
  cookies: any;
  hasFiltersApplied?: boolean;
  hasSearchTerm?: boolean;
  isAttractionPage?: boolean;
  containerClass: string;
};

const AttractionsList = ({
  attractions,
  cookies,
  hasFiltersApplied,
  hasSearchTerm,
  isAttractionPage,
  containerClass,
}: AttractionsListProps) => {
  if (attractions.length > 0) {
    return (
      <div className="row content-row">
        {attractions.map((attraction, i) => {
          return (
            <AttractionCard
              key={`${attraction.id}-${i}`}
              attraction={attraction}
              cookies={cookies}
              containerClass={containerClass}
            />
          );
        })}
      </div>
    );
  } else {
    return <NoResults data={{ hasFiltersApplied, hasSearchTerm, isAttractionPage }} />;
  }
};

export default AttractionsList;
