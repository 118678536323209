import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { PageLoading } from "../components/common-components";
import RouterBreadcrumbs from "../components/routerBreadcrumbs";
import { setPageTitleText } from "../scripts/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteFacility, getFacility } from "../scripts/api-core";
import {
  faExclamationTriangle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router";
import { getAdminAuthenticationDetails } from "../scripts/admin-auth";

class DeleteFacility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPageLoaded: false,
      isAuthenticated: false,
      token: null,
      facility: null,
      facilityName: "",
      displayName: "",
      icon: {
        prefix: "",
        iconName: "",
      },
      availableDescription: "",
      unknownDescription: "",
      unavailableDescription: "",
      colour: "",
      forDisabled: false,
      hasDeleted: false,
    };
  }

  async componentDidMount() {
    const { isAuthenticated, token } = await this.renderData();

    setPageTitleText(`Delete Facility`);

    const facility = await getFacility(
      this.props.match.params.facilityName,
      token
    );
    this.mapFacilityToProperties({ facility });

    this.setState({
      isPageLoaded: true,
      isAuthenticated,
      token,
      facility: facility,
    });
  }

  async renderData() {
    const { token } = getAdminAuthenticationDetails(this.props.cookies);
    return {
      isAuthenticated: !!token,
      token: token,
    };
  }

  mapFacilityToProperties({ facility }) {
    this.setState({
      facilityName: facility.facilityName,
      displayName: facility.displayName,
      icon: facility.icon,
      availableDescription: facility.availableDescription,
      unavailableDescription: facility.unavailableDescription,
      unknownDescription: facility.unknownDescription,
      colour: facility.colour,
      forDisabled: facility.forDisabled,
    });
  }

  async deleteFacility() {
    // we will need something in here when it has attraction consequences
    // to check it's not tied to existing attractions and submissions
    // if it is, we need to list them in this view and remove them first
    // before allowing the removal of a facility

    try {
      const result = await deleteFacility(
        this.state.facility,
        this.state.token
      );
      this.setState({
        hasDeleted: result,
      });
    } catch {
      console.error(
        "This facility was not deleted. Please contact us for assistance."
      );
    }
  }

  render() {
    if (this.state.isPageLoaded) {
      const {
        isAuthenticated,
        icon,
        displayName,
        facilityName,
        availableDescription,
        unavailableDescription,
        unknownDescription,
        hasDeleted,
      } = this.state;

      if (isAuthenticated) {
        return (
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1>Delete Facility</h1>
                <RouterBreadcrumbs
                  pathName={this.props.location.pathname}
                  pageItemLabel={displayName}
                />
                <div className="card">
                  <div className="card-body">
                    {hasDeleted ? (
                      <Redirect to="/admin/facilities/all" />
                    ) : (
                      <div>
                        <div>
                          <div className="alert alert-danger">
                            <div className="d-flex flex-row align-items-center">
                              <div className="p-0">
                                <FontAwesomeIcon icon={faExclamationTriangle} />
                              </div>
                              <div className="pl-1">
                                Are you sure you want to delete this facility?
                                Once deleted, this facility cannot be
                                reeeetrieved.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <h2>Facility Details</h2>
                          <div className="row">
                            <div className="col-md-3">
                              <b>Facility Name</b>
                            </div>
                            <div className="col-md-9">{facilityName}</div>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <b>Display Name</b>
                            </div>
                            <div className="col-md-9">{displayName}</div>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <b>Icon</b>
                            </div>
                            <div className="col-md-9">
                              <FontAwesomeIcon icon={icon} />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <b>Available Description</b>
                            </div>
                            <div className="col-md-9">
                              {availableDescription}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <b>Unavailable Description</b>
                            </div>
                            <div className="col-md-9">
                              {unavailableDescription}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3">
                              <b>Unknown Description</b>
                            </div>
                            <div className="col-md-9">{unknownDescription}</div>
                          </div>
                        </div>
                        <div>
                          <div className="d-flex flex-row align-items-center">
                            <div className="ml-auto mr-auto p-0">
                              <button
                                className="btn btn-danger"
                                onClick={() => this.deleteFacility()}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                                <span className="ml-1">Delete Facility</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else return <Redirect to="/admin" />;
    } else return <PageLoading />;
  }
}

export default withCookies(DeleteFacility);
