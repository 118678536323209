import React from "react";
import { Form } from "react-bootstrap";

export function NameSection({
  categoryPageTitle,
  displayName,
  categoryName,
  rank,
  onChange,
}) {
  return (
    <div className="row">
      <div className="col-md-6">
        <Form.Group>
          <Form.Label>Category Page Title *</Form.Label>
          <Form.Control
            type="text"
            placeholder={"e.g. Theme Park"}
            id="categoryPageTitle"
            name="categoryPageTitle"
            value={categoryPageTitle.value}
            onChange={(e) => onChange("categoryPageTitle", e.target.value)}
            isValid={
              categoryPageTitle.isDirty && categoryPageTitle.errors.length === 0
            }
            isInvalid={
              categoryPageTitle.isDirty && categoryPageTitle.errors.length > 0
            }
          />
          {categoryPageTitle.isDirty && categoryPageTitle.errors.length > 0 ? (
            <Form.Control.Feedback type="invalid">
              {categoryPageTitle.errors}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>

        <Form.Group>
          <Form.Label>Display Name *</Form.Label>
          <Form.Control
            type="text"
            placeholder={"e.g. Theme Park"}
            id="displayName"
            name="displayName"
            value={displayName.value}
            onChange={(e) => onChange("displayName", e.target.value)}
            isValid={displayName.isDirty && displayName.errors.length === 0}
            isInvalid={displayName.isDirty && displayName.errors.length > 0}
          />
          {displayName.isDirty && displayName.errors.length > 0 ? (
            <Form.Control.Feedback type="invalid">
              {displayName.errors}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>
      </div>

      <div className="col-md-6">
        <Form.Group>
          <Form.Label>Name *</Form.Label>
          <Form.Control
            type="text"
            placeholder={"e.g. themepark"}
            id="categoryName"
            name="categoryName"
            value={categoryName.value}
            readOnly={true}
            onChange={(e) => onChange("categoryName", e.target.value)}
            isValid={categoryName.isDirty && categoryName.errors.length === 0}
            isInvalid={categoryName.isDirty && categoryName.errors.length > 0}
          />
          {categoryName.isDirty && categoryName.errors.length > 0 ? (
            <Form.Control.Feedback type="invalid">
              {categoryName.errors}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>

        <Form.Group>
          <Form.Label>Rank *</Form.Label>
          <Form.Control
            type="text"
            placeholder={"e.g. 1500"}
            id="rank"
            name="rank"
            value={rank.value}
            readOnly={true}
            onChange={(e) => onChange("rank", e.target.value)}
            isValid={rank.isDirty && rank.errors.length === 0}
            isInvalid={rank.isDirty && rank.errors.length > 0}
          />
          {rank.isDirty && rank.errors.length > 0 ? (
            <Form.Control.Feedback type="invalid">
              {rank.errors}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>
      </div>
    </div>
  );
}
