import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { sortByAz } from "../../../scripts/utils";
import useStyle from "../../../Hooks/Style";
import SelectBoxStyles from "./selectbox.scss";
import { SelectBoxItem } from ".";

type SelectBoxProps = {
  label: string;
  onChange: Dispatch<SetStateAction<string>>;
  property: any;
  properties: Array<any>;
};

const SelectBox = ({
  label,
  onChange,
  property,
  properties,
}: SelectBoxProps) => {
  useStyle(SelectBoxStyles);
  
  const lowercaseLabel = label.toLowerCase();

  return (
    <FormControl className="selectbox-formcontrol">
      <InputLabel
        shrink
        className="mui-selectlabel h6 selectbox-label"
        id={`${lowercaseLabel}-select-label`}
      >
        {label}
      </InputLabel>
      <Select
        displayEmpty
        labelId={`${lowercaseLabel}-select-label`}
        id={`${lowercaseLabel}-select`}
        value={property}
        onChange={(e: any) => onChange(e.target.value)}
        label={label}
        className="selectbox paper"
      >
        <MenuItem value="" aria-hidden="true">
          <SelectBoxItem defaultItem />
        </MenuItem>
        {properties &&
          properties
            .sort((a: any, b: any) => sortByAz(a.displayName, b.displayName))
            .map((p: any, i: number) => (
              <MenuItem key={i} value={p} aria-hidden="true">
                <SelectBoxItem label={p.displayName} />
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  );
};
export default SelectBox;
