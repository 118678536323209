import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { PageLoading } from "../components/common-components";
import RouterBreadcrumbs from "../components/routerBreadcrumbs";
import {
  setPageTitleText,
  getCreateEditPageType,
  flattenString,
} from "../scripts/utils";
import { FacilityDetails } from "../components/admin-createEdit-facility-components";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { createEditType } from "../config/enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { facilityValidationConfig } from "../config/manageAttraction-config";
import { isInputValid } from "../scripts/validation";
import {
  getFacility,
  updateFacility,
  createFacility,
} from "../scripts/api-core";
import CustomSnackbar from "../components/snackbars";
import { Redirect } from "react-router";
import { getAdminAuthenticationDetails } from "../scripts/admin-auth";

class CreateEditFacility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPageLoaded: false,
      isAuthenticated: false,
      token: null,
      facility: null,
      searchInputValue: "",
      facilityName: {
        value: "",
        isDirty: false,
        errors: isInputValid(facilityValidationConfig, "facilityName", ""),
      },
      displayName: {
        value: "",
        isDirty: false,
        errors: isInputValid(facilityValidationConfig, "displayName", ""),
      },
      colour: "green",
      forDisabled: false,
      hasUpdated: false,
      hasCreated: false,
      openSaveSuccessSnackbar: false,
      isActive: true,
      isSelectIconView: false,
    };
  }

  async componentDidMount() {
    const { isAuthenticated, token, pageType, isEdit } =
      await this.renderData();

    setPageTitleText(`${pageType.displayName} Facility`);

    let facility = this.state.facility;

    if (isEdit) {
      facility = await getFacility(this.props.match.params.facilityName, token);
      this.mapFacilityToProperties({ facility });
    }

    this.setState({
      isPageLoaded: true,
      isAuthenticated,
      token,
      pageType,
      isEdit,
    });
  }

  async renderData() {
    const { cookies, location } = this.props;
    const { token } = getAdminAuthenticationDetails(cookies);
    const pageType = getCreateEditPageType(location.pathname);
    const isEdit = pageType === createEditType.Edit;

    return {
      isAuthenticated: !!token,
      token: token,
      pageType: pageType,
      isEdit: isEdit,
    };
  }

  getFacilityObject({ value, isDirty, fieldName }) {
    return {
      value: value || "",
      isDirty: isDirty,
      errors: isInputValid(facilityValidationConfig, fieldName, value || ""),
    };
  }

  mapFacilityToProperties({ facility }) {
    const { facilityName, displayName, colour, forDisabled } = facility;

    this.setState({
      facilityName: this.getFacilityObject({
        value: facilityName,
        isDirty: false,
        fieldName: "facilityName",
      }),
      displayName: this.getFacilityObject({
        value: displayName,
        isDirty: false,
        fieldName: "displayName",
      }),
      colour: colour,
      forDisabled: forDisabled,
    });
  }

  mapPropertiesToFacility() {
    const { facilityName, displayName, colour, forDisabled, isActive } =
      this.state;

    const sanitisedFacilityName = flattenString(
      facilityName.value.toLowerCase()
    );

    const newFacility = {
      isActive: isActive,
      facilityName: sanitisedFacilityName,
      displayName: displayName.value,
      colour: colour,
      forDisabled: forDisabled,
    };

    this.setState({ facility: newFacility });

    return newFacility;
  }

  async saveFacility() {
    this.setState({ isPageLoaded: false });
    const facility = this.mapPropertiesToFacility();
    try {
      this.setState({
        facility: this.state.isEdit
          ? await updateFacility(facility, this.state.token)
          : await createFacility(facility, this.state.token),
        hasUpdated: this.state.isEdit ? true : false,
        hasCreated: this.state.isEdit ? false : true,
        openSaveSuccessSnackbar: true,
        isPageLoaded: true,
      });
    } catch {
      this.setState({ isPageLoaded: true });
      console.error(
        "This facility was not updated. Please contact us for assistance."
      );
    }
  }

  render() {
    if (this.state.isPageLoaded) {
      const {
        isAuthenticated,
        pageType,
        displayName,
        facilityName,
        openSaveSuccessSnackbar,
        isEdit,
      } = this.state;

      if (isAuthenticated) {
        const inputs = [displayName, facilityName];
        const hasErrors = inputs.some(x => {
          return (
            displayName.errors.length > 0 || facilityName.errors.length > 0
          );
        });

        return (
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1>{pageType.displayName} Facility</h1>
                <RouterBreadcrumbs
                  pathName={this.props.location.pathname}
                  pageItemLabel={displayName.value}
                />
                <div className="card">
                  <div className="card-body">
                    <div>
                      <h2>Facility Details</h2>
                      <div className="mb-3">
                        <FacilityDetails
                          displayName={displayName}
                          onChange={(name, val) => {
                            if (name === "displayName" && !isEdit) {
                              this.setState({
                                facilityName: this.getFacilityObject({
                                  value: flattenString(val.toLowerCase()),
                                  isDirty: true,
                                  fieldName: "facilityName",
                                }),
                              });
                            }
                            this.setState({
                              [name]: this.getFacilityObject({
                                value: val,
                                isDirty: true,
                                fieldName: name,
                              }),
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="d-flex flex-row align-items-center">
                        <div className="ml-auto mr-auto p-0">
                          <button
                            className={`btn btn-primary ${
                              hasErrors ? "disabled" : ""
                            }`}
                            onClick={() => {
                              if (!hasErrors) this.saveFacility();
                            }}
                          >
                            <FontAwesomeIcon icon={faSave} />
                            <span className="pl-1">Save Changes</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <CustomSnackbar
                      open={openSaveSuccessSnackbar}
                      setOpen={(val) =>
                        this.setState({ openSaveSuccessSnackbar: val })
                      }
                      severity={"success"}
                      message={"Saved successfully."}
                      autoHideDuration={3000}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else return <Redirect to="/admin" />;
    } else return <PageLoading />;
  }
}

export default withCookies(CreateEditFacility);
