import { EmailBanner as UIEmailBanner } from "../../components/emailBanner-component";

const EmailBanner = () => {
  return (
    <div className="email-banner-page-container">
      <div className="container">
        <div className="row">
          <div className="col-12 mb-4">
            <UIEmailBanner />
          </div>
        </div>
      </div>
    </div>
  );
};
export default EmailBanner;
