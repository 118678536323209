import { getFilteredAttractions } from "../../scripts/api-core";



export function getGreeting() {
    const currentHour = new Date().getHours();
    const isMorning = currentHour >= 6 && currentHour < 12;
    const isAfternoon = currentHour >= 12 && currentHour < 17;
    const isEvening = currentHour >= 17 && currentHour < 21;
    const isNight = currentHour >= 21 || currentHour < 6;
  
    if (isMorning) return "Good Morning!";
    else if (isAfternoon) return "Good Afternoon!";
    else if (isEvening) return "Good Evening!";
    else if (isNight) return "Hello Night Owl!";
  }

export async function getNumberOfMatchingAttractions({
  category,
  region,
}: {
  category: any;
  region: any;
}) {
  let categories;
  if (category && category !== "") {
    // Just needed to append filter string without a new function
    category.isFilterSelected = true;
    categories = [category];
  }

  let regions;
  if (region && region !== "") {
    // Just needed to append filter string without a new function
    region.isFilterSelected = true;
    regions = [region];
  }
  const { metadata } = await getFilteredAttractions({ categories, regions });
  return metadata.totalResults;
}
