// Package Imports
import React, { Component } from "react";

// Dayhoot Components & Scripts
import { setPageTitleText } from "../scripts/utils";

// Dayhoot Components
import { InfoPageBodyContent, AccessibilityPolicy } from "../components/infoPage-components";

class Accessibility extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPageLoaded: false,
		};
	}

	componentDidMount() {
		setPageTitleText("Accessibility Policy");
		this.setState({ isPageLoaded: true });
	}

	render() {
		return (
			<div>
				<InfoPageBodyContent
					id={"accessibility"}
					title={"Accessibility on Dayhoot"}
					bodyHtml={<AccessibilityPolicy />}
				/>
			</div>
		);
	}
}

export default Accessibility;
