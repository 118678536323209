import {
  faSortAlphaDown,
  faSortAlphaUp,
  faSortAmountDown,
  faSortAmountUp,
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const attractionTabs = {
  Details: {
    value: 0,
    name: "details",
    displayName: "Details",
  },
  Map: {
    value: 1,
    name: "map",
    displayName: "Map",
  },
  Discover: {
    value: 2,
    name: "discover",
    displayName: "More",
  },
  Back: {
    value: 3,
    name: "goBack",
    displayName: "Back",
  },
};

export const filterType = {
  Radio: {
    value: 0,
    name: "radio",
    displayName: "radio",
  },
  Checkbox: {
    value: 1,
    name: "checkbox",
    displayName: "checkbox",
  },
};

export const attractionsFilterType = {
  Distance: {
    value: 0,
    name: "distance",
    displayName: "Distance",
  },
  Category: {
    value: 1,
    name: "category",
    displayName: "Category",
  },
  Region: {
    value: 2,
    name: "region",
    displayName: "Region",
  },
  Facility: {
    value: 3,
    name: "facility",
    displayName: "Facility",
  },
};

export const attractionListSortTypeEnums = {
  NoSort: {
    value: 0,
    name: "none",
    displayName: `Default`,
    badgeText: "Relevance",
    subHeadingText: "Sorting Results by Most Relevant",
  },
  AlphabeticalAsc: {
    value: 1,
    name: "az",
    displayName: `Name: A to Z`,
    badgeText: (
      <span>
        Name <FontAwesomeIcon icon={faSortAlphaDown} />
      </span>
    ),
    subHeadingText: "Sorting Results from A to Z",
  },
  AlphabeticalDesc: {
    value: 2,
    name: "za",
    displayName: `Name: Z to A`,
    badgeText: (
      <span>
        Name <FontAwesomeIcon icon={faSortAlphaUp} />
      </span>
    ),
    subHeadingText: "Sorting Results from Z to A",
  },
  DistanceAsc: {
    value: 3,
    name: "closest",
    displayName: `Distance: Closest First`,
    badgeText: "Closest",
    subHeadingText: "Sorting Results on closest to",
  },
  DistanceDesc: {
    value: 4,
    name: "furthest",
    displayName: `Distance: Furthest First`,
    badgeText: "Furthest",
    subHeadingText: "Sorting Results on furthest from",
  },
  MostPopular: {
    value: 5,
    name: "mostpopular",
    displayName: `Popularity: High to Low`,
    badgeText: (
      <span>
        Popularity <FontAwesomeIcon icon={faThumbsUp} />
      </span>
    ),
    subHeadingText: "Sorting Results from most popular",
  },
  LeastPopular: {
    value: 6,
    name: "leastpopular",
    displayName: `Popularity: Low to High`,
    badgeText: (
      <span>
        Popularity <FontAwesomeIcon icon={faThumbsDown} />
      </span>
    ),
    subHeadingText: "Sorting Results from least popular",
  },
  LeastExpensive: {
    value: 7,
    name: "leastexpensive",
    displayName: "Cost: Low to High",
    badgeText: (
      <span>
        Cost <FontAwesomeIcon icon={faSortAmountUp} />
      </span>
    ),
    subHeadingText: "Sorting Results from least expensive",
  },
  MostExpensive: {
    value: 8,
    name: "mostexpensive",
    displayName: "Cost: High to Low",
    badgeText: (
      <span>
        Cost <FontAwesomeIcon icon={faSortAmountDown} />
      </span>
    ),
    subHeadingText: "Sorting Results from most expensive",
  },
};

export const attractionListSortTypeEnumList = [
  attractionListSortTypeEnums.NoSort,
  attractionListSortTypeEnums.AlphabeticalAsc,
  attractionListSortTypeEnums.AlphabeticalDesc,
  attractionListSortTypeEnums.DistanceAsc,
  attractionListSortTypeEnums.DistanceDesc,
  attractionListSortTypeEnums.MostPopular,
  attractionListSortTypeEnums.LeastPopular,
  attractionListSortTypeEnums.LeastExpensive,
  attractionListSortTypeEnums.MostExpensive,
];

export const sortTypesRequiringDistance = [
  attractionListSortTypeEnums.DistanceAsc,
  attractionListSortTypeEnums.DistanceDesc,
];

export const categorySortTypes = {
  Rank: {
    value: 0,
    name: "rank",
    displayName: "Rank",
  },
  AlphabeticalAsc: {
    value: 1,
    name: "az",
    displayName: `Name: A to Z`,
  },
  AlphabeticalDesc: {
    value: 2,
    name: "za",
    displayName: `Name: Z to A`,
  },
};

export const categorySortTypeList = [
  categorySortTypes.Rank,
  categorySortTypes.AlphabeticalAsc,
  categorySortTypes.AlphabeticalDesc,
];

export const regionSortTypes = {
  AlphabeticalAsc: {
    value: 0,
    name: "az",
    displayName: `Name: A to Z`,
  },
  AlphabeticalDesc: {
    value: 1,
    name: "za",
    displayName: `Name: Z to A`,
  },
};

export const regionSortTypeList = [
  regionSortTypes.Rank,
  regionSortTypes.AlphabeticalAsc,
  regionSortTypes.AlphabeticalDesc,
];

export const distanceFilterValues = {
  AnyDistance: {
    value: 0,
    name: "anydistance",
    displayName: "Any Distance",
    milesValue: 0,
  },
  WithinOne: {
    value: 1,
    name: "within1",
    displayName: "Within 1 Mile",
    milesValue: 1,
  },
  WithinFive: {
    value: 2,
    name: "within5",
    displayName: "Within 5 Mile",
    milesValue: 5,
  },
  WithinTen: {
    value: 3,
    name: "within10",
    displayName: "Within 10 Mile",
    milesValue: 10,
  },
  WithinTwentyFive: {
    value: 4,
    name: "within25",
    displayName: "Within 25 Mile",
    milesValue: 25,
  },
  WithinFifty: {
    value: 5,
    name: "within50",
    displayName: "Within 50 Mile",
    milesValue: 50,
  },
};

export const distanceFilterValueList = [
  distanceFilterValues.AnyDistance,
  distanceFilterValues.WithinOne,
  distanceFilterValues.WithinFive,
  distanceFilterValues.WithinTen,
  distanceFilterValues.WithinTwentyFive,
  distanceFilterValues.WithinFifty,
];

export const featuredBannerOrientation = {
  Left: {
    value: 0,
    name: "left",
  },
  Right: {
    value: 1,
    name: "right",
  },
};

export const attractionPageParams = {
  Page: {
    value: 0,
    name: "page",
  },
  Sort: {
    value: 1,
    name: "sort",
  },
  Category: {
    value: 2,
    name: "category",
  },
  Region: {
    value: 3,
    name: "region",
  },
  Search: {
    value: 4,
    name: "search",
  },
  Distance: {
    value: 5,
    name: "distance",
  },
  Facility: {
    value: 7,
    name: "facility",
  },
};

export const attractionEditSaveType = {
  SaveChanges: 0,
  Deactivate: 1,
  Reactivate: 2,
  RejectSubmission: 3,
  ApproveSubmission: 4,
};

export const attractionPageParamList = [
  attractionPageParams.Page,
  attractionPageParams.Sort,
  attractionPageParams.Category,
  attractionPageParams.Region,
  attractionPageParams.Search,
  attractionPageParams.Distance,
];

export const createEditType = {
  Create: {
    value: 0,
    displayName: "Create",
  },
  Edit: {
    value: 1,
    displayName: "Edit",
  },
};

export const attractionPageType = {
  View: {
    value: 0,
    displayName: "View",
  },
  Create: {
    value: 1,
    displayName: "Create",
  },
  Edit: {
    value: 2,
    displayName: "Edit",
  },
  Submit: {
    value: 3,
    displayName: "Submit",
  },
  EditSubmission: {
    value: 4,
    displayName: "Edit Submission",
  },
};

export const adminObjectTypes = {
  Root: 0,
  List: 1,
  Manage: 2,
};

export const adminAreas = {
  None: 0,
  Attractions: 1,
  AttractionSubmissions: 2,
  Categories: 3,
  Regions: 4,
  AttractionGroups: 5,
  FeaturedBanners: 6,
  Facilities: 7,
};
