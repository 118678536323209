// Package Imports
import React, { Component } from "react";

// Dayhoot Components
import { PrivacyPolicy, InfoPageBodyContent } from "../components/infoPage-components";

// Dayhoot Functions & Scripts
import { setPageTitleText } from "../scripts/utils";

class Privacy extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPageLoaded: false,
		};
	}

	componentDidMount() {
		setPageTitleText("Privacy Policy");
		this.setState({ isPageLoaded: true });
	}

	render() {
		return (
			<InfoPageBodyContent
				id={"privacy"}
				title={"Privacy on Dayhoot"}
				bodyHtml={<PrivacyPolicy />}
			/>
		);
	}
}

export default Privacy;