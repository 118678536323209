import { createMuiTheme } from "@material-ui/core/styles";

const actionColours = require("../styling/actionColours.json");
const mainColours = require("../styling/brandColours.json");
const standardColours = require("../styling/standardColours.json");

export const dayhootTheme = createMuiTheme({
	palette: {
		// Main
		primary: mainColours.primary,
		secondary: mainColours.secondary,
		tertiary: mainColours.tertiary,
		light: mainColours.light,
		dark: mainColours.dark,
		// Actions
		success: actionColours.success,
		danger: actionColours.danger,
		warning: actionColours.warning,
		info: actionColours.info,
		// Standard
		grey: standardColours.grey,
		green: standardColours.green,
		blue: standardColours.blue,
		purple: standardColours.purple,
		red: standardColours.red,
		orange: standardColours.orange,
		yellow: standardColours.yellow,
	},
	shape: {
		borderRadius: "2em",
	},
});
