type AttractionCardImageProps = { imageUrl: string; imageDescription?: string };

const AttractionCardImage = ({
  imageUrl,
  imageDescription,
}: AttractionCardImageProps) => {
  return (
    <img
      className="attractions-image"
      src={imageUrl}
      alt={imageDescription || `This is an example image description`}
    />
  );
};
export default AttractionCardImage;
