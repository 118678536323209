// Package Imports
import React from "react";
import { NavLink } from "react-router-dom";

// Dayhoot Functions & Scripts
import { getTileFromName } from "../scripts/utils";
import { regionTileList } from "../config/regionTiles";

// Dayhoot Components
import { TileContainer } from "../components/common-components";
import { attractionListSortTypeEnums } from "../config/enums";

export function RegionTileContainer({ region, numberOfTiles }) {
	const { id, regionName, displayName } = region;
	const desktopWidth = numberOfTiles ? Math.round(12/numberOfTiles) : 3;
	return (
		<div className={`col-md-6 col-lg-${desktopWidth} mt-1 mb-1`}>
			<NavLink key={id + regionName} to={`/regions/${regionName}?sort=${attractionListSortTypeEnums.MostPopular.value}`}>
				<TileContainer
					imageSource={getTileFromName(regionTileList, regionName)}
					displayName={displayName}
				/>
			</NavLink>
		</div>
	);
}

export function RegionTileList({ regions, numberOfTiles }) {
	const componentsToRender = regions.map((region, i) => (
		<RegionTileContainer key={region.id + region.regionName} region={region} numberOfTiles={numberOfTiles} />
	));
	return <div className="row content-row">{componentsToRender}</div>;
}
