// Package Imports
import React from "react";

// Dayhoot Scripts
import { getCityLocationDetails } from "../scripts/utils";
import {
	getDefaultUserCityIndexFromCityList,
	toggleLocationPopupElement,
	getToggleGeolocationAlertText,
} from "../component-functions/settings-core";

// Dayhoot Config
import { WebConfig } from "../config/webconfig";

// Fonts & Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faLocationArrow } from "@fortawesome/free-solid-svg-icons";

export function SettingsSectionHeading({ headingText }) {
	return (
		<div className="row pt-3">
			<div className="col-md-12 text-center text-md-left">
				<p className="h5">{headingText}</p>
			</div>
		</div>
	);
}

export function ToggleableSetting({ data }) {
	const {
		id,
		labelText,
		accessibilityText,
		value,
		onChange,
		defaultChecked,
		disabled,
		showHr,
	} = data;
	return (
		<div className="row py-2">
			<div className="col-12">
				<div className="d-flex flex-row align-items-center">
					<div className="p-0 flex-fill">
						<span className="wordbreak">{labelText}</span>
					</div>
					<div className="ml-auto pl-2">
						<label className="switch">
							<input
								id={id}
								type="checkbox"
								value={value}
								onChange={onChange}
								defaultChecked={defaultChecked}
								disabled={disabled}
							/>
							<span className="slider round"></span>
							<span className="accessibility-item wordbreak">{accessibilityText}</span>
						</label>
					</div>
				</div>
				{showHr !== false ? (<hr className="mb-2" />) : null}
			</div>
		</div>
	);
}

export function DropdownSetting({ data }) {
	const { titleText, dropdownList } = data;
	return (
		<div className="row">
			<div className="col-md-12 text-center text-md-left">
				<span className="h-100 m-0 wordbreak">{titleText}</span>
			</div>
			<div className="col-md-12 text-center">{dropdownList}</div>
		</div>
	);
}

export function SettingsBody({
	cookieSettingsDto,
	locationSettingsDto,
	personalisationSettingsDto,
}) {
	return (
		<div>
			<CookieSettings data={cookieSettingsDto} />
			<LocationSettings data={locationSettingsDto} />
			<PersonalisationSettings data={personalisationSettingsDto} />
		</div>
	);
}

function CitiesDropdownListItems({ data }) {
	// orderedList = data.citiesList.sort((a, b) => sortArray(a.cityName, b.cityName, attractionListSortTypeEnums.AlphabeticalAsc));
	return data.citiesList.map((city, i) => {
		return (
			<option key={i} value={i}>
				{city.cityName}
			</option>
		);
	});
}

export function CitiesDropdownList({ data }) {
	const { values, citiesList, handleFormChange } = data;
	const defaultUserCityIndex = getDefaultUserCityIndexFromCityList({
		citiesList: citiesList,
		defaultUserLocation: values.defaultUserLocation,
	});

	return (
		<div className="my-2">
			<label className="accessibility-item" htmlFor="citySelector">
				Change default location
			</label>
			<select
				id="citySelector"
				className="form-control"
				onChange={handleFormChange}
				defaultValue={defaultUserCityIndex}
			>
				<CitiesDropdownListItems data={{ citiesList: citiesList }} />
			</select>
		</div>
	);
}

export function CookieSettings({ data }) {
	const { personalCookiesDto, analyticsCookiesDto, advertCookies } = data;
	return (
		<div>
			<SettingsSectionHeading headingText={"Cookie Settings"} />
			<ToggleableSetting data={personalCookiesDto} />
			<ToggleableSetting data={analyticsCookiesDto} />
			<ToggleableSetting data={advertCookies} />
		</div>
	);
}

export function LocationSettings({ data }) {
	const { defaultUserCookie, useGeolocationCookie, locationDropdownDto } = data;
	return (
		<div>
			<SettingsSectionHeading headingText={"Location Settings"} />
			<ToggleLocationPopup data={defaultUserCookie} />
			<ToggleableSetting data={useGeolocationCookie} />
			<DropdownSetting data={locationDropdownDto} />
		</div>
	);
}

export function EnabledCookiesNotice(cookiesAreDisabled) {
	if (cookiesAreDisabled) {
		return (
			<div className="row py-1">
				<div className="col-12">
					<div className="col-md-12 alert alert-info m-0">
						<span>
							<FontAwesomeIcon icon={faInfoCircle}/> You must accept personalisation cookies to make changes to the
							following settings.
						</span>
					</div>
				</div>
			</div>
		);
	} else return null;
}

export function PersonalisationSettings({ data }) {
	const {
		cookiesAreDisabled,
		darkModeCookieDto,
		values,
		handleFormChange,
	} = data;
	return (
		<div>
			{WebConfig.enabledPersonalisationOptions ? (
				<SettingsSectionHeading headingText={"Personalisation Settings"} />
			) : null }
			{WebConfig.enabledPersonalisationOptions ? (
				<EnabledCookiesNotice cookiesAreDisabled={cookiesAreDisabled} />
			) : null }
			{WebConfig.enableDistanceMeasurementToggle ? (
				<ChangedDistanceUnit
					data={{
						values: values,
						handleFormChange: handleFormChange,
						cookiesAreDisabled: cookiesAreDisabled,
					}}
				/>
			) : null}
			{WebConfig.enableDarkModeToggle ? (
				<ToggleableSetting data={darkModeCookieDto} />
			) : null}
		</div>
	);
}

export function ChangedDistanceUnit({ data }) {
	const { values, cookiesAreDisabled } = data;
	return (
		<div className="row py-2">
			<div className="col-md-10 text-center text-md-left my-auto">
				<span className="h-100 m-0">
					Show the distances in kilometers (km) instead of miles (mi)
				</span>
			</div>
			<div className="col-md-2 text-center my-auto">
				<label className="switch">
					<input
						id="distanceUnitKm"
						type="checkbox"
						value={values.distanceUnitKm}
						onChange={this.handleFormChange}
						defaultChecked={values.distanceUnitKm}
						disabled={cookiesAreDisabled}
					/>
					<span className="slider round"></span>
					<span className="accessibility-item">
						Set distances in kilometers instead of miles
					</span>
				</label>
			</div>
		</div>
	);
}

export function ToggleLocationPopup({ data }) {
	const {
		useGeolocationData,
		userLocation,
		userDefaultLocation,
		userLatitude,
		userLongitude,
		titleText,
	} = data;
	const defautlLocationDetails = getCityLocationDetails(userDefaultLocation);
	const geolocationAlertText = getToggleGeolocationAlertText({
		useGeolocationData: useGeolocationData,
		userLatitude: userLatitude,
		userLongitude: userLongitude,
		userLocation: userLocation,
		userDefaultLocation: userDefaultLocation,
		defautlLocationDetails: defautlLocationDetails,
	});

	return (
		<div>
			<div className="row py-2">
				<div className="col-md-10 text-center text-md-left my-auto">
					<button
						className="btn btn-bordered"
						onClick={toggleLocationPopupElement}
					>
						{titleText}
					</button>
				</div>
			</div>
			<div id="locationPopup" className="row py-1" style={{ display: "none" }}>
				<div className="col-12">
					<div className="col-md-12 alert alert-info m-0">
						<FontAwesomeIcon className="pr-1" icon={faLocationArrow} />
						{geolocationAlertText}
					</div>
				</div>
			</div>
		</div>
	);
}
