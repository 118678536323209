// Package Imports
import React, { Component } from "react";
import { withCookies } from "react-cookie";

// Dayhoot Functions & Scripts
import { setPageTitleText } from "../scripts/utils";
import { getFacilities } from "../scripts/api-core";

// Dayhoot Components
import { PageLoading } from "../components/common-components";
import { FacilityTileList } from "../components/facilities-components";
import { PageTitle } from "../components";

class Facilities extends Component {
	state = {
		facilities: {},
		attractions: [],
		isPageLoaded: false,
		cookies: this.props.cookies.getAll(),
	};

	async componentDidMount() {
		const { facilities } = await this.renderMyData();
		this.setState({
			facilities: facilities.results,
			isPageLoaded: true,
		});
		setPageTitleText("Facilities");
	}

	async renderMyData() {
		return {
			facilities: await getFacilities({}),
		};
	}

	render() {
		const { isPageLoaded, facilities } = this.state;
		if (isPageLoaded) {
			return (
				<div>
					<div id="root"></div>
					<div className="container mt-3">
						<PageTitle
							headingId={"facilitiesTitle"}
							headingText={"Facilities"}
						/>
						<FacilityTileList facilities={facilities} />
					</div>
				</div>
			);
		} else return <PageLoading />;
	}
}

export default withCookies(Facilities);
