import { Component } from "react";
import { Form, InputGroup, FormControl } from "react-bootstrap";
import { withCookies } from "react-cookie";
import { setPageTitleText } from "../scripts/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit } from "@fortawesome/free-solid-svg-icons";
import RouterBreadcrumbs from "../components/routerBreadcrumbs";

class RetrieveSubmittedAttraction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPageLoaded: false,
      id: "",
      showAttraction: false,
      showIncorrectDetailsMessage: false,
      attraction: null,
    };
  }

  async componentDidMount() {
    setPageTitleText("Retrieve Submission");
    this.setState({
      isPageLoaded: true,
      id: "",
    });
  }

  updateValue(field, value) {
    this.setState({
      [field]: value,
    });
  }

  async tryGetAttraction() {
    const { id } = this.state;
	// TODO we need to check if it exists before doing this
    this.props.history.push(`/attraction/editsubmission/${id}`);
  }

  render() {
    if (this.state.isPageLoaded) {
      const { showAttraction, showIncorrectDetailsMessage } = this.state;
      const pathName = this.props.location.pathname;
      const isAdminRoute = pathName.includes("admin/");
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Retrieve Submitted Attraction</h1>
              {isAdminRoute ? <RouterBreadcrumbs pathName={pathName} /> : null}
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  {!showAttraction ? (
                    <div className="row">
                      <div className="col-lg-6">
                        <Form>
                          <b>Attraction Submission ID</b>
                          <InputGroup>
                            <FormControl
                              type="text"
                              placeholder="e.g. c8h9S2b0"
                              id="id"
                              name="id"
                              value={this.state.id}
                              onChange={e =>
                                this.updateValue("id", e.target.value)
                              }
                            />

                            <InputGroup.Append>
                              <button
                                className="btn btn-primary"
                                onClick={() => this.tryGetAttraction()}
                              >
                                <FontAwesomeIcon
                                  icon={faUserEdit}
                                ></FontAwesomeIcon>
                                <span className="pl-1">
                                  Retrieve Submission
                                </span>
                              </button>
                            </InputGroup.Append>
                          </InputGroup>
                        </Form>
                        {showIncorrectDetailsMessage ? (
                          <div className="mb-3">
                            <em className="forced-invalid-feedback">
                              The details entered did not match a submitted
                              attraction. Please check the spelling of the
                              attraction name and ensure the reference number
                              matches that which you took note of on submission.
                              If this persists, don't hesistate to contact us on{" "}
                              <a
                                href="mailto:info@dayhoot.co.uk"
                                className="hyperlink"
                              >
                                info@dayhoot.co.uk
                              </a>{" "}
                              and we'll be in touch.
                            </em>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else return null;
  }
}

export default withCookies(RetrieveSubmittedAttraction);
