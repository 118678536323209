// Package Imports
import React, { Component } from "react";

// Dayhoot Components
import { InfoPageBodyContent } from "../components/infoPage-components";

// Fonts & Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
	faFacebook,
	faTwitter,
	faInstagram,
} from "@fortawesome/free-brands-svg-icons";

// Dayhoot Config
import { setPageTitleText } from "../scripts/utils";
import { WebConfig } from "../config/webconfig";
class Contact extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPageLoaded: false,
		};
	}

	componentDidMount() {
		this.setState({ isPageLoaded: true });
		setPageTitleText("Contact");
	}

	render() {
		const bodyHtml = (
			<div className="container">
				<div className="page-margin">
					<div className="row">
						<div className="col-md-12 pt-3 px-3">
							<div className="pt-3 pb-0">
								<div className="px-0 px-sm-5 pt-3">
									<div className="row justify-content-center">
										<div className="col-6 col-md-3 text-center h5">
											<button className="btn">
												<div>
													<a
														href={WebConfig.facebookUrl}
														target="_blank"
														rel="noopener noreferrer"
													>
														<FontAwesomeIcon
															className="contact-icon"
															icon={faFacebook}
														/>
														<p className="font-weight-bold pt-1">Facebook</p>
													</a>
												</div>
											</button>
										</div>
										<div className="col-6 col-md-3 text-center h5">
											<button className="btn">
												<div>
													<a
														href={WebConfig.twitterUrl}
														target="_blank"
														rel="noopener noreferrer"
													>
														<FontAwesomeIcon
															className="contact-icon"
															icon={faTwitter}
														/>
														<p className="font-weight-bold pt-1">Twitter</p>
													</a>
												</div>
											</button>
										</div>
										<div className="col-6 col-md-3 text-center h5">
											<button className="btn">
												<div>
													<a
														href={WebConfig.instagramUrl}
														target="_blank"
														rel="noopener noreferrer"
													>
														<FontAwesomeIcon
															className="contact-icon"
															icon={faInstagram}
														/>
														<p className="font-weight-bold pt-1">Instagram</p>
													</a>
												</div>
											</button>
										</div>
										<div className="col-6 col-md-3 text-center h5">
											<button
												className="btn"
												id="emailLink"
												type="submit"
												onClick={() =>
													(window.location.href = `mailto:${WebConfig.infoEmailAddress}`)
												}
											>
												<div>
													<FontAwesomeIcon
														className="contact-icon"
														icon={faEnvelope}
													/>
													<p className="font-weight-bold pt-1">Email</p>
												</div>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);

		return (
			<InfoPageBodyContent
				id={"contact"}
				title={"Contact the Dayhoot Team"}
				bodyHtml={bodyHtml}
			/>
		);
	}
}

export default Contact;
