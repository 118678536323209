// Package Imports
import 'react-bootstrap';

// Dayhoot Components
import { PageTitle } from "../components";

export function getNoResultsTitleText(titleDto){
    let pageTitleDto;
    if (titleDto){
        if (titleDto.hasFiltersApplied)
            pageTitleDto = {
                headingText: "There are no Dayhoots matching the applied filters.",
                isCentered: true,
                subHeadingText: "Please try deselecting some to discover other incredible Dayhoots!"
            };
        else if (titleDto.hasSearchTerm)
            pageTitleDto = {
                headingText: "There are no Dayhoots matching your search term.",
                isCentered: true,
                subHeadingText: "Please try searching for something else to discover some incredible Dayhoots!"
            };
        else if (titleDto.isAttractionPage)
            pageTitleDto = {
                headingText: "This attraction has no related attractions.",
                isCentered: true,
                subHeadingText: "Check out one of the other categories for some more incredible Dayhoots!"
            };
        else
            pageTitleDto = { 
                headingText: "Oops! There are no results.", 
                isCentered: true 
            };
    }
    else {
        pageTitleDto = { 
            headingText: "Oops! There are no results.", 
            isCentered: true 
        };
    }
    return (<PageTitle headingText={pageTitleDto.headingText} subHeadingText={pageTitleDto.subHeadingText} isCentered={pageTitleDto.isCentered} />)
}