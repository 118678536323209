import { faCheck, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { withCookies } from "react-cookie";
import { AdminBanner } from "../components/admin-core-components";
import { setPageTitleText } from "../scripts/utils";
import { authenticateAdminUser } from "../scripts/api-core";
import RouterBreadcrumbs from "../components/routerBreadcrumbs";
import { AdminAreaConfigs } from "../config/adminPaths";
import {
  getAdminAuthenticationDetails,
  setAdminAuthenticationDetails,
  unsetAdminAuthenticationDetails,
} from "../scripts/admin-auth";

class AdminHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPageLoaded: false,
      isAuthenticated: false,
      isUnauthorized: false,
      token: null,
    };
  }

  async componentDidMount() {
    setPageTitleText("Admin Home");
    const { token } = getAdminAuthenticationDetails(this.props.cookies);
    this.setState({
      isPageLoaded: true,
      isAuthenticated: !!token,
      token: token,
      isUnauthorized: false, // probably do this if the log-in fails
    });
  }

  async logIn() {
    this.setState({ isPageLoaded: false });

    // make the API authentication call here
    const { response, result } = await authenticateAdminUser({
      username: document.getElementById("userName").value || undefined,
      password: document.getElementById("password").value || undefined,
    });

    if (result) {
      const { token } = setAdminAuthenticationDetails(
        this.props.cookies,
        response
      );
      this.setState({
        isPageLoaded: true,
        isAuthenticated: !!token,
        isUnauthorized: false,
        token: token,
      });
    } else {
      this.setState({
        isPageLoaded: true,
        isAuthenticated: false,
        isUnauthorized: true,
      });
    }
    window.location.reload();
  }

  logOut() {
    const { token } = unsetAdminAuthenticationDetails(this.props.cookies);
    this.setState({
      isPageLoaded: true,
      isAuthenticated: !!token,
      isUnauthorized: false,
      token: token,
    });
    window.location.reload();
  }

  render() {
    if (!this.state.isAuthenticated) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Administration Log-in</h1>
              <div className="card">
                <div className="card-body">
                  <Form.Row>
                    <Form.Group as={Col} md={6}>
                      <Form.Label>User Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="john.smith"
                        name="userName"
                        id="userName"
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        name="password"
                        id="password"
                      />
                    </Form.Group>
                  </Form.Row>
                  <Button variant="primary" onClick={this.logIn.bind(this)}>
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                    <span className="ml-1">Log In</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex flex-row align-items-center">
                <div className="p-0">
                  <h1>Admin Home</h1>
                  <RouterBreadcrumbs pathName={this.props.location.pathname} />
                </div>
                <div className="ml-auto pl-2">
                  <button
                    className="btn btn-sm btn-warning"
                    onClick={this.logOut.bind(this)}
                  >
                    <FontAwesomeIcon icon={faPowerOff}></FontAwesomeIcon>
                    <span className="ml-1">Log Out</span>
                  </button>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <Row>
                    {AdminAreaConfigs.filter((x) => !x.isTileHidden)
                      .sort((l, r) => (l.label < r.label ? -1 : 1))
                      .map((config) => (
                        <Col md={6} lg={4} key={config.value}>
                          <AdminBanner config={config} />
                        </Col>
                      ))}
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withCookies(AdminHome);
