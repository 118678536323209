import easternengland from '../img/regionTiles/easternengland.jpg';
import eastmidlands from '../img/regionTiles/eastmidlands.jpg';
import london from '../img/regionTiles/london.jpg';
import northeastengland from '../img/regionTiles/northeastengland.jpg';
import northscotland from '../img/regionTiles/northscotland.jpg';
import northwestengland from '../img/regionTiles/northwestengland.jpg';
import southeastengland from '../img/regionTiles/southeastengland.jpg';
import southernengland from '../img/regionTiles/southernengland.jpg';
import southscotland from '../img/regionTiles/southscotland.jpg';
import southwestengland from '../img/regionTiles/southwestengland.jpg';
import wales from '../img/regionTiles/wales.jpg';
import westmidlands from '../img/regionTiles/westmidlands.jpg';
import yorkshire from '../img/regionTiles/yorkshire.jpg';
import northernireland from '../img/regionTiles/northernireland.jpg';

// Mappings for all icons to their category name
export const regionTileList = [
    { name: 'easternengland', tile: easternengland},
    { name: 'eastmidlands', tile: eastmidlands},
    { name: 'london', tile: london},
    { name: 'northeastengland', tile: northeastengland},
    { name: 'northscotland', tile: northscotland},
    { name: 'northwestengland', tile: northwestengland},
    { name: 'southeastengland', tile: southeastengland},
    { name: 'southernengland', tile: southernengland},
    { name: 'southscotland', tile: southscotland},
    { name: 'southwestengland', tile: southwestengland},
    { name: 'wales', tile: wales},
    { name: 'westmidlands', tile: westmidlands},
    { name: 'yorkshire', tile: yorkshire},
    { name: 'northernireland', tile: northernireland}
];