import { faMapMarkerAlt as MapMarker } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { getAttractionItemDistanceAway } from "../../scripts/utils";
import {
  faChevronRight as ChevronRight,
  faExternalLinkAlt as ExternalLink,
} from "@fortawesome/free-solid-svg-icons";
import { AttractionCardFooterLink } from ".";

type AttractionCardFooterProps = {
  attraction: any;
  cookies: any;
};

const AttractionCardFooter = ({
  attraction,
  cookies,
}: AttractionCardFooterProps) => {
  const [distanceAway, setDistanceAway] = useState<string | undefined>();

  useEffect(() => {
    if (attraction && cookies && attraction.location?.coordinates) {
      const { latitude, longitude } = attraction.location.coordinates;
      setDistanceAway(
        getAttractionItemDistanceAway({ cookies, latitude, longitude })
      );
    } else setDistanceAway(undefined);
    return () => setDistanceAway(undefined);
  }, [attraction, cookies]);

  return (
    <div className="d-flex justify-content-between">
      <div className="p-0">
        {distanceAway && (
          <b className="text-left text-nowrap card-footer-text">
            <FontAwesomeIcon className="mr-2" icon={MapMarker} />
            {distanceAway}
          </b>
        )}
      </div>
      <div className="p-0">
        <b className="text-right text-nowrap card-footer-text">
          <AttractionCardFooterLink
            icon={attraction?.detailedView ? ChevronRight : ExternalLink}
            text={attraction?.detailedView ? "Show Details" : "Visit Website"}
          />
        </b>
      </div>
    </div>
  );
};
export default AttractionCardFooter;
