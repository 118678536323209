// Package Imports
import React, { Component } from "react";
import { withCookies } from "react-cookie";

// Dayhoot Functions & Scripts
import { setupGoogleAnalytics } from "./scripts/dh-analytics";

// Dayhoot Config
import { WebConfig } from "./config/webconfig";

// Dayhoot Components
import { MainPageContent } from "./components/main-components";
import { getLocalInformation } from "./scripts/api-core";
import {
  cookieDictionary,
  getCookie,
  removeCookiesIfOutdatedByPolicy,
  setCookie,
} from "./storage/cookies";
import { isEmptyObject } from "jquery";
import { getCityLocationDetails } from "./scripts/utils";

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userLatitude: WebConfig.defaultCoordinates.latitude,
      userLongitude: WebConfig.defaultCoordinates.longitude,
      userLocation: WebConfig.defaultLocation,
      promptUserToSetLocation: false,
      isPageLoaded: false,
    };
  }

  async componentDidMount() {
    await this.renderData(this.props.cookies);
  }

  async trySetPositionFromGeolocation(cookies, position) {
    const data = await getLocalInformation({ coordinates: position.coords });

    // Once we have the user's geolocation, we can then set the cookies
    const { userLatitude, userLongitude, userLocation } = setLocationCookies({
      cookies: cookies,
      coords: {
        userLatitude: position.coords.latitude,
        userLongitude: position.coords.longitude,
      },
      location: data.locality,
      isUsingGeolocation: true,
    });

    this.setState({
      userLatitude: userLatitude,
      userLongitude: userLongitude,
      userLocation: userLocation,
      promptUserToSetLocation: false,
      isPageLoaded: true,
    });
  }

  handleGeolocationError(cookies, positionAndGeolocationDto, error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.log("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        console.log("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        console.warn("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
      default:
        console.error("An error occured with the Geolocation request.");
        break;
    }

    this.setPositionWithoutGeolocation(cookies, positionAndGeolocationDto);
  }

  setPositionWithoutGeolocation(cookies, positionAndGeolocationDto) {
    const { positionDto, hasDefaultLocation } = positionAndGeolocationDto;
    if (hasDefaultLocation) {
      this.setState({
        userLatitude: positionDto.coords.userLatitude,
        userLongitude: positionDto.coords.userLongitude,
        userLocation: positionDto.location,
        promptUserToSetLocation: false,
        isPageLoaded: true,
      });
      this.setUserLocationCookies(cookies, {
        userLatitude: positionDto.coords.userLatitude,
        userLongitude: positionDto.coords.userLongitude,
        userLocationName: positionDto.location,
      });
    } else {
      this.setState({
        userLatitude: WebConfig.defaultCoordinates.latitude,
        userLongitude: WebConfig.defaultCoordinates.longitude,
        userLocation: WebConfig.defaultLocation,
        promptUserToSetLocation: true,
        isPageLoaded: true,
      });
      this.setUserLocationCookies(cookies, {
        userLatitude: WebConfig.defaultCoordinates.latitude,
        userLongitude: WebConfig.defaultCoordinates.longitude,
        userLocationName: WebConfig.defaultLocation,
      });
    }
  }

  setUserLocationCookies(
    cookies,
    { userLocationName, userLatitude, userLongitude }
  ) {
    setCookie(
      cookies,
      cookieDictionary.SettingUserLocationName.key,
      userLocationName
    );
    setCookie(
      cookies,
      cookieDictionary.SettingUserLocationLatitude.key,
      userLatitude
    );
    setCookie(
      cookies,
      cookieDictionary.SettingUserLocationLongitude.key,
      userLongitude
    );
    setCookie(
      cookies,
      cookieDictionary.SettingDefaultUserLocationName.key,
      userLocationName
    );
  }

  async trySetUserPositionWithGeolocator(cookies) {
    const positionAndGeolocationDto = getPositionAndGeolocationDetails(cookies);
    const { positionDto, canUseGeolocation } = positionAndGeolocationDto;

    if (canUseGeolocation) {
      navigator.geolocation.getCurrentPosition(
        await this.trySetPositionFromGeolocation.bind(
          this,
          positionDto.cookies
        ),
        this.handleGeolocationError.bind(this, {
          cookies,
          positionAndGeolocationDto,
        }),
        { timeout: 5000 }
      );
    } else
      this.setPositionWithoutGeolocation(cookies, positionAndGeolocationDto);
  }

  async renderData(cookies) {
    this.setState({
      isPageLoaded: false,
    });

    removeCookiesIfOutdatedByPolicy(cookies);
    setupGoogleAnalytics(cookies);
    await this.trySetUserPositionWithGeolocator(cookies);
  }

  render() {
    return (
      <MainPageContent
        isPageLoaded={this.state.isPageLoaded}
        props={this.props}
        promptUserToSetLocation={this.state.promptUserToSetLocation}
        cookies={this.props.cookies}
      />
    );
  }
}

// Regardless of whether the user has specified a location or not, we set a cookie of coordinates here
// The coordinates may or may not be the user's current location. If not, it uses the default of London userLatitude: 51.5074, userLongitude: 0.1278
function setLocationCookies(positionDto) {
  const { cookies, coords, location, isUsingGeolocation } = positionDto;
  const { userLatitude, userLongitude } = coords;

  // If the geolocation is not used by default we want to populate the cookie default location
  if (!isUsingGeolocation) {
    const storedUserLocation = getCookie(
      cookies,
      cookieDictionary.SettingDefaultUserLocationName.key
    );

    const { cityName, latitude, longitude } =
      getCityLocationDetails(storedUserLocation);

    setCookie(
      cookies,
      cookieDictionary.SettingUserLocationName.key,
      cityName || WebConfig.defaultLocation
    );
    setCookie(
      cookies,
      cookieDictionary.SettingUserLocationLatitude.key,
      latitude || WebConfig.defaultCoordinates.latitude
    );
    setCookie(
      cookies,
      cookieDictionary.SettingUserLocationLongitude.key,
      longitude || WebConfig.defaultCoordinates.longitude
    );

    const currentDefaultLocation = getCookie(
      cookies,
      cookieDictionary.SettingDefaultUserLocationName.key
    );

    setCookie(
      cookies,
      cookieDictionary.SettingDefaultUserLocationName.key,
      currentDefaultLocation || WebConfig.defaultLocation
    );
  } else {
    setCookie(
      cookies,
      cookieDictionary.SettingDefaultUserLocationName.key,
      location
    );
    setCookie(cookies, cookieDictionary.SettingUserLocationName.key, location);
    setCookie(
      cookies,
      cookieDictionary.SettingUserLocationLatitude.key,
      userLatitude
    );
    setCookie(
      cookies,
      cookieDictionary.SettingUserLocationLongitude.key,
      userLongitude
    );
  }

  return {
    userLatitude: userLatitude,
    userLongitude: userLongitude,
    userLocation: location,
    isPageLoaded: true,
  };
}

function getPositionAndGeolocationDetails(cookies) {
  const geolocation = navigator.geolocation;
  return {
    positionDto: {
      cookies: cookies,
      coords: {
        userLatitude: getCookie(
          cookies,
          cookieDictionary.SettingUserLocationLatitude.key
        ),
        userLongitude: getCookie(
          cookies,
          cookieDictionary.SettingUserLocationLongitude.key
        ),
      },
      location: getCookie(
        cookies,
        cookieDictionary.SettingUserLocationName.key
      ),
    },
    hasDefaultLocation: !isEmptyObject(
      getCookie(cookies, cookieDictionary.SettingUserLocationName.key)
    ),
    canUseGeolocation:
      navigator.geolocation &&
      getCookie(cookies, cookieDictionary.SettingShouldUseGeolocation.key),
    isUsingGeolocation: !isEmptyObject(geolocation),
  };
}

export default withCookies(Main);
