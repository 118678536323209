import {
  faBan,
  faCheck,
  faCloudDownloadAlt,
  faEdit,
  faInfoCircle,
  faSave,
  faTimes,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import {
  AttractionEmail,
  AttractionFacebook,
  AttractionImage,
  AttractionInstagram,
  AttractionPhoneNumber,
  AttractionTags,
  AttractionTwitter,
  AttractionWebsite,
  AttractionRelatedAttractionsContainer,
  AttractionRatings,
} from "../components/attraction-components";
import { Form, InputGroup } from "react-bootstrap";
import {
  FormControl,
  makeStyles,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Radio,
  RadioGroup,
  Badge,
} from "@material-ui/core";
import { getCategoryAttractions } from "../scripts/api-core";
import {
  getMainCategoryDropdownOptions,
  updateSelectedCategories,
} from "../component-functions/manageAttraction-core";
import {
  getPreHeader,
  handleCategorySelect,
  handleFacilityChange,
  setTempCoordsFromPostcode,
} from "../component-functions/attraction-edit-core";
import { setPriceRatingPopoverText } from "../component-functions/attraction-core";
import { isInputValid } from "../scripts/validation";
import { attractionEditValidationConfig as validationConfig } from "../config/manageAttraction-config";
import CustomSnackbar from "./snackbars";
import clsx from "clsx";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { SetupMap } from "../component-functions/map-core";
import { checkUrlHasHttp } from "../scripts/utils";
import { Grid } from "@mui/material";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "fixed",
    },
    appBar: {
      top: "56px",
      [theme.breakpoints.up("md")]: {
        top: "64px",
      },
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: theme.palette.light.main,
    },
    appBarShift: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer - 1,
      color: "#fff",
    },
    grow: {
      flexGrow: 1,
      marginBottom: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    sortDrawerTitleText: {
      margin: theme.spacing(0, 0.5, 0, 2.5),
      fontSize: "1em",
    },
    filterDrawerTitleText: {
      margin: theme.spacing(0, 2.5, 0, 0.5),
      fontSize: "1em",
    },
    buttonItem: {
      margin: 0,
      padding: theme.spacing(0.5, 1, 1, 0),
      borderRadius: theme.shape.borderRadius,
    },
  };
});

// #region Attraction Details Containers

export function AttractionGooglePlaceIdContainer({
  attraction,
  pagePermissions,
  updateErrors,
  isInEditorView,
  startState,
  isEditing,
  setIsEditing,
}) {
  const { googlePlaceId, update } = attraction;
  const hasGooglePlaceId = googlePlaceId && googlePlaceId !== "";

  if (isEditing && !isInEditorView) {
    return (
      <AttractionGooglePlaceIdInlineEditor
        googlePlaceId={googlePlaceId}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        update={update}
        pagePermissions={pagePermissions}
        updateErrors={updateErrors}
        startState={startState}
      />
    );
  } else {
    return (
      <AttractionGooglePlaceIdRead
        hasGooglePlaceId={hasGooglePlaceId}
        googlePlaceId={googlePlaceId}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        pagePermissions={pagePermissions}
        isInEditorView={isInEditorView}
        startState={startState}
      />
    );
  }
}

export function AttractionNameContainer({
  attraction,
  pagePermissions,
  updateErrors,
  isInEditorView,
  reRenderMap,
  startState,
  isEditing,
  setIsEditing,
}) {
  const { name, update } = attraction;
  const hasName = name && name !== "";

  if (isEditing && !isInEditorView) {
    return (
      <AttractionNameInlineEditor
        name={name}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        update={update}
        pagePermissions={pagePermissions}
        updateErrors={updateErrors}
        reRenderMap={reRenderMap}
        startState={startState}
      />
    );
  } else {
    return (
      <AttractionNameRead
        hasName={hasName}
        name={name}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        update={update}
        pagePermissions={pagePermissions}
        isInEditorView={isInEditorView}
        startState={startState}
      />
    );
  }
}

export function AttractionImageContainer({
  attraction,
  pagePermissions,
  updateErrors,
  startState,
  isEditing,
  setIsEditing,
}) {
  const { isView, isManaging } = pagePermissions;
  const { imageUrl, update } = attraction;
  const hasUrl = imageUrl && imageUrl !== "";

  return (
    <>
      {!isView && isManaging && !isEditing ? (
        <AttractionImageEditButton
          hasUrl={hasUrl}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          startState={startState}
        />
      ) : null}
      {!isView && isManaging && isEditing ? (
        <AttractionImageInlineEditor
          imageUrl={imageUrl}
          update={update}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          updateErrors={updateErrors}
          startState={startState}
        />
      ) : null}
      <AttractionImageRead
        hasUrl={hasUrl}
        imageUrl={imageUrl}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        startState={startState}
      />
    </>
  );
}

export function AttractionRatingsContainer({
  attraction,
  isEditing,
  setIsEditing,
  pagePermissions,
  startState,
  disableEdit,
}) {
  if (isEditing) return null;
  else {
    return (
      <AttractionRatingsRead
        attraction={attraction}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        pagePermissions={pagePermissions}
        startState={startState}
        disableEdit={disableEdit}
      />
    );
  }
}

export function AttractionDescriptionContainer({
  attraction,
  pagePermissions,
  updateErrors,
  startState,
  isEditing,
  setIsEditing,
}) {
  if (isEditing) {
    return (
      <AttractionDescriptionInlineEditor
        attraction={attraction}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        pagePermissions={pagePermissions}
        updateErrors={updateErrors}
        startState={startState}
      />
    );
  } else {
    const { description } = attraction;
    return (
      <AttractionDescriptionRead
        hasDescription={description && description !== ""}
        attraction={attraction}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        pagePermissions={pagePermissions}
        startState={startState}
      />
    );
  }
}

export function AttractionContactDetailsContainer({
  url,
  phoneNumber,
  emailAddress,
  isEditing,
  setIsEditing,
  pagePermissions,
  disableEdit,
}) {
  if (isEditing) return null;
  else {
    return (
      <AttractionContactDetailsRead
        url={url}
        phoneNumber={phoneNumber}
        emailAddress={emailAddress}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        pagePermissions={pagePermissions}
        disableEdit={disableEdit}
      />
    );
  }
}

export function AttractionSocialMediaLinksContainer({
  facebookUsername,
  twitterUsername,
  instagramUsername,
  isEditing,
  setIsEditing,
  pagePermissions,
  disableEdit,
}) {
  if (isEditing) return null;
  else {
    return (
      <AttractionSocialMediaLinksRead
        facebookUsername={facebookUsername}
        twitterUsername={twitterUsername}
        instagramUsername={instagramUsername}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        pagePermissions={pagePermissions}
        disableEdit={disableEdit}
      />
    );
  }
}

export function AttractionAddressContainer({
  address,
  isEditing,
  setIsEditing,
  pagePermissions,
  disableEdit,
}) {
  if (isEditing) return null;
  else {
    return (
      <AttractionAddressRead
        address={address}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        pagePermissions={pagePermissions}
        disableEdit={disableEdit}
      />
    );
  }
}

export function AttractionFacilitiesContainer({
  allFacilities,
  isEditing,
  setIsEditing,
  pagePermissions,
  disableEdit,
}) {
  if (isEditing) return null;
  else {
    return (
      <AttractionFacilitiesRead
        allFacilities={allFacilities}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        pagePermissions={pagePermissions}
        disableEdit={disableEdit}
      />
    );
  }
}

export function AttractionMapContainer({
  name,
  address,
  coordinates,
  isEditing,
  setIsEditing,
  pagePermissions,
  setupMap,
  disableEdit,
}) {
  if (isEditing) return null;
  else {
    setupMap();
    return (
      <AttractionMapRead
        name={name}
        address={address}
        coordinates={coordinates}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        pagePermissions={pagePermissions}
        disableEdit={disableEdit}
      />
    );
  }
}

export function AttractionRelatedDayhootsContainer({
  attraction,
  mainCategory,
  isEditing,
  setIsEditing,
  cookies,
  pagePermissions,
  disableEdit,
}) {
  if (isEditing) return null;
  else {
    return (
      <AttractionRelatedDayhootsRead
        attraction={attraction}
        mainCategory={mainCategory}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        cookies={cookies}
        pagePermissions={pagePermissions}
        disableEdit={disableEdit}
      />
    );
  }
}

export function AttractionTagContainer({
  allCategories,
  categories,
  isEditing,
  setIsEditing,
  pagePermissions,
  disableEdit,
}) {
  if (isEditing) return null;
  else {
    return (
      <AttractionTagsRead
        allCategories={allCategories}
        categories={categories}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        pagePermissions={pagePermissions}
        disableEdit={disableEdit}
      />
    );
  }
}

// #endregion Attraction Details Containers

// #region Detail/Read Views (not in edit mode)

function AttractionGooglePlaceIdRead({
  hasGooglePlaceId,
  googlePlaceId,
  isEditing,
  setIsEditing,
  pagePermissions,
  isInEditorView,
  startState,
}) {
  const { isView, isManaging } = pagePermissions;

  return (
    <>
      <div className="d-flex flex-row align-items-center">
        <div className="p-0">
          <h2 className="mb-0">Google Place ID</h2>
        </div>
        {!isView && isManaging && !isInEditorView ? (
          <div className="pl-2 flex-shrink-0">
            <button
              className="btn btn-warning btn-xs"
              onClick={() => setIsEditing(!isEditing)}
            >
              <FontAwesomeIcon icon={faEdit} />
              <span className="pl-1">{hasGooglePlaceId ? "Edit" : "Set"}</span>
            </button>
          </div>
        ) : null}
      </div>

      <p className={hasGooglePlaceId ? "" : "text-grey"}>
        {hasGooglePlaceId ? (
          googlePlaceId
        ) : (
          <em>No valid Google Place ID has been set.</em>
        )}
      </p>
    </>
  );
}

function AttractionNameRead({
  hasName,
  name,
  isEditing,
  setIsEditing,
  pagePermissions,
  isInEditorView,
  startState,
}) {
  const preHeader = getPreHeader(pagePermissions);

  return (
    <>
      {!pagePermissions.isView ? (
        <div>
          <span className="h3 wordbreak text-grey">{preHeader}</span>
        </div>
      ) : null}
      <div className="mb-2 d-flex flex-row align-items-center">
        <div className="p-0">
          <h1 className={`mb-0 wordbreak ${hasName ? "" : "text-grey"}`}>
            {hasName ? (
              <span className="apply-ellipsis-1">{name}</span>
            ) : (
              <em>Attraction Name</em>
            )}
          </h1>
        </div>
        {!pagePermissions.isView &&
        pagePermissions.isManaging &&
        !isInEditorView ? (
          <div className="pl-3 flex-shrink-0">
            <button
              className="btn btn-warning btn-xs"
              onClick={() => setIsEditing(!isEditing)}
            >
              <FontAwesomeIcon icon={faEdit} />
              <span className="pl-1">{hasName ? "Edit" : "Set"}</span>
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
}

function AttractionImageRead({ hasUrl, imageUrl }) {
  if (hasUrl) {
    return (
      <div className="mt-2">
        <AttractionImage
          image={imageUrl}
          alt={""}
          url={imageUrl}
          cleanUrl={imageUrl.split("/")[2]}
        />
      </div>
    );
  } else {
    return (
      <div>
        <em className="text-grey">
          No valid image address (URL) has been selected.
        </em>
      </div>
    );
  }
}

function AttractionRatingsRead({
  attraction,
  isEditing,
  setIsEditing,
  pagePermissions,
  disableEdit,
}) {
  const { name, price, rating } = attraction;
  const { isView, isManaging } = pagePermissions;
  const hasPrice = price && price !== "";
  const hasRating = rating && rating !== "";
  const isEmpty = !hasPrice && !hasRating;

  if (isManaging || !isEmpty) {
    return (
      <div className="mb-3">
        {!isView && isManaging ? (
          <div className="d-flex flex-row align-items-center">
            <div className="p-0">
              <h2 className="mb-0">Ratings Summary</h2>
            </div>
            <div className="pl-2 flex-shrink-0">
              <button
                className="btn btn-warning btn-xs"
                onClick={() => setIsEditing(!isEditing)}
                disabled={disableEdit}
                title={
                  disableEdit
                    ? "Close all open editors before selecting ratings."
                    : "Select Ratings"
                }
              >
                <FontAwesomeIcon icon={faEdit} />
                <span className="pl-1">{isEmpty ? "Set" : "Edit"}</span>
              </button>
            </div>
          </div>
        ) : null}
        {isEmpty ? (
          <div className="text-grey">
            <em>No ratings have been set/generated yet.</em>
          </div>
        ) : (
          <AttractionRatings name={name} price={price} rating={rating} />
        )}
      </div>
    );
  } else return null;
}

function AttractionDescriptionRead({
  hasDescription,
  attraction,
  isEditing,
  setIsEditing,
  pagePermissions,
}) {
  const { isView, isManaging } = pagePermissions;
  const { description } = attraction;

  return (
    <div className="mt-2 d-flex flex-row align-items-center">
      <div className="p-0">
        <div className="d-flex flex-row align-items-center">
          <div className="p-0">
            <h2 className="mb-0 wordbreak">About</h2>
          </div>
          {!isView && isManaging ? (
            <div className="pl-2 flex-shrink-0">
              <button
                className="btn btn-warning btn-xs"
                onClick={() => setIsEditing(!isEditing)}
              >
                <FontAwesomeIcon icon={faEdit} />
                <span className="pl-1">{hasDescription ? "Edit" : "Set"}</span>
              </button>
            </div>
          ) : null}
        </div>
        {hasDescription ? (
          <pre className="wordbreak apply-ellipsis-9">{description}</pre>
        ) : null}
        {!hasDescription && !isView && isManaging ? (
          <em className="text-grey">
            No attraction description has been set. Without a description, this
            page will only be accessible through direct links excluding
            attraction cards.
          </em>
        ) : null}
      </div>
    </div>
  );
}

function AttractionContactDetailsRead({
  url,
  phoneNumber,
  emailAddress,
  isEditing,
  setIsEditing,
  pagePermissions,
  disableEdit,
}) {
  const { isView, isManaging } = pagePermissions;
  const hasUrl = url && url !== "";
  const hasPhoneNumber = phoneNumber && phoneNumber !== "";
  const hasEmailAddress = emailAddress && emailAddress !== "";
  const isEmpty = !hasUrl && !hasPhoneNumber && !hasEmailAddress;
  if (!isEmpty || (!isView && isManaging)) {
    return (
      <>
        <div className="d-flex flex-row align-items-center">
          <div className="p-0">
            <h2 className="mb-0">Contact Details</h2>
          </div>
          {!isView && isManaging ? (
            <div className="pl-2 flex-shrink-0">
              <button
                className="btn btn-warning btn-xs"
                onClick={() => setIsEditing(!isEditing)}
                disabled={disableEdit}
                title={
                  disableEdit
                    ? "Close all open editors before editing contact details."
                    : "Edit Contact Details"
                }
              >
                <FontAwesomeIcon icon={faEdit} />
                <span className="pl-1">{isEmpty ? "Set" : "Edit"}</span>
              </button>
            </div>
          ) : null}
        </div>
        {!isView && isManaging && isEmpty ? (
          <div className="text-grey">
            <em>No contact details have been set.</em>
          </div>
        ) : null}
        {!isEmpty ? (
          <>
            {hasUrl ? (
              <div>
                <AttractionWebsite url={url} isPreview={!isView} />
              </div>
            ) : null}
            {hasPhoneNumber ? (
              <div>
                <AttractionPhoneNumber
                  phoneNumber={phoneNumber}
                  isPreview={!isView}
                />
              </div>
            ) : null}
            {hasEmailAddress ? (
              <div>
                <AttractionEmail
                  emailAddress={emailAddress}
                  isPreview={!isView}
                />
              </div>
            ) : null}
          </>
        ) : null}
      </>
    );
  } else return null;
}

function AttractionSocialMediaLinksRead({
  facebookUsername,
  twitterUsername,
  instagramUsername,
  isEditing,
  setIsEditing,
  pagePermissions,
  disableEdit,
}) {
  const { isView, isManaging } = pagePermissions;
  const hasFacebookUsername = facebookUsername && facebookUsername !== "";
  const hasTwitterUsername = twitterUsername && twitterUsername !== "";
  const hasInstagramUsername = instagramUsername && instagramUsername !== "";
  const isEmpty =
    !hasFacebookUsername && !hasTwitterUsername && !hasInstagramUsername;
  if (!isEmpty || (!isView && isManaging)) {
    return (
      <>
        <div className="d-flex flex-row align-items-center">
          <div className="p-0">
            <h2 className="mb-0">Social Media Links</h2>
          </div>
          {!isView && isManaging ? (
            <div className="pl-2 flex-shrink-0">
              <button
                className="btn btn-warning btn-xs"
                onClick={() => setIsEditing(!isEditing)}
                disabled={disableEdit}
                title={
                  disableEdit
                    ? "Close all open editors before editing social media links."
                    : "Edit social media links"
                }
              >
                <FontAwesomeIcon icon={faEdit} />
                <span className="pl-1">{isEmpty ? "Set" : "Edit"}</span>
              </button>
            </div>
          ) : null}
        </div>
        {!isView && isManaging && isEmpty ? (
          <div className="text-grey">
            <em>No social media links have been set.</em>
          </div>
        ) : null}
        {!isEmpty ? (
          <>
            {hasFacebookUsername ? (
              <div>
                <AttractionFacebook
                  facebookAccount={facebookUsername}
                  isPreview={!isView}
                />
              </div>
            ) : null}
            {hasTwitterUsername ? (
              <div>
                <AttractionTwitter
                  twitterAccount={twitterUsername}
                  isPreview={!isView}
                />
              </div>
            ) : null}
            {hasInstagramUsername ? (
              <div>
                <AttractionInstagram
                  instagramAccount={instagramUsername}
                  isPreview={!isView}
                />
              </div>
            ) : null}
          </>
        ) : null}
      </>
    );
  } else return null;
}

function AttractionAddressRead({
  address,
  isEditing,
  setIsEditing,
  pagePermissions,
  disableEdit,
}) {
  const { isView, isManaging } = pagePermissions;
  const { address1, address2, town, postCode, country } = address;
  const hasValidAddress1 = address1 && address1 !== "";
  const hasValidAddress2 = address2 && address2 !== "";
  const hasValidTown = town && town !== "";
  const hasValidPostCode = postCode && postCode !== "";
  const hasValidCountry = country && country !== "";
  const hasNoAddress =
    !hasValidAddress1 &&
    !hasValidAddress2 &&
    !hasValidTown &&
    !hasValidPostCode &&
    !hasValidCountry;

  if (!hasNoAddress || (!isView && isManaging)) {
    return (
      <>
        <div className="d-flex flex-row align-items-center">
          <div className="p-0">
            <h2 className="mb-0 wordbreak">Location</h2>
          </div>
          {!isView && isManaging ? (
            <div className="pl-2 flex-shrink-0">
              <button
                className="btn btn-warning btn-xs"
                onClick={() => setIsEditing(!isEditing)}
                disabled={disableEdit}
                title={
                  disableEdit
                    ? "Close all open editors before editing the location."
                    : "Edit Location"
                }
              >
                <FontAwesomeIcon icon={faEdit} />
                <span className="pl-1">{hasNoAddress ? "Set" : "Edit"}</span>
              </button>
            </div>
          ) : null}
        </div>
        {hasNoAddress ? null : (
          <>
            <div>
              {hasValidAddress1 ? (
                <span className="wordbreak">{address1}</span>
              ) : null}
              {hasValidAddress2 ? (
                <span className="wordbreak">
                  {hasValidAddress1 && ", "}
                  {address2}
                </span>
              ) : null}
              {hasValidTown ? (
                <span className="wordbreak">
                  {(hasValidAddress1 || hasValidAddress2) && ", "}
                  {town}
                </span>
              ) : null}
            </div>
            <div>
              {hasValidPostCode ? (
                <span className="wordbreak">{postCode}</span>
              ) : null}
              {hasValidCountry ? (
                <span className="wordbreak">
                  {hasValidPostCode && ", "}
                  {country}
                </span>
              ) : null}
            </div>
          </>
        )}
        {!isView && isManaging && hasNoAddress ? (
          <>
            <div className="text-grey">
              <em>No valid address has been set.</em>
            </div>
          </>
        ) : null}
      </>
    );
  } else return null;
}

function AttractionFacilitiesRead({
  allFacilities,
  isEditing,
  setIsEditing,
  pagePermissions,
  disableEdit,
}) {
  const { isView, isManaging } = pagePermissions;
  const selectedFacilities = allFacilities.filter((af) => af.isChecked);
  const hasSelectedFacilities = selectedFacilities.length > 0;
  if ((!isView && isManaging) || hasSelectedFacilities) {
    return (
      <>
        <div className="d-flex flex-row align-items-center">
          {hasSelectedFacilities || !isView ? (
            <div className="p-0">
              <h2 className="mb-0 wordbreak">Facilities</h2>
            </div>
          ) : null}
          {!isView && isManaging ? (
            <div className="pl-2 flex-shrink-0">
              <button
                className="btn btn-warning btn-xs"
                onClick={() => setIsEditing(!isEditing)}
                disabled={disableEdit}
                title={
                  disableEdit
                    ? "Close all open editors before selecting facilities."
                    : "Select Facilities"
                }
              >
                <FontAwesomeIcon icon={faEdit} />
                <span className="pl-1">
                  {hasSelectedFacilities ? "Edit" : "Set"}
                </span>
              </button>
            </div>
          ) : null}
        </div>
        {hasSelectedFacilities ? (
          <div className="mt-2">
            <SelectedFacilityList selectedFacilities={selectedFacilities} />
          </div>
        ) : null}
        {!isView && isManaging && !hasSelectedFacilities ? (
          <div className="text-grey">
            <em>No facilities have been selected.</em>
          </div>
        ) : null}
      </>
    );
  } else return null;
}

function AttractionMapRead({
  name,
  address,
  coordinates,
  isEditing,
  setIsEditing,
  pagePermissions,
  disableEdit,
}) {
  const { isView, isManaging } = pagePermissions;
  const hasName = name && name !== "";
  const { address1, address2, town, country, postCode } = address;
  const hasValidAddress1 = address1 && address1 !== "";
  const hasValidAddress2 = address2 && address2 !== "";
  const hasValidTown = town && town !== "";
  const hasValidPostCode = postCode && postCode !== "";
  const hasValidCountry = country && country !== "";
  const hasUsableAddress = hasValidAddress1 && hasValidPostCode;
  const hasNoAddress =
    !hasValidAddress1 &&
    !hasValidAddress2 &&
    !hasValidTown &&
    !hasValidPostCode &&
    !hasValidCountry;

  const { latitude, longitude } = coordinates;
  const [tempLatitude, setTempLatitude] = React.useState(latitude);
  const [tempLongitude, setTempLongitude] = React.useState(longitude);
  const hasLatitude = tempLatitude && tempLatitude !== "";
  const hasLongitude = tempLongitude && tempLongitude !== "";
  const hasCoordinates = hasLatitude && hasLongitude;
  const [isLatAutoPopulated, setIsLatAutoPopulated] = React.useState(false);
  const [isLongAutoPopulated, setIsLongAutoPopulated] = React.useState(false);

  if (!hasCoordinates && hasValidPostCode) {
    setTempCoordsFromPostcode({
      postCode: postCode,
      hasLatitude: hasLatitude,
      hasLongitude: hasLongitude,
      setTempLatitude: setTempLatitude,
      setTempLongitude: setTempLongitude,
      setIsLatAutoPopulated: setIsLatAutoPopulated,
      setIsLongAutoPopulated: setIsLongAutoPopulated,
    });
  }

  const hasValidCoordinates =
    hasCoordinates ||
    (isLatAutoPopulated && hasLongitude) ||
    (hasLatitude && isLongAutoPopulated) ||
    (isLatAutoPopulated && isLongAutoPopulated);

  const showCoordinatesPrompt =
    !hasCoordinates && hasUsableAddress && !isView && isManaging;
  const showAddressPrompt =
    !hasCoordinates && hasNoAddress && !isView && isManaging;

  if (hasCoordinates || showCoordinatesPrompt || showAddressPrompt) {
    return (
      <>
        <div className="d-flex flex-row align-items-center">
          <div className="p-0">
            <h2>Location</h2>
          </div>
          {!isView && isManaging ? (
            <div className="pl-2 flex-shrink-0">
              <button
                className="btn btn-warning btn-xs"
                onClick={() => setIsEditing(!isEditing)}
                disabled={disableEdit}
                title={
                  disableEdit
                    ? "Close all open editors before selecting a location."
                    : "Select Location"
                }
              >
                <FontAwesomeIcon icon={faEdit} />
                <span className="pl-1">{hasCoordinates ? "Edit" : "Set"}</span>
              </button>
            </div>
          ) : null}
        </div>
        {hasValidCoordinates ? (
          <AttractionMapWindow
            attraction={{
              name: hasName ? name : "this attraction",
            }}
            coordinates={{
              latitude: tempLatitude,
              longitude: tempLongitude,
            }}
          />
        ) : null}
        {showCoordinatesPrompt ? (
          <div>
            <em className="text-grey">
              Coordinates have not been set for this attraction.
            </em>
          </div>
        ) : null}
        {showAddressPrompt ? (
          <div>
            <em className="text-grey">
              No address has been set. To show the attraction details and
              location on the map, first ensure coordinates have been assigned.
            </em>
          </div>
        ) : null}
      </>
    );
  } else return null;
}

function AttractionRelatedDayhootsRead({
  attraction,
  mainCategory,
  isEditing,
  setIsEditing,
  cookies,
  pagePermissions,
  disableEdit,
}) {
  const { isView, isManaging } = pagePermissions;
  const hasMainCategory = mainCategory && mainCategory !== "";
  const [relatedDayhoots, setRelatedDayhoots] = React.useState([]);
  useEffect(() => {
    async function fetchData() {
      if (mainCategory) {
        const { attractions } = await getCategoryAttractions({
          name: mainCategory,
          excludeId: attraction.id || null,
          limit: 6,
        });
        setRelatedDayhoots(attractions || []);
      } else setRelatedDayhoots([]);
    }
    fetchData();
  }, [mainCategory, attraction]);

  if (hasMainCategory || (!isView && isManaging)) {
    return (
      <>
        <div className="d-flex flex-row align-items-center">
          <div className="p-0">
            <h2 className="mb-0 wordbreak">
              {!hasMainCategory && !isView && isManaging
                ? "Selected Categories "
                : "Related Dayhoots"}
            </h2>
          </div>
          {!isView && isManaging ? (
            <div className="pl-2 flex-shrink-0">
              <button
                className="btn btn-warning btn-xs"
                onClick={() => setIsEditing(!isEditing)}
                disabled={disableEdit}
                title={
                  disableEdit
                    ? "Close all open editors before selecting categories."
                    : "Select Categories"
                }
              >
                <FontAwesomeIcon icon={faEdit} />
                <span className="pl-1">{hasMainCategory ? "Edit" : "Set"}</span>
              </button>
            </div>
          ) : null}
        </div>
        {hasMainCategory ? (
          <div className="mt-2">
            <AttractionRelatedAttractionsContainer
              hideTitle={true}
              attraction={attraction}
              attractions={relatedDayhoots}
              cookies={cookies}
              isVisible={true}
            />
          </div>
        ) : (
          <div className="text-grey">
            <em>No main category has been set.</em>
          </div>
        )}
      </>
    );
  } else return null;
}

function AttractionTagsRead({
  allCategories,
  categories,
  isEditing,
  setIsEditing,
  pagePermissions,
  disableEdit,
}) {
  const { isView, isManaging } = pagePermissions;
  const hasCategories = categories && categories.length > 0;
  if (hasCategories || (!isView && isManaging)) {
    return (
      <>
        <div className="d-flex flex-row align-items-center">
          <div className="p-0">
            <h2 className="mb-0">Tags</h2>
          </div>
          {!isView && isManaging ? (
            <div className="pl-2 flex-shrink-0">
              <button
                className="btn btn-warning btn-xs"
                onClick={() => setIsEditing(!isEditing)}
                disabled={disableEdit}
                title={
                  disableEdit
                    ? "Close all open editors before selecting categories."
                    : "Select Categories"
                }
              >
                <FontAwesomeIcon icon={faEdit} />
                <span className="pl-1">{hasCategories ? "Edit" : "Set"}</span>
              </button>
            </div>
          ) : null}
        </div>
        {hasCategories ? (
          <div className="mt-2">
            <AttractionTags
              categories={allCategories.filter((ac) => {
                return categories.find((c) => c === ac.categoryName);
              })}
              isPreview={true}
            />
          </div>
        ) : null}
        {!isView && isManaging && !hasCategories ? (
          <div className="text-grey">
            <em>
              No categories have been selected. Select one or more categories to
              see attraction tags.
            </em>
          </div>
        ) : null}
      </>
    );
  } else return null;
}

// #endregion Detail/Read Views (not in edit mode)

// #region Inline Editors

function AttractionGooglePlaceIdInlineEditor({
  googlePlaceId,
  isEditing,
  setIsEditing,
  update,
  updateErrors,
  startState,
}) {
  const [tempGooglePlaceId, setTempGooglePlaceId] =
    React.useState(googlePlaceId);
  const [errors, setErrors] = React.useState([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const hasChanged = tempGooglePlaceId !== googlePlaceId;

  return (
    <>
      <div className="d-flex flex-row align-items-center">
        <div className="p-0">
          <h2 className="mb-0">Google Place ID</h2>
        </div>
        <div className="pl-2">
          <button
            className="btn btn-primary btn-xs mb-1"
            onClick={() => {
              if (errors.length === 0) {
                update(tempGooglePlaceId);
                setIsEditing(!isEditing);
                updateErrors([{ name: "googlePlaceId", errors: errors }]);
              } else setOpenSnackbar(true);
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
            <span className="pl-1">Confirm</span>
          </button>
        </div>
      </div>
      <div className="d-flex flex-row align-items-center">
        <div className="p-0 flex-fill" style={{ minWidth: "85%" }}>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder={"Google Place ID"}
              id="attractionGooglePlaceId"
              name="attractionGooglePlaceId"
              value={tempGooglePlaceId}
              onChange={(e) => {
                setErrors(
                  isInputValid(
                    validationConfig,
                    "googlePlaceId",
                    e.target.value
                  )
                );
                setTempGooglePlaceId(e.target.value);
              }}
              isValid={hasChanged && errors.length === 0}
              isInvalid={hasChanged && errors.length > 0}
            />
            {hasChanged && errors.length > 0 ? (
              <Form.Control.Feedback type="invalid">
                {errors}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        </div>
      </div>
      <CustomSnackbar
        open={openSnackbar}
        setOpen={(val) => setOpenSnackbar(val)}
        severity={"error"}
        message={errors}
        autoHideDuration={3000}
      />
    </>
  );
}

function AttractionNameInlineEditor({
  name,
  isEditing,
  setIsEditing,
  update,
  updateErrors,
  reRenderMap,
}) {
  const [tempName, setTempName] = React.useState(name);
  const [errors, setErrors] = React.useState([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const hasChanged = tempName !== name;

  return (
    <div className="d-flex flex-row align-items-center">
      <div className="p-0 flex-fill" style={{ minWidth: "70%" }}>
        <Form.Group>
          <Form.Control
            type="text"
            placeholder={"Attraction Name"}
            id="attractionName"
            name="attractionName"
            value={tempName}
            onChange={(e) => {
              setErrors(isInputValid(validationConfig, "name", e.target.value));
              setTempName(e.target.value);
            }}
            isValid={hasChanged && errors.length === 0}
            isInvalid={hasChanged && errors.length > 0}
          />
          {hasChanged && errors.length > 0 ? (
            <Form.Control.Feedback type="invalid">
              {errors}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>
      </div>
      <div className="pl-2 flex-shrink-0">
        <button
          className="btn btn-primary btn-xs mb-1"
          onClick={() => {
            if (errors.length === 0) {
              update(tempName);
              setIsEditing(!isEditing);
              updateErrors([{ name: "name", errors: errors }]);
              reRenderMap(tempName);
            } else setOpenSnackbar(true);
          }}
        >
          <FontAwesomeIcon icon={faCheck} />
          <span className="pl-1">Confirm</span>
        </button>
      </div>
      <CustomSnackbar
        open={openSnackbar}
        setOpen={(val) => setOpenSnackbar(val)}
        severity={"error"}
        message={errors}
        autoHideDuration={3000}
      />
    </div>
  );
}

function AttractionImageInlineEditor({
  imageUrl,
  update,
  isEditing,
  setIsEditing,
  updateErrors,
  startState,
}) {
  const [tempUrl, setTempUrl] = React.useState(imageUrl);
  const [errors, setErrors] = React.useState([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const hasChanged = tempUrl !== imageUrl;

  return (
    <div className="d-flex flex-row align-items-center">
      <div className="p-0 flex-fill" style={{ minWidth: "85%" }}>
        <Form.Group>
          <Form.Control
            type="text"
            placeholder={"Image URL (from a website link)"}
            id="imageUrl"
            name="imageUrl"
            value={tempUrl}
            onChange={(e) => {
              setErrors(
                isInputValid(validationConfig, "imageUrl", e.target.value)
              );
              setTempUrl(e.target.value);
            }}
            isValid={hasChanged && errors.length === 0}
            isInvalid={hasChanged && errors.length > 0}
          />
          {hasChanged && errors.length > 0 ? (
            <Form.Control.Feedback type="invalid">
              {errors}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>
      </div>
      <div className="pl-2 flex-shrink-0">
        <button
          className="btn btn-primary btn-xs mb-1"
          onClick={() => {
            if (errors.length === 0) {
              update(tempUrl);
              setIsEditing(!isEditing);
              updateErrors([{ name: "imageUrl", errors: errors }]);
            } else setOpenSnackbar(true);
          }}
        >
          <FontAwesomeIcon icon={faCheck} />
          <span className="pl-1">Confirm</span>
        </button>
      </div>
      <CustomSnackbar
        open={openSnackbar}
        setOpen={(val) => setOpenSnackbar(val)}
        severity={"error"}
        message={errors}
        autoHideDuration={3000}
      />
    </div>
  );
}

function AttractionDescriptionInlineEditor({
  attraction,
  isEditing,
  setIsEditing,
  updateErrors,
}) {
  const { description, update } = attraction;
  const [tempDescription, setTempDescription] = React.useState(description);
  const [errors, setErrors] = React.useState([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const hasChanged = tempDescription !== description;

  return (
    <div className="mt-2 d-flex flex-row align-items-center">
      <div className="p-0 flex-fill">
        <div className="d-flex flex-row align-items-center">
          <div className="p-0">
            <h2 className="mb-0 wordbreak">About</h2>
          </div>
          <div className="pl-2">
            <button
              className="btn btn-primary btn-xs mb-1"
              onClick={() => {
                if (errors.length === 0) {
                  update(tempDescription);
                  setIsEditing(!isEditing);
                  updateErrors([
                    {
                      name: "description",
                      errors: errors,
                    },
                  ]);
                } else setOpenSnackbar(true);
              }}
            >
              <FontAwesomeIcon icon={faCheck} />
              <span className="pl-1">Confirm</span>
            </button>
          </div>
        </div>
        <Form.Group className="mt-2">
          <Form.Control
            as="textarea"
            rows={8}
            placeholder="Attraction Description"
            id="description"
            name="description"
            value={tempDescription}
            onChange={(e) => {
              setErrors(
                isInputValid(validationConfig, "description", e.target.value)
              );
              setTempDescription(e.target.value);
            }}
            style={{ width: "100%" }}
            isValid={hasChanged && errors.length === 0}
            isInvalid={hasChanged && errors.length > 0}
          />
          {hasChanged && errors.length > 0 ? (
            <Form.Control.Feedback type="invalid">
              {errors}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>
        <CustomSnackbar
          open={openSnackbar}
          setOpen={(val) => setOpenSnackbar(val)}
          severity={"error"}
          message={errors}
          autoHideDuration={3000}
        />
      </div>
    </div>
  );
}

// #endregion Inline Editors

// #region Editor Context

export function RatingDetailsEditorContext({
  attraction,
  setPrice,
  isEditing,
  setIsEditing,
  updateErrors,
  startState,
}) {
  const { price, name } = attraction;
  const sanitisedPrice = price != null ? price : "default";
  const [tempPrice, setTempPrice] = React.useState(sanitisedPrice);
  const [errors, setErrors] = React.useState([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const hasPriceChanged = tempPrice !== price;

  return (
    <>
      <h2>Price</h2>
      <hr className="mt-0 mb-1" />
      <RadioGroup
        aria-label="price"
        name="price"
        value={tempPrice}
        onChange={(event) => {
          const propVal = isNaN(event.target.value)
            ? "default"
            : parseInt(event.target.value);
          setErrors(
            isInputValid(validationConfig, "price", event.target.value)
          );
          setTempPrice(propVal);
        }}
      >
        <PriceRadioGroup name={name} />
      </RadioGroup>
      {hasPriceChanged && errors.length > 0 ? (
        <span className="forced-invalid-feedback">{errors}</span>
      ) : null}
      <div className="d-flex flex-row align-items-center">
        <div className="ml-auto mr-auto p-0 pt-3">
          <button
            className="btn btn-primary"
            onClick={() => {
              if (errors.length === 0) {
                const propVal = tempPrice === "default" ? null : tempPrice;
                setPrice(propVal);
                setIsEditing(!isEditing);
                updateErrors([
                  {
                    name: "price",
                    errors: errors,
                  },
                ]);
              } else setOpenSnackbar(true);
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
            <span className="pl-1">Finished Selecting</span>
          </button>
        </div>
      </div>
      <CustomSnackbar
        open={openSnackbar}
        setOpen={(val) => setOpenSnackbar(val)}
        severity={"error"}
        message={errors}
        autoHideDuration={3000}
      />
    </>
  );
}

export function ContactDetailsEditorContext({
  website,
  phone,
  email,
  facebook,
  twitter,
  instagram,
  isEditing,
  setIsEditing,
  updateErrors,
}) {
  const [tempUrl, setTempUrl] = React.useState(website.url);
  const [linkUrlErrors, setLinkUrlErrors] = React.useState([]);
  const [openLinkUrlSnackbar, setOpenLinkUrlSnackbar] = React.useState(false);
  const hasUrlChanged = tempUrl !== website.url;

  const [tempPhoneNumber, setTempPhoneNumber] = React.useState(
    phone.phoneNumber
  );
  const [phoneNumberErrors, setPhoneNumberErrors] = React.useState([]);
  const [openPhoneNumberSnackbar, setOpenPhoneNumberSnackbar] =
    React.useState(false);
  const hasPhoneNumberChanged = tempPhoneNumber !== phone.phoneNumber;

  const [tempEmailAddress, setTempEmailAddress] = React.useState(
    email.emailAddress
  );
  const [emailAddressErrors, setEmailAddressErrors] = React.useState([]);
  const [openEmailAddressSnackbar, setOpenEmailAddressSnackbar] =
    React.useState(false);
  const hasEmailAddressChanged = tempEmailAddress !== email.emailAddress;

  const [tempFacebookAccount, setTempFacebookAccount] = React.useState(
    facebook.username
  );
  const [facebookAccountErrors, setFacebookAccountErrors] = React.useState([]);
  const [openFacebookAccountSnackbar, setOpenfacebookAccountSnackbar] =
    React.useState(false);
  const hasFacebookAccountChanged = tempFacebookAccount !== facebook.username;

  const [tempTwitterAccount, setTempTwitterAccount] = React.useState(
    twitter.username
  );
  const [twitterAccountErrors, setTwitterAccountErrors] = React.useState([]);
  const [openTwitterAccountSnackbar, setOpenTwitterAccountSnackbar] =
    React.useState(false);
  const hasTwitterAccountChanged = tempTwitterAccount !== twitter.username;

  const [tempInstagramAccount, setTempInstagramAccount] = React.useState(
    instagram.username
  );
  const [instagramAccountErrors, setInstagramAccountErrors] = React.useState(
    []
  );
  const [openInstagramAccountSnackbar, setOpenInstagramAccountSnackbar] =
    React.useState(false);
  const hasInstagramAccountChanged =
    tempInstagramAccount !== instagram.username;

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <h2>Contact Details</h2>
          <div>
            <Form.Group>
              <Form.Label for="linkUrl">Website Address (URL)</Form.Label>
              <Form.Control
                type="text"
                placeholder={"Website Address (URL)"}
                id="linkUrl"
                name="linkUrl"
                value={tempUrl}
                onChange={(e) => {
                  setLinkUrlErrors(
                    isInputValid(validationConfig, "linkUrl", e.target.value)
                  );
                  setTempUrl(e.target.value);
                }}
                isValid={hasUrlChanged && linkUrlErrors.length === 0}
                isInvalid={hasUrlChanged && linkUrlErrors.length > 0}
              />
              {hasUrlChanged && linkUrlErrors.length > 0 ? (
                <Form.Control.Feedback type="invalid">
                  {linkUrlErrors}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </div>
          <div>
            <Form.Group>
              <Form.Label for="phoneNumber">Phone Number</Form.Label>
              <Form.Control
                type="phone"
                placeholder={"Phone Number"}
                id="phoneNumber"
                name="phoneNumber"
                value={tempPhoneNumber}
                onChange={(e) => {
                  setPhoneNumberErrors(
                    isInputValid(
                      validationConfig,
                      "phoneNumber",
                      e.target.value
                    )
                  );
                  setTempPhoneNumber(e.target.value);
                }}
                isValid={
                  hasPhoneNumberChanged && phoneNumberErrors.length === 0
                }
                isInvalid={
                  hasPhoneNumberChanged && phoneNumberErrors.length > 0
                }
              />
              {hasPhoneNumberChanged && phoneNumberErrors.length > 0 ? (
                <Form.Control.Feedback type="invalid">
                  {phoneNumberErrors}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </div>
          <div>
            <Form.Group>
              <Form.Label for="emailAddress">Email Address</Form.Label>
              <Form.Control
                type="text"
                placeholder={"Email Address"}
                id="emailAddress"
                name="emailAddress"
                value={tempEmailAddress}
                onChange={(e) => {
                  setEmailAddressErrors(
                    isInputValid(
                      validationConfig,
                      "emailAddress",
                      e.target.value
                    )
                  );
                  setTempEmailAddress(e.target.value);
                }}
                isValid={
                  hasEmailAddressChanged && emailAddressErrors.length === 0
                }
                isInvalid={
                  hasEmailAddressChanged && emailAddressErrors.length > 0
                }
              />
              {hasEmailAddressChanged && emailAddressErrors.length > 0 ? (
                <Form.Control.Feedback type="invalid">
                  {emailAddressErrors}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </div>
        </div>
        <div className="col-md-6">
          <h2>Social Media Details</h2>
          <div>
            <Form.Group>
              <Form.Label for="facebookAccount">Facebook Account</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>@</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  placeholder="Facebook Account (e.g. DayhootUK)"
                  id="facebookAccount"
                  value={tempFacebookAccount}
                  onChange={(e) => {
                    setFacebookAccountErrors(
                      isInputValid(
                        validationConfig,
                        "socialAccount",
                        e.target.value
                      )
                    );
                    setTempFacebookAccount(e.target.value);
                  }}
                  isValid={
                    hasFacebookAccountChanged &&
                    facebookAccountErrors.length === 0
                  }
                  isInvalid={
                    hasFacebookAccountChanged &&
                    facebookAccountErrors.length > 0
                  }
                />
                {hasFacebookAccountChanged &&
                facebookAccountErrors.length > 0 ? (
                  <Form.Control.Feedback type="invalid">
                    {facebookAccountErrors}
                  </Form.Control.Feedback>
                ) : null}
              </InputGroup>
            </Form.Group>
          </div>
          <div>
            <Form.Group>
              <Form.Label for="twitterAccount">Twitter Account</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>@</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  placeholder="Twitter Account (e.g. DayhootUK)"
                  id="twitterAccount"
                  value={tempTwitterAccount}
                  onChange={(e) => {
                    setTwitterAccountErrors(
                      isInputValid(
                        validationConfig,
                        "socialAccount",
                        e.target.value
                      )
                    );
                    setTempTwitterAccount(e.target.value);
                  }}
                  isValid={
                    hasTwitterAccountChanged &&
                    twitterAccountErrors.length === 0
                  }
                  isInvalid={
                    hasTwitterAccountChanged && twitterAccountErrors.length > 0
                  }
                />
                {hasTwitterAccountChanged && twitterAccountErrors.length > 0 ? (
                  <Form.Control.Feedback type="invalid">
                    {twitterAccountErrors}
                  </Form.Control.Feedback>
                ) : null}
              </InputGroup>
            </Form.Group>
          </div>
          <div>
            <Form.Group>
              <Form.Label for="instagramAccount">Instagram Account</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>@</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  placeholder="Instagram Account (e.g. DayhootUK)"
                  id="instagramAccount"
                  value={tempInstagramAccount}
                  onChange={(e) => {
                    setInstagramAccountErrors(
                      isInputValid(
                        validationConfig,
                        "socialAccount",
                        e.target.value
                      )
                    );
                    setTempInstagramAccount(e.target.value);
                  }}
                  isValid={
                    hasInstagramAccountChanged &&
                    instagramAccountErrors.length === 0
                  }
                  isInvalid={
                    hasInstagramAccountChanged &&
                    instagramAccountErrors.length > 0
                  }
                />
                {hasInstagramAccountChanged &&
                instagramAccountErrors.length > 0 ? (
                  <Form.Control.Feedback type="invalid">
                    {instagramAccountErrors}
                  </Form.Control.Feedback>
                ) : null}
              </InputGroup>
            </Form.Group>
          </div>
        </div>
      </div>
      <div>
        <div className="d-flex flex-row align-items-center">
          <div className="ml-auto mr-auto p-0">
            <button
              className="btn btn-primary"
              onClick={() => {
                const isLinkUrlValid = linkUrlErrors.length === 0;
                const isEmailAddressValid = emailAddressErrors.length === 0;
                const isPhoneNumberValid = phoneNumberErrors.length === 0;
                const isFacebookAccountValid =
                  facebookAccountErrors.length === 0;
                const isTwitterAccountValid = twitterAccountErrors.length === 0;
                const isInstagramAccountValid =
                  instagramAccountErrors.length === 0;

                const isValid =
                  isLinkUrlValid &&
                  isEmailAddressValid &&
                  isPhoneNumberValid &&
                  isFacebookAccountValid &&
                  isTwitterAccountValid &&
                  isInstagramAccountValid;

                if (isLinkUrlValid) {
                  if (tempUrl) {
                    const sanitisedUrl = checkUrlHasHttp(tempUrl)
                      ? tempUrl
                      : `http://${tempUrl}`;
                    website.update(sanitisedUrl);
                    updateErrors([
                      {
                        name: "linkUrl",
                        errors: linkUrlErrors,
                      },
                    ]);
                  }
                } else setOpenLinkUrlSnackbar(true);

                if (isEmailAddressValid) {
                  email.update(tempEmailAddress);
                  updateErrors([
                    {
                      name: "emailAddress",
                      errors: emailAddressErrors,
                    },
                  ]);
                } else setOpenPhoneNumberSnackbar(true);

                if (isPhoneNumberValid) {
                  phone.update(tempPhoneNumber);
                  updateErrors([
                    {
                      name: "phoneNumber",
                      errors: linkUrlErrors,
                    },
                  ]);
                } else setOpenPhoneNumberSnackbar(true);

                if (isFacebookAccountValid) {
                  facebook.update(tempFacebookAccount);
                  updateErrors([
                    {
                      name: "facebookAccount",
                      errors: facebookAccountErrors,
                    },
                  ]);
                } else setOpenPhoneNumberSnackbar(true);

                if (isTwitterAccountValid) {
                  twitter.update(tempTwitterAccount);
                  updateErrors([
                    {
                      name: "twitterAccount",
                      errors: twitterAccountErrors,
                    },
                  ]);
                } else setOpenPhoneNumberSnackbar(true);

                if (isInstagramAccountValid) {
                  instagram.update(tempInstagramAccount);
                  updateErrors([
                    {
                      name: "instagramAccount",
                      errors: instagramAccountErrors,
                    },
                  ]);
                } else setOpenInstagramAccountSnackbar(true);

                if (isValid) setIsEditing(!isEditing);
              }}
            >
              <FontAwesomeIcon icon={faCheck} />
              <span className="pl-1">Finished Selecting</span>
            </button>
          </div>
        </div>
      </div>
      <CustomSnackbar
        open={openLinkUrlSnackbar}
        setOpen={(val) => setOpenLinkUrlSnackbar(val)}
        severity={"error"}
        message={linkUrlErrors}
        autoHideDuration={3000}
      />
      <CustomSnackbar
        open={openPhoneNumberSnackbar}
        setOpen={(val) => setOpenPhoneNumberSnackbar(val)}
        severity={"error"}
        message={phoneNumberErrors}
        autoHideDuration={3000}
      />
      <CustomSnackbar
        open={openEmailAddressSnackbar}
        setOpen={(val) => setOpenEmailAddressSnackbar(val)}
        severity={"error"}
        message={emailAddressErrors}
        autoHideDuration={3000}
      />
      <CustomSnackbar
        open={openFacebookAccountSnackbar}
        setOpen={(val) => setOpenfacebookAccountSnackbar(val)}
        severity={"error"}
        message={facebookAccountErrors}
        autoHideDuration={3000}
      />
      <CustomSnackbar
        open={openTwitterAccountSnackbar}
        setOpen={(val) => setOpenTwitterAccountSnackbar(val)}
        severity={"error"}
        message={twitterAccountErrors}
        autoHideDuration={3000}
      />
      <CustomSnackbar
        open={openInstagramAccountSnackbar}
        setOpen={(val) => setOpenInstagramAccountSnackbar(val)}
        severity={"error"}
        message={instagramAccountErrors}
        autoHideDuration={3000}
      />
    </>
  );
}

export function LocationDetailsEditorContext({
  address1,
  address2,
  town,
  postCode,
  country,
  latitude,
  longitude,
  allRegions,
  region,
  setAddress,
  setCoordinates,
  setRegion,
  isEditing,
  setIsEditing,
  updateErrors,
}) {
  const classes = useStyles();

  const [tempAddress1, setTempAddress1] = React.useState(address1.value);
  const [address1Errors, setAddress1Errors] = React.useState([]);
  const [openAddress1Snackbar, setOpenAddress1Snackbar] = React.useState(false);
  const isAddress1Valid = address1Errors.length === 0;
  const hasAddress1Changed = tempAddress1 !== address1.value;

  const [tempAddress2, setTempAddress2] = React.useState(address2.value);
  const [address2Errors, setAddress2Errors] = React.useState([]);
  const [openAddress2Snackbar, setOpenAddress2Snackbar] = React.useState(false);
  const isAddress2Valid = address2Errors.length === 0;
  const hasAddress2Changed = tempAddress2 !== address2.value;

  const [tempTown, setTempTown] = React.useState(town.value);
  const [townErrors, setTownErrors] = React.useState([]);
  const [openTownSnackbar, setOpenTownSnackbar] = React.useState(false);
  const isTownValid = townErrors.length === 0;
  const hasTownChanged = tempTown !== town.value;

  const [tempPostCode, setTempPostCode] = React.useState(postCode.value);
  const [postCodeErrors, setPostCodeErrors] = React.useState([]);
  const [openPostCodeSnackbar, setOpenPostCodeSnackbar] = React.useState(false);
  const isPostCodeValid = postCodeErrors.length === 0;
  const hasPostCodeChanged = tempPostCode !== postCode.value;

  const [tempCountry, setTempCountry] = React.useState(country.value);
  const [countryErrors, setCountryErrors] = React.useState([]);
  const [openCountrySnackbar, setOpenCountrySnackbar] = React.useState(false);
  const isCountryValid = countryErrors.length === 0;
  const hasCountryChanged = tempCountry !== country.value;

  const [tempLatitude, setTempLatitude] = React.useState(latitude.value); // not done yet
  const [latitudeErrors, setLatitudeErrors] = React.useState([]);
  const [openLatitudeSnackbar, setOpenLatitudeSnackbar] = React.useState(false);
  const isLatitudeValid = latitudeErrors.length === 0;
  const hasLatitudeChanged = tempLatitude !== latitude.value;

  const [tempLongitude, setTempLongitude] = React.useState(longitude.value); // not done yet
  const [longitudeErrors, setLongitudeErrors] = React.useState([]);
  const [openLongitudeSnackbar, setOpenLongitudeSnackbar] =
    React.useState(false);
  const isLongitudeValid = longitudeErrors.length === 0;
  const hasLongitudeChanged = tempLongitude !== longitude.value;

  const [tempRegion, setTempRegion] = React.useState(region);
  const [regionErrors, setRegionErrors] = React.useState([]);
  const [openRegionSnackbar, setOpenRegionSnackbar] = React.useState(false);
  const isRegionValid = regionErrors.length === 0;
  const hasRegionChanged = tempRegion !== region;

  const [isLatAutoPopulated, setIsLatAutoPopulated] = React.useState(false);
  const [isLongAutoPopulated, setIsLongAutoPopulated] = React.useState(false);

  const hasLatitude = tempLatitude && tempLatitude !== "";
  const hasLongitude = tempLongitude && tempLongitude !== "";
  const isCoordsAutoPopulated = isLatAutoPopulated || isLongAutoPopulated;

  const isValid =
    isAddress1Valid &&
    isAddress2Valid &&
    isCountryValid &&
    isTownValid &&
    isPostCodeValid &&
    isLatitudeValid &&
    isLongitudeValid &&
    isRegionValid;

  useEffect(() => {
    async function fetchData() {
      await setTempCoordsFromPostcode({
        postCode: tempPostCode,
        hasLatitude: hasLatitude,
        hasLongitude: hasLongitude,
        setTempLatitude: setTempLatitude,
        setTempLongitude: setTempLongitude,
        setIsLatAutoPopulated: setIsLatAutoPopulated,
        setIsLongAutoPopulated: setIsLongAutoPopulated,
      });
    }
    fetchData();
  }, [tempPostCode, hasLatitude, hasLongitude]);

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <h2>Regions</h2>
          <hr className="mt-0 mb-1" />
          <RadioGroup
            aria-label="region"
            name="region"
            value={tempRegion}
            onChange={(event) => {
              setRegionErrors(
                isInputValid(validationConfig, "region", event.target.value)
              );
              setTempRegion(event.target.value);
            }}
          >
            {allRegions.map((region) => {
              const { regionName, displayName } = region;
              return (
                <FormControlLabel
                  key={`region-${regionName}`}
                  value={regionName}
                  control={<Radio color="primary" />}
                  label={
                    <Typography className={classes.radioButtonLabelText}>
                      {displayName}
                    </Typography>
                  }
                />
              );
            })}
            {hasRegionChanged && !isRegionValid ? (
              <span className="forced-invalid-feedback">{regionErrors}</span>
            ) : null}
          </RadioGroup>
        </div>
        <div className="col-md-6">
          <div>
            <h2>Address</h2>
            <hr className="mt-0 mb-1" />
            <div>
              <Form.Group>
                <Form.Label for="address1">Address Line 1</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={"Address Line 1 (e.g. 9 Common Lane)"}
                  id="address1"
                  name="address1"
                  value={tempAddress1}
                  onChange={(e) => {
                    setAddress1Errors(
                      isInputValid(validationConfig, "address1", e.target.value)
                    );
                    setTempAddress1(e.target.value);
                  }}
                  isValid={hasAddress1Changed && isAddress1Valid}
                  isInvalid={hasAddress1Changed && !isAddress1Valid}
                />
                {hasAddress1Changed && !isAddress1Valid ? (
                  <Form.Control.Feedback type="invalid">
                    {address1Errors}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
            </div>
            <div>
              <Form.Group>
                <Form.Label for="address2">Address Line 2</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={"Address Line 2 (e.g. Springfield Bridge)"}
                  id="address2"
                  name="address2"
                  value={tempAddress2}
                  onChange={(e) => {
                    setAddress2Errors(
                      isInputValid(validationConfig, "address2", e.target.value)
                    );
                    setTempAddress2(e.target.value);
                  }}
                  isValid={hasAddress2Changed && isAddress2Valid}
                  isInvalid={hasAddress2Changed && !isAddress2Valid}
                />
                {hasAddress2Changed && !isAddress2Valid ? (
                  <Form.Control.Feedback type="invalid">
                    {address2Errors}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
            </div>
            <div>
              <Form.Group>
                <Form.Label for="town">Town</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={"Town (e.g. Springfield)"}
                  id="town"
                  name="town"
                  value={tempTown}
                  onChange={(e) => {
                    setTownErrors(
                      isInputValid(validationConfig, "town", e.target.value)
                    );
                    setTempTown(e.target.value);
                  }}
                  isValid={hasTownChanged && isTownValid}
                  isInvalid={hasTownChanged && !isTownValid}
                />
                {hasTownChanged && !isTownValid ? (
                  <Form.Control.Feedback type="invalid">
                    {townErrors}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
            </div>
            <div>
              <Form.Group>
                <Form.Label for="postCode">Post Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={"Post Code (e.g. SF10 9CL)"}
                  id="postCode"
                  name="postCode"
                  value={tempPostCode}
                  onChange={(e) => {
                    setPostCodeErrors(
                      isInputValid(validationConfig, "postCode", e.target.value)
                    );
                    setTempPostCode(e.target.value);
                  }}
                  isValid={hasPostCodeChanged && isPostCodeValid}
                  isInvalid={hasPostCodeChanged && !isPostCodeValid}
                />
                {hasPostCodeChanged && !isPostCodeValid ? (
                  <Form.Control.Feedback type="invalid">
                    {postCodeErrors}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
            </div>
            <div>
              <Form.Group>
                <Form.Label for="country">Country</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={"Country (e.g. Springfield)"}
                  id="country"
                  name="country"
                  value={tempCountry}
                  onChange={(e) => {
                    setCountryErrors(
                      isInputValid(validationConfig, "country", e.target.value)
                    );
                    setTempCountry(e.target.value);
                  }}
                  isValid={hasCountryChanged && isCountryValid}
                  isInvalid={hasCountryChanged && !isCountryValid}
                />
                {hasCountryChanged && !isCountryValid ? (
                  <Form.Control.Feedback type="invalid">
                    {countryErrors}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
            </div>
          </div>
          <div>
            <h2>Location</h2>
            <hr className="mt-0 mb-1" />
            <div>
              <Form.Group>
                <Form.Label for="latitude">Latitude</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={"Latitude"}
                  id="latitude"
                  name="latitude"
                  value={tempLatitude}
                  onChange={(e) => {
                    setLatitudeErrors(
                      isInputValid(validationConfig, "latitude", e.target.value)
                    );
                    setTempLatitude(e.target.value);
                  }}
                  isValid={hasLatitudeChanged && isLatitudeValid}
                  isInvalid={hasLatitudeChanged && !isLatitudeValid}
                />
                {hasLatitudeChanged && !isLatitudeValid ? (
                  <Form.Control.Feedback type="invalid">
                    {latitudeErrors}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
            </div>
            <div>
              <Form.Group>
                <Form.Label for="longitude">Longitude</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={"Longitude"}
                  id="longitude"
                  name="longitude"
                  value={tempLongitude}
                  onChange={(e) => {
                    setLongitudeErrors(
                      isInputValid(
                        validationConfig,
                        "longitude",
                        e.target.value
                      )
                    );
                    setTempLongitude(e.target.value);
                  }}
                  isValid={hasLongitudeChanged && isLongitudeValid}
                  isInvalid={hasLongitudeChanged && !isLongitudeValid}
                />
                {hasLongitudeChanged && !isLongitudeValid ? (
                  <Form.Control.Feedback type="invalid">
                    {longitudeErrors}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
            </div>
            {isCoordsAutoPopulated ? (
              <div className="alert alert-info">
                <div className="d-flex flex-row align-items-top">
                  <div className="p-0">
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </div>
                  <div className="pl-2">
                    These coordinates have been pre-populated from the post code
                    added in the address and may not accurately represent the
                    attraction location. These can be changed manually otherwise
                    more accurate coordinates will overwrite these. The
                    pre-population is purely for demonstrative purposes.
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="d-flex flex-row align-items-center">
        <div className="ml-auto mr-auto p-0">
          <button
            className="btn btn-primary"
            onClick={() => {
              if (isValid) {
                setAddress({
                  address1: tempAddress1,
                  address2: tempAddress2,
                  town: tempTown,
                  country: tempCountry,
                  postCode: tempPostCode,
                });
                setCoordinates({
                  latitude: tempLatitude,
                  longitude: tempLongitude,
                });
                setRegion(tempRegion);
                updateErrors([
                  {
                    name: "address1",
                    errors: address1Errors,
                  },
                  {
                    name: "address2",
                    errors: address2Errors,
                  },
                  {
                    name: "country",
                    errors: countryErrors,
                  },
                  {
                    name: "town",
                    errors: townErrors,
                  },
                  {
                    name: "postCode",
                    errors: postCodeErrors,
                  },
                  {
                    name: "latitude",
                    errors: latitudeErrors,
                  },
                  {
                    name: "longitude",
                    errors: longitudeErrors,
                  },
                  {
                    name: "region",
                    errors: regionErrors,
                  },
                ]);

                setIsEditing(!isEditing);
              } else {
                if (!isAddress1Valid) setOpenAddress1Snackbar(true);
                if (!isAddress2Valid) setOpenAddress2Snackbar(true);
                if (!isTownValid) setOpenTownSnackbar(true);
                if (!isPostCodeValid) setOpenPostCodeSnackbar(true);
                if (!isCountryValid) setOpenCountrySnackbar(true);
                if (!isLatitudeValid) setOpenLatitudeSnackbar(true);
                if (!isLongitudeValid) setOpenLongitudeSnackbar(true);
                if (!isRegionValid) setOpenRegionSnackbar(true);
              }
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
            <span className="pl-1">Finished Selecting</span>
          </button>
        </div>
      </div>
      <CustomSnackbar
        open={openAddress1Snackbar}
        setOpen={(val) => setOpenAddress1Snackbar(val)}
        severity={"error"}
        message={address1Errors}
        autoHideDuration={3000}
      />
      <CustomSnackbar
        open={openAddress2Snackbar}
        setOpen={(val) => setOpenAddress2Snackbar(val)}
        severity={"error"}
        message={address2Errors}
        autoHideDuration={3000}
      />
      <CustomSnackbar
        open={openTownSnackbar}
        setOpen={(val) => setOpenTownSnackbar(val)}
        severity={"error"}
        message={townErrors}
        autoHideDuration={3000}
      />
      <CustomSnackbar
        open={openPostCodeSnackbar}
        setOpen={(val) => setOpenPostCodeSnackbar(val)}
        severity={"error"}
        message={postCodeErrors}
        autoHideDuration={3000}
      />
      <CustomSnackbar
        open={openCountrySnackbar}
        setOpen={(val) => setOpenCountrySnackbar(val)}
        severity={"error"}
        message={countryErrors}
        autoHideDuration={3000}
      />
      <CustomSnackbar
        open={openLatitudeSnackbar}
        setOpen={(val) => setOpenLatitudeSnackbar(val)}
        severity={"error"}
        message={latitudeErrors}
        autoHideDuration={3000}
      />
      <CustomSnackbar
        open={openLongitudeSnackbar}
        setOpen={(val) => setOpenLongitudeSnackbar(val)}
        severity={"error"}
        message={longitudeErrors}
        autoHideDuration={3000}
      />
      <CustomSnackbar
        open={openRegionSnackbar}
        setOpen={(val) => setOpenRegionSnackbar(val)}
        severity={"error"}
        message={regionErrors}
        autoHideDuration={3000}
      />
    </>
  );
}

export function FacilityDetailsEditorContext({
  update,
  allFacilities,
  isEditing,
  setIsEditing,
  setIsSaving,
}) {
  const classes = useStyles();
  const [tempAllFacilities, setTempAllFacilities] =
    React.useState(allFacilities);
  const activeFacilities = tempAllFacilities.filter((f) => f.isActive);
  return (
    <>
      <h2>Facilities</h2>
      <hr className="mt-0 mb-1" />
      <div className={classes.root}>
        <FormControl component="fieldset" className={classes.formControl}>
          <Form.Label hidden={true}>
            <span>Applicable Facilities</span>
          </Form.Label>
          <FormGroup>
            <div className="row mb-3">
              {activeFacilities.map((facility) => {
                return (
                  <FacilityItem
                    key={`facilityCheckbox-${facility.facilityName}`}
                    facility={facility}
                    allFacilities={tempAllFacilities}
                    updateAllFacilities={(newVal) => {
                      setTempAllFacilities(newVal);
                    }}
                  />
                );
              })}
            </div>
          </FormGroup>
        </FormControl>
      </div>
      <div className="d-flex flex-row align-items-center">
        <div className="ml-auto mr-auto p-0">
          <button
            className="btn btn-primary"
            onClick={() => {
              setIsSaving(true);
              setTimeout(() => {
                let newFacilities = tempAllFacilities.filter(
                  (taf) => taf.isChecked
                );
                newFacilities.forEach((nf) => {
                  nf.types = nf.types.filter((t) => t.isChecked);
                });
                update("facilities", newFacilities);
                update("allFacilities", tempAllFacilities);
                setIsSaving(false);
                setIsEditing(!isEditing);
              }, 100);
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
            <span className="pl-1">Finished Selecting</span>
          </button>
        </div>
      </div>
    </>
  );
}

export function CategoryDetailsEditorContext({
  updateMainCategory,
  updateCategories,
  allCategories,
  categories,
  mainCategory,
  isEditing,
  setIsEditing,
  updateErrors,
}) {
  const classes = useStyles();
  const [categoryErrors, setCategoryErrors] = React.useState([]);
  const [openCategorySnackbar, setOpenCategorySnackbar] = React.useState(false);
  const isCategoryValid = categoryErrors.length === 0;

  const initialMainCategoryOptions =
    categories && categories.length
      ? getMainCategoryDropdownOptions(
          allCategories.filter((c) =>
            categories.find((uc) => c.categoryName === uc)
          )
        )
      : [];

  const [mainCategoryOptions, setMainCategoryOptions] = React.useState(
    initialMainCategoryOptions
  );
  const [mainCategoryErrors, setMainCategoryErrors] = React.useState([]);
  const [openMainCategorySnackbar, setOpenMainCategorySnackbar] =
    React.useState(false);
  const isMainCategoryValid = mainCategoryErrors.length === 0;

  return (
    <>
      <h2>Categories</h2>
      <hr className="mt-0 mb-1" />
      <div className={classes.root}>
        <FormControl component="fieldset" className={classes.formControl}>
          <Form.Label>
            <span>
              Applicable Categories <em>(select at least one)</em> *
            </span>
          </Form.Label>
          <FormGroup>
            <div className="row mb-3">
              {!isCategoryValid ? (
                <div className="col-12">
                  <span className="forced-invalid-feedback">
                    {categoryErrors}
                  </span>
                </div>
              ) : null}
              <input
                type="hidden"
                value={categories}
                id="category-categories"
              />
              {allCategories.map((category) => {
                const { categoryName, categoryPageTitle } = category;
                const isChecked =
                  categories.find((c) => c === category.categoryName) != null;
                return (
                  <div
                    className="col-lg-4"
                    key={`categoryCheckbox-${categoryName}`}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked}
                          onChange={(e) =>
                            handleCategorySelect({
                              updateSelectedCategories,
                              e,
                              categoryName,
                              categories,
                              updateCategories,
                              updateMainCategory,
                              setMainCategoryOptions,
                              allCategories,
                              setCategoryErrors,
                              validationConfig,
                            })
                          }
                          name={categoryName}
                          id={`category-${categoryName}`}
                        />
                      }
                      label={categoryPageTitle}
                    />
                  </div>
                );
              })}
            </div>
          </FormGroup>
        </FormControl>
      </div>
      <div>
        {mainCategoryOptions.length < 2 ? null : (
          <>
            <Form.Label>
              <span>Main Category *</span>
            </Form.Label>
            <Form.Control
              as="select"
              value={mainCategory}
              onChange={(e) => {
                const errors = isInputValid(
                  validationConfig,
                  "mainCategory",
                  e.target.value
                );
                setMainCategoryErrors(errors);
                updateMainCategory(e.target.value);
              }}
              style={{
                display: mainCategoryOptions.length > 1 ? "block" : "none",
              }}
              id="category-mainCategory"
            >
              {mainCategoryOptions.map(
                ({ categoryName, categoryPageTitle }) => (
                  <option
                    key={categoryName}
                    value={categoryName}
                    id={categoryName}
                  >
                    {categoryPageTitle}
                  </option>
                )
              )}
            </Form.Control>
            {!isMainCategoryValid ? (
              <span className="forced-invalid-feedback">
                {mainCategoryErrors}
              </span>
            ) : null}
          </>
        )}
        <div
          className="alert alert-warning"
          style={{
            display: mainCategoryOptions.length < 2 ? "block" : "none",
          }}
        >
          <div className="d-flex flex-row">
            <div className="p-0">
              <FontAwesomeIcon icon={faInfoCircle} />
            </div>
            <div className="pl-2">
              <span>
                Select at least one category before selecting a main category.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-row align-items-center">
        <div className="ml-auto mr-auto p-0 pt-3">
          <button
            className="btn btn-primary"
            onClick={() => {
              if (isCategoryValid && isMainCategoryValid) {
                updateErrors([
                  {
                    name: "categories",
                    errors: isInputValid(
                      validationConfig,
                      "categories",
                      categories
                    ),
                  },
                  {
                    name: "mainCategory",
                    errors: isInputValid(
                      validationConfig,
                      "mainCategory",
                      mainCategory
                    ),
                  },
                ]);
                setIsEditing(!isEditing);
              } else {
                if (!isCategoryValid) setOpenCategorySnackbar(true);
                if (!isMainCategoryValid) setOpenMainCategorySnackbar(true);
              }
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
            <span className="pl-1">Finished Selecting</span>
          </button>
        </div>
      </div>
      <CustomSnackbar
        open={openCategorySnackbar}
        setOpen={(val) => setOpenCategorySnackbar(val)}
        severity={"error"}
        message={categoryErrors}
        autoHideDuration={3000}
      />
      <CustomSnackbar
        open={openMainCategorySnackbar}
        setOpen={(val) => setOpenMainCategorySnackbar(val)}
        severity={"error"}
        message={mainCategoryErrors}
        autoHideDuration={3000}
      />
    </>
  );
}

// #endregion Editor Context

// #region Additional Components

function AttractionImageEditButton({ hasUrl, isEditing, setIsEditing }) {
  return (
    <button
      className="btn btn-xs btn-warning"
      onClick={() => setIsEditing(!isEditing)}
    >
      <FontAwesomeIcon icon={faEdit} />
      <span className="pl-1">{hasUrl ? "Edit" : "Set Image URL"}</span>
    </button>
  );
}

function PriceRadioGroup({ name }) {
  const classes = useStyles();
  let group = [
    <FormControlLabel
      className="col-12 pb-2"
      key={`price-default`}
      value={"default"}
      control={<Radio color="primary" />}
      defaultChecked={true}
      label={
        <Typography className={classes.radioButtonLabelText + " wordbreak"}>
          {setPriceRatingPopoverText(undefined, name)}
        </Typography>
      }
    />,
  ];
  for (let count = 0; count < 6; count++) {
    const text = setPriceRatingPopoverText(count, name);
    group.push(
      <FormControlLabel
        className="col-12 pb-2"
        key={`price-${count}`}
        value={count}
        control={<Radio color="primary" />}
        label={
          <Typography className={classes.radioButtonLabelText + " wordbreak"}>
            {text}
          </Typography>
        }
      />
    );
  }
  return <div className="row">{group}</div>;
}

function SelectedFacilityList({ selectedFacilities }) {
  if (selectedFacilities) {
    return (
      <div className="row">
        {selectedFacilities.map((facility) => {
          const { displayName } = facility;
          let parent = displayName;
          let child;
          if (displayName.includes(" | ")) {
            const facilities = displayName.split(" | ");
            parent = facilities[0];
            child = facilities[1];
          }
          return (
            <div className="col-md-3 mb-3" key={displayName}>
              <Grid container alignItems="center">
                <Grid item xs={1}>
                  <Badge className="pl-1" color="primary" variant="dot"></Badge>
                </Grid>
                <Grid item xs={11}>
                  <p
                    style={{
                      ...(child && { fontSize: "0.8em" }),
                      fontWeight: "600",
                      marginBottom: 0,
                    }}
                  >
                    {parent}
                  </p>
                  {child && <p style={{ fontWeight: "600", marginBottom: 0 }}>{child}</p>}
                </Grid>
              </Grid>
            </div>
          );
        })}
      </div>
    );
  } else return null;
}

function FacilityItem({ facility, allFacilities, updateAllFacilities }) {
  const { displayName, facilityName } = facility;
  const [isChecked, setIsChecked] = React.useState(facility.isChecked);
  const classes = useStyles();

  return (
    <div className="col-md-6 col-lg-4">
      <div className="d-flex flex-row align-items-top">
        <div className="p-0">
          <FormControlLabel
            aria-label={`facility-${facilityName}`}
            htmlFor={`facility-${facilityName}`}
            for={`facility-${facilityName}`}
            aria-hidden={true}
            control={
              <Checkbox
                checked={isChecked}
                onChange={(e) => {
                  setIsChecked(e.target.checked);
                  handleFacilityChange({
                    isChecked: e.target.checked,
                    facilityName: facility.facilityName,
                    allFacilities,
                    updateAllFacilities,
                  });
                }}
                name={facilityName}
                id={`facility-${facilityName}`}
                className={classes.facilityCheckbox}
              />
            }
          />
        </div>
        <div className="p-0">
          <span className="wordbreak">{displayName}</span>
        </div>
      </div>{" "}
    </div>
  );
}

function AttractionMapWindow({ attraction, coordinates }) {
  setTimeout(() => {
    SetupMap(
      "attractionMap",
      [coordinates.latitude, coordinates.longitude],
      13,
      attraction.name,
      ""
    );
  }, 100);
  return <div id="attractionMap"></div>;
}

export function ManagementBar({
  pagePermissions,
  isApproved,
  isAwaitingAdminReview,
  isLive,
  deactivateAttraction,
  reactivateAttraction,
  rejectSubmission,
  approveSubmission,
  loadGooglePlaceData,
  saveChanges,
}) {
  const { isCreate, isEdit, isSubmit, isEditSubmission, isAdmin } =
    pagePermissions;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={clsx(classes.appBar)}>
        <Toolbar className="container">
          {isEdit && isAdmin && isApproved && isLive ? (
            <div className="pr-2">
              <button
                className="btn btn-sm btn-danger"
                onClick={deactivateAttraction}
              >
                <FontAwesomeIcon
                  className="mr-1 d-none d-sm-inline"
                  icon={faBan}
                />
                <span className="ml-1">Deactivate</span>
              </button>
            </div>
          ) : null}
          {isEdit && isAdmin && !isLive ? (
            <div className="pr-2">
              <button
                className="btn btn-sm btn-success"
                onClick={reactivateAttraction}
              >
                <FontAwesomeIcon
                  className="mr-1 d-none d-sm-inline"
                  icon={isApproved ? faUndo : faCheck}
                />
                <span className="ml-1">
                  {isApproved ? "Reactivate" : "Activate"}
                </span>
              </button>
            </div>
          ) : null}
          {isEditSubmission && isAdmin && isAwaitingAdminReview ? (
            <>
              <div className="pr-2">
                <button
                  className="btn btn-sm btn-danger"
                  onClick={rejectSubmission}
                >
                  <FontAwesomeIcon
                    className="mr-1 d-none d-sm-inline"
                    icon={faTimes}
                  />
                  <span className="ml-1">Reject</span>
                </button>
              </div>
              <div className="pr-2">
                <button
                  className="btn btn-sm btn-success"
                  onClick={approveSubmission}
                >
                  <FontAwesomeIcon
                    className="mr-1 d-none d-sm-inline"
                    icon={faCheck}
                  />
                  <span className="ml-1">Approve</span>
                </button>
              </div>
            </>
          ) : null}
          <div className="ml-auto p-0">
            <button className="btn btn-sm btn-primary" onClick={saveChanges}>
              <FontAwesomeIcon
                className="mr-1 d-none d-sm-inline"
                icon={faSave}
              />
              <span>Save</span>
            </button>
          </div>
          {!isCreate && !isSubmit && isAdmin ? (
            <div className="pl-2">
              <button
                className="btn btn-sm btn-secondary"
                onClick={loadGooglePlaceData}
              >
                <FontAwesomeIcon
                  className="mr-1 d-none d-sm-inline"
                  icon={faCloudDownloadAlt}
                />
                <span>Load Google Place Data</span>
              </button>
            </div>
          ) : null}
        </Toolbar>
      </AppBar>
    </div>
  );
}

// #endregion Additional Components
