// This file imports and loads attraction icon images
// Function returns the category icon based on whether darkmdoe is enabled

// Light theme icons
import aquariumLight from '../img/categoryIcons/aquarium-light.png';
import entertainmentLight from '../img/categoryIcons/entertainment-light.png';
import experienceLight from '../img/categoryIcons/experience-light.png';
import historicLight from '../img/categoryIcons/historic-light.png';
import museumLight from '../img/categoryIcons/museum-light.png';
import natureLight from '../img/categoryIcons/nature-light.png';
import outdoorsLight from '../img/categoryIcons/outdoors-light.png';
import racingLight from '../img/categoryIcons/racing-light.png';
import sportsLight from '../img/categoryIcons/sports-light.png';
import themeparkLight from '../img/categoryIcons/themepark-light.png';
import tourLight from '../img/categoryIcons/tour-light.png';
import zooLight from '../img/categoryIcons/zoo-light.png';
import dayhootLadLight from '../img/logos/hoot-white.png';

// Dark theme icons
import aquariumDark from '../img/categoryIcons/aquarium-dark.png';
import entertainmentDark from '../img/categoryIcons/entertainment-dark.png';
import experienceDark from '../img/categoryIcons/experience-dark.png';
import historicDark from '../img/categoryIcons/historic-dark.png';
import museumDark from '../img/categoryIcons/museum-dark.png';
import natureDark from '../img/categoryIcons/nature-dark.png';
import outdoorsDark from '../img/categoryIcons/outdoors-dark.png';
import racingDark from '../img/categoryIcons/racing-dark.png';
import sportsDark from '../img/categoryIcons/sports-dark.png';
import themeparkDark from '../img/categoryIcons/themepark-dark.png';
import tourDark from '../img/categoryIcons/tour-dark.png';
import zooDark from '../img/categoryIcons/zoo-dark.png';
import dayhootLadDark from '../img/logos/hoot-dark.png';

// Mappings for all icons to their category name
export const icons = [
    { name: 'aquarium', light: aquariumLight, dark: aquariumDark },
    { name: 'entertainment', light: entertainmentLight, dark: entertainmentDark },
    { name: 'experience', light: experienceLight, dark: experienceDark },
    { name: 'historic', light: historicLight, dark: historicDark },
    { name: 'museum', light: museumLight, dark: museumDark },
    { name: 'nature', light: natureLight, dark: natureDark },
    { name: 'outdooractivity', light: outdoorsLight, dark: outdoorsDark },
    { name: 'racing', light: racingLight, dark: racingDark },
    { name: 'sports', light: sportsLight, dark: sportsDark },
    { name: 'themepark', light: themeparkLight, dark: themeparkDark },
    { name: 'tours', light: tourLight, dark: tourDark },
    { name: 'zoo', light: zooLight, dark: zooDark },
    { name: 'dayhootlad', light: dayhootLadLight, dark: dayhootLadDark }
];

// If all else fails, we show the dayhoot lad as the default icon
export const defaultIcon =  icons.find(icon => icon.name === 'dayhootlad');
