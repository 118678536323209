// Package Imports
import React from "react";

// Dayhoot Functions & Scripts
import { BasicPagination } from "../components/common-components";

export function Toolbar({ numberOfPages, handleChange, currentPage }) {
  return (
    <div className="row mt-2 mb-2">
      <div className="col-12">
        <div id="toolbar">
          <div className="d-flex align-items-center">
            <div className="ml-auto mr-auto">
              <BasicPagination
                numberOfPages={numberOfPages || 1}
                handleChange={handleChange}
                currentPage={currentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
