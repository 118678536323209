// Package Imports
import React, { Component } from "react";
import { withCookies } from "react-cookie";

// Dayhoot Functions & Scripts
import { setPageTitleText } from "../scripts/utils";
import { getFilteredCategories } from "../scripts/api-core";

// Dayhoot Components
import { PageLoading } from "../components/common-components";
import { CategoryTileList } from "../components/categories-components";
import { PageTitle } from "../components";

// Dayhoot Config
import { categorySortTypes } from "../config/enums";

class Categories extends Component {
  state = {
    categories: {},
    attractions: [],
    isPageLoaded: false,
    cookies: this.props.cookies.getAll(),
  };

  async componentDidMount() {
    const { categories } = await this.renderMyData();
    this.setState({
      categories: categories,
      isPageLoaded: true,
    });
    setPageTitleText("Categories");
  }

  async renderMyData() {
    const { results } = await getFilteredCategories({
      sortOptionValue: categorySortTypes.AlphabeticalAsc.value,
    });
    return {
      categories: results,
    };
  }

  render() {
    const { isPageLoaded, categories } = this.state;
    if (isPageLoaded) {
      return (
        <div>
          <div id="root"></div>
          <div className="container mt-3">
            <PageTitle
              headingId={"categoriesTitle"}
              headingText={"Categories"}
            />
            <CategoryTileList categories={categories} />
          </div>
        </div>
      );
    } else return <PageLoading />;
  }
}

export default withCookies(Categories);
