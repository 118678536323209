import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import defaultTile from "../img/placeHolders/defaultTile.png";
import { citiesList } from "../config/citiesList";
import { attractionListSortTypeEnums, createEditType } from "../config/enums";
import { WebConfig } from "../config/webconfig";
import { cookieDictionary, getCookie } from "../storage/cookies";

export function getGoogleMapsUrl(coordinates) {
  return `https://www.google.com/maps/dir/Current+Location/${coordinates.latitude},${coordinates.longitude}`;
}

export function convertMilesToKilometers(miles) {
  if (miles != null) {
    const km = miles * 1.60934;
    return km.toFixed(4);
  } else console.error("An invalid number of miles were entered.");
}

export function convertKilometersToMiles(km) {
  if (km != null) {
    const miles = km * 0.62137;
    return miles.toFixed(4);
  } else {
    console.error("An invalid number of miles were entered.");
  }
}

export function handleCoordinates({ latitude, longitude }) {
  const hasCoordinates = latitude && longitude;
  return {
    isUsingLocation: hasCoordinates, // Included so we can show banners
    latitude: hasCoordinates ? latitude : WebConfig.defaultCoordinates.latitude,
    longitude: hasCoordinates
      ? longitude
      : WebConfig.defaultCoordinates.longitude,
  };
}

export function getFilterButton(buttonText, icon) {
  const iconContent =
    icon != null ? <FontAwesomeIcon icon={icon}></FontAwesomeIcon> : null;
  return (
    <>
      {iconContent}
      <span className="ml-2">{buttonText}</span>
    </>
  );
}

// Outputs the distance between the user and an attraction, appends the units set
export function getAttractionItemDistanceAway(props) {
  const { cookies, latitude: attractionLatitude, longitude: attractionLongitude } = props;

  if (!cookies) {
    console.error("Cookies was not defined.");
    return;
  }

  if (!attractionLatitude || !attractionLongitude) {
    console.error("Attraction coordinates were not defined or were unable to retrieved.");
    return;
  }
  
  const {
    SettingUserLocationLatitude: LatitudeCookie,
    SettingUserLocationLongitude: LongitudeCookie,
    SettingUserDistanceUnitKm: UserDistanceCookie
  } = cookieDictionary;

  const userCoords = {
    latitude: getCookie(cookies, LatitudeCookie.key),
    longitude: getCookie(cookies, LongitudeCookie.key),
  };

  const attractionCoords = {
    latitude: attractionLatitude,
    longitude: attractionLongitude,
  };

  const distanceAwayKm = getDistanceFromLatLonInKm(
    userCoords,
    attractionCoords
  );

  if (isNaN(distanceAwayKm)) {
    return undefined;
  }
  else if (getCookie(cookies, UserDistanceCookie.key) === true) {
    return `${formatNumberWithCommas(distanceAwayKm)} km away`;
  } else {
    const distanceAwayMi = Math.round(convertKilometersToMiles(distanceAwayKm));
    return `${formatNumberWithCommas(distanceAwayMi)} mi away`;
  }
}

//Calculation to determine how far apart two lat/long are (ie distance between 2 locations)
export function getDistanceFromLatLonInKm(coords1, coords2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(coords1.latitude - coords2.latitude); // deg2rad below
  var dLon = deg2rad(coords1.longitude - coords2.longitude);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(coords1.latitude)) *
      Math.cos(deg2rad(coords2.latitude)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return Math.round(d);
}

// converts degress to radians (needed to calc distance between two points)
export function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

// Converts a number to a format with commas if needed for presentation (ie 1000 becomes 1,000)
export function formatNumberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// Generates a random string, used when we need a unique ID
export function getUuid() {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  return s.join("");
}

export function pluralze(number) {
  return number === 1 ? "" : "s";
}

// Sanitises any Id passed to ensure if there isn't one, we set a random one
export function SetId({ id }) {
  return id || getUuid();
}

export function sortArray(a, b, sortType) {
  let sortResult = "default";
  switch (sortType) {
    case attractionListSortTypeEnums.AlphabeticalAsc:
      sortResult = a < b ? -1 : a > b ? 1 : 0;
      break;
    default:
      sortResult = a;
      break; // do nothing and return the unsorted array
  }
  return sortResult;
}

export function sortByAz(a, b) {
  if (a.toLowerCase() < b.toLowerCase()) return -1;
  if (a.toLowerCase() > b.toLowerCase()) return 1;
  return 0; // a must be equal to b
}

export function setPageTitleText(titleText) {
  document.title = "Dayhoot" + (titleText ? " - " + titleText : "");
}

export function getTileFromName(tileList, tileName) {
  const selectedTile = tileList.find(tile => tile.name === tileName);
  return selectedTile ? selectedTile.tile : defaultTile;
}

export function isUsingMobile() {
  const { innerWidth: width, innerHeight: height } = window;
  return height < 850 || width < 400;
}

export function multiLineOverflows(element) {
  return element ? element.scrollHeight > element.clientHeight : false;
}

export function getCityLocationDetails(CityName) {
  const cityDetails = citiesList.find(x => x.cityName === CityName);
  let city = false;
  if (cityDetails !== undefined) {
    city = {
      cityName: cityDetails.cityName.toString(),
      latitude: cityDetails.latitude,
      longitude: cityDetails.longitude,
    };
  }
  return city;
}

export function tryParseNumber(valueToParse) {
  const parsedValue = parseInt(valueToParse);
  return isNaN(parsedValue) ? valueToParse : parsedValue;
}

export function invertColour(hex) {
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error("Invalid HEX colour.");
  }
  // invert colour components
  var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
    g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
    b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
  // pad each with zeros and return
  return "#" + padZero(r) + padZero(g) + padZero(b);
}

function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
}

export function viewWebsite(link) {
  window.open(link, "_blank");
}

export function checkIsValueInArray(array, value) {
  return array.find(x => x === value) != null;
}

// Removes punctuation and spaces from a string
export function flattenString(str) {
  return str
    .replace(/[^\w\s]|_/g, "")
    .replace(/\s+/g, " ")
    .replace(/\s/g, "");
}

export function getCreateEditPageType(pathname) {
  const pathnames = pathname.split("/").filter(x => x);
  return pathnames[pathnames.length - 1] === "create"
    ? createEditType.Create
    : pathnames[pathnames.length - 2] === "edit"
    ? createEditType.Edit
    : undefined;
}

export function getMatchingPageValueString(pathname) {
  let matchingPageValue = "";
  pathname
    .split("/")
    .filter(x => x)
    .map((p, i) => {
      return { name: p, index: i };
    })
    .sort((l, r) => (l.index > r.index ? -1 : 1))
    .forEach(path => {
      if (path.index !== 0) matchingPageValue = path.name + matchingPageValue;
    });
  return matchingPageValue;
}

export async function checkElement(selector) {
  while (document.querySelector(selector) === null) {
    await new Promise(resolve => requestAnimationFrame(resolve));
  }
  return document.querySelector(selector);
}

export function checkUrlHasHttp(url) {
  return url && (url.includes("http://") || url.includes("https://"));
}

// get offset of element
export function getOffset(el) {
  var _x = 0;
  var _y = 0;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft;
    _y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }
  return { top: _y, left: _x };
}
