export const citiesList = [
    {"id":1,"cityName":"Bath","country":"England","latitude":51.38,"longitude":-2.36},
    {"id":2,"cityName":"Birmingham","country":"England","latitude":52.48,"longitude":-1.9},
    {"id":3,"cityName":"Bradford","country":"England","latitude":53.79,"longitude":-1.75},
    {"id":4,"cityName":"Brighton and Hove","country":"England","latitude":50.83,"longitude":-0.15},
    {"id":5,"cityName":"Bristol","country":"England","latitude":51.45,"longitude":-2.58},
    {"id":6,"cityName":"Cambridge","country":"England","latitude":52.21,"longitude":0.12},
    {"id":7,"cityName":"Canterbury","country":"England","latitude":51.28,"longitude":1.08},
    {"id":8,"cityName":"Carlisle","country":"England","latitude":54.89,"longitude":-2.94},
    {"id":9,"cityName":"Chelmsford","country":"England","latitude":51.74,"longitude":0.48},
    {"id":10,"cityName":"Chester","country":"England","latitude":53.19,"longitude":-2.89},
    {"id":11,"cityName":"Chichester","country":"England","latitude":50.84,"longitude":-0.78},
    {"id":12,"cityName":"Coventry","country":"England","latitude":52.41,"longitude":-1.51},
    {"id":13,"cityName":"Derby","country":"England","latitude":52.92,"longitude":-1.47},
    {"id":14,"cityName":"Durham","country":"England","latitude":54.78,"longitude":-1.57},
    {"id":15,"cityName":"Ely","country":"England","latitude":52.4,"longitude":0.26},
    {"id":16,"cityName":"Exeter","country":"England","latitude":50.72,"longitude":-3.53},
    {"id":17,"cityName":"Gloucester","country":"England","latitude":51.86,"longitude":-2.24},
    {"id":18,"cityName":"Hereford","country":"England","latitude":52.06,"longitude":-2.72},
    {"id":19,"cityName":"Kingston upon Hull","country":"England","latitude":53.74,"longitude":-0.33},
    {"id":20,"cityName":"Lancaster","country":"England","latitude":54.05,"longitude":-2.8},
    {"id":21,"cityName":"Leeds","country":"England","latitude":53.8,"longitude":-1.55},
    {"id":22,"cityName":"Leicester","country":"England","latitude":52.63,"longitude":-1.13},
    {"id":23,"cityName":"Lichfield","country":"England","latitude":52.68,"longitude":-1.83},
    {"id":24,"cityName":"Lincoln","country":"England","latitude":53.23,"longitude":-0.54},
    {"id":25,"cityName":"Liverpool","country":"England","latitude":53.4,"longitude":-2.98},
    {"id":26,"cityName":"London","country":"England","latitude":51.52,"longitude":-0.09},
    {"id":27,"cityName":"Manchester","country":"England","latitude":53.48,"longitude":-2.25},
    {"id":28,"cityName":"Newcastle upon Tyne","country":"England","latitude":55.01,"longitude":-1.66},
    {"id":29,"cityName":"Norwich","country":"England","latitude":52.63,"longitude":1.3},
    {"id":30,"cityName":"Nottingham","country":"England","latitude":52.95,"longitude":-1.15},
    {"id":31,"cityName":"Oxford","country":"England","latitude":51.75,"longitude":-1.26},
    {"id":32,"cityName":"Peterborough","country":"England","latitude":52.58,"longitude":-0.25},
    {"id":33,"cityName":"Plymouth","country":"England","latitude":50.37,"longitude":-4.14},
    {"id":34,"cityName":"Portsmouth","country":"England","latitude":50.81,"longitude":-1.09},
    {"id":35,"cityName":"Preston","country":"England","latitude":53.76,"longitude":-2.7},
    {"id":36,"cityName":"Ripon","country":"England","latitude":54.14,"longitude":-1.52},
    {"id":37,"cityName":"Salford","country":"England","latitude":53.48,"longitude":-2.29},
    {"id":38,"cityName":"Salisbury","country":"England","latitude":51.07,"longitude":-1.79},
    {"id":39,"cityName":"Sheffield","country":"England","latitude":53.38,"longitude":-1.47},
    {"id":40,"cityName":"Southampton","country":"England","latitude":50.9,"longitude":-1.4},
    {"id":41,"cityName":"St Albans","country":"England","latitude":51.75,"longitude":-0.34},
    {"id":42,"cityName":"Stoke-on-Trent","country":"England","latitude":53,"longitude":-2.18},
    {"id":43,"cityName":"Sunderland","country":"England","latitude":54.91,"longitude":-1.39},
    {"id":44,"cityName":"Truro","country":"England","latitude":50.26,"longitude":-5.05},
    {"id":45,"cityName":"Wakefield","country":"England","latitude":53.68,"longitude":-1.5},
    {"id":46,"cityName":"Wells","country":"England","latitude":51.21,"longitude":-2.65},
    {"id":47,"cityName":"Westminster","country":"England","latitude":51.5,"longitude":-0.14},
    {"id":48,"cityName":"Winchester","country":"England","latitude":51.06,"longitude":-1.32},
    {"id":49,"cityName":"Wolverhampton","country":"England","latitude":52.58,"longitude":-2.13},
    {"id":50,"cityName":"Worcester","country":"England","latitude":52.19,"longitude":-2.22},
    {"id":51,"cityName":"York","country":"England","latitude":53.96,"longitude":-1.08},
    {"id":52,"cityName":"Aberdeen","country":"Scotland","latitude":57.15,"longitude":-2.11},
    {"id":53,"cityName":"Dundee","country":"Scotland","latitude":56.46,"longitude":-2.97},
    {"id":54,"cityName":"Edinburgh","country":"Scotland","latitude":55.95,"longitude":-3.19},
    {"id":55,"cityName":"Glasgow","country":"Scotland","latitude":55.86,"longitude":-4.25},
    {"id":56,"cityName":"Inverness","country":"Scotland","latitude":57.48,"longitude":-4.22},
    {"id":57,"cityName":"Perth","country":"Scotland","latitude":56.4,"longitude":-3.44},
    {"id":58,"cityName":"Stirling","country":"Scotland","latitude":56.12,"longitude":-3.94},
    {"id":59,"cityName":"Bangor","country":"Wales","latitude":53.23,"longitude":-4.13},
    {"id":60,"cityName":"Cardiff","country":"Wales","latitude":51.48,"longitude":-3.18},
    {"id":61,"cityName":"Newport","country":"Wales","latitude":51.58,"longitude":-3},
    {"id":62,"cityName":"St Asaph","country":"Wales","latitude":53.26,"longitude":-3.44},
    {"id":63,"cityName":"St David's","country":"Wales","latitude":51.88,"longitude":-5.27},
    {"id":64,"cityName":"Swansea","country":"Wales","latitude":51.62,"longitude":-3.95},
    {"id":65,"cityName":"Armagh","country":"Northern Ireland","latitude":54.35,"longitude":-6.65},
    {"id":66,"cityName":"Belfast","country":"Northern Ireland","latitude":54.6,"longitude":-5.93},
    {"id":67,"cityName":"Derry","country":"Northern Ireland","latitude":55,"longitude":-7.31},
    {"id":68,"cityName":"Lisburn","country":"Northern Ireland","latitude":54.51,"longitude":-6.03},
    {"id":69,"cityName":"Newry","country":"Northern Ireland","latitude":54.18,"longitude":-6.35}
];