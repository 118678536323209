import { Component } from "react";
import { withCookies } from "react-cookie";
import { PageLoading } from "../components/common-components";
import {
  flattenString,
  getCreateEditPageType,
  setPageTitleText,
} from "../scripts/utils";
import { createRegion, getRegion, updateRegion } from "../scripts/api-core";
import RouterBreadcrumbs from "../components/routerBreadcrumbs";
import { regionValidationConfig } from "../config/manageAttraction-config";
import { isInputValid } from "../scripts/validation";
import { createEditType } from "../config/enums";
import { NameSection } from "../components/admin-createEdit-regions-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAdminAuthenticationDetails } from "../scripts/admin-auth";
import {
  faCheckCircle,
  faSave,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router";

class CreateEditRegion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPageLoaded: false,
      isAuthenticated: false,
      token: null,
      region: null,

      displayName: {
        value: "",
        isDirty: false,
        errors: isInputValid(regionValidationConfig, "displayName", ""),
      },

      regionName: {
        value: "",
        isDirty: false,
        errors: isInputValid(regionValidationConfig, "regionName", ""),
      },

      latitude: {
        value: "",
        isDirty: false,
        errors: isInputValid(regionValidationConfig, "latitude", ""),
      },

      longitude: {
        value: "",
        isDirty: false,
        errors: isInputValid(regionValidationConfig, "longitude", ""),
      },

      hasUpdated: false,
      hasCreated: false,
    };
  }

  async componentDidMount() {
    const { isAuthenticated, token, pageType, isEdit } =
      await this.renderData();

    setPageTitleText(`${pageType.displayName} Region`);

    let region = this.state.region;
    if (isEdit) {
      region = await getRegion({
        name: this.props.match.params.regionName,
      });
      this.mapRegionToProperties({ region });
    }

    this.setState({
      isPageLoaded: true,
      isAuthenticated,
      token,
      pageType,
      isEdit,
    });
  }

  async renderData() {
    const { cookies, location } = this.props;
    const { token } = getAdminAuthenticationDetails(cookies);
    const pageType = getCreateEditPageType(location.pathname);
    const isEdit = pageType === createEditType.Edit;

    return {
      isAuthenticated: !!token,
      token: token,
      pageType: pageType,
      isEdit: isEdit,
    };
  }

  mapRegionToProperties({ region }) {
    const { displayName, regionName, location } = region;
    const { latitude, longitude } = location;

    this.setState({
      displayName: this.getRegionObject({
        value: displayName,
        isDirty: false,
        fieldName: "displayName",
      }),
      regionName: this.getRegionObject({
        value: regionName,
        isDirty: false,
        fieldName: "regionName",
      }),
      latitude: this.getRegionObject({
        value: latitude,
        isDirty: false,
        fieldName: "latitude",
      }),
      longitude: this.getRegionObject({
        value: longitude,
        isDirty: false,
        fieldName: "longitude",
      }),
    });
  }

  mapPropertiesToRegion() {
    const { displayName, regionName, latitude, longitude } = this.state;

    const newRegion = {
      displayName: displayName.value,
      regionName: flattenString(regionName.value.toLowerCase()),
      location: {
        latitude: latitude.value,
        longitude: longitude.value,
      },
    };

    this.setState({ region: newRegion });

    return newRegion;
  }

  async saveRegion() {
    this.setState({ isPageLoaded: false });
    const region = this.mapPropertiesToRegion();
    const { token } = getAdminAuthenticationDetails(this.props.cookies);
    const updatedRegion = this.state.isEdit
      ? await updateRegion(region, token)
      : await createRegion(region, token);
    try {
      this.setState({
        region: updatedRegion,
        hasUpdated: this.state.isEdit ? true : false,
        hasCreated: this.state.isEdit ? false : true,
        isPageLoaded: true,
      });
    } catch {
      this.setState({ isPageLoaded: true });
      console.error(
        "This Region was not updated. Please contact us for assistance."
      );
    }
  }

  getRegionObject({ value, isDirty, fieldName }) {
    return {
      value: value,
      isDirty: isDirty,
      errors: isInputValid(regionValidationConfig, fieldName, value),
    };
  }

  render() {
    if (this.state.isPageLoaded) {
      const {
        isAuthenticated,
        pageType,
        displayName,
        regionName,
        latitude,
        longitude,
        hasUpdated,
        hasCreated,
        isEdit,
      } = this.state;

      if (isAuthenticated) {
        const inputs = [displayName, regionName, latitude, longitude];

        const hasErrors = inputs.some((x) => {
          return regionName.errors.length > 0 || regionName.errors.length > 0;
        });
        return (
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1>{pageType.displayName} Region</h1>
                <RouterBreadcrumbs
                  pathName={this.props.location.pathname}
                  pageItemLabel={displayName.value}
                />
                <div className="card">
                  <div className="card-body">
                    <div>
                      <h2>Region Details</h2>
                      {hasUpdated || hasCreated ? (
                        <div className="alert alert-success">
                          <div className="d-flex flex-row align-items-center">
                            <div className="p-0">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="pl-1">
                              <span>
                                This Region has been successfully{" "}
                                {hasUpdated
                                  ? "updated"
                                  : hasCreated
                                  ? "created"
                                  : ""}
                                !
                              </span>
                            </div>
                            {hasUpdated ? (
                              <div className="ml-auto pl-1">
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  className="hyperlink"
                                  onClick={() =>
                                    this.setState({ hasUpdated: false })
                                  }
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                      {hasCreated ? <Redirect to="/admin/regions/all" /> : null}
                      <NameSection
                        regionName={regionName}
                        displayName={displayName}
                        latitude={latitude}
                        longitude={longitude}
                        onChange={(name, val) => {
                          if (name === "displayName" && !isEdit) {
                            this.setState({
                              regionName: this.getRegionObject({
                                value: flattenString(val.toLowerCase()),
                                isDirty: true,
                                fieldName: "regionName",
                              }),
                            });
                          }
                          this.setState({
                            [name]: this.getRegionObject({
                              value: val,
                              isDirty: true,
                              fieldName: name,
                            }),
                          });
                        }}
                        isEdit={pageType === createEditType.Edit}
                      />
                    </div>
                    <div>
                      <div className="d-flex flex-row align-items-center">
                        <div className="ml-auto mr-auto p-0">
                          <button
                            className={`btn btn-primary ${
                              hasErrors ? "disabled" : ""
                            }`}
                            onClick={() => {
                              if (!hasErrors) this.saveRegion();
                            }}
                          >
                            <FontAwesomeIcon icon={faSave} />
                            <span className="ml-1">Save Changes</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else return <Redirect to="/admin" />;
    } else return <PageLoading />;
  }
}

export default withCookies(CreateEditRegion);
