import "../../App.scss";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { AttractionsList, PageTitle } from "../../components";
import { LocationDescriptor, Location } from "history";

type AttractionShowcaseProps = {
  attractions: Array<any>;
  cookies: any;
  title: string;
  to:
    | LocationDescriptor<unknown>
    | ((location: Location<unknown>) => LocationDescriptor<unknown>);
};

const AttractionShowcase = ({
  attractions,
  cookies,
  title,
  to
}: AttractionShowcaseProps) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 mb-4">
          <div className="d-flex flex-column">
            <div className="p-0">
              <PageTitle subHeadingText={title} />
            </div>
            <div className="p-0">
              {/* @ts-ignore */}
              <AttractionsList
                attractions={attractions}
                cookies={cookies}
              />
            </div>
            {attractions.length > 0 && (
              <div className="pt-2 ml-auto mr-auto">
                <NavLink className="p-0 hyperlink" to={to} >
                  <span className="mr-2">Show More</span>
                  <FontAwesomeIcon icon={faChevronRight} />
                </NavLink>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AttractionShowcase;
