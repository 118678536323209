import { Dispatch, ReactElement, SetStateAction } from "react";
import { LocationButton, MenuButton, SettingsButton } from ".";
import { makeStyles } from "@material-ui/core/styles";

type AnchorTypes = "top" | "right" | "bottom" | "left";

type MobileMenuProps = {
  locationModalShow: boolean;
  setLocationModalShow: Dispatch<SetStateAction<boolean>>;
  isUsingGeolocation: boolean;
  settingsModalShow: boolean;
  setSettingsModalShow: Dispatch<SetStateAction<boolean>>;
  toggleDrawer: (anchor: AnchorTypes, open: boolean) => (event: any) => void;
  drawerState: boolean;
};

const useStyles = makeStyles((theme) => {
  return {
    section: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  };
});

const MobileMenu = ({
  locationModalShow,
  setLocationModalShow,
  isUsingGeolocation,
  settingsModalShow,
  setSettingsModalShow,
  toggleDrawer,
  drawerState,
}: MobileMenuProps): ReactElement => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.section}>
        <LocationButton
          modalShow={locationModalShow}
          setModalShow={setLocationModalShow.bind(this)}
          isUsingGeolocation={isUsingGeolocation}
        />
        <SettingsButton
          modalShow={settingsModalShow}
          setModalShow={setSettingsModalShow.bind(this)}
        />
        <MenuButton
          toggleDrawer={toggleDrawer}
          drawerState={drawerState}
          drawerPosition="top"
        />
      </div>
    </>
  );
};
export default MobileMenu;
