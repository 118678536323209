// Package Imports
import React from 'react';
import $ from 'jquery';
import { Typography, Popover, makeStyles } from '@material-ui/core';

// Fonts & Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme) => ({
  typography: { padding: theme.spacing(2) }
}));

export function RatingIcon({data}){
    const { id, icon, className, popoverContent } = data;

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
  
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    // Closes the popover when clicking away
    $(document).on("click", function(e) {
        handleClose();
    });

    return (
        <li id={id} className={className}  onClick={popoverContent ? handleClick : null} style={{cursor: 'pointer'}}>
          <FontAwesomeIcon className="pr-1 fa-fw" icon={icon}/>
          <Popover
            id={Boolean(anchorEl) ? 'simple-popover' : undefined}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Typography className={classes.typography}>{popoverContent}</Typography>
          </Popover>
        </li>
    )
}