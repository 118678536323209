import { getMainCategoryDropdownOptions } from "../component-functions/manageAttraction-core";
import {
  attractionEditSaveType,
  attractionPageType,
  categorySortTypes,
} from "../config/enums";
import { getAdminAuthenticationDetails } from "../scripts/admin-auth";
import {
  getFacilities,
  getFilteredCategories,
  getLocationDetailsByPostcode,
} from "../scripts/api-core";
import { isInputValid } from "../scripts/validation";

export function handleCategorySelect({
  updateSelectedCategories,
  e,
  categoryName,
  categories,
  updateCategories,
  updateMainCategory,
  setMainCategoryOptions,
  allCategories,
  setCategoryErrors,
  validationConfig,
}) {
  const updatedCategories = updateSelectedCategories({
    checked: e.target.checked,
    val: categoryName,
    selectedCategories: categories,
  });
  updateCategories(updatedCategories);
  const errors = isInputValid(
    validationConfig,
    "categories",
    updatedCategories
  );
  setCategoryErrors(errors);
  updateMainCategory(
    updatedCategories.length === 1 ? updatedCategories[0] : "N/A"
  );
  setMainCategoryOptions(
    getMainCategoryDropdownOptions(
      allCategories.filter((c) =>
        updatedCategories.find((uc) => c.categoryName === uc)
      )
    )
  );
}

export function handleFacilityChange({
  isChecked,
  facilityName,
  allFacilities,
  updateAllFacilities,
}) {
  const updatedAllFacilities = allFacilities;
  const index = updatedAllFacilities
    .map((uf) => uf.facilityName)
    .indexOf(facilityName);
  updatedAllFacilities[index].isChecked = isChecked;
  updateAllFacilities(updatedAllFacilities);
}

export function getPageType(pathname) {
  const pathnames = pathname.split("/").filter((x) => x);
  return pathnames[pathnames.length - 1] === "create"
    ? attractionPageType.Create
    : pathnames[pathnames.length - 1] === "submit"
    ? attractionPageType.Submit
    : pathnames[pathnames.length - 2] === "edit"
    ? attractionPageType.Edit
    : pathnames[pathnames.length - 2] === "editsubmission"
    ? attractionPageType.EditSubmission
    : pathnames[pathnames.length - 2] === "attraction"
    ? attractionPageType.View
    : undefined;
}

export async function setTempCoordsFromPostcode({
  postCode,
  hasLatitude,
  hasLongitude,
  setTempLatitude,
  setTempLongitude,
  setIsLatAutoPopulated,
  setIsLongAutoPopulated,
}) {
  const response = await getLocationDetailsByPostcode(postCode);
  if (response && !response.error && response.status === 200) {
    const { latitude, longitude } = response.result;
    if (!hasLatitude) {
      setTempLatitude(latitude);
      setIsLatAutoPopulated(true);
    }
    if (!hasLongitude) {
      setTempLongitude(longitude);
      setIsLongAutoPopulated(true);
    }
  }
}

export function getPreHeader(pagePermissions) {
  const { isCreate, isEdit, isSubmit, isEditSubmission } = pagePermissions;
  let preHeader = "";
  if (isCreate) preHeader += "Creating Attraction";
  if (isSubmit) preHeader += "Submitting Attraction";
  if (isEdit) preHeader += "Editing Attraction";
  if (isEditSubmission) preHeader += "Editing Attraction Submission ";
  return preHeader;
}

export function setupPagePermissions({ pathname, cookies }) {
  const pageType = getPageType(pathname);
  const { token } = getAdminAuthenticationDetails(cookies);
  const isAdminLoggedIn = !!token;
  return {
    isView: pageType === attractionPageType.View,
    isCreate: pageType === attractionPageType.Create,
    isEdit: pageType === attractionPageType.Edit,
    isSubmit: pageType === attractionPageType.Submit,
    isEditSubmission: pageType === attractionPageType.EditSubmission,
    isManaging:
      isAdminLoggedIn ||
      pageType === attractionPageType.Submit ||
      pageType === attractionPageType.EditSubmission,
    isAdmin: isAdminLoggedIn,
  };
}

async function getSanitisedCategories({ selectedCategories }) {
  const { results } = await getFilteredCategories({
    sortOptionValue: categorySortTypes.AlphabeticalAsc.value,
  });
  return results
    .filter((category) =>
      selectedCategories.find((sc) => sc.categoryName === category.categoryName)
    )
    .map((category) => category.categoryName);
}

async function getSanitisedMainCategory({ mainCategory }) {
  return mainCategory.categoryName;
}

export function mapPropertiesToAttraction({ props, type }) {
  const { isCreate, isSubmit } = props.pagePermissions;

  const {
    SaveChanges,
    Deactivate,
    Reactivate,
    RejectSubmission,
    ApproveSubmission,
  } = attractionEditSaveType;

  const facilities =
    props.facilities && props.facilities.length > 0
      ? props.facilities.map((f) => {
          return {
            name: f.facilityName,
            types:
              f.types && f.types.length > 0 ? f.types.map((t) => t.name) : [],
          };
        })
      : [];

  let isLive = props.isLive;
  let isAwaitingAdminReview = props.isAwaitingAdminReview;
  let isApproved = props.isApproved;

  if (isCreate || isSubmit) {
    isAwaitingAdminReview = true;
    isApproved = false;
    isLive = false;
  }

  switch (type) {
    case Deactivate:
      isLive = false;
      break;
    case Reactivate:
      isLive = true;
      isApproved = true;
      isAwaitingAdminReview = false;
      break;
    case RejectSubmission:
      isApproved = false;
      isAwaitingAdminReview = false;
      break;
    case ApproveSubmission:
      isApproved = true;
      isAwaitingAdminReview = false;
      isLive = true;
      break;
    case SaveChanges:
    default:
      break;
  }

  return {
    id: props.id || undefined,
    name: props.name || undefined,
    description: props.description || undefined,
    metadata: {
      googlePlaceId: props.googlePlaceId || undefined,
      hvt: props.hvt || false,
      isUserSubmitted: props.isUserSubmitted,
    },
    status: {
      isLive: isLive,
      isApproved: isApproved,
      isAwaitingAdminReview: isAwaitingAdminReview,
    },
    detailedView: true, // always true
    location: {
      coordinates: {
        latitude: props.latitude || undefined,
        longitude: props.longitude || undefined,
      },
      description: props.locationDescription || undefined,
      address: {
        addressLineOne: props.address1,
        addressLineTwo: props.address2,
        postalTown: props.town,
        postalCode: props.postCode,
        country: props.country,
      },
      region: props.region || undefined,
    },
    attractionGroups: [],
    facilities: facilities,
    rating: props.rating || undefined,
    price: props.price || undefined,
    hitCount: props.hitCount || 0,
    mainCategory: props.mainCategory || undefined, // {categoryname}
    categories: props.categories || [],
    contact: {
      phone: props.phoneNumber || undefined,
      email: props.emailAddress || undefined,
    },
    links: {
      mainImage: props.imageUrl || undefined,
      website: props.linkUrl || undefined,
      facebook: props.facebookUsername || undefined,
      twitter: props.twitterUsername || undefined,
      instagram: props.instagramUsername || undefined,
    },
  };
}

export async function mapAttractionToProperties({ attraction }) {
  return {
    id: attraction.id || undefined,
    name: attraction.name || "",
    description: attraction.description || "",
    googlePlaceId:
      attraction.metadata && attraction.metadata.googlePlaceId
        ? attraction.metadata.googlePlaceId
        : "",
    hvt:
      attraction.metadata && attraction.metadata.hvt
        ? attraction.metadata.hvt
        : "",
    isUserSubmitted:
      attraction.metadata && attraction.metadata.isUserSubmitted
        ? attraction.metadata.isUserSubmitted
        : false,
    isApproved:
      attraction.status && attraction.status.isApproved
        ? attraction.status.isApproved
        : false,
    isAwaitingAdminReview:
      attraction.status && attraction.status.isAwaitingAdminReview
        ? attraction.status.isAwaitingAdminReview
        : false,
    isRejected:
      attraction.status &&
      attraction.status.isApproved != null &&
      attraction.status.isAwaitingAdminReview != null
        ? !attraction.status.isApproved &&
          !attraction.status.isAwaitingAdminReview
        : false,
    isLive:
      attraction.status && attraction.status.isLive
        ? attraction.status.isLive
        : false,
    detailedView: attraction.detailedView || true,
    latitude:
      attraction.location &&
      attraction.location.coordinates &&
      attraction.location.coordinates.latitude
        ? attraction.location.coordinates.latitude
        : 0,
    longitude:
      attraction.location &&
      attraction.location.coordinates &&
      attraction.location.coordinates.longitude
        ? attraction.location.coordinates.longitude
        : 0,
    locationDescription:
      attraction.location && attraction.location.description
        ? attraction.location.coordinates.description
        : "",
    address1:
      attraction.location &&
      attraction.location.address &&
      attraction.location.address.addressLineOne
        ? attraction.location.address.addressLineOne
        : "",
    address2:
      attraction.location &&
      attraction.location.address &&
      attraction.location.address.addressLineTwo
        ? attraction.location.address.addressLineTwo
        : "",
    town:
      attraction.location &&
      attraction.location.address &&
      attraction.location.address.postalTown
        ? attraction.location.address.postalTown
        : "",
    postCode:
      attraction.location &&
      attraction.location.address &&
      attraction.location.address.postalCode
        ? attraction.location.address.postalCode
        : "",
    country:
      attraction.location &&
      attraction.location.address &&
      attraction.location.address.country
        ? attraction.location.address.country
        : "",
    region:
      attraction.location && attraction.location.region
        ? attraction.location.region
        : "",
    attractionGroups: attraction.attractionGroups || [],
    facilities: attraction.facilities || [],
    rating: attraction.rating || null,
    price: attraction.price || null,
    hitCount: attraction.hitCount || 0,
    mainCategory: attraction.mainCategory
      ? await getSanitisedMainCategory({
          mainCategory: attraction.mainCategory,
        })
      : "",
    categories: attraction.categories
      ? await getSanitisedCategories({
          selectedCategories: attraction.categories,
        })
      : [],
    phoneNumber:
      attraction.contact && attraction.contact.phone
        ? attraction.contact.phone
        : "",
    emailAddress:
      attraction.contact && attraction.contact.email
        ? attraction.contact.email
        : "",
    imageUrl:
      attraction.links && attraction.links.mainImage
        ? attraction.links.mainImage
        : "",
    linkUrl:
      attraction.links && attraction.links.website
        ? attraction.links.website
        : "",
    facebookUsername:
      attraction.links && attraction.links.facebook
        ? attraction.links.facebook
        : "",
    twitterUsername:
      attraction.links && attraction.links.twitter
        ? attraction.links.twitter
        : "",
    instagramUsername:
      attraction.links && attraction.links.instagram
        ? attraction.links.instagram
        : "",
  };
}

export async function getSanitisedFacilities({ selectedFacilities }) {
  const { results } = await getFacilities({ pageSize: 1000 });
  return results.map((facility) => {
    const selectedFacility = selectedFacilities.find(
      (sf) => sf.facilityName === facility.facilityName
    );
    facility.isChecked = selectedFacility != null;
    const hasTypes = facility.types && facility.types.length > 0;
    if (hasTypes) {
      facility.types.forEach((t) => {
        if (selectedFacility && selectedFacility.types) {
          const matchingType = selectedFacility.types.find(
            (sft) => sft.name === t.name
          );
          t.isChecked = !!matchingType;
        } else t.isChecked = false;
      });
    } else facility.types = [];
    return facility;
  });
}
