import { ListItem, ListItemText } from "@mui/material";
import { ReactElement, ReactNode, useState } from "react";
import { NavLink } from "react-router-dom";
import SidebarList from "./SidebarList";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

type SidebarListItemProps = {
  children?: ReactNode;
  list?: boolean;
  text: string;
  to?: string;
};

const SidebarListItem = ({
  children,
  list,
  text,
  to,
}: SidebarListItemProps): ReactElement => {
  const [showList, setShowList] = useState<boolean>(false);
  return (
    <>
      {list && (
        <>
          <ListItem button onClick={() => setShowList(!showList)}>
            <ListItemText>{text}</ListItemText>
            {showList ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          {showList && <SidebarList list>{children}</SidebarList>}
        </>
      )}
      {!list && (
        <NavLink to={to || "/"}>
          <ListItem button>
            <ListItemText primary={text} />
          </ListItem>
        </NavLink>
      )}
    </>
  );
};
SidebarListItem.displayName = "SidebarListItem";
export default SidebarListItem;
