// Package Imports
import React from "react";
import "react-bootstrap";
import LazyLoad from "react-lazyload";

// Fonts & Icons
import Pagination from "@material-ui/lab/Pagination";

// Dayhoot Functions & Scripts
import { getNoResultsTitleText } from "../component-functions/common-core";

// Images
import HootLadBroke from "../img/logos/hoot-green-broke.png";

// Dayhoot Components
import ScrollToTop from "./scrollToTop";
import { AddAttractionBanner } from "./manageAttraction-components";
import { makeStyles } from "@material-ui/core";
import DayhootLad from "../img/logos/hoot-white.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";

import { PageTitle } from ".";

export function NoResults({ data }) {
  return (
    <div>
      <div className="d-flex flex-column align-items-center justify-content-center mb-2">
        <div className="py-2">
          <LazyLoad once={true}>
            <img
              src={HootLadBroke}
              alt="No results Dayhoot Logo Icon"
              style={{ width: "70%" }}
            ></img>
          </LazyLoad>
        </div>
        <div>{getNoResultsTitleText(data)}</div>
      </div>
      <div className="row">
        <div className="offset-md-4 col-md-4">
          <AddAttractionBanner />
        </div>
      </div>
    </div>
  );
}

export function PageLoading({ loadingMessage }) {
  return (
    <div className="page-loading">
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-center">
            <div className="pb-5">
              <PageTitle
                headingText={loadingMessage || "Page Loading..."}
                isCentered={true}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="p-0">
              <div
                className="spinner-border text-success"
                style={{
                  width: "5rem",
                  height: "5rem",
                  animation: "spinner-border 1.5s linear infinite",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function HoldingPage() {
  const page = document.getElementById("body");
  page.style.backgroundColor = "#15a084";
  const [isPageLoaded, setIsPageLoaded] = React.useState(false);
  setTimeout(() => setIsPageLoaded(true), 100);
  return isPageLoaded ? (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "40%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <div className="d-flex justify-content-center pb-2">
        <img src={DayhootLad} alt="dayhoot-logo" />
      </div>
      <div className="d-flex justify-content-center text-lightgrey">
        <h1 style={{ fontSize: "2em" }}>Dayhoot</h1>
      </div>
      <div className="d-flex justify-content-center pb-3 text-lightgrey">
        <span className="h2">Hoot! We'll be with you shortly...</span>
      </div>
      <div className="d-flex justify-content-center pb-2 text-lightgrey" style={{textAlign: "center"}}>
        Our site is currently down for maintenance. There's nothing to worry about, we're just in the process of making our site even better for you!
        We shouldn't be long...
      </div>
      <hr />
      <div className="d-flex justify-content-center pb-2 text-lightgrey" style={{textAlign: "center"}}>
        Until we get back, why not have a glance at our
        social media accounts for brilliant Dayhoot ideas!?
      </div>
      <div className="d-flex justify-content-center">
        <div className="pr-3 text-white">
          <a href="http://facebook.com/dayhootuk">
            <FontAwesomeIcon icon={faFacebook} />
            <span className="pl-1">DayhootUK</span>
          </a>
        </div>
        <div className="pl-3 pr-3 text-white">
          <a href="http://twitter.com/dayhootuk">
            <FontAwesomeIcon icon={faTwitter} />
            <span className="pl-1">DayhootUK</span>
          </a>
        </div>
        <div className="pl-3 text-white">
          <a href="http://instagram.com/dayhootuk">
            <FontAwesomeIcon icon={faInstagram} />
            <span className="pl-1">DayhootUK</span>
          </a>
        </div>
      </div>
    </div>
  ) : null;
}

export function BasicPagination({ numberOfPages, handleChange, currentPage }) {
  const noOfPages = numberOfPages || 1;

  return (
    <div>
      <ScrollToTop />
      <Pagination
        count={noOfPages}
        onChange={handleChange}
        page={currentPage}
        size={"small"}
      />
    </div>
  );
}

function TileImageBackground({ imageSource, displayName }) {
  if (imageSource) {
    return (
      <LazyLoad once={true}>
        <img
          style={{ width: "100%" }}
          className="tilesmobile img-responsive align-self-center"
          src={imageSource}
          alt={displayName}
        ></img>
      </LazyLoad>
    );
  } else return null;
}

const useStyles = makeStyles(theme => {
  return {
    tileContainer: {
      [theme.breakpoints.up("sm")]: {
        width: "25vw",
      },
      [theme.breakpoints.up("md")]: {
        width: "15vw",
      },
      [theme.breakpoints.up("lg")]: {
        width: "10vw",
      },
    },
  };
});

export function TileContainer({ imageSource, displayName }) {
  const classes = useStyles();
  return (
    <div className="tile-container background-green">
      <TileImageBackground
        imageSource={imageSource}
        displayName={displayName}
      />
      <div className={`tile-text-centered ${classes.tileContainer} wordbreak`}>
        <span className="h6 text-white pt-2 pb-0 apply-ellipsis-3">
          {displayName}
        </span>
      </div>
    </div>
  );
}

export function Button({ data }) {
  const { id, buttonClick, buttonClass, buttonText, title } = data;
  return (
    <button
      id={id}
      className={buttonClass}
      onClick={buttonClick.bind(this)}
      title={title}
    >
      {buttonText}
    </button>
  );
}

export function MobileOnlyHorizontalRule({ hrClass = "" }) {
  return (
    <div className="d-xl-none">
      <hr className={hrClass} />
    </div>
  );
}
