import { List } from "@mui/material";
import { ReactElement, ReactNode } from "react";

type SidebarListProps = {
  children: ReactNode;
  list?: boolean;
};

const SidebarList = ({
  children,
  list,
}: SidebarListProps): ReactElement => {
  return <List className={list ? "ml-3" : undefined}>{children}</List>;
};
SidebarList.displayName = "SidebarList";
export default SidebarList;
