import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { PageLoading } from "../components/common-components";
import { PageTitle } from "../components";
import { setPageTitleText } from "../scripts/utils";
import {
  getFilteredCategories,
  getFilteredRegions,
  getSubmittedAttraction,
  postNewlySubmittedAttraction,
  updateSubmittedAttraction,
} from "../scripts/api-core";
import { categorySortTypes } from "../config/enums";
import {
  SubmissionComplete,
  WizardStep,
} from "../components/manageAttraction-components";
import { wizardStepEnums } from "../config/manageAttraction-config";
import { AttractionDetailsContainer } from "../components/attraction-components";
import RouterBreadcrumbs from "../components/routerBreadcrumbs";
import {
  faCheckCircle,
  faInfoCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAdminAuthenticationDetails } from "../scripts/admin-auth";

class ManageAttraction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPageLoaded: false,
      hasUpdated: false,
      hasCreated: false,
      categories: [],
      mainCategory: null,
      regions: [],
      facilities: [],
      currentStep: 0,
      isReviewing: false,
      isAdmin: false,
      attraction: null,
      basicDetails: null,
      contactInformation: null,
      categoryInformation: null,
      facilityInformation: null,
      locationInformation: null,
      canEdit: false,
      isApproved: false,
      isRejected: false,
    };
  }

  async componentDidMount() {
    const {
      categories,
      regions,
      facilities,
      attraction,
      isReviewing,
      isAdmin,
      canEdit,
      isApproved,
      isRejected,
    } = await this.renderData();
    setPageTitleText(
      isReviewing ? "Reviewing Submission" : "Submit Attraction"
    );

    this.setState({
      isPageLoaded: true,
      categories: categories,
      regions: regions,
      facilities: facilities,
      attraction: attraction,
      isReviewing: isReviewing,
      isAdmin: isAdmin,
      canEdit: canEdit,
      isApproved: isApproved,
      isRejected: isRejected,
    });
  }

  async componentDidUpdate(prevProps) {
    const prevId = prevProps.match.params.attractionId;
    const currId = this.props.match.params.attractionId;
    if (prevId !== currId) {
      if (!prevId && !!currId) window.location.reload();
      else {
        const {
          categories,
          regions,
          facilities,
          attraction,
          isReviewing,
          isAdmin,
          canEdit,
          isApproved,
          isRejected,
        } = await this.renderData();
        setPageTitleText(
          isReviewing ? "Reviewing Submission" : "Submit Attraction"
        );
        this.setState({
          isPageLoaded: true,
          categories: categories,
          regions: regions,
          facilities: facilities,
          attraction: attraction,
          isReviewing: isReviewing,
          isAdmin: isAdmin,
          hasUpdated: false,
          hasCreated: false,
          currentStep: wizardStepEnums.BasicDetails.value,
          canEdit: canEdit,
          isApproved: isApproved,
          isRejected: isRejected,
        });
      }
    }
  }

  async renderData() {
    const attractionId = this.props.match.params.attractionId;

    // we want to check cookies here to see whether there's a token
    // const cookies = this.props.cookies.getAll();
    // I guess we check it again the token stored?

    const { results: categories } = await getFilteredCategories({
      sortOptionValue: categorySortTypes.AlphabeticalAsc.value,
    });
    const { results: regions } = await getFilteredRegions({
      sortOptionValue: categorySortTypes.AlphabeticalAsc.value,
    });
    let attraction = !!attractionId
      ? await getSubmittedAttraction({ id: attractionId })
      : null;
    if (!!attraction) {
      if (!attraction.categories.some((c) => c.categoryName)) {
        attraction.categories = attraction.categories.map((c) =>
          categories.find((x) => x.categoryName === c)
        );
      }
      if (!attraction.mainCategory.categoryName) {
        attraction.mainCategory = categories.find(
          (x) => x.categoryName === attraction.mainCategory
        );
      }
    }
    const { token } = getAdminAuthenticationDetails(this.props.cookies);
    return {
      categories: categories.map((category) => {
        category.checked = false;
        return category;
      }),
      regions: regions.map((region) => {
        region.checked = !!attraction
          ? region.regionName === attraction.location.region
          : false;
        return region;
      }),
      attraction: attraction,
      isReviewing: !!attractionId,
      isAdmin: !!token,
      canEdit: attractionId != null ? !attraction.isApproved : true,
      isApproved: attractionId != null ? attraction.isApproved : false,
      isRejected: attractionId != null ? attraction.isRejected : false,
    };
  }

  getElementValue(id) {
    const element = document.getElementById(id);
    return !!element ? element.value : undefined;
  }

  getEntryPriceValue(id) {
    const elementValue = this.getElementValue(id);
    return !!elementValue ? parseFloat(elementValue).toFixed(2) : undefined;
  }

  getBasicDetails() {
    return {
      attractionName: this.getElementValue("attractionName"),
      attractionDescription: this.getElementValue("attractionDescription"),
      imageUrl: this.getElementValue("imageUrl"),
      entryPrice: this.getEntryPriceValue("entryPrice"),
    };
  }

  getContactInformation() {
    return {
      websiteUrl: this.getElementValue("websiteUrl"),
      phoneNumber: this.getElementValue("phoneNumber"),
      emailAddress: this.getElementValue("emailAddress"),
      facebookAccount: this.getElementValue("facebookAccount"),
      twitterAccount: this.getElementValue("twitterAccount"),
      instagramAccount: this.getElementValue("instagramAccount"),
    };
  }

  getCategoryInformation() {
    return {
      categories: this.state.categories.filter(
        (c) => document.getElementById(`category-${c.categoryName}`).checked
      ),
      mainCategory: this.getElementValue("category-mainCategory"),
    };
  }

  getFacilityInformation() {
    const mappedFacilities = this.state.facilities
      .filter((x) => x.id !== "default")
      .map((f) => {
        f.selectedValue =
          document.getElementById(`facility-${f.id}-value`).value || undefined;
        return f;
      });
    return {
      availablefacilities: mappedFacilities
        .filter((f) => f.selectedValue === "YES")
        .map((f) => f.id),
      unknownfacilities: mappedFacilities
        .filter((f) => f.selectedValue === "N/A")
        .map((f) => f.id),
      unavailablefacilities: mappedFacilities
        .filter((f) => f.selectedValue === "NO")
        .map((f) => f.id),
      facilityadvisories: mappedFacilities
        .filter((f) => f.selectedValue === "PARTIAL")
        .map((f) => f.id),
    };
  }

  getLocationInformation() {
    return {
      region: this.getElementValue("region-selectedRegion"),
      googlePlaceId: this.getElementValue("googlePlaceId"),
      address: {
        address1: this.getElementValue("address1"),
        address2: this.getElementValue("address2"),
        country: this.getElementValue("country"),
        postCode: this.getElementValue("postCode"),
      },
    };
  }

  getFormInputInfo() {
    return {
      basicInformation: this.getBasicDetails(),
      contactInformation: this.getContactInformation(),
      categoryInformation: this.getCategoryInformation(),
      facilityInformation: this.getFacilityInformation(),
      locationInformation: this.getLocationInformation(),
    };
  }

  buildAttraction() {
    const {
      basicInformation,
      contactInformation,
      categoryInformation,
      facilityInformation,
      locationInformation,
    } = this.getFormInputInfo();

    this.setState({
      attraction: {
        id: !!this.state.attraction ? this.state.attraction.id : undefined,
        isApproved: !!this.state.attraction
          ? this.state.attraction.isApproved
          : false,
        isRejected: !!this.state.attraction
          ? this.state.attraction.isRejected
          : false,
        googlePlaceId:
          this.state.isAdmin || locationInformation.googlePlaceId
            ? locationInformation.googlePlaceId
            : undefined,
        location: {
          coordinates: {
            latitude: 0,
            longitude: 0,
          },
          description: locationInformation.address.country,
          address1: locationInformation.address.address1,
          address2: locationInformation.address.address2,
          country: locationInformation.address.country,
          postCode: locationInformation.address.postCode,
          region: locationInformation.region,
        },
        facilities: facilityInformation.availablefacilities,
        unknownfacilities: facilityInformation.unknownfacilities,
        rating: null,
        contact: {
          phone: contactInformation.phoneNumber,
          email: contactInformation.emailAddress,
          facebook: contactInformation.facebookAccount,
          twitter: contactInformation.twitterAccount,
          instagram: contactInformation.instagramAccount,
        },
        mainCategory: categoryInformation.mainCategory,
        name: basicInformation.attractionName,
        imageUrl: basicInformation.imageUrl,
        unavailablefacilities: facilityInformation.unavailablefacilities,
        categories: categoryInformation.categories.map((c) => c.categoryName),
        facilityadvisories: facilityInformation.facilityadvisories,
        linkUrl: contactInformation.websiteUrl,
        price: parseFloat(basicInformation.entryPrice).toFixed(2),
        description: basicInformation.attractionDescription,
      },
    });
  }

  handleStepChange = (stepValue, canChange) => {
    const { currentStep } = this.state;

    if (canChange) {
      window.scrollTo(0, 0);
      if (currentStep === wizardStepEnums.BasicDetails.value) {
        this.setState({
          basicDetails: this.getBasicDetails(),
          currentStep: stepValue,
          hasUpdated: false,
        });
      } else if (currentStep === wizardStepEnums.ContactInformation.value) {
        this.setState({
          contactInformation: this.getContactInformation(),
          currentStep: stepValue,
          hasUpdated: false,
        });
      } else if (currentStep === wizardStepEnums.Categories.value) {
        this.setState({
          categoryInformation: this.getCategoryInformation(),
          currentStep: stepValue,
          hasUpdated: false,
        });
      } else if (currentStep === wizardStepEnums.Facilities.value) {
        this.setState({
          facilityInformation: this.getFacilityInformation(),
          currentStep: stepValue,
          hasUpdated: false,
        });
      } else if (currentStep === wizardStepEnums.Location.value) {
        this.setState({
          locationInformation: this.getLocationInformation(),
          currentStep: stepValue,
          hasUpdated: false,
        });
      } else {
        this.setState({
          currentStep: stepValue,
          hasUpdated: false,
        });
      }

      if (stepValue === wizardStepEnums.Confirmation.value) {
        this.buildAttraction();
      }
    }
  };

  prepareAttractionForSave({ isApproved, isRejected }) {
    let attraction = this.state.attraction;
    // We can probably use an API here to try and get it from the postCode and country.
    // If not, then it can be undefined and we can try to pull it using the Google Place ID.
    attraction.location.coordinates = undefined;
    attraction.rating = undefined;
    attraction.isApproved = isApproved
      ? true
      : attraction.isApproved != null
      ? attraction.isApproved
      : false;
    attraction.isRejected =
      isRejected != null
        ? isRejected
        : attraction.isRejected != null
        ? attraction.isRejected
        : false;
    return attraction;
  }

  async approveAttraction() {
    this.setState({ isPageLoaded: false });
    let attraction = this.prepareAttractionForSave({
      isApproved: true,
    });
    attraction = await updateSubmittedAttraction(attraction);
    try {
      this.setState({
        attraction: attraction,
        hasUpdated: true,
        isPageLoaded: true,
      });
    } catch {
      console.error(
        "This attraction was not updated. Please contact us for assistance."
      );
    }
  }

  async rejectAttraction() {
    this.setState({ isPageLoaded: false });
    let attraction = this.prepareAttractionForSave({
      isRejected: true,
    });
    attraction = await updateSubmittedAttraction(attraction);
    try {
      this.setState({
        attraction: attraction,
        hasUpdated: true,
        isPageLoaded: true,
      });
    } catch {
      console.error(
        "This attraction was not updated. Please contact us for assistance."
      );
    }
  }

  async undoRejectAttraction() {
    this.setState({ isPageLoaded: false });
    let attraction = this.prepareAttractionForSave({
      isRejected: false,
    });
    attraction = await updateSubmittedAttraction(attraction);
    try {
      this.setState({
        attraction: attraction,
        hasUpdated: true,
        isPageLoaded: true,
      });
    } catch {
      console.error(
        "This attraction was not updated. Please contact us for assistance."
      );
    }
  }

  async saveAttraction(isReviewing) {
    this.setState({ isPageLoaded: false });
    let attraction = this.prepareAttractionForSave({});
    if (!isReviewing) {
      attraction = await postNewlySubmittedAttraction(attraction);
      try {
        this.setState({
          attraction: attraction,
          hasCreated: true,
          isPageLoaded: true,
        });
      } catch {
        console.error(
          "This attraction was not submitted. Please contact us for assistance."
        );
      }
    } else {
      window.scrollTo(0, 0);
      attraction = await updateSubmittedAttraction(attraction);
      try {
        this.setState({
          attraction: attraction,
          hasUpdated: true,
          isPageLoaded: true,
        });
      } catch {
        console.error(
          "This attraction was not updated. Please contact us for assistance."
        );
      }
    }
  }

  render() {
    if (this.state.isPageLoaded) {
      const pathName = this.props.location.pathname;
      const isAdminRoute = pathName.includes("admin/");

      return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <PageTitle
                headingId={
                  this.state.isReviewing
                    ? "update-an-attraction"
                    : "submit-an-attraction"
                }
                headingText={
                  this.state.isReviewing
                    ? (this.state.isApproved || this.state.isRejected
                        ? "View"
                        : "Update") + ` an Attraction Submission`
                    : `Submit an Attraction`
                }
              />
              {isAdminRoute ? (
                <RouterBreadcrumbs
                  pathName={pathName}
                  pageItemLabel={this.state.attraction.name}
                />
              ) : null}
              <div className="card">
                <div className="card-body">
                  {!this.state.canEdit ? (
                    <div>
                      <div className="alert alert-info">
                        <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
                        {this.state.isApproved ? (
                          <span className="ml-1">
                            This submission has been
                            <span className="text-success ml-2 mr-1">
                              <FontAwesomeIcon icon={faCheckCircle} />
                              <b className="ml-1">Approved</b>
                            </span>{" "}
                            and cannot be changed. For any changes, please don't
                            hesistate to contact us.
                          </span>
                        ) : this.state.isRejected ? (
                          <span className="ml-1">
                            This submission has been{" "}
                            <span className="text-danger">
                              <FontAwesomeIcon icon={faTimesCircle} />
                              <b className="ml-1">Rejected</b>
                            </span>{" "}
                            and cannot be changed.
                          </span>
                        ) : (
                          <span className="ml-1">
                            This submission cannot be edited. For more
                            information, please don't hesistate to contact us.
                          </span>
                        )}
                      </div>
                      <AttractionDetailsContainer
                        attraction={this.state.attraction}
                        cookies={this.props.cookies}
                        isVisible={true}
                        isDetailsOverflow={false}
                        showDetailsModal={false}
                        setShowDetailsModal={null}
                        isPreview={true}
                      />
                    </div>
                  ) : this.state.hasUpdated ? (
                    <div>
                      <SubmissionComplete
                        attraction={this.state.attraction}
                        hasUpdated={this.state.hasUpdated}
                        viewSubmission={() => window.location.reload()}
                      />
                      <hr />
                      <h3>Attraction Preview</h3>
                      <AttractionDetailsContainer
                        attraction={this.state.attraction}
                        cookies={this.props.cookies}
                        isVisible={true}
                        isDetailsOverflow={false}
                        showDetailsModal={false}
                        setShowDetailsModal={null}
                        isPreview={true}
                      />
                    </div>
                  ) : (
                    <WizardStep
                      cookies={this.props.cookies}
                      currentStep={this.state.currentStep}
                      facilities={this.state.facilities}
                      categories={this.state.categories}
                      regions={this.state.regions}
                      isReviewing={this.state.isReviewing}
                      attraction={this.state.attraction}
                      handleStepChange={this.handleStepChange.bind(this)}
                      saveAttraction={this.saveAttraction.bind(this)}
                      approveAttraction={this.approveAttraction.bind(this)}
                      rejectAttraction={this.rejectAttraction.bind(this)}
                      undoRejectAttraction={this.undoRejectAttraction.bind(
                        this
                      )}
                      hasCreated={this.state.hasCreated}
                      viewSubmission={() => window.location.reload()}
                      isAdmin={this.state.isAdmin}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else return <PageLoading />;
  }
}

export default withCookies(ManageAttraction);
