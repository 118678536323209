// Package Imports
import React from "react";
import "bootstrap/dist/js/bootstrap.js";
import { NavLink } from "react-router-dom";
import LazyLoad from "react-lazyload";

// Dayhoot Components
import { DayhootStandardModal } from "../components/modal";

// Fonts & Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faBars,
	faCog,
	faLocationArrow,
	faMapMarkerAlt,
	faSearch,
} from "@fortawesome/free-solid-svg-icons";

// Images
import logoIconLight from "../img/logos/owl-tight-white.png";

// Dayhoot Functions & Scripts
import { WebConfig } from "../config/webconfig";

// Pages
import Settings from "../pages/settings";
import ChangeLocation from "../pages/changeLocation";

export function NavBarAttractionsLink() {
	return (
		<NavLink to="/attractions/">
			<span className="custom-nav-item">All Dayhoots</span>
		</NavLink>
	);
}

function SearchLocationButton({ data }) {
	const { icon, location, showModal } = data;
	return (
		<div className="input-group-append">
			<span
				className="input-group-text form-control border-dark navbar-locationbox"
				style={{borderRadius: '0'}}
				id="basic-addon2"
				onClick={() => showModal(true)}
			>
				<FontAwesomeIcon icon={icon}></FontAwesomeIcon>
				<b className="ml-2 search-location wordbreak">{location}</b>
			</span>
		</div>
	);
}

export function NavbarToggler() {
	return (
		<div className="d-xl-none">
			<button
				className="ml-auto custom-nav-toggler"
				type="button"
				data-toggle="collapse"
				data-target="#navbarSupportedContent"
				aria-controls="navbarSupportedContent"
				aria-expanded="false"
				aria-label="Toggle navigation"
			>
				<FontAwesomeIcon icon={faBars} />
			</button>
		</div>
	);
}

export function NavbarSettings({ data }) {
	const { settingsModalShow, setSettingsModalShow } = data;

	const modalDto = {
		show: settingsModalShow,
		onHide: () => setSettingsModalShow(false),
		headerContent: "Settings",
		bodyContent: <Settings isInModal={true} />,
	};

	return (
		<div>
			<button
				className="btn btn-dark"
				id="settings-button"
				onClick={() => setSettingsModalShow(true)}
			>
				<FontAwesomeIcon icon={faCog} />
				<span className="ml-2 mobile-only">Settings</span>
			</button>
			{/* This location doesn't really matter, it just needs to be placed somewhere... */}
			<DayhootStandardModal data={modalDto} />
		</div>
	);
}

export function NavbarLogo() {
	return (
		<NavLink className="navbar-brand" to="/">
			<div className="d-flex">
				<div className="p-0">
					<LazyLoad once={true}>
						<img
							src={logoIconLight}
							alt="Dayhoot Logo"
							style={{ width: "1.5rem", height: "2.1rem", marginTop: "0.15rem" }}
						></img>
					</LazyLoad>
				</div>
				<div className="pl-2">
					<span className="text-white h2" style={{ fontSize: "2rem" }}>
						Dayhoot
					</span>
				</div>
			</div>
		</NavLink>
	);
}

export function NavbarSearch({ data }) {
	const {
		locationModalShow,
		setLocationModalShow,
		query,
		handleSearchInputChange,
		searchLink,
		userLocation,
		isUsingGeolocation,
	} = data;

	// This is to give a more visual representation that the user's geolocation is being used
	const locationSettingIcon = isUsingGeolocation
		? faLocationArrow
		: faMapMarkerAlt;

	const modalDto = {
		show: locationModalShow,
		onHide: () => setLocationModalShow(false),
		headerContent: <span>Change Location</span>,
		bodyContent: <ChangeLocation />,
	};

	const searchLocation = WebConfig.enableChangeableSearchLocation ? (
		<SearchLocationButton
			data={{
				icon: locationSettingIcon,
				location: userLocation,
				showModal: setLocationModalShow,
			}}
		/>
	) : null;

	return (
		<div>
			<div className="input-group">
				<input
					className="form-control border-dark"
					id="navSearchBox"
					type="search"
					placeholder="Find a Dayhoot..."
					aria-label="Search"
					value={query}
					onChange={handleSearchInputChange}
					onKeyPress={(event) => {
						if (event.key === "Enter") {
							document.getElementById("searchSubmit").click();
							document.getElementById("navSearchBox").value = "";
						}
					}}
				/>
				{/* On larger screens the location displays next to the search box */}
				<div className="d-none d-md-block">{searchLocation}</div>
				<div className="input-group-append">
					<NavLink to={searchLink}>
						<button
							id="searchSubmit"
							onClick={() => {
								document.getElementById("navSearchBox").value = "";
							}}
							className="btn btn-dark"
							style={{ borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }}
						>
							<FontAwesomeIcon icon={faSearch} />
						</button>
					</NavLink>
				</div>
				{/* This location doesn't really matter, it just needs to be placed somewhere... */}
				<DayhootStandardModal data={modalDto} />
			</div>
			{/* This puts the location change button on a new line on small mobiles */}
			<div className="d-md-none mt-1">{searchLocation}</div>
		</div>
	);
}
