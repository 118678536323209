// Package Imports
import React from "react";
import LazyLoad from "react-lazyload";

// Dayhoot Images
import CookiesImageDark from "../img/icons/cookies-dark.png";

// Dayhoot Functions
import {
  acknowledgeAndManageCookies,
  confirmCookieNotice,
} from "../component-functions/cookies-core";

import { cookieDictionary, getCookie } from "../storage/cookies";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCheck, faCog } from "@fortawesome/free-solid-svg-icons";

export function CookiesNotice(cookiesNoticeDto) {
  //Check to see if the cookie consent has previously been given or not but acknowledged, hide it with none if it has.
  const { cookies } = cookiesNoticeDto.cookiesNoticeDto;

  const userHasAcknowledgedCookies = getCookie(
    cookies,
    cookieDictionary.CookiePolicyAcknowledged.key
  );

  const cookiesDisplay = userHasAcknowledgedCookies ? "none" : "block";

  return (
    <div
      id="cookiesNotice"
      className="cookiesCard"
      data-nosnippet
      style={{ display: cookiesDisplay }}
    >
      <div className="container p-1">
        <div className="row p-1 align-items-center">
          <div className="d-none d-md-block col-sm-1">
            <LazyLoad once={true}>
              <img
                className="img"
                src={CookiesImageDark}
                alt="Cookies"
                style={{
                  display: "block",
                  width: "100%",
                  maxWidth: "100px",
                  height: "auto",
                }}
              ></img>
            </LazyLoad>
          </div>
          <div className="col-sm-9 col-md-8">
            <p className="h6 m-0">Dayhoot uses Cookies</p>
            <p className="m-0" style={{ fontSize: "0.8em" }}>
              Cookies are used on Dayhoot to improve your user experience by
              setting options based on your interactions.{" "}
              <span className="d-none d-sm-inline">
                By choosing to accept our cookies, we are able to provide a more
                personalised experience during your time on our site.
              </span>{" "}
              Find out more and view our{" "}
              <a href="/policies/cookies" className="hyperlink">
                Cookie Policy
              </a>
              .
            </p>
          </div>
          <div className="col-sm-2 col-md-3 mt-1">
            <a
              className="btn btn-info ml-auto"
              id="cookiesManage"
              href="/settings/"
              onClick={(event) => {
                acknowledgeAndManageCookies(cookiesNoticeDto);
              }}
            >
              <FontAwesomeIcon className="pr-1" icon={faCog} />
              Manage
            </a>
            <button
              id="cookiesAgree"
              className="btn btn-success ml-2"
              onClick={(event) => confirmCookieNotice(cookiesNoticeDto)}
            >
              <FontAwesomeIcon className="pr-1" icon={faCheck} />
              Accept All
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
