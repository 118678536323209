import { ReactChild, ReactNode } from "react";
import { Carousel as ReactResponsiveCarousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useHistory } from "react-router-dom";
import { Banners, BannerTypes, CarouselBannerProps } from "../../config";
import useStyle from "../../Hooks/Style";
import CarouselStyles from "./carousel.scss";

export type CarouselProps = {
  /** Children of carousel should be of type CarouselItem */
  children: Array<ReactChild> & ReactNode;
};

const Carousel = ({ children }: CarouselProps) => {
  useStyle(CarouselStyles);
  let history = useHistory();
  return (
    <ReactResponsiveCarousel
      showThumbs={false}
      autoPlay
      infiniteLoop
      onClickItem={() => {
        const listItem: Element | undefined =
          document.querySelectorAll("li.slide.selected")[0];
        const slide = listItem
          ?.querySelector("div")
          ?.getAttribute("data-selectedslide") as BannerTypes | undefined;
        const banner: CarouselBannerProps | undefined = slide
          ? Banners[slide]
          : undefined;

        if (banner && banner.path) {
          history.push(banner.path);
        }
      }}
    >
      {children}
    </ReactResponsiveCarousel>
  );
};
export default Carousel;
