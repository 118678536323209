import adventuresAndExperiences from "../img/banners/adventuresAndExperiences.jpg";
import aquariums from "../img/banners/aquariums.jpg";
import mailingList from "../img/banners/mailingList.jpg";
import zoos from "../img/banners/zoos.jpg";
import social from "../img/banners/social.jpg";
import museums from "../img/banners/museums.jpg";
import themeparks from "../img/banners/themeparks.jpg";

export type CarouselBannerProps = {
  altText?: string;
  image: string;
  path: string;
};

export type BannerTypes =
  | "adventuresAndExperiences"
  | "aquariums"
  | "museums"
  | "mailingList"
  | "zoos"
  | "social"
  | "themeparks";

const Banners: { [id in BannerTypes]: CarouselBannerProps } = {
  adventuresAndExperiences: {
    altText: "Adventures & Experiences Banner",
    image: adventuresAndExperiences,
    path: "categories/experience",
  },
  aquariums: {
    altText: "Aquarium",
    image: aquariums,
    path: "categories/aquarium",
  },
  mailingList: {
    altText: "Mailing List",
    image: mailingList,
    path: "",
  },
  zoos: {
    altText: "Zoos",
    image: zoos,
    path: "categories/zoo",
  },
  social: {
    altText: "Social",
    image: social,
    path: "",
  },
  museums: {
    altText: "Museums",
    image: museums,
    path: "categories/museum",
  },
  themeparks: {
    altText: "Theme Parks",
    image: themeparks,
    path: "categories/themepark",
  },
};
export default Banners;
