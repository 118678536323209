import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import {
  faChevronLeft,
  faChevronRight,
  faSave,
  faInfoCircle,
  faExclamationCircle,
  faHome,
  faHatWizard,
  faUserEdit,
  faTimes,
  faTimesCircle,
  faCheck,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import {
  getMainCategoryDropdownOptions,
  getStepType,
  getValidationStateType,
  getWizardState,
  updateSelectedCategories,
} from "../component-functions/manageAttraction-core";
import {
  attractionValidationConfig,
  stepTypes,
  wizardStepEnums,
  wizardSteps,
} from "../config/manageAttraction-config";
import { isInputValid } from "../scripts/validation";
import { Col, Form, InputGroup } from "react-bootstrap";
import { AttractionDetailsContainer } from "../components/attraction-components";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(0),
  },
}));

export function WizardSteps({ handleChange, currentStep, isValid, hasErrors }) {
  const orderedSteps = wizardSteps.sort((a, b) => a.value - b.value);
  return (
    <div className="row" style={{ textAlign: "center" }}>
      {orderedSteps.map(step => {
        const stepType = getStepType(currentStep, step.value);
        const stateType = getValidationStateType(isValid, hasErrors);
        const { icon, colour } = getWizardState(stepType, stateType);

        const canMoveForward = stepType === stepTypes.NextStep && isValid;
        const canChangeStep = canMoveForward || stepType === stepTypes.PastStep;

        return (
          <div
            key={`step-${step.value}`}
            className="col-lg-2 mt-auto d-none d-lg-block"
          >
            <div className="d-flex flex-column">
              <div className="p-0">
                <FontAwesomeIcon
                  onClick={handleChange.bind(this, step.value, canChangeStep)}
                  icon={icon}
                  style={{
                    cursor: canChangeStep ? "pointer" : "default",
                    color: colour,
                  }}
                />
              </div>
              <div className="pb-2">
                <span
                  onClick={handleChange.bind(this, step.value, canChangeStep)}
                  className="pb-1 hyperlink text-darkgrey"
                  style={{
                    borderBottom: `solid 5px ${colour}`,
                    cursor: canChangeStep ? "pointer" : "default",
                  }}
                >
                  {step.name}
                </span>
              </div>
            </div>
          </div>
        );
      })}
      <div className="col-12 d-none d-lg-block">
        <hr className="mt-3" />
      </div>
    </div>
  );
}

export function WizardStep({
  cookies,
  currentStep,
  facilities,
  categories,
  regions,
  isReviewing,
  attraction,
  handleStepChange,
  saveAttraction,
  approveAttraction,
  rejectAttraction,
  undoRejectAttraction,
  hasCreated,
  viewSubmission,
  isAdmin,
}) {
  return (
    <div>
      <BasicDetails
        currentStep={currentStep}
        step={wizardStepEnums.BasicDetails}
        handleStepChange={handleStepChange}
        saveAttraction={saveAttraction}
        isReviewing={isReviewing}
        attraction={attraction}
      />
      <LocationDetails
        currentStep={currentStep}
        step={wizardStepEnums.Location}
        regions={regions}
        handleStepChange={handleStepChange}
        saveAttraction={saveAttraction}
        isReviewing={isReviewing}
        isAdmin={isAdmin}
        attraction={attraction}
      />
      <CategoryDetails
        currentStep={currentStep}
        step={wizardStepEnums.Categories}
        categories={categories}
        handleStepChange={handleStepChange}
        saveAttraction={saveAttraction}
        isReviewing={isReviewing}
        attraction={attraction}
      />
      <ContactInformation
        currentStep={currentStep}
        step={wizardStepEnums.ContactInformation}
        handleStepChange={handleStepChange}
        saveAttraction={saveAttraction}
        isReviewing={isReviewing}
        attraction={attraction}
      />
      <Confirmation
        currentStep={currentStep}
        step={wizardStepEnums.Confirmation}
        isReviewing={isReviewing}
        attraction={attraction}
        cookies={cookies}
        handleStepChange={handleStepChange}
        saveAttraction={saveAttraction}
        approveAttraction={approveAttraction}
        rejectAttraction={rejectAttraction}
        undoRejectAttraction={undoRejectAttraction}
        hasCreated={hasCreated}
        categories={categories}
        viewSubmission={viewSubmission}
        isAdmin={isAdmin}
      />
    </div>
  );
}

function WizardNavigation({
  handleStepChange,
  currentStep,
  saveAttraction,
  approveAttraction,
  rejectAttraction,
  undoRejectAttraction,
  enableNext,
  enableSave,
  isReviewing,
  isAdmin,
  isRejected,
  isApproved,
}) {
  const canGoBack = currentStep !== wizardSteps.find(x => x.value === 0).value;
  const prevStep = canGoBack ? currentStep - 1 : currentStep;
  const isFinalStep =
    currentStep ===
    wizardSteps.find(x => x.value === wizardSteps.length - 1).value;
  const canGoForward = enableNext && !isFinalStep;
  const nextStep = canGoForward ? currentStep + 1 : currentStep;
  const isValid = enableSave && isFinalStep;

  return (
    <div className="row">
      <div className="col-12">
        <hr />
      </div>
      <div className="col-12">
        <div className="d-flex flex-row align-items-center">
          <div
            className={`ml-auto pl-1 pr-1 ${
              isFinalStep && (isApproved || isRejected) ? "mr-auto" : ""
            }`}
          >
            <button
              className={`btn btn-success ${canGoBack ? "" : "disabled"}`}
              onClick={handleStepChange.bind(this, prevStep, canGoBack)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <span className="ml-1">Previous</span>
            </button>
          </div>

          {isAdmin && isReviewing && !isRejected && !isApproved ? (
            <div className="pl-1 pr-1">
              <button
                className={`btn btn-danger ${isValid ? "" : "disabled"}`}
                onClick={() => {
                  if (isValid) {
                    rejectAttraction();
                  }
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
                <span className="ml-1 d-none d-md-inline">Reject</span>
              </button>
            </div>
          ) : null}

          {isAdmin && isReviewing && isRejected ? (
            <div className="pl-1 pr-1">
              <button
                className={`btn btn-warning ${isValid ? "" : "disabled"}`}
                onClick={() => {
                  if (isValid) {
                    undoRejectAttraction();
                  }
                }}
              >
                <FontAwesomeIcon icon={faUndo} />
                <span className="ml-1 d-none d-md-inline">Undo Reject</span>
              </button>
            </div>
          ) : null}

          {isAdmin && isReviewing && !isRejected && !isApproved ? (
            <div className="pl-1 pr-1">
              <button
                className={`btn btn-success ${isValid ? "" : "disabled"}`}
                onClick={() => {
                  if (isValid) {
                    approveAttraction();
                  }
                }}
              >
                <FontAwesomeIcon icon={faCheck} />
                <span className="ml-1 d-none d-md-inline">Approve</span>
              </button>
            </div>
          ) : null}

          {isFinalStep && !isApproved && !isRejected ? (
            <div className="mr-auto pl-1 pr-1">
              <button
                className={`btn btn-primary ${isValid ? "" : "disabled"}`}
                onClick={() => {
                  if (isValid) {
                    saveAttraction(isReviewing);
                  }
                }}
              >
                <FontAwesomeIcon icon={faSave} />
                <span className="ml-1">
                  {isReviewing ? "Apply Changes" : "Save Attraction"}
                </span>
              </button>
            </div>
          ) : null}

          {isFinalStep ? null : (
            <div className="mr-auto pl-1 pr-1">
              <button
                className={`btn btn-success ${canGoForward ? "" : "disabled"}`}
                onClick={handleStepChange.bind(this, nextStep, canGoForward)}
              >
                <span className="mr-1">Next</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function BasicDetails({
  currentStep,
  step,
  handleStepChange,
  saveAttraction,
  isReviewing,
  attraction,
}) {
  const isVisible = currentStep === step.value;

  const [attractionName, setAttractionName] = React.useState({
    value: isReviewing ? attraction.name : "",
    isDirty: false,
    errors: isInputValid(
      attractionValidationConfig,
      "name",
      isReviewing ? attraction.name : ""
    ),
  });
  const [entryPrice, setEntryPrice] = React.useState({
    value: isReviewing ? `${parseFloat(attraction.price).toFixed(2)}` : "",
    isDirty: false,
    errors: isInputValid(
      attractionValidationConfig,
      "price",
      isReviewing ? `${parseFloat(attraction.price).toFixed(2)}` : ""
    ),
  });
  const [imageUrl, setImageUrl] = React.useState({
    value: isReviewing ? attraction.imageUrl : "",
    isDirty: false,
    errors: isInputValid(
      attractionValidationConfig,
      "imageUrl",
      isReviewing ? attraction.imageUrl : ""
    ),
  });
  const [attractionDescription, setAttractionDescription] = React.useState({
    value: isReviewing ? attraction.description : "",
    isDirty: false,
    errors: isInputValid(
      attractionValidationConfig,
      "description",
      isReviewing ? attraction.description : ""
    ),
  });
  const [showPriceInfoText, setShowPriceInfoText] = React.useState(false);

  const inputs = [attractionName, entryPrice, imageUrl, attractionDescription];
  const hasErrors = inputs.some(x => {
    return (
      attractionName.errors.length > 0 ||
      entryPrice.errors.length > 0 ||
      imageUrl.errors.length > 0 ||
      attractionDescription.errors.length > 0
    );
  });

  return (
    <div className="row" style={{ display: isVisible ? "block" : "none" }}>
      <div className="col-12">
        <WizardSteps
          handleChange={handleStepChange}
          currentStep={currentStep}
          isValid={!hasErrors}
          hasErrors={hasErrors}
        />
      </div>
      <div className="col-12">
        <h2>{step.name}</h2>
        <div className="row">
          <div className="col-lg-6">
            <Form.Group>
              <Form.Label>Attraction Name *</Form.Label>
              <Form.Control
                type="text"
                placeholder="e.g. Alton Towers"
                name="attractionName"
                value={attractionName.value}
                onChange={e => {
                  return setAttractionName({
                    value: e.target.value,
                    isDirty: true,
                    errors: isInputValid(
                      attractionValidationConfig,
                      "name",
                      e.target.value
                    ),
                  });
                }}
                isValid={
                  attractionName.isDirty && attractionName.errors.length === 0
                }
                isInvalid={
                  attractionName.isDirty && attractionName.errors.length > 0
                }
                id="attractionName"
              />
              {attractionName.isDirty && attractionName.errors.length > 0 ? (
                <Form.Control.Feedback type="invalid">
                  {attractionName.errors}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </div>
          <div className="col-lg-6">
            <div className="d-flex flex-row align-items-center">
              <div className="p-0">
                <Form.Label>Adult Entry Price *</Form.Label>
              </div>
              <div className="ml-auto pl-1">
                <FontAwesomeIcon
                  className={
                    showPriceInfoText ? "hyperlink text-orange" : "hyperlink"
                  }
                  icon={showPriceInfoText ? faTimesCircle : faInfoCircle}
                  onClick={() => setShowPriceInfoText(!showPriceInfoText)}
                />
              </div>
            </div>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>£</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="number"
                  placeholder="e.g. 14.79"
                  value={entryPrice.value}
                  onChange={e =>
                    setEntryPrice({
                      value: e.target.value,
                      isDirty: true,
                      errors: isInputValid(
                        attractionValidationConfig,
                        "price",
                        e.target.value
                      ),
                    })
                  }
                  isValid={entryPrice.isDirty && entryPrice.errors.length === 0}
                  isInvalid={entryPrice.isDirty && entryPrice.errors.length > 0}
                  id="entryPrice"
                />
                {entryPrice.isDirty && entryPrice.errors.length > 0 ? (
                  <Form.Control.Feedback type="invalid">
                    {entryPrice.errors}
                  </Form.Control.Feedback>
                ) : null}
              </InputGroup>
            </Form.Group>
            {showPriceInfoText ? (
              <div className="alert alert-info">
                <div className="d-flex flex-row">
                  <div className="p-0">
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </div>
                  <div className="pl-1">
                    <span className="ml-1">
                      Prices are not shown on Dayhoot. This amount will instead
                      be used to give a cost rating out of 5.
                    </span>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-12">
            <Form.Group>
              <Form.Label>Description *</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={attractionDescription.value}
                onChange={e =>
                  setAttractionDescription({
                    value: e.target.value,
                    isDirty: true,
                    errors: isInputValid(
                      attractionValidationConfig,
                      "description",
                      e.target.value
                    ),
                  })
                }
                isValid={
                  attractionDescription.isDirty &&
                  attractionDescription.errors.length === 0
                }
                isInvalid={
                  attractionDescription.isDirty &&
                  attractionDescription.errors.length > 0
                }
                id="attractionDescription"
                placeholder="e.g. Alton Towers is a theme park near Stoke-on-Trent in England and is home to some of the UK's top rollercoasters such as..."
              />
              {attractionDescription.isDirty &&
              attractionDescription.errors.length > 0 ? (
                <Form.Control.Feedback type="invalid">
                  {attractionDescription.errors}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </div>
          <div className="col-12">
            {isReviewing ? (
              <Form.Group>
                <Form.Label>Image URL</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Image URL"
                  value={imageUrl.value}
                  onChange={e =>
                    setImageUrl({
                      value: e.target.value,
                      isDirty: true,
                      errors: isInputValid(
                        attractionValidationConfig,
                        "imageUrl",
                        e.target.value
                      ),
                    })
                  }
                  isValid={imageUrl.isDirty && imageUrl.errors.length === 0}
                  isInvalid={imageUrl.isDirty && imageUrl.errors.length > 0}
                  id="imageUrl"
                />
                {imageUrl.isDirty && imageUrl.errors.length > 0 ? (
                  <Form.Control.Feedback type="invalid">
                    {imageUrl.errors}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
            ) : null}
          </div>
        </div>
      </div>
      <div className="col-12">
        <WizardNavigation
          handleStepChange={handleStepChange}
          currentStep={currentStep}
          saveAttraction={saveAttraction}
          enableNext={!hasErrors}
        />
      </div>
    </div>
  );
}

function LocationDetails({
  currentStep,
  step,
  regions,
  handleStepChange,
  saveAttraction,
  isReviewing,
  isAdmin,
  attraction,
}) {
  const classes = useStyles();
  const isVisible = currentStep === step.value;

  const [selectedRegion, setSelectedRegion] = React.useState({
    value: isReviewing ? attraction.location.region : "",
    isDirty: false,
    errors: isInputValid(
      attractionValidationConfig,
      "region",
      isReviewing ? attraction.location.region : ""
    ),
  });
  const [address1, setAddress1] = React.useState({
    value: isReviewing ? attraction.location.address1 : "",
    isDirty: false,
    errors: isInputValid(
      attractionValidationConfig,
      "address1",
      isReviewing ? attraction.location.address1 : ""
    ),
  });
  const [address2, setAddress2] = React.useState({
    value: isReviewing ? attraction.location.address2 : "",
    isDirty: false,
    errors: isInputValid(
      attractionValidationConfig,
      "address2",
      isReviewing ? attraction.location.address2 : ""
    ),
  });
  const [postCode, setPostCode] = React.useState({
    value: isReviewing ? attraction.location.postCode : "",
    isDirty: false,
    errors: isInputValid(
      attractionValidationConfig,
      "postCode",
      isReviewing ? attraction.location.postCode : ""
    ),
  });
  const [country, setCountry] = React.useState({
    value: isReviewing ? attraction.location.country : "",
    isDirty: false,
    errors: isInputValid(
      attractionValidationConfig,
      "country",
      isReviewing ? attraction.location.country : ""
    ),
  });
  const [googlePlaceId, setGooglePlaceId] = React.useState({
    value:
      isReviewing && !!attraction.googlePlaceId ? attraction.googlePlaceId : "",
  });
  const inputs = [selectedRegion, address1, address2, country, postCode];
  const hasErrors = inputs.some(x => {
    return (
      selectedRegion.errors.length > 0 ||
      address1.errors.length > 0 ||
      address2.errors.length > 0 ||
      country.errors.length > 0 ||
      postCode.errors.length > 0
    );
  });

  return (
    <div className="row" style={{ display: isVisible ? "block" : "none" }}>
      <div className="col-12">
        <WizardSteps
          handleChange={handleStepChange}
          currentStep={currentStep}
          isValid={!hasErrors}
          hasErrors={hasErrors}
        />
      </div>
      <div className="col-12">
        <h2>{step.name}</h2>
        {isAdmin ? (
          <div className="row mb-3">
            <div className="col-12">
              <Form.Group>
                <Form.Label>Google Place ID</Form.Label>
                <Form.Control
                  placeholder="Google Place ID"
                  id="googlePlaceId"
                  value={googlePlaceId.value}
                  onChange={e =>
                    setGooglePlaceId({
                      value: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </div>
          </div>
        ) : null}
        <div className="row mb-3">
          <div className="col-12">
            <Form.Label>Applicable Region *</Form.Label>
            <div className={classes.root}>
              <input
                type="hidden"
                value={selectedRegion.value}
                id="region-selectedRegion"
              />
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  <RadioGroup
                    value={selectedRegion.value}
                    onChange={e =>
                      setSelectedRegion({
                        value: e.target.value,
                        isDirty: true,
                        errors: isInputValid(
                          attractionValidationConfig,
                          "region",
                          e.target.value
                        ),
                      })
                    }
                    id="region-selectedRegion"
                  >
                    <div className="row mb-3">
                      {regions.map(region => {
                        return (
                          <div
                            className="col-lg-4"
                            key={`region-${region.regionName}`}
                          >
                            <FormControlLabel
                              value={region.regionName}
                              control={<Radio />}
                              label={region.displayName}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </RadioGroup>
                  {selectedRegion.isDirty &&
                  selectedRegion.errors.length > 0 ? (
                    <span className="forced-invalid-feedback">
                      {selectedRegion.errors}
                    </span>
                  ) : null}
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Address 1</Form.Label>
              <Form.Control
                placeholder="123 Common Lane"
                id="address1"
                value={address1.value}
                onChange={e =>
                  setAddress1({
                    value: e.target.value,
                    isDirty: true,
                    errors: isInputValid(
                      attractionValidationConfig,
                      "address1",
                      e.target.value
                    ),
                  })
                }
                isValid={address1.isDirty && address1.errors.length === 0}
                isInvalid={address1.isDirty && address1.errors.length > 0}
              />
              {address1.isDirty && address1.errors.length > 0 ? (
                <Form.Control.Feedback type="invalid">
                  {address1.errors}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Address 2</Form.Label>
              <Form.Control
                placeholder="Address Line 2"
                id="address2"
                value={address2.value}
                onChange={e =>
                  setAddress2({
                    value: e.target.value,
                    isDirty: true,
                    errors: isInputValid(
                      attractionValidationConfig,
                      "address2",
                      e.target.value
                    ),
                  })
                }
                isValid={address2.isDirty && address2.errors.length === 0}
                isInvalid={address2.isDirty && address2.errors.length > 0}
              />
              {address2.isDirty && address2.errors.length > 0 ? (
                <Form.Control.Feedback type="invalid">
                  {address2.errors}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Country/City</Form.Label>
              <Form.Control
                placeholder="Country/City"
                id="country"
                value={country.value}
                onChange={e =>
                  setCountry({
                    value: e.target.value,
                    isDirty: true,
                    errors: isInputValid(
                      attractionValidationConfig,
                      "country",
                      e.target.value
                    ),
                  })
                }
                isValid={country.isDirty && country.errors.length === 0}
                isInvalid={country.isDirty && country.errors.length > 0}
              />
              {country.isDirty && country.errors.length > 0 ? (
                <Form.Control.Feedback type="invalid">
                  {country.errors}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Post Code</Form.Label>
              <Form.Control
                placeholder="Post Code"
                id="postCode"
                value={postCode.value}
                onChange={e =>
                  setPostCode({
                    value: e.target.value,
                    isDirty: true,
                    errors: isInputValid(
                      attractionValidationConfig,
                      "postCode",
                      e.target.value
                    ),
                  })
                }
                isValid={postCode.isDirty && postCode.errors.length === 0}
                isInvalid={postCode.isDirty && postCode.errors.length > 0}
              />
              {postCode.isDirty && postCode.errors.length > 0 ? (
                <Form.Control.Feedback type="invalid">
                  {postCode.errors}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </Form.Row>
        </div>
      </div>
      <div className="col-12">
        <WizardNavigation
          handleStepChange={handleStepChange}
          currentStep={currentStep}
          saveAttraction={saveAttraction}
          enableNext={!hasErrors}
        />
      </div>
    </div>
  );
}

function CategoryDetails({
  currentStep,
  step,
  categories,
  handleStepChange,
  saveAttraction,
  isReviewing,
  attraction,
}) {
  const classes = useStyles();
  const isVisible = currentStep === step.value;
  const preSelectedCategoryNames = isReviewing
    ? attraction.categories.map(c => c.categoryName)
    : [];
  const preSelectedCategoryDropdownValues = isReviewing
    ? categories
        .filter(c => preSelectedCategoryNames.find(cn => cn === c.categoryName))
        .map(c => {
          return {
            categoryName: c.categoryName,
            categoryPageTitle: c.categoryPageTitle,
          };
        })
    : [];

  const [mainCategoryDropdownOptions, setMainCategoryDropdownOptions] =
    React.useState(
      getMainCategoryDropdownOptions(preSelectedCategoryDropdownValues)
    );
  const [selectedCategories, setSelectedCategories] = React.useState({
    value: isReviewing ? attraction.categories.map(c => c.categoryName) : [],
    isDirty: false,
    errors: isInputValid(
      attractionValidationConfig,
      "categories",
      isReviewing ? attraction.categories.map(c => c.categoryName) : []
    ),
  });
  const [mainCategory, setMainCategory] = React.useState({
    value: isReviewing ? attraction.mainCategory.categoryName : "",
    isDirty: false,
    errors: isInputValid(
      attractionValidationConfig,
      "mainCategory",
      isReviewing ? attraction.mainCategory.categoryName : ""
    ),
  });
  const inputs = [selectedCategories, mainCategory];
  const hasErrors = inputs.some(x => {
    return (
      selectedCategories.errors.length > 0 || mainCategory.errors.length > 0
    );
  });

  return (
    <div className="row" style={{ display: isVisible ? "block" : "none" }}>
      <div className="col-12">
        <WizardSteps
          handleChange={handleStepChange}
          currentStep={currentStep}
          isValid={!hasErrors}
          hasErrors={hasErrors}
        />
      </div>
      <div className="col-12">
        <h2>{step.name}</h2>
        <div className={classes.root}>
          <FormControl component="fieldset" className={classes.formControl}>
            <Form.Label>
              <span>
                Applicable Categories <em>(select at least one)</em> *
              </span>
            </Form.Label>
            {selectedCategories.isDirty &&
            selectedCategories.errors.length > 0 ? (
              <span className="forced-invalid-feedback">
                {selectedCategories.errors}
              </span>
            ) : null}
            <FormGroup>
              <div className="row mb-3">
                <input
                  type="hidden"
                  value={selectedCategories.value}
                  id="category-categories"
                />
                {categories.map(category => {
                  const isChecked =
                    selectedCategories.value.find(
                      c => c === category.categoryName
                    ) != null;
                  return (
                    <div
                      className="col-lg-4"
                      key={`categoryCheckbox-${category.categoryName}`}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isChecked}
                            onChange={e => {
                              const updatedCategories =
                                updateSelectedCategories({
                                  checked: e.target.checked,
                                  val: category.categoryName,
                                  selectedCategories: selectedCategories.value,
                                });
                              setSelectedCategories({
                                value: updatedCategories,
                                isDirty: true,
                                errors: isInputValid(
                                  attractionValidationConfig,
                                  "categories",
                                  updatedCategories
                                ),
                              });
                              const updatedMainCategory =
                                updatedCategories.length === 1
                                  ? updatedCategories[0]
                                  : "N/A";
                              setMainCategory({
                                value: updatedMainCategory,
                                isValid: updatedMainCategory !== "N/A",
                                isDirty: true,
                                errors:
                                  !updatedMainCategory ||
                                  updatedMainCategory === "N/A"
                                    ? ["A main category must be selected."]
                                    : [],
                              });
                              setMainCategoryDropdownOptions(
                                getMainCategoryDropdownOptions(
                                  categories.filter(c =>
                                    updatedCategories.find(
                                      uc => c.categoryName === uc
                                    )
                                  )
                                )
                              );
                            }}
                            name={category.categoryName}
                            id={`category-${category.categoryName}`}
                          />
                        }
                        label={category.categoryPageTitle}
                      />
                    </div>
                  );
                })}
              </div>
            </FormGroup>
          </FormControl>
        </div>
        <div className="row">
          <div className="col-12">
            <div>
              <Form.Label>
                <span>Main Category *</span>
              </Form.Label>
              <Form.Control
                as="select"
                value={mainCategory.value}
                onChange={e =>
                  setMainCategory({
                    value: e.target.value,
                    isValid: e.target.value !== "N/A",
                    isDirty: true,
                    errors:
                      !e.target.value || e.target.value === "N/A"
                        ? ["A main category must be selected."]
                        : [],
                  })
                }
                style={{
                  display:
                    mainCategoryDropdownOptions.length > 1 ? "block" : "none",
                }}
                id="category-mainCategory"
                isValid={
                  mainCategory.isDirty && mainCategory.errors.length === 0
                }
                isInvalid={
                  mainCategory.isDirty && mainCategory.errors.length > 0
                }
              >
                {mainCategoryDropdownOptions.map(c => (
                  <option
                    key={c.categoryName}
                    value={c.categoryName}
                    id={c.categoryName}
                  >
                    {c.categoryPageTitle}
                  </option>
                ))}
              </Form.Control>
              <div
                className="alert alert-warning"
                style={{
                  display:
                    mainCategoryDropdownOptions.length < 2 ? "block" : "none",
                }}
              >
                <div className="d-flex flex-row">
                  <div className="p-0">
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </div>
                  <div className="pl-2">
                    <span>
                      Select at least one category before selecting a main
                      category.
                    </span>
                  </div>
                </div>
              </div>
              {mainCategory.isDirty && mainCategory.errors.length > 0 ? (
                <span className="forced-invalid-feedback">
                  {mainCategory.errors}
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <WizardNavigation
          handleStepChange={handleStepChange}
          currentStep={currentStep}
          saveAttraction={saveAttraction}
          enableNext={!hasErrors}
        />
      </div>
    </div>
  );
}

function ContactInformation({
  currentStep,
  step,
  handleStepChange,
  saveAttraction,
  isReviewing,
  attraction,
}) {
  const isVisible = currentStep === step.value;
  const [websiteUrl, setWebsiteUrl] = React.useState({
    value: isReviewing ? attraction.linkUrl : "",
    isDirty: false,
    errors: isInputValid(
      attractionValidationConfig,
      "linkUrl",
      isReviewing ? attraction.linkUrl : ""
    ),
  });
  const [phoneNumber, setPhoneNumber] = React.useState({
    value: isReviewing
      ? !!attraction.contact && !!attraction.contact.phone
        ? attraction.contact.phone
        : ""
      : "",
    isDirty: false,
    errors: isInputValid(
      attractionValidationConfig,
      "phone",
      isReviewing
        ? !!attraction.contact && !!attraction.contact.phone
          ? attraction.contact.phone
          : ""
        : ""
    ),
  });
  const [emailAddress, setEmailAddress] = React.useState({
    value: isReviewing
      ? !!attraction.contact && !!attraction.contact.email
        ? attraction.contact.email
        : ""
      : "",
    isDirty: false,
    errors: isInputValid(
      attractionValidationConfig,
      "email",
      isReviewing
        ? !!attraction.contact && !!attraction.contact.email
          ? attraction.contact.email
          : ""
        : ""
    ),
  });
  const [facebookAccount, setFacebookAccount] = React.useState({
    value: isReviewing
      ? !!attraction.contact && !!attraction.contact.facebook
        ? attraction.contact.facebook
        : ""
      : "",
    isDirty: false,
    errors: isInputValid(
      attractionValidationConfig,
      "socialAccount",
      isReviewing
        ? !!attraction.contact && !!attraction.contact.facebook
          ? attraction.contact.facebook
          : ""
        : ""
    ),
  });
  const [twitterAccount, setTwitterAccount] = React.useState({
    value: isReviewing
      ? !!attraction.contact && !!attraction.contact.twitter
        ? attraction.contact.twitter
        : ""
      : "",
    isDirty: false,
    errors: isInputValid(
      attractionValidationConfig,
      "socialAccount",
      isReviewing
        ? !!attraction.contact && !!attraction.contact.twitter
          ? attraction.contact.twitter
          : ""
        : ""
    ),
  });
  const [instagramAccount, setInstagramAccount] = React.useState({
    value: isReviewing
      ? !!attraction.contact && !!attraction.contact.instagram
        ? attraction.contact.instagram
        : ""
      : "",
    isDirty: false,
    errors: isInputValid(
      attractionValidationConfig,
      "socialAccount",
      isReviewing
        ? !!attraction.contact && !!attraction.contact.instagram
          ? attraction.contact.instagram
          : ""
        : ""
    ),
  });

  const inputs = [
    websiteUrl,
    phoneNumber,
    emailAddress,
    facebookAccount,
    twitterAccount,
    instagramAccount,
  ];

  const hasErrors = inputs.some(x => {
    return (
      websiteUrl.errors.length > 0 ||
      phoneNumber.errors.length > 0 ||
      emailAddress.errors.length > 0 ||
      facebookAccount.errors.length > 0 ||
      twitterAccount.errors.length > 0 ||
      instagramAccount.errors.length > 0
    );
  });

  return (
    <div className="row" style={{ display: isVisible ? "block" : "none" }}>
      <div className="col-12">
        <WizardSteps
          handleChange={handleStepChange}
          currentStep={currentStep}
          isValid={!hasErrors}
          hasErrors={hasErrors}
        />
      </div>
      <div className="col-12">
        <h2>{step.name}</h2>
        <Form.Group>
          <Form.Label>Website URL</Form.Label>
          <Form.Control
            type="text"
            placeholder="Website URL"
            id="websiteUrl"
            value={websiteUrl.value}
            onChange={e =>
              setWebsiteUrl({
                value: e.target.value,
                isDirty: true,
                errors: isInputValid(
                  attractionValidationConfig,
                  "linkUrl",
                  e.target.value
                ),
              })
            }
            isValid={websiteUrl.isDirty && websiteUrl.errors.length === 0}
            isInvalid={websiteUrl.isDirty && websiteUrl.errors.length > 0}
          />
          {websiteUrl.isDirty && websiteUrl.errors.length > 0 ? (
            <Form.Control.Feedback type="invalid">
              {websiteUrl.errors}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col} md={6}>
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email Address"
              id="emailAddress"
              value={emailAddress.value}
              onChange={e =>
                setEmailAddress({
                  value: e.target.value,
                  isDirty: true,
                  errors: isInputValid(
                    attractionValidationConfig,
                    "email",
                    e.target.value
                  ),
                })
              }
              isValid={emailAddress.isDirty && emailAddress.errors.length === 0}
              isInvalid={emailAddress.isDirty && emailAddress.errors.length > 0}
            />
            {emailAddress.isDirty && emailAddress.errors.length > 0 ? (
              <Form.Control.Feedback type="invalid">
                {emailAddress.errors}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} md={6}>
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Phone Number"
              id="phoneNumber"
              value={phoneNumber.value}
              onChange={e =>
                setPhoneNumber({
                  value: e.target.value,
                  isDirty: true,
                  errors: isInputValid(
                    attractionValidationConfig,
                    "phone",
                    e.target.value
                  ),
                })
              }
              isValid={phoneNumber.isDirty && phoneNumber.errors.length === 0}
              isInvalid={phoneNumber.isDirty && phoneNumber.errors.length > 0}
            />
            {phoneNumber.isDirty && phoneNumber.errors.length > 0 ? (
              <Form.Control.Feedback type="invalid">
                {phoneNumber.errors}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        </Form.Row>
      </div>
      <div className="col-12">
        <h2>Social Media Accounts</h2>
        <div className="row">
          <div className="col-md-6">
            <Form.Label>
              <span className="mb-0">Facebook</span>
            </Form.Label>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>@</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  placeholder="e.g. DayhootUK"
                  id="facebookAccount"
                  value={facebookAccount.value}
                  onChange={e =>
                    setFacebookAccount({
                      value: e.target.value,
                      isDirty: true,
                      errors: isInputValid(
                        attractionValidationConfig,
                        "socialAccount",
                        e.target.value
                      ),
                    })
                  }
                  isValid={
                    facebookAccount.isDirty &&
                    facebookAccount.errors.length === 0
                  }
                  isInvalid={
                    facebookAccount.isDirty && facebookAccount.errors.length > 0
                  }
                />
                {facebookAccount.isDirty &&
                facebookAccount.errors.length > 0 ? (
                  <Form.Control.Feedback type="invalid">
                    {facebookAccount.errors}
                  </Form.Control.Feedback>
                ) : null}
              </InputGroup>
            </Form.Group>
          </div>
          <div className="col-md-6">
            <Form.Label>
              <span className="mb-0">Twitter</span>
            </Form.Label>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>@</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  placeholder="e.g. DayhootUK"
                  id="twitterAccount"
                  value={twitterAccount.value}
                  onChange={e =>
                    setTwitterAccount({
                      value: e.target.value,
                      isDirty: true,
                      errors: isInputValid(
                        attractionValidationConfig,
                        "socialAccount",
                        e.target.value
                      ),
                    })
                  }
                  isValid={
                    twitterAccount.isDirty && twitterAccount.errors.length === 0
                  }
                  isInvalid={
                    twitterAccount.isDirty && twitterAccount.errors.length > 0
                  }
                />
                {twitterAccount.isDirty && twitterAccount.errors.length > 0 ? (
                  <Form.Control.Feedback type="invalid">
                    {twitterAccount.errors}
                  </Form.Control.Feedback>
                ) : null}
              </InputGroup>
            </Form.Group>
          </div>
          <div className="col-md-6">
            <Form.Label>
              <span className="mb-0">Instagram</span>
            </Form.Label>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>@</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  placeholder="e.g. DayhootUK"
                  id="instagramAccount"
                  value={instagramAccount.value}
                  onChange={e =>
                    setInstagramAccount({
                      value: e.target.value,
                      isDirty: true,
                      errors: isInputValid(
                        attractionValidationConfig,
                        "socialAccount",
                        e.target.value
                      ),
                    })
                  }
                  isValid={
                    instagramAccount.isDirty &&
                    instagramAccount.errors.length === 0
                  }
                  isInvalid={
                    instagramAccount.isDirty &&
                    instagramAccount.errors.length > 0
                  }
                />
                {instagramAccount.isDirty &&
                instagramAccount.errors.length > 0 ? (
                  <Form.Control.Feedback type="invalid">
                    {instagramAccount.errors}
                  </Form.Control.Feedback>
                ) : null}
              </InputGroup>
            </Form.Group>
          </div>
        </div>
      </div>
      <div className="col-12">
        <WizardNavigation
          handleStepChange={handleStepChange}
          currentStep={currentStep}
          saveAttraction={saveAttraction}
          enableNext={!hasErrors}
        />
      </div>
    </div>
  );
}

export function Confirmation({
  currentStep,
  step,
  cookies,
  handleStepChange,
  saveAttraction,
  approveAttraction,
  rejectAttraction,
  undoRejectAttraction,
  categories,
  isReviewing,
  isAdmin,
  attraction,
  hasCreated,
  viewSubmission,
}) {
  const isVisible = currentStep === step.value && attraction != null;
  if (isVisible) {
    if (!hasCreated) {
      let attractionPreview = attraction;

      attractionPreview.categories = attraction.categories.map(c =>
        categories.find(x => x.categoryName === c)
      );
      attractionPreview.mainCategory = categories.find(
        x => x.categoryName === attraction.mainCategory
      );
      return (
        <div className="row" style={{ display: isVisible ? "block" : "none" }}>
          <div className="col-12">
            <WizardSteps
              handleChange={handleStepChange}
              currentStep={currentStep}
              isValid={true}
              hasErrors={false}
            />
          </div>
          <div className="col-12">
            <h2>Confirmation</h2>
            <div className="alert alert-info">
              <div className="d-flex flex-row">
                <div className="p-0">
                  <FontAwesomeIcon icon={faExclamationCircle}></FontAwesomeIcon>
                </div>
                <div className="pl-2">
                  <span>
                    Please review the information shown in the preview of the
                    attraction you are about to{" "}
                    {attraction.id ? "update" : "create"}. If there are any
                    issues or if there is any information you would like to
                    change before submitting, feel free to use the step and
                    button navigation above / below to do so before saving.
                  </span>
                </div>
              </div>
            </div>
            <h3>Attraction Preview</h3>
            <AttractionDetailsContainer
              attraction={attractionPreview}
              cookies={cookies}
              isVisible={true}
              isDetailsOverflow={false}
              showDetailsModal={false}
              setShowDetailsModal={null}
              isPreview={true}
            />
          </div>
          <div className="col-12">
            <div className="alert alert-warning mt-3">
              <div className="d-flex flex-row">
                <div className="p-0">
                  <FontAwesomeIcon icon={faExclamationCircle} />
                </div>
                <div className="pl-2">
                  <span>
                    Once the attraction has been{" "}
                    {attraction.id ? "updated" : "submitted"}, it will be
                    reviewed before being displayed publicly on Dayhoot. Please
                    confirm that the above attraction is correct and that you
                    are happy for this information be publicly available once
                    reviewed.{" "}
                    {attraction.id
                      ? ""
                      : `To
										make changes to this submission, you will need the
										submission reference number (given to you when you press
										submit so make a note of it) and the submission must not
										have already been reviewed.`}
                  </span>
                </div>
              </div>
            </div>
            <WizardNavigation
              handleStepChange={handleStepChange}
              currentStep={currentStep}
              saveAttraction={saveAttraction}
              approveAttraction={approveAttraction}
              rejectAttraction={rejectAttraction}
              undoRejectAttraction={undoRejectAttraction}
              enableSave={true}
              isReviewing={isReviewing}
              isRejected={!!attraction.isRejected}
              isApproved={!!attraction.isApproved}
              isAdmin={isAdmin}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <SubmissionComplete
            attraction={attraction}
            viewSubmission={viewSubmission}
          />
          <hr />
          <h3>Attraction Preview</h3>
          <AttractionDetailsContainer
            attraction={attraction}
            cookies={cookies}
            isVisible={true}
            isDetailsOverflow={false}
            showDetailsModal={false}
            setShowDetailsModal={null}
            isPreview={true}
          />
        </div>
      );
    }
  } else return null;
}

export function SubmissionComplete({ attraction, hasUpdated, viewSubmission }) {
  return (
    <div className="d-flex flex-row align-items-center">
      <div className="text-center">
        <div className="row">
          <div className="col-12">
            <b className="h1">
              Submission {hasUpdated ? "Updated" : "Successful"}!
            </b>
            <p>
              Thank you for taking the time to add an attraction and make
              Dayhoot better for everyone! If you would like to make any changes
              to your recent submission, you will need your reference number
              below so please make note of it otherwise you will not be able to
              retrieve it.
            </p>
            <p>
              Your reference number is{" "}
              <b className="text-green">{attraction.id}</b>
            </p>
          </div>
          <div className="col-md-6 col-lg-3">
            <AddAttractionBanner />
          </div>
          <div className="col-md-6 col-lg-3">
            <ViewSubmissionBanner
              referenceNumber={attraction.id}
              viewSubmission={hasUpdated ? viewSubmission : null}
            />
          </div>
          <div className="col-md-6 col-lg-3">
            <RetrieveSubmissionBanner />
          </div>
          <div className="col-md-6 col-lg-3">
            <HomeBanner />
          </div>
        </div>
      </div>
    </div>
  );
}

function Banner({ link, click, bannerClass, icon, text }) {
  const content = (
    <div
      className={`${bannerClass}-banner-container`}
      style={{ borderRadius: "0.75em" }}
    >
      <div className="d-flex flex-row align-items-center">
        <div className="pb-2 pt-2 pr-1 ml-auto">
          <FontAwesomeIcon
            className="text-white"
            icon={icon}
            style={{ fontSize: "1.8rem" }}
          />
        </div>
        <div className="pt-2 pb-2 pl-1 mr-auto flex-shrink-1">
          <span className="h3 text-white wordbreak" style={{ fontSize: "1rem" }}>
            {text}
          </span>
        </div>
      </div>
    </div>
  );
  if (click)
    return (
      <div onClick={click} style={{ cursor: "pointer" }}>
        {content}
      </div>
    );
  else return <NavLink to={link}>{content}</NavLink>;
}

export function AddAttractionBanner() {
  return (
    <Banner
      link={"/attraction/submit"}
      bannerClass={"add-attraction"}
      icon={faHatWizard}
      text={"Submit a Dayhoot"}
    />
  );
}

export function RetrieveSubmissionBanner() {
  return (
    <Banner
      link={"/retrieveAttraction"}
      bannerClass={"retrieve-attraction"}
      icon={faUserEdit}
      text={"Retrieve Submission"}
    />
  );
}

export function ViewSubmissionBanner({ referenceNumber, viewSubmission }) {
  return (
    <Banner
      link={viewSubmission ? null : `/retrieveAttraction/${referenceNumber}`}
      click={viewSubmission}
      bannerClass={"view-submission"}
      icon={faChevronRight}
      text={"Edit Submission"}
    />
  );
}

export function HomeBanner() {
  return (
    <Banner link={"/"} bannerClass={"home"} icon={faHome} text={"Go Home"} />
  );
}
