// Package Imports
import React, { Component } from "react";

// Dayhoot Components
import { InfoPageBodyContent, Policies } from "../components/infoPage-components";

// Dayhoot Functions & Scripts
import { setPageTitleText } from "../scripts/utils";


class Privacy extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPageLoaded: false,
		};
	}

	componentDidMount() {
		setPageTitleText("Policies");
		this.setState({ isPageLoaded: true });
	}

	render() {
		return (
			<div>
				<InfoPageBodyContent
					id={"policies"}
					title={"Dayhoot Policies"}
					bodyHtml={<Policies />}
				/>
			</div>
		);
	}
}

export default Privacy;