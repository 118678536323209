// Package Imports
import React from 'react';

// Dayhoot Functions & Scripts
import { RatingIcon } from '../components/ratings-components';
import { getUuid } from '../scripts/utils';

// Fonts & Icons
import { faPoundSign } from '@fortawesome/free-solid-svg-icons';

export function convertRatingToIcons(rating, icon, css, popoverText){
    let ratingActive = [];
    const uuid = getUuid();
    for (var i = 1; i < 6; i++) {
        const className = i <= rating ? 'rating-icon-active' : (css ? `rating-icon-${css}` : 'rating-icon');
        ratingActive.push((<RatingIcon key={i + uuid} data={{id: rating + uuid, icon: icon, className: className, popoverContent: popoverText }} />))
    }
    return ratingActive;
}

export function setPriceRatingPopoverText(price, name){
    const hasName = name && name !== "";
    const attractionName = hasName ? name : 'This attraction';
    switch (price){
        case 0:
            return `${attractionName} is free!`;
        case 1:
            return `${attractionName} is priced between £0.01 and £4.99.`;
        case 2:
            return `${attractionName} is priced between £5.00 and £14.99.`;
        case 3:
            return `${attractionName} is priced between £15.00 and £29.99.`;
        case 4:
            return `${attractionName} is priced between £30.00 and £49.99.`;
        case 5:
            return `${attractionName} is priced over £50.00.`;
        case "default":
        default:
            return `${attractionName} does not have a price specified.`
    }
}

export function setPriceRatingBadgeContent(price, name){
    if (price > 0){
        const priceRangePopoverText = setPriceRatingPopoverText(price, name);
        return convertRatingToIcons(price, faPoundSign, 'success', priceRangePopoverText);
    }
    else {
        return (
            <b>
                <span className="text-white pr-4 pl-4" style={{fontSize: '0.85rem'}}>Free</span>
            </b>
        )
    }
}

