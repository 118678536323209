import { checkUrlHasHttp, pluralze } from "./utils";
import { findIconDefinition } from "@fortawesome/fontawesome-svg-core";

export function setValidation({
  isRequired = false,
  isLowerCase = false,
  allowWhiteSpace = true,
  minLength = null,
  maxLength = null,
  type = "string",
}) {
  return {
    isRequired: isRequired,
    isLowerCase: isLowerCase,
    allowWhiteSpace: allowWhiteSpace,
    minLength: minLength,
    maxLength: maxLength,
    type: type,
  };
}

export function isValidUrl(url) {
  const sanitisedUrl = checkUrlHasHttp(url) ? url : `http://${url}`;
  try {
    new URL(sanitisedUrl);
  } catch (e) {
    return false;
  }
  return true;
}

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function typeErrorMessage(expectedType, actualType) {
  return `This field is expected to be ${expectedType} but was ${actualType}`;
}

export function isValidPhoneNumber(number) {
  if (number.match(/^(\(?(0|\+44)[1-9]{1}\d{1,4}?\)?\s?\d{3,4}\s?\d{3,4})$/))
    return true;
  else return false;
}

export function isLowerCase(str) {
  return str === str.toLowerCase() && str !== str.toUpperCase();
}

export function hasWhiteSpace(str) {
  return str.indexOf(" ") >= 0;
}

export function hasValidIconSelected(icon) {
  return icon && icon.prefix !== "" && icon.iconName !== ""
    ? !!findIconDefinition(icon)
    : false;
}
export function isInputValid(validationConfig, input, value) {
  const config = validationConfig[input];
  if (!config){}
  let errors = [];
  if (
    config.isRequired &&
    (!value || (Array.isArray(value) && value.length === 0))
  ) {
    errors.push("This field is required.");
  }

  if (config.minLength > 0) {
    if (!value || value.length < config.minLength)
      errors.push(
        `This field must be a minimum of ${
          config.minLength
        } character${pluralze(config.minLength)}.`
      );
  }

  if (!!value) {
    if (config.maxLength) {
      if (value.length > config.maxLength)
        errors.push(
          `This field must be a maximum of ${
            config.maxLength
          } character${pluralze(config.maxLength)}.`
        );
    }

    if (config.isLowerCase) {
      if (!isLowerCase(value)) {
        errors.push("This field must be lowercase.");
      }
    }

    if (!config.allowWhiteSpace) {
      if (hasWhiteSpace(value)) {
        errors.push("This field must not have spaces.");
      }
    }

    if (config.type) {
      if (config.type === "string") {
        if (typeof value !== "string")
          errors.push(typeErrorMessage("string", typeof value));
      } else if (config.type === "array") {
        if (!Array.isArray(value))
          errors.push(typeErrorMessage("array", typeof value));
      } else if (config.type === "number") {
        if (typeof value !== "number")
          errors.push(typeErrorMessage("number", typeof value));
      } else if (config.type === "url") {
        if (!isValidUrl(value)) {
          errors.push("Please enter a valid URL.");
        }
      } else if (config.type === "email") {
        if (!validateEmail(value))
          errors.push("Please enter a valid Email Address.");
      } else if (config.type === "phone") {
        if (!isValidPhoneNumber(value))
          errors.push("Please enter a valid UK Phone Number.");
      } else if (config.type === "icon") {
        if (!hasValidIconSelected(value))
          errors.push("A valid icon must be selected.");
      }
    }
  }

  return errors;
}
