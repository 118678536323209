import {
  cookieDictionary,
  deleteCookie,
  getCookie,
  setCookie,
} from "../storage/cookies";

export function setAdminAuthenticationDetails(cookies, response) {
  setCookie(cookies, cookieDictionary.AdminToken.key, response.token);
  setCookie(cookies, cookieDictionary.AdminTokenExpires.key, response.expires);
  return getAdminAuthenticationDetails(cookies);
}

export function unsetAdminAuthenticationDetails(cookies) {
  deleteCookie(cookies, cookieDictionary.AdminToken.key);
  deleteCookie(cookies, cookieDictionary.AdminTokenExpires.key);
  return getAdminAuthenticationDetails(cookies);
}

export function getAdminAuthenticationDetails(cookies) {
  const token = getCookie(cookies, cookieDictionary.AdminToken.key);
  if (!!token) {
    const tokenExpires = getCookie(
      cookies,
      cookieDictionary.AdminTokenExpires.key
    );
    unsetAdminAuthenticationIfExpired(cookies, token, tokenExpires);
  }

  return {
    token: getCookie(cookies, cookieDictionary.AdminToken.key),
    tokenExpires: getCookie(cookies, cookieDictionary.AdminTokenExpires.key),
  };
}

function unsetAdminAuthenticationIfExpired(cookies, token, expires) {
  if (!!token) {
    if (!expires || new Date() > new Date(expires)) {
      unsetAdminAuthenticationDetails(cookies);
    }
  }
}
