// Package Imports
import { FormControl, FormGroup, RadioGroup } from "@material-ui/core";

// Dayhoot Config
import { filterType } from "../config/enums";

// Dayhoot Scripts
import { getFiltersToShow } from "../component-functions/filterPanel-core";

// Dayhoot Components
import { CheckboxInput, RadioButton } from "./inputs";

// Icons & Fonts
import { faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SectionFilter({ data }) {
	const { filter, hideToggle, handleFilterChange } = data;
	const {
		key,
		value,
		selectedValue,
		type,
		label,
		isToggleOpen,
		isPreSelected,
	} = filter;
	const isSelected = selectedValue === value;
	const hideInput = hideToggle && !isSelected;

	switch (type) {
		case filterType.Radio:
			return (
				<RadioButton
					key={key}
					value={value}
					selectedValue={selectedValue}
					label={label}
					isOpen={isToggleOpen}
				/>
			);
		case filterType.Checkbox:
			return (
				<CheckboxInput
					key={key}
					value={value}
					isDisabled={isPreSelected}
					id={value}
					selectedValue={selectedValue}
					label={label}
					isOpen={isToggleOpen}
					handleChange={handleFilterChange}
					hideInput={hideInput}
				/>
			);
		default:
			return null;
	}
}

function SectionFilters({ data }) {
	const { hideToggle, filters, handleFilterChange } = data;
	const filtersToShow = getFiltersToShow({ hideToggle, filters });
	return filtersToShow.map((filter, i) => {
		return (
			<SectionFilter
				key={filter + i}
				data={{
					filter: filter,
					hideToggle: hideToggle,
					handleFilterChange: handleFilterChange,
				}}
			/>
		);
	});
}

function FilterGroup({ data }) {
	const {
		type,
		hideToggle,
		filters,
		filterGroupLabel,
		selectedFilterValue,
		handleFilterChange,
	} = data;

	switch (type) {
		case filterType.Radio:
			return (
				<RadioGroup
					aria-label={filterGroupLabel}
					name={filterGroupLabel}
					value={selectedFilterValue}
					onChange={handleFilterChange.bind(this)}
				>
					<SectionFilters
						data={{
							hideToggle: hideToggle,
							filters: filters,
							handleFilterChange: handleFilterChange,
						}}
					/>
				</RadioGroup>
			);
		case filterType.Checkbox:
			return (
				<FormGroup>
					<SectionFilters
						data={{
							hideToggle: hideToggle,
							filters: filters,
							handleFilterChange: handleFilterChange,
						}}
					/>
				</FormGroup>
			);
		default:
			return null;
	}
}

function FilterToggleButton({ data }) {
	const { hideToggle, toggleClick, toggleButtonContent } = data;
	if (!hideToggle) {
		return (
			<button className="noStyle filter-title" onClick={() => toggleClick()}>
				{toggleButtonContent}
				<span className="accessibility-item">Filter Expand and Collapse</span>
			</button>
		);
	} else return null;
}

function FilterSectionHeader({ data }) {
	const { filterGroupTitle, toggleButtonContent, toggleClick, hideToggle } =
		data;
	return (
		<div className="d-flex">
			<div className="p-0 flex-grow-1">
				<button className="noStyle filter-title" onClick={() => toggleClick()}>
					<b>{filterGroupTitle}</b>
				</button>
			</div>
			<div className="ml-auto pl-2">
				<FilterToggleButton
					data={{
						hideToggle: hideToggle,
						toggleClick: toggleClick,
						toggleButtonContent: toggleButtonContent,
					}}
				/>
			</div>
		</div>
	);
}

function FilterSectionBody({ isOpen, hasSelectedFilters, filterGroupDto }) {
	if (isOpen || hasSelectedFilters) {
		return (
			<FormControl component="fieldset">
				<legend className="accessibility-item">Sort by</legend>
				<FilterGroup data={filterGroupDto} />
			</FormControl>
		);
	} else return null;
}

function FilterSection({
	toggleClick,
	hasToggle,
	buttonContent,
	selectedFilterValue,
	isOpen,
	filterGroupTitle,
	filterGroupDto,
	hideToggle,
}) {
	const toggleButtonContent = hasToggle ? buttonContent : null;
	return (
		<div className="row">
			<div className="col-12 p-0 pb-2">
				<div className="col-12">
					<FilterSectionHeader
						data={{
							filterGroupTitle: filterGroupTitle,
							toggleButtonContent: toggleButtonContent,
							toggleClick: toggleClick,
							hideToggle: hideToggle,
						}}
					/>
				</div>
				<div className="col-12">
					<FilterSectionBody
						isOpen={isOpen}
						hasSelectedFilters={selectedFilterValue.length > 0}
						filterGroupDto={filterGroupDto}
					/>
				</div>
			</div>
		</div>
	);
}

export function FilterList({
	filterPanel,
	button,
	filters,
	isOpen,
	hideToggle,
}) {
	const {
		type,
		title,
		hasToggle,
		label,
		selectedFilterValue,
		handleFilterChange,
	} = filterPanel;

	return (
		<FilterSection
			toggleClick={button.handleButtonClick}
			hasToggle={hasToggle}
			type={type}
			hideToggle={hideToggle}
			selectedFilterValue={selectedFilterValue}
			isOpen={isOpen}
			filterGroupTitle={title || "No Title Provided"}
			filterGroupDto={{
				type: type,
				hideToggle: hideToggle,
				filters: filters,
				filterGroupLabel: label || "No Label Provided",
				selectedFilterValue: selectedFilterValue,
				handleFilterChange: handleFilterChange,
			}}
			buttonContent={button.buttonContent}
		/>
	);
}

export function FilterPanelHeader({ closePanelClick }) {
	return (
		<div className="card-header bg-success">
			<div className="d-flex align-items-center">
				<div className="p-0 flex-shrink-0">
					<FontAwesomeIcon className="mr-2 text-white" icon={faFilter} />
					<b className="text-white">Filters</b>
				</div>
				<div className="ml-auto pl-2 flex-shrink-0">
					<FontAwesomeIcon
						className="text-white"
						icon={faTimes}
						onClick={closePanelClick.bind(this)}
						style={{ cursor: "pointer" }}
					/>
				</div>
			</div>
		</div>
	);
}
