import { Component } from "react";
import { withCookies } from "react-cookie";
import { Redirect } from "react-router";
import { AdminSection } from "../components/admin-core-components";
import { PageLoading } from "../components/common-components";
import RouterBreadcrumbs from "../components/routerBreadcrumbs";
import { AdminAreaConfigs } from "../config/adminPaths";
import { getAdminAuthenticationDetails } from "../scripts/admin-auth";
import { setPageTitleText } from "../scripts/utils";

class AdminArea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPageLoaded: false,
      isAuthenticated: false,
      token: null,
      adminArea: null,
    };
  }

  getAdminArea() {
    const pathnames = this.props.location.pathname.split("/").filter((x) => x);
    return AdminAreaConfigs.find(
      (x) => x.value === pathnames[pathnames.length - 1]
    );
  }

  async componentDidMount() {
    const adminArea = this.getAdminArea();
    setPageTitleText(`Admin ${adminArea.label}`);

    const { token } = getAdminAuthenticationDetails(this.props.cookies);

    this.setState({
      isPageLoaded: true,
      isAuthenticated: !!token,
      token: token,
      adminArea: adminArea,
    });
  }

  render() {
    if (this.state.isPageLoaded) {
      if (this.state.isAuthenticated) {
        return (
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1>{this.state.adminArea.label}</h1>
                <RouterBreadcrumbs pathName={this.props.location.pathname} />
                <div className="card">
                  <div className="card-body">
                    <AdminSection config={this.state.adminArea} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else return <Redirect to="/admin" />;
    } else return <PageLoading />;
  }
}

export default withCookies(AdminArea);
