// Dayhoot Functions & Scripts
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { attractionPageParams, attractionsFilterType } from "../config/enums";
import { generateStringJoinCharacter } from "../scripts/api-utils";
import { isEmptyObject } from "jquery";
import { checkIsValueInArray } from "../scripts/utils";
import { WebConfig } from "../config/webconfig";

function getRandomAttractionDescriptionStart(attraction) {
  const randomInt = Math.floor(Math.random() * Math.floor(6));
  switch (randomInt) {
    case 0:
      return `Find out what's going on at ${attraction.name} and plan an incredible Dayhoot!`;
    case 1:
      return `See what's happening over at ${attraction.name} and have a Dayhoot!`;
    case 2:
      return `Have a Dayhoot at the ${attraction.mainCategory.displayName}, ${attraction.name}!`;
    case 3:
      return `Explore all that ${attraction.name} has to offer and plan an outstanding Dayhoot!`;
    case 4:
      return `Plan a dayhoot at ${attraction.name} to beat those COVID blues!`;
    case 5:
      return `Have an expedition exploring ${attraction.name} Today, with Dayhoot!`;
    case 6:
      return `Take the family to see ${attraction.name} and make it a Dayhoot to remember!`;
    default:
      return `Have a Dayhoot at ${attraction.name}!`;
  }
}

export function getAttractionDescription(props) {
  const { attraction } = props;
  return attraction.detailedView
    ? attraction.description
    : getRandomAttractionDescriptionStart(attraction);
}

function mapFilter({
  filter,
  elementName,
  filterName,
  selectedFilters,
  pageFilter,
}) {
  filter.elementName = elementName;
  filter.filterName = filterName;
  filter.elementId = `${filter.elementName}-${filter.filterName}`;
  filter.isFilterSelected =
    Array.isArray(selectedFilters) &&
    checkIfFilterValueIsInArray(selectedFilters, filterName);
  filter.isPageFilter = filter === pageFilter;
  return filter;
}

async function mapCategoryFilters({
  allCategories,
  filtersToSelect,
  pagePreSelectValue = null,
}) {
  return await allCategories.map((filter, i) => {
    return mapFilter({
      filter: filter,
      elementName: "filterCategory",
      filterName: filter.categoryName,
      selectedFilters: filtersToSelect,
      pageFilter: pagePreSelectValue,
    });
  });
}

async function mapRegionFilters({
  allRegions,
  filtersToSelect,
  pagePreSelectValue = null,
}) {
  return await allRegions.map((filter, i) => {
    return mapFilter({
      filter: filter,
      elementName: "filterRegion",
      filterName: filter.regionName,
      selectedFilters: filtersToSelect,
      pageFilter: pagePreSelectValue,
    });
  });
}

async function mapFacilityFilters({
  allFacilities,
  filtersToSelect,
  pagePreSelectValue = null,
}) {
  return await allFacilities.map((filter, i) => {
    return mapFilter({
      filter: filter,
      elementName: "filterFacility",
      filterName: filter.facilityName,
      selectedFilters: filtersToSelect,
      pageFilter: pagePreSelectValue,
    });
  });
}

async function setFilterValue(newValue, stateFilters) {
  let updatedFilterValue = stateFilters;

  // If the value is already in the array in the array, we want to remove it
  if (checkIsValueInArray(stateFilters, newValue)) {
    updatedFilterValue.splice(updatedFilterValue.indexOf(newValue), 1);
  }
  // If the selected filters come in as an array,
  //    we want to update the array with the distinct values
  else if (Array.isArray(newValue)) {
    newValue.forEach(value => {
      if (!checkIfFilterValueIsInArray(updatedFilterValue, value))
        updatedFilterValue.push(value);
    });
  } else updatedFilterValue.push(newValue);

  return updatedFilterValue;
}

function checkIsPreSelectMissing(filters, value) {
  return Array.isArray(filters) && !checkIsValueInArray(filters, value);
}

export function tryGetPreSelectValue({ preSelect, type }) {
  if (preSelect) {
    if (type === attractionsFilterType.Category) return preSelect.category;
    else if (type === attractionsFilterType.Region) return preSelect.region;
    else if (type === attractionsFilterType.Facility) return preSelect.facility;
    else return null;
  } else return null;
}

async function trySetPreSelectValue({ value, stateFilters }) {
  if (value) {
    if (checkIsPreSelectMissing(stateFilters, value))
      return await setFilterValue(value, stateFilters);
    else return await stateFilters;
  } else return await stateFilters;
}

export async function prepareFilters({
  preSelect,
  filtersToSelect,
  selectedFilters: existingSelectedFilters,
  pageData,
}) {
  const { categoryFilters, regionFilters, facilityFilters } = filtersToSelect;
  const { categoryFilterValues, regionFilterValues, facilityFilterValues } =
    existingSelectedFilters;

  const selectedFilters = {
    categories: await trySetPreSelectValue({
      value: categoryFilters,
      stateFilters: categoryFilterValues,
    }),
    regions: await trySetPreSelectValue({
      value: regionFilters,
      stateFilters: regionFilterValues,
    }),
    facilities: await trySetPreSelectValue({
      value: facilityFilters,
      stateFilters: facilityFilterValues,
    }),
  };

  return {
    mappedFilters: {
      categories: await mapCategoryFilters({
        allCategories: pageData.categories,
        filtersToSelect: selectedFilters.categories,
        pagePreSelectValue: tryGetPreSelectValue({
          preSelect: preSelect,
          type: attractionsFilterType.Category,
        }),
      }),
      regions: await mapRegionFilters({
        allRegions: pageData.regions,
        filtersToSelect: selectedFilters.regions,
        pagePreSelectValue: tryGetPreSelectValue({
          preSelect: preSelect,
          type: attractionsFilterType.Region,
        }),
      }),
      facilities: await mapFacilityFilters({
        allFacilities: pageData.facilities,
        filtersToSelect: selectedFilters.facilities,
        pagePreSelectValue: tryGetPreSelectValue({
          preSelect: preSelect,
          type: attractionsFilterType.Facility,
        }),
      }),
    },
    selectedFilters: selectedFilters,
  };
}

export function hasOnlyPreSelectFilterSelected({
  preSelect,
  categoryFilterValue,
  regionFilterValue,
  facilityFilterValue,
}) {
  const isSearchPreSelected = preSelect != null && preSelect.search != null;
  const isCategoryPreSelected = preSelect != null && preSelect.category != null;
  const isRegionPreSelected = preSelect != null && preSelect.region != null;
  const isFacilityPreSelected = preSelect != null && preSelect.facility != null;

  const hasCategoryFiltersSelected = categoryFilterValue.length > 0;
  const hasRegionFiltersSelected = regionFilterValue.length > 0;
  const hasFacilityFiltersSelected = facilityFilterValue.length > 0;

  let hasOnlyPreSelectFilterSelected =
    (isSearchPreSelected &&
      !hasCategoryFiltersSelected &&
      !hasRegionFiltersSelected &&
      !hasFacilityFiltersSelected) ||
    (isCategoryPreSelected &&
      categoryFilterValue.length === 1 &&
      categoryFilterValue.some(x => x === preSelect.category)) ||
    (isRegionPreSelected &&
      regionFilterValue.length === 1 &&
      regionFilterValue.some(x => x === preSelect.region)) ||
    (isFacilityPreSelected &&
      facilityFilterValue.length === 1 &&
      facilityFilterValue.some(x => x === preSelect.facility));

  return hasOnlyPreSelectFilterSelected;
}

export function toggleFilterPanelContainer(isFiltersOpen) {
  const newFilterState = !isFiltersOpen;
  const isNewFilterStateOpen = newFilterState === true;

  return {
    isFiltersOpen: newFilterState,
    attractionsListContainerClass: `col-sm-6  ${
      isNewFilterStateOpen ? "" : "col-md-4"
    } mb-2 attractionItemContainer`,
    attractionsContainerClass: isNewFilterStateOpen ? "col-md-8" : "col-12",
    filterPanelContainerDisplay: isNewFilterStateOpen ? "block" : "none",
  };
}

export function swapButton(isOpen) {
  return <FontAwesomeIcon icon={isOpen ? faChevronDown : faChevronUp} />;
}

export function getHasSelectedFilters({
  distanceFilterValue,
  selectedFilters,
}) {
  const isDefaultDistance =
    distanceFilterValue === WebConfig.defaultFilterDistance;
  const hasSelectedCategories = selectedFilters.categories.length > 0;
  const hasSelectedRegions = selectedFilters.regions.length > 0;
  return !isDefaultDistance || hasSelectedCategories || hasSelectedRegions;
}

export function setTitleText({ filters, hasAttractions, search }) {
  let title = "No Dayhoots Found";
  if (hasAttractions) {
    const categoryFilters = filters.categories.filter(
      x => !!x.isFilterSelected
    );
    const regionFilters = filters.regions.filter(x => !!x.isFilterSelected);
    const facilityFilters = filters.facilities.filter(
      x => !!x.isFilterSelected
    );

    title = "Showing All Dayhoots";
    if (
      categoryFilters.length > 0 ||
      regionFilters.length > 0 ||
      facilityFilters.length > 0
    ) {
      // Has a single category selected
      if (
        categoryFilters.length === 1 &&
        regionFilters.length === 0 &&
        facilityFilters.length === 0
      ) {
        title = `Showing ${categoryFilters[0].displayName} Dayhoots`;
      }
      // Has a single region selected
      else if (
        categoryFilters.length === 0 &&
        regionFilters.length === 1 &&
        facilityFilters.length === 0
      ) {
        title = `Showing Dayhoots in ${regionFilters[0].displayName}`;
      }
      // Has a single facility selected
      else if (
        categoryFilters.length === 0 &&
        regionFilters.length === 0 &&
        facilityFilters.length === 1
      ) {
        title = `Showing Dayhoots with ${facilityFilters[0].displayName}`;
      } else if (
        categoryFilters.length === 1 ||
        regionFilters.length === 1 ||
        facilityFilters.length === 1
      ) {
        title =
          "Showing " +
          (categoryFilters.length === 1
            ? categoryFilters[0].displayName
            : "filtered Dayhoots");
        title +=
          " in " +
          (regionFilters.length === 1
            ? regionFilters[0].displayName
            : "multiple regions");
        title +=
          " with " +
          (facilityFilters.length === 1
            ? facilityFilters[0].displayName
            : "selected facilities");
      } else title = "Showing filtered Dayhoots";
    }
    if (search) title += ` matching '${search}'`;
  }
  return title;
}

// This is useful when changing between options from the navbar. For example categories/themepark to categories/zoo without going elsewhere
// We want to re-render the attractions and categories when doing this
export function checkIfPageParamsHaveChanged(prevProps, currProps) {
  const hasCategoryChanged = prevProps.category !== currProps.category;
  const hasRegionChanged = prevProps.region !== currProps.region;
  const hasFacilityChanged = prevProps.facility !== currProps.facility;
  const hasSearchTermChanged = prevProps.search !== currProps.search;
  return (
    hasCategoryChanged ||
    hasRegionChanged ||
    hasFacilityChanged ||
    hasSearchTermChanged
  );
}

export function getAttractionListUrlQueryStringParams(queryString) {
  const params = new URLSearchParams(queryString);
  return {
    page: getIntFromQueryString({
      params: params,
      type: attractionPageParams.Page.name,
    }),
    sortOptionValue: getIntFromQueryString({
      params: params,
      type: attractionPageParams.Sort.name,
    }),
    category: params.getAll(attractionPageParams.Category.name),
    region: params.getAll(attractionPageParams.Region.name),
    facility: params.getAll(attractionPageParams.Facility.name),
    search: getStringFromQueryString({
      params: params,
      type: attractionPageParams.Search.name,
    }),
    withinMiles: getIntFromQueryString({
      params: params,
      type: attractionPageParams.Distance.name,
    }),
  };
}

function getStringFromQueryString({ params, type }) {
  const paramArray = params.getAll(type);
  const value = queryStringFirstResultOrNull(paramArray);
  return isEmptyObject(value) ? null : value;
}

function getIntFromQueryString({ params, type }) {
  const paramArray = params.getAll(type);
  const value = queryStringFirstResultOrNull(paramArray);
  return value ? parseInt(value) : null;
}

function queryStringFirstResultOrNull(valueArray) {
  return valueArray.length === 0 ? null : valueArray[0];
}

export function getPreSelectedCategoryName(preSelect, mappedFilters) {
  if (preSelect && preSelect.category != null) {
    return mappedFilters.categories.find(
      c => c.categoryName === preSelect.category
    );
  } else return null;
}

export function getPreSelectedRegionName(preSelect, mappedFilters) {
  if (preSelect && preSelect.region != null) {
    return mappedFilters.regions.find(r => r.regionName === preSelect.region);
  } else return null;
}

export function getPreSelectedFacilityName(preSelect, mappedFilters) {
  if (preSelect && preSelect.facility != null) {
    return mappedFilters.facilities.find(
      f => f.facilityName === preSelect.facility
    );
  } else return null;
}

function shouldJoinParam({ preSelect, type, value }) {
  if (preSelect) {
    if (type === attractionsFilterType.Category)
      return preSelect.category !== value;
    else if (type === attractionsFilterType.Region)
      return preSelect.region !== value;
    if (type === attractionsFilterType.Facility)
      return preSelect.facility !== value;
    else if (type === attractionsFilterType.Search)
      return preSelect.search !== value;
    else return true;
  } else return true;
}

function isSearchPreSelected(preSelect) {
  return !preSelect || (preSelect && !preSelect.search);
}

function buildQueryString({
  page,
  sortOptionValue,
  categories,
  regions,
  facilities,
  search,
  withinMiles,
  preSelect,
}) {
  let queryString = "";

  if (page) {
    const joinChar = generateStringJoinCharacter(queryString);
    queryString += `${joinChar}page=${page}`;
  }

  if (sortOptionValue) {
    const joinChar = generateStringJoinCharacter(queryString);
    queryString += `${joinChar}sort=${sortOptionValue}`;
  }

  if (categories.length > 0) {
    categories.forEach((c, i) => {
      const shouldJoin = shouldJoinParam({
        preSelect: preSelect,
        type: attractionsFilterType.Category,
        value: c,
      });
      if (shouldJoin) {
        const joinChar =
          i === 0 ? generateStringJoinCharacter(queryString) : "&";
        queryString += `${joinChar}category=${c}`;
      }
    });
  }

  if (regions.length > 0) {
    regions.forEach((r, i) => {
      const shouldJoin = shouldJoinParam({
        preSelect: preSelect,
        type: attractionsFilterType.Region,
        value: r,
      });
      if (shouldJoin) {
        const joinChar =
          i === 0 ? generateStringJoinCharacter(queryString) : "&";
        queryString += `${joinChar}region=${r}`;
      }
    });
  }

  if (facilities.length > 0) {
    facilities.forEach((f, i) => {
      const shouldJoin = shouldJoinParam({
        preSelect: preSelect,
        type: attractionsFilterType.Facility,
        value: f,
      });
      if (shouldJoin) {
        const joinChar =
          i === 0 ? generateStringJoinCharacter(queryString) : "&";
        queryString += `${joinChar}facility=${f}`;
      }
    });
  }

  if (!isEmptyObject(search) && isSearchPreSelected(preSelect)) {
    const joinChar = generateStringJoinCharacter(queryString);
    queryString += `${joinChar}search=${search}`;
  }

  if (withinMiles) {
    const joinChar = generateStringJoinCharacter(queryString);
    queryString += `${joinChar}distance=${withinMiles}`;
  }

  return queryString;
}

function handleFilterSelection({ filterToHandle, currentValues }) {
  let filterList = currentValues;
  if (filterToHandle) {
    if (checkIfFilterValueIsInArray(filterList, filterToHandle)) {
      filterList
        .filter(filter => filter === filterToHandle)
        .forEach(filter => {
          const filterIndex = filterList.indexOf(filter);
          filterList.splice(filterIndex, 1);
        });
    } else filterList.push(filterToHandle);
  }

  return filterList;
}

function tryReturnFilterUpdate({
  currentValues,
  filterToHandle,
  hasFilterChanged,
}) {
  return hasFilterChanged
    ? handleFilterSelection({
        filterToHandle: filterToHandle,
        currentValues: currentValues,
      })
    : currentValues;
}

export function handleFilterChangeQueryUrl({
  queryStringParams,
  filterType,
  filterValue: filterToHandle,
  categoryFilterValues,
  regionFilterValues,
  facilityFilterValues,
  preSelect,
}) {
  return buildQueryString({
    page: 1,
    sortOptionValue: queryStringParams.sortOptionValue,
    categories: tryReturnFilterUpdate({
      currentValues: categoryFilterValues,
      filterToHandle: filterToHandle,
      hasFilterChanged: filterType === attractionsFilterType.Category,
    }),
    regions: tryReturnFilterUpdate({
      currentValues: regionFilterValues,
      filterToHandle: filterToHandle,
      hasFilterChanged: filterType === attractionsFilterType.Region,
    }),
    facilities: tryReturnFilterUpdate({
      currentValues: facilityFilterValues,
      filterToHandle: filterToHandle,
      hasFilterChanged: filterType === attractionsFilterType.Facility,
    }),
    search: queryStringParams.search,
    withinMiles: queryStringParams.withinMiles,
    preSelect: preSelect,
  });
}

export function handleWithinMilesChangeQueryUrl({
  queryStringParams,
  withinMiles,
  preSelect,
}) {
  return buildQueryString({
    page: 1,
    sortOptionValue: queryStringParams.sortOptionValue,
    categories: queryStringParams.category,
    regions: queryStringParams.region,
    facilities: queryStringParams.facility,
    search: queryStringParams.search,
    withinMiles: withinMiles,
    preSelect: preSelect,
  });
}

export function handleChangePageQueryUrl({
  queryStringParams,
  page,
  preSelect,
}) {
  return buildQueryString({
    page: page,
    sortOptionValue: queryStringParams.sortOptionValue,
    categories: queryStringParams.category,
    regions: queryStringParams.region,
    facilities: queryStringParams.facility,
    search: queryStringParams.search,
    withinMiles: queryStringParams.withinMiles,
    preSelect: preSelect,
  });
}

export function handleSortOptionChangeQueryUrl({
  queryStringParams,
  sortOptionValue,
  preSelect,
}) {
  return buildQueryString({
    page: 1,
    sortOptionValue: sortOptionValue,
    categories: queryStringParams.category,
    regions: queryStringParams.region,
    facilities: queryStringParams.facility,
    search: queryStringParams.search,
    withinMiles: queryStringParams.withinMiles,
    preSelect: preSelect,
  });
}

function checkIfFilterValueIsInArray(filters, value) {
  return filters.find(f => f === value);
}

// This gets the values to filter on from the pre-select on the page as well as
//    the params passed in the url query string
export function getFilterValues({ preSelectValue, urlValues, resetFilters }) {
  let propsToFilter = [];
  if (
    preSelectValue &&
    !checkIfFilterValueIsInArray(propsToFilter, preSelectValue)
  ) {
    propsToFilter.push(preSelectValue);
  }
  if (urlValues.length > 0) {
    urlValues.forEach(urlValue => {
      if (resetFilters)
        propsToFilter.splice(propsToFilter.indexOf(urlValue), 1);
      else if (!checkIfFilterValueIsInArray(propsToFilter, urlValue))
        propsToFilter.push(urlValue);
    });
  }
  return propsToFilter;
}

function checkIfQueryStringContainsFilters(queryString) {
  return (
    queryString.includes("category=") ||
    queryString.includes("region=") ||
    queryString.includes("facility=") ||
    queryString.includes("distance=")
  );
}

export function checkIfShouldResetFilters(prevQueryString, currQueryString) {
  return (
    checkIfQueryStringContainsFilters(prevQueryString) &&
    !checkIfQueryStringContainsFilters(currQueryString)
  );
}

export function setPageRenderData({ prevProps, currProps }) {
  const { location: currLocation, match: currMatch } = currProps;
  const { location: prevLocation, match: prevMatch } = prevProps;

  const prevQueryString = prevLocation.search;
  const currQueryString = currLocation.search;

  const shouldResetFilters = checkIfShouldResetFilters(
    prevQueryString,
    currQueryString
  );

  const hasPageRouteChanged = checkIfPageParamsHaveChanged(
    prevMatch.params,
    currMatch.params
  );

  return {
    resetFilters: shouldResetFilters || hasPageRouteChanged,
    updateQueryString: prevQueryString !== currQueryString,
  };
}

function validateFilterValue({ resetFilters, preSelectValue, filter }) {
  if (resetFilters && preSelectValue) return filter === preSelectValue;
  else if (resetFilters && isEmptyObject(preSelectValue)) return false;
  else return true;
}

export function getSelectedFilters({
  filterValues,
  preSelectedFilter,
  resetFilters,
}) {
  return filterValues.filter(filter => {
    return validateFilterValue({
      resetFilters: resetFilters,
      preSelectValue: preSelectedFilter,
      filter: filter,
    });
  });
}
