// Package Imports
import {
  faChevronRight,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { NavLink } from "react-router-dom";

// Fonts & Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Dayhoot Functions & Scripts
import { viewWebsite } from "../scripts/utils";
import {
  calculateAttractionGroupLimit,
  calculateAttractionGroupTileColSize,
} from "../component-functions/attractionGroup-core";

export function AttractionGroupTileContainer({ group, colWidth }) {
	const {
	  groupId,
	  displayName,
	  branding,
	} = group;
  const { primaryColour, secondaryColour, textColour } = branding;

  return (
    <div className={`${colWidth} pb-2`}>
      <NavLink key={groupId} to={`/group/${groupId}`}>
        <div
          className="h-100 ml-0 mr-0 pt-3 pb-3 row"
          style={{
            backgroundColor: primaryColour,
            borderLeft: `solid 15px ${secondaryColour}`,
          }}
        >
          <div className="col-12 mt-2 mb-2">
            <p className="h2" style={{ color: textColour }}>
              {displayName}
            </p>
          </div>
        </div>
      </NavLink>
    </div>
  );
}

export function AttractionGroupBannerContainer({ group, colWidth }) {
  const {
    groupId,
    displayName,
    branding,
    websiteUrl,
    description,
    numberOfAttractions,
  } = group;
  const { primaryColour, secondaryColour, textColour } = branding;

  return (
    <div className={`${colWidth} pb-2`}>
      <div
        className="h-100 ml-0 mr-0 row"
        style={{
          backgroundColor: primaryColour,
          borderLeft: `solid 15px ${secondaryColour}`,
        }}
      >
        <div className="col-12 mt-2 mb-2">
          <p className="h2" style={{ color: textColour }}>
            {displayName}
          </p>
          <p
            className="apply-ellipsis-3 wordbreak"
            style={{ color: textColour }}
          >
            {description}
          </p>
        </div>
        <div className={`col-12 pb-3 mt-auto`}>
          <hr
            className="mb-2"
            style={{ border: `solid 1px ${secondaryColour}` }}
          />
          <div className="d-flex flex-row align-items-center">
            <div className="p-0 flex-fill">
              <span
                onClick={() => viewWebsite(websiteUrl)}
                style={{ cursor: "pointer" }}
              >
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  style={{ color: textColour }}
                />
                <b className="pl-2" style={{ color: textColour }}>
                  Visit Website
                </b>
              </span>
            </div>
            <div className="ml-auto pl-2">
              <NavLink key={groupId} to={`/group/${groupId}`}>
                <b className="pr-2" style={{ fontSize: 13, color: textColour }}>
                  Explore All {numberOfAttractions} Attractions
                </b>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  style={{ color: textColour }}
                />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function AttractionGroupHeaderContainer({ group, colWidth }) {
  const { displayName, branding, websiteUrl, description } = group;
  const { primaryColour, secondaryColour, textColour } = branding;

  return (
    <div className={`${colWidth} pb-2`}>
      <div
        className="h-100 ml-0 mr-0 row"
        style={{
          backgroundColor: primaryColour,
          borderLeft: `solid 15px ${secondaryColour}`,
        }}
      >
        <div className="col-12 mt-2 mb-2">
          <p className="h2" style={{ color: textColour }}>
            {displayName}
          </p>
          <p
            className="apply-ellipsis-3 wordbreak"
            style={{ color: textColour }}
          >
            {description}
          </p>
        </div>
        <div className={`col-12 pb-3 mt-auto`}>
          <hr
            className="mb-2"
            style={{ border: `solid 1px ${secondaryColour}` }}
          />
          <div className="d-flex flex-row align-items-center">
            <div className="p-0 flex-fill">
              <span
                onClick={() => viewWebsite(websiteUrl)}
                style={{ cursor: "pointer" }}
              >
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  style={{ color: textColour }}
                />
                <b className="pl-2" style={{ color: textColour }}>
                  Visit Website
                </b>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function AttractionGroupTileList({ groups, limit }) {
  const groupLimit = limit
    ? calculateAttractionGroupLimit(limit)
    : groups.length;
  const colWidth = calculateAttractionGroupTileColSize(groupLimit);
  const componentsToRender = groups.map((group, i) => {
    if (i < groupLimit) {
      return (
        <AttractionGroupTileContainer
          key={group.groupId}
          group={group}
          colWidth={colWidth}
        />
      );
    } else return null;
  });
  return <div className="row">{componentsToRender}</div>;
}
