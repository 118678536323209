// Dayhoot Config
import { attractionsFilterType } from '../config/enums';
import { distanceFilterList } from '../config/attractionFilters';

export function getFiltersToShow(props){
    const { hideToggle, filters } = props;
    return filters
        .filter(f => {
            // We want to show selected filters, even if the toggle is closed
            if (hideToggle && Array.isArray(f.selectedValue)){
                return f.selectedValue.some(sv => sv === f.value)
            } 
            // Otherwise the toggle is open and we show it anyway
            else return true;
        });
}

// This takes specific array details from a dto and maps it to one which will work with filters
export function mapInputValuesToFilterArray(filterConfig){
    const { arrayToMap, selectedValue, type, defaultValue, isToggleOpen, isPreSelected } = filterConfig;
    const filterArray = [];
    
    // If a default value is passed, we want to add this to the array first
    if (defaultValue != null)
        filterArray.push({ key: defaultValue.key, value: defaultValue.value, selectedValue: selectedValue, type: type, label: defaultValue.label, isToggleOpen: isToggleOpen, isPreSelected: isPreSelected });

    // Then we want to map all other values which are passed to the array
    arrayToMap.forEach((val, i) => {
        const { key, value, label, isPreSelected } = arrayToMap[i];        
        filterArray.push({ key: key, value: value, selectedValue: selectedValue, type: type, label: label, isToggleOpen: isToggleOpen, isPreSelected: isPreSelected });
    });

    return filterArray;
}

// This brings back the filter name until we standardise it as "name" in the database
function getFilterName(filter, filterType){
    switch (filterType){
        case attractionsFilterType.Category:
            return filter.categoryName;
        case attractionsFilterType.Region:
            return filter.regionName;
        default: return null;
    }
}

export function updateFilterSelection(filters, filterType, selectedFilters){
    filters.forEach(filter => {
        const filterName = getFilterName(filter, filterType);
        const isSelected = selectedFilters.find(selectedFilter => filterName === selectedFilter) != null;
        filter.isFilterSelected = isSelected;
        return filter;
    })
    return filters;
}

export function mapDistanceEnumsToFilters(){
    return distanceFilterList.map((filter, i) => {
        const { name, displayName } = filter;
        return { 
            key: i + name, 
            value: name, 
            label: displayName 
        };
    });
  }