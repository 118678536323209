import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { ReactElement } from "react";
import { NavLink } from "react-router-dom";

type ExpandedListItemProps = {
  id: string;
  to: string;
  icon: any;
  text: string;
};

const ExpandedListItem = ({
  id,
  to,
  icon,
  text,
}: ExpandedListItemProps): ReactElement => {
  return (
    <>
      <NavLink key={id} to={to} exact>
        <ListItem button key={text}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      </NavLink>
    </>
  );
};
export default ExpandedListItem;
