import { ReactElement, ReactNode, useEffect, useState } from "react";
import { Banners, BannerTypes, CarouselBannerProps } from "../../config";

export type CarouselItemProps = {
  banner: BannerTypes;
  children?: ReactNode;
  text?: string;
};

const CarouselItem = ({
  banner,
  children,
  text,
}: CarouselItemProps): ReactElement | null => {
  const [item, setItem] = useState<CarouselBannerProps | undefined>(
    Banners[banner]
  );
  useEffect(() => {
    setItem(Banners[banner]);
    return () => setItem(undefined);
  }, [banner]);

  return item ? (
    <div data-selectedslide={banner}>
        {item.image && (
          <img
            src={item.image}
            alt={item.altText}
            style={{ cursor: item.path ? "pointer" : "default" }}
          />
        )}
        {text && <p className="legend">{text}</p>}
        {children}
    </div>
  ) : null;
};
export default CarouselItem;
