import { ReactElement } from "react";
import { Menu as MenuIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from ".";

type AnchorTypes = "top" | "right" | "bottom" | "left";

type MenuButtonProps = {
  toggleDrawer: (anchor: AnchorTypes, open: boolean) => (event: any) => void;
  drawerState: boolean;
  drawerPosition: any;
};

const useStyles = makeStyles((theme) => {
  return {
    icon: {
      fill: theme.palette.primary.contrastText,
    },
  };
});

const MenuButton = ({
  toggleDrawer,
  drawerState,
  drawerPosition,
}: MenuButtonProps): ReactElement => {
  const classes = useStyles();
  return (
    <IconButton
      edge="end"
      aria-label="show more"
      aria-controls={"test"}
      aria-haspopup="true"
      color="inherit"
      onClick={toggleDrawer(drawerPosition, !drawerState)}
    >
      <MenuIcon className={classes.icon} />
    </IconButton>
  );
};
export default MenuButton;
