import { Component } from "react";
import { PageLoading } from "../components/common-components";
import {
  flattenString,
  getCreateEditPageType,
  setPageTitleText,
} from "../scripts/utils";
import {
  getAllCategories,
  getAttractionGroup,
  createAttractionGroup,
  updateAttractionGroup,
} from "../scripts/api-core";
import RouterBreadcrumbs from "../components/routerBreadcrumbs";
import { attractionGroupValidationConfig } from "../config/manageAttraction-config";
import { isInputValid } from "../scripts/validation";
import { createEditType } from "../config/enums";
import {
  NameSection,
  DescriptorInput,
  WebsiteInput,
  MainCategoryInput,
  BrandingPicker,
} from "../components/admin-createEdit-attractiongroup-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faSave,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router";
import { getMainCategoryDropdownOptions } from "../component-functions/manageAttraction-core";
import { withCookies } from "react-cookie";
import { getAdminAuthenticationDetails } from "../scripts/admin-auth";

class CreateEditAttractionGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPageLoaded: false,
      isAuthenticated: false,
      token: null,
      attractionGroup: null,
      groupId: {
        value: "",
        isDirty: false,
        errors: isInputValid(attractionGroupValidationConfig, "groupId", ""),
      },
      displayName: {
        value: "",
        isDirty: false,
        errors: isInputValid(
          attractionGroupValidationConfig,
          "displayName",
          ""
        ),
      },
      websiteUrl: {
        value: "",
        isDirty: false,
        errors: isInputValid(attractionGroupValidationConfig, "websiteUrl", ""),
      },
      mainCategory: {
        value: "",
        isDirty: false,
        errors: isInputValid(
          attractionGroupValidationConfig,
          "mainCategory",
          []
        ),
      },
      description: {
        value: "",
        isDirty: false,
        errors: isInputValid(
          attractionGroupValidationConfig,
          "description",
          ""
        ),
      },
      primaryColour: {
        value: "#FFFFFF",
        isDirty: false,
        errors: [],
      },
      secondaryColour: {
        value: "#FFFFFF",
        isDirty: false,
        errors: [],
      },
      textColour: {
        value: "#FFFFFF",
        isDirty: false,
        errors: [],
      },
      hasUpdated: false,
      hasCreated: false,
      categories: [],
      categoryDropdownOptions: null,
      isEdit: false,
    };
  }

  async componentDidMount() {
    const {
      isAuthenticated,
      token,
      pageType,
      isEdit,
      categories,
      categoryDropdownOptions,
    } = await this.renderData();

    setPageTitleText(`${pageType.displayName} Attraction Group`);

    let attractionGroup = this.state.attractionGroup;

    if (isEdit) {
      attractionGroup = await getAttractionGroup(
        this.props.match.params.groupId
      );
      this.mapAttractionGroupToProperties({ attractionGroup });
    }

    this.setState({
      isPageLoaded: true,
      isAuthenticated,
      token,
      pageType,
      categories,
      categoryDropdownOptions,
      isEdit,
    });
  }

  async renderData() {
    const { cookies, location } = this.props;
    const { token } = getAdminAuthenticationDetails(cookies);
    const pageType = getCreateEditPageType(location.pathname);
    const isEdit = pageType === createEditType.Edit;
    const { results: categories } = await getAllCategories();
    const categoryDropdownOptions = getMainCategoryDropdownOptions(categories);

    return {
      isAuthenticated: !!token,
      token: token,
      pageType: pageType,
      isEdit: isEdit,
      categories: categories,
      categoryDropdownOptions: categoryDropdownOptions,
    };
  }

  mapAttractionGroupToProperties({ attractionGroup }) {
    const {
      groupId,
      displayName,
      websiteUrl,
      mainCategory,
      description,
      branding,
    } = attractionGroup;

    const { primaryColour, secondaryColour, textColour } = branding;

    this.setState({
      groupId: this.getAttractionGroup({
        value: groupId,
        isDirty: false,
        fieldName: "groupId",
      }),
      displayName: this.getAttractionGroup({
        value: displayName,
        isDirty: false,
        fieldName: "displayName",
      }),
      websiteUrl: this.getAttractionGroup({
        value: websiteUrl,
        isDirty: false,
        fieldName: "websiteUrl",
      }),
      mainCategory: this.getAttractionGroup({
        value: mainCategory,
        isDirty: false,
        fieldName: "mainCategory",
      }),
      description: this.getAttractionGroup({
        value: description,
        isDirty: false,
        fieldName: "description",
      }),
      primaryColour: this.getAttractionGroup({
        value: primaryColour,
        isDirty: false,
        fieldName: "primaryColour",
      }),
      secondaryColour: this.getAttractionGroup({
        value: secondaryColour,
        isDirty: false,
        fieldName: "secondaryColour",
      }),
      textColour: this.getAttractionGroup({
        value: textColour,
        isDirty: false,
        fieldName: "textColour",
      }),
    });
  }

  mapPropertiesToAttractionGroup() {
    const {
      groupId,
      displayName,
      websiteUrl,
      mainCategory,
      description,
      primaryColour,
      secondaryColour,
      textColour,
    } = this.state;

    const newAttractionGroup = {
      groupId: flattenString(groupId.value.toLowerCase()),
      displayName: displayName.value,
      websiteUrl: websiteUrl.value,
      mainCategory: mainCategory.value,
      description: description.value,
      branding: {
        primaryColour: primaryColour.value,
        secondaryColour: secondaryColour.value,
        textColour: textColour.value,
      },
    };

    this.setState({ attractionGroup: newAttractionGroup });

    return newAttractionGroup;
  }

  async saveAttractionGroup() {
    this.setState({ isPageLoaded: false });
    const attractionGroup = this.mapPropertiesToAttractionGroup();
    const { token } = getAdminAuthenticationDetails(this.props.cookies);
    const updatedAttractionGroup = this.state.isEdit
      ? await updateAttractionGroup(attractionGroup, token)
      : await createAttractionGroup(attractionGroup, token);
    try {
      this.setState({
        attractionGroup: updatedAttractionGroup,
        hasUpdated: this.state.isEdit ? true : false,
        hasCreated: this.state.isEdit ? false : true,
        isPageLoaded: true,
      });
    } catch {
      this.setState({ isPageLoaded: true });
      console.error(
        "This Attraction Group was not updated. Please contact us for assistance."
      );
    }
  }

  getAttractionGroup({ value, isDirty, fieldName }) {
    return {
      value: value,
      isDirty: isDirty,
      errors: isInputValid(attractionGroupValidationConfig, fieldName, value),
    };
  }

  render() {
    if (this.state.isPageLoaded) {
      const {
        isAuthenticated,
        pageType,
        displayName,
        groupId,
        websiteUrl,
        mainCategory,
        description,
        hasUpdated,
        hasCreated,
        categoryDropdownOptions,
        primaryColour,
        secondaryColour,
        textColour,
        isEdit,
      } = this.state;

      if (isAuthenticated) {
        const inputs = [
          displayName,
          groupId,
          websiteUrl,
          mainCategory,
          description,
        ];

        const hasErrors = inputs.some((x) => {
          return (
            displayName.errors.length > 0 ||
            groupId.errors.length > 0 ||
            websiteUrl.errors.length > 0 ||
            mainCategory.errors.length > 0 ||
            description.errors.length > 0
          );
        });
        return (
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1>{pageType.displayName} Attraction Group</h1>
                <RouterBreadcrumbs
                  pathName={this.props.location.pathname}
                  pageItemLabel={displayName.value}
                />
                <div className="card">
                  <div className="card-body">
                    <div>
                      <h2>Attraction Group Details</h2>
                      {hasUpdated || hasCreated ? (
                        <div className="alert alert-success">
                          <div className="d-flex flex-row align-items-center">
                            <div className="p-0">
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="pl-1">
                              <span>
                                This Attraction Group has been successfully{" "}
                                {hasUpdated
                                  ? "updated"
                                  : hasCreated
                                  ? "created"
                                  : ""}
                                !
                              </span>
                            </div>
                            {hasUpdated ? (
                              <div className="ml-auto pl-1">
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  className="hyperlink"
                                  onClick={() =>
                                    this.setState({ hasUpdated: false })
                                  }
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                      {hasCreated ? (
                        <Redirect to="/admin/attractiongroups/all" />
                      ) : null}
                      <NameSection
                        groupId={groupId}
                        displayName={displayName}
                        onChange={(name, val) => {
                          if (name === "displayName" && !isEdit) {
                            this.setState({
                              groupId: this.getAttractionGroup({
                                value: flattenString(val.toLowerCase()),
                                isDirty: true,
                                fieldName: "groupId",
                              }),
                            });
                          }
                          this.setState({
                            [name]: this.getAttractionGroup({
                              value: val,
                              isDirty: true,
                              fieldName: name,
                            }),
                          });
                        }}
                        isEdit={pageType === createEditType.Edit}
                      />
                      <DescriptorInput
                        description={description}
                        onChange={(name, val) =>
                          this.setState({
                            [name]: this.getAttractionGroup({
                              value: val,
                              isDirty: true,
                              fieldName: name,
                            }),
                          })
                        }
                      />
                      <div className="row">
                        <div className="col-md-7">
                          <WebsiteInput
                            websiteUrl={websiteUrl}
                            onChange={(name, val) =>
                              this.setState({
                                [name]: this.getAttractionGroup({
                                  value: val,
                                  isDirty: true,
                                  fieldName: name,
                                }),
                              })
                            }
                          />
                        </div>
                        <div className="col-md-5">
                          <MainCategoryInput
                            mainCategory={mainCategory}
                            options={categoryDropdownOptions}
                            onChange={(name, val) =>
                              this.setState({
                                [name]: {
                                  value: val,
                                  isValid: val !== "N/A",
                                  isDirty: true,
                                  errors:
                                    !val || val === "N/A"
                                      ? ["A main category must be selected."]
                                      : [],
                                },
                              })
                            }
                          />
                        </div>
                      </div>
                      <BrandingPicker
                        primaryColour={primaryColour}
                        secondaryColour={secondaryColour}
                        textColour={textColour}
                        isEdit={isEdit}
                        onChange={(name, hexColour) =>
                          this.setState({
                            [name]: {
                              value: hexColour,
                              isDirty: true,
                              errors: [],
                            },
                          })
                        }
                      />
                      <div className="d-flex flex-row align-items-center">
                        <div className="ml-auto mr-auto p-0 pt-3">
                          <button
                            className={`btn btn-primary ${
                              hasErrors ? "disabled" : ""
                            }`}
                            onClick={() => {
                              if (!hasErrors) this.saveAttractionGroup();
                            }}
                          >
                            <FontAwesomeIcon icon={faSave} />
                            <span className="ml-1">Save Changes</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else return <Redirect to="/admin/attractiongroups/all" />;
    } else return <PageLoading />;
  }
}

export default withCookies(CreateEditAttractionGroup);
