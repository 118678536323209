import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { getGreeting, getNumberOfMatchingAttractions } from "..";
import { SelectBox } from ".";
import { attractionListSortTypeEnums } from "../../../config/enums";
import { PageTitle } from "../../../components";
import { useHistory } from "react-router-dom";

type AttractionFinderProps = {
  noOfAttractions: number;
  regions: Array<any>;
  categories: Array<any>;
  goToAttractions: (queryString: string) => void;
};

const AttractionFinder = ({
  categories,
  regions,
  noOfAttractions: initialNoOfAttractions,
  goToAttractions,
}: AttractionFinderProps) => {
  const [category, setCategory] = useState<any>("");
  const [region, setRegion] = useState<any>("");
  const [noOfAttractions, setNoOfAttractions] = useState(
    initialNoOfAttractions
  );

  let history = useHistory();

  useEffect(() => {
    async function fetchAttractions() {
      setNoOfAttractions(
        await getNumberOfMatchingAttractions({ category, region })
      );
    }
    fetchAttractions();
  }, [category, region]);

  const greeting = getGreeting();
  const isInitialNumber = noOfAttractions === initialNoOfAttractions;
  
  return (
    <div className="card card-body">
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <PageTitle headingId="page-greeting" headingText={greeting} />
        </Grid>
        <Grid item xs={12}>
          <p
            className="d-block d-sm-none d-md-block"
            style={{ fontSize: "0.8em", marginBottom: "0.8em" }}
          >
            With hundreds of Dayhoots to choose from, we've got something for
            everyone. Jump in and take a look!
          </p>
          <p
            className="d-none d-sm-block d-md-none"
            style={{ fontSize: "0.8em", marginBottom: "0.8em" }}
          >
            Have a look at some incredible UK Dayhoots!
          </p>
        </Grid>
        <Grid item xs={12} md={4} lg={12}>
          <SelectBox
            label="Category"
            property={category}
            properties={categories}
            onChange={setCategory}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={12}>
          <SelectBox
            label="Region"
            property={region}
            properties={regions}
            onChange={setRegion}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={12}>
          <button
            className="btn btn-primary mt-1"
            onClick={() => {
              let queryString = "";
              const hasCategory = category && category !== "";
              const hasRegion = region && region !== "";
              if (hasCategory) {
                queryString += `?category=${category.categoryName}`;
              }
              if (hasRegion) {
                queryString += hasCategory ? "&" : "?";
                queryString += `region=${region.regionName}&sort=${attractionListSortTypeEnums.MostPopular.value}`;
              }
              history.push(`/attractions${queryString}`);
            }}
            disabled={noOfAttractions === 0}
            style={{ width: "100%" }}
          >
            <span>
              Explore {noOfAttractions}
              {(isInitialNumber ? " Dayhoot" : " Matching Dayhoot") +
                (noOfAttractions === 1 ? "" : "s")}
            </span>
          </button>
        </Grid>
      </Grid>
    </div>
  );
};
export default AttractionFinder;
