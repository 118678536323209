
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type AttractionCardFooterLinkProps = {
  icon: any;
  text: string;
};

const AttractionCardFooterLink = ({
  icon,
  text,
}: AttractionCardFooterLinkProps) => {
  return (
    <>
      <span>{text}</span>
      <FontAwesomeIcon icon={icon} className="ml-2" />
    </>
  );
};
export default AttractionCardFooterLink;
