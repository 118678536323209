// Package Imports
import { Route, BrowserRouter, NavLink, Switch } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { cookieDictionary, getCookie } from "../storage/cookies";

// Fonts & Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

// Pages
import Home from "../pages/Home/home";
import Attractions from "../pages/Attractions/allAttractions";
import Groups from "../pages/groups";
import AttractionEdit from "../pages/attraction-edit";
import Categories from "../pages/categories";
import Facilities from "../pages/facilities";
import Regions from "../pages/regions";
import Info from "../pages/info";
import ErrorPage from "../pages/error404";
import AttractionGroup from "../pages/attractionGroup";

// These can all come out when we replace the dashboard
import Attraction from "../pages/attraction";
import CreateEditCategory from "../pages/admin-createEdit-category";
import CreateEditRegion from "../pages/admin-createEdit-region";
import CreateEditAttractionGroup from "../pages/admin-createEdit-attractiongroup";
import AdminHome from "../pages/admin-home";
import AdminArea from "../pages/admin-area";
import AdminList from "../pages/admin-list";
import CreateEditFacility from "../pages/admin-createEdit-facility";
import DeleteFacility from "../pages/admin-delete-facility";
import { AdminLinks } from "../config/adminPaths";
import ManageSocial from "../pages/admin-managesocial";

// This is for the new admin area
// import Admin from "../pages/Admin/Admin";

// These will be replaced when we can get metadata returned with the lists
//

import ManageAttraction from "../pages/manageAttraction";

// Dayhoot Functions & Scripts
//import Navbar from "../components/navbar";
import AppBar from "./AppBar/AppBar";
import { WebConfig } from "../config/webconfig";

// Dayhoot Components
import { PageLoading, HoldingPage } from "../components/common-components";
import { CookiesNotice } from "./cookies-components";
import SiteMap from "../pages/sitemap";
import ScrollToTop from "./scrollToTop";
import RetrieveSubmittedAttraction from "../pages/retrieveSubmittedAttraction";
import { ThemeProvider } from "@material-ui/core/styles";
import { dayhootTheme } from "../styling/theme";

export function MainPageContent({
  isPageLoaded,
  props,
  promptUserToSetLocation,
  cookies,
}) {
  if (WebConfig.isInHoldingPageMode) {
    return <HoldingPage />;
  } else if (isPageLoaded) {
    return (
      <>
        <MainPage
          props={props}
          promptUserToSetLocation={promptUserToSetLocation}
          cookies={cookies}
        />
      </>
    );
  } else {
    return <PageLoading />;
  }
}

function MainPage({ props, cookies }) {
  return (
    <CookiesProvider>
      <BrowserRouter>
        <ThemeProvider theme={dayhootTheme}>
          <div className="pageContainer">
            <ScrollToTop />
            <AppBar
              isUsingGeolocation={getCookie(
                props.cookies,
                cookieDictionary.SettingShouldUseGeolocation.key
              )}
              userLocation={
                getCookie(
                  props.cookies,
                  cookieDictionary.SettingUserLocationName
                ) || WebConfig.defaultLocation
              }
              cookies={cookies}
            />
            <BodyContent />
            <footer className="footer" id="footer">
              <FooterContent />
            </footer>
            <CookiesNotice cookiesNoticeDto={props} />
          </div>
        </ThemeProvider>
      </BrowserRouter>
    </CookiesProvider>
  );
}

function BodyContent() {
  return (
    <div className="content" style={{ marginTop: "90px" }}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/attraction/create/" component={AttractionEdit} />
        <Route path="/attraction/submit/" component={AttractionEdit} />
        <Route
          path="/attraction/edit/:attractionId"
          component={AttractionEdit}
        />
        <Route
          path="/attraction/editsubmission/:submissionId"
          component={AttractionEdit}
        />
        <Route
          path="/attraction/:attractionId"
          exact
          component={AttractionEdit}
        />
        <Route path="/categories/" exact component={Categories} />
        <Route path="/facilities/" exact component={Facilities} />
        <Route path="/group/:groupId" exact component={AttractionGroup} />
        <Route path="/groups" exact component={Groups} />
        <Route path="/regions/" exact component={Regions} />
        {/* Attraction List */}
        <Route path="/categories/:category" component={Attractions} />
        <Route path="/regions/:region" component={Attractions} />
        <Route path="/facilities/:facility" component={Attractions} />
        <Route path="/attractions/" exact component={Attractions} />
        <Route path="/search/:search" component={Attractions} />
        {/* Info */}
        <Route path="/about/" component={Info} />
        <Route path="/policies/" exact component={Info} />
        <Route path="/policies/privacy/" exact component={Info} />
        <Route path="/policies/cookies/" exact component={Info} />
        <Route path="/policies/accessibility/" exact component={Info} />
        <Route path="/contact/" component={Info} />
        <Route path="/settings/" component={Info} />

         {/* Admin Root Pages */}
         <Route path={AdminLinks.Attractions.Root} exact component={AdminArea} />
        <Route path={AdminLinks.Regions.Root} exact component={AdminArea} />
        <Route
          path={AdminLinks.AttractionSubmissions.Root}
          exact
          component={AdminArea}
        />
        <Route
          path={AdminLinks.AttractionSubmissions.Root + "/:submissionId"}
          exact
          component={AdminList}
        />
        <Route path={AdminLinks.Categories.Root} exact component={AdminArea} />
        <Route
          path={AdminLinks.AttractionGroups.Root}
          exact
          component={AdminArea}
        />
        <Route path={AdminLinks.Facilities.Root} exact component={AdminArea} />
 
        {/* Creates */}
        <Route
          path={AdminLinks.Categories.Create}
          exact
          component={CreateEditCategory}
        />
        <Route
          path={AdminLinks.Regions.Create}
          exact
          component={CreateEditRegion}
        />
        <Route
          path={AdminLinks.AttractionGroups.Create}
          exact
          component={CreateEditAttractionGroup}
        />
        <Route
          path={AdminLinks.Facilities.Create}
          exact
          component={CreateEditFacility}
        />
 
        <Route
          path={"/attraction/:attractionId"}
          exact
          component={AttractionEdit}
        />
        <Route
          path={AdminLinks.Attractions.ManageSocial + "/:attractionId"}
          exact
          component={ManageSocial}
        />
        <Route
          path={AdminLinks.AttractionSubmissions.Edit + "/:attractionId"}
          exact
          component={ManageAttraction}
        />
        <Route
          path={AdminLinks.Categories.Edit + "/:categoryName"}
          exact
          component={CreateEditCategory}
        />
        <Route
          path={AdminLinks.Regions.Edit + "/:regionName"}
          exact
          component={CreateEditRegion}
        />
        <Route
          path={AdminLinks.AttractionGroups.Edit + "/:groupId"}
          exact
          component={CreateEditAttractionGroup}
        />
        <Route
          path={AdminLinks.Facilities.Edit + "/:facilityName"}
          exact
          component={CreateEditFacility}
        />
        <Route
          path={AdminLinks.Facilities.Delete + "/:facilityName"}
          exact
          component={DeleteFacility}
        />
 
        {/* Other */}
        <Route path="/sitemap/" component={SiteMap} />
        <Route
          path="/retrieveAttraction/:attractionId"
          exact
          component={ManageAttraction}
        />
        <Route
          path="/retrieveAttraction/"
          component={RetrieveSubmittedAttraction}
        />
 
        {/* Admin List Pages */}
        <Route path={AdminLinks.Attractions.All + "/"} component={AdminList} />
        <Route
          path={AdminLinks.Attractions.All + "/:attractionId"}
          component={Attraction}
        />
 
        <Route
          path={AdminLinks.Attractions.Active + "/"}
          component={AdminList}
        />
        <Route
          path={AdminLinks.Attractions.Active + "/:attractionId"}
          component={Attraction}
        />
 
        <Route
          path={AdminLinks.Attractions.Inactive + "/"}
          component={AdminList}
        />
        <Route
          path={AdminLinks.Attractions.Inactive + "/:attractionId"}
          component={Attraction}
        />
 
        <Route
          path={AdminLinks.AttractionSubmissions.Approved + "/"}
          component={AdminList}
        />
        <Route
          path={AdminLinks.AttractionSubmissions.Rejected + "/"}
          component={AdminList}
        />
        <Route
          path={AdminLinks.AttractionSubmissions.AwaitingApproval + "/"}
          component={AdminList}
        />
        <Route path={AdminLinks.Categories.All + "/"} component={AdminList} />
        <Route path={AdminLinks.Regions.All + "/"} component={AdminList} />
        <Route
          path={AdminLinks.AttractionGroups.All + "/"}
          component={AdminList}
        />
        <Route path={AdminLinks.Facilities.All + "/"} component={AdminList} />

        {/* Admin */}
        <Route path="/admin" component={AdminHome} />
        {/* Creates */}

        {/* Other */}
        <Route path="/sitemap/" component={SiteMap} />
        <Route
          path="/retrieveAttraction/:attractionId"
          exact
          component={ManageAttraction}
        />
        <Route
          path="/retrieveAttraction/"
          component={RetrieveSubmittedAttraction}
        />
        <Route path="*" component={ErrorPage} />
      </Switch>
    </div>
  );
}
function FooterContent() {
  return (
    <div className="container">
      <div className="row m-0" style={{ width: "100%" }}>
        <div className="col-md-4 text-center text-md-left p-0 m-0 d-none d-md-block ">
          <span style={{ verticalAlign: "middle" }}>
            <a
              href={WebConfig.facebookUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon className="footer-icon" icon={faFacebook} />
              <span className="accessibility-item">Facebook</span>
            </a>
            <a
              href={WebConfig.twitterUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon className="footer-icon" icon={faTwitter} />
              <span className="accessibility-item">Twitter</span>
            </a>

            <a
              href={WebConfig.instagramUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon className="footer-icon" icon={faInstagram} />
              <span className="accessibility-item">Instagram</span>
            </a>
          </span>
        </div>
        <div className="col-md-4 text-nowrap text-center text-md-center p-0 m-0">
          <span className="footer-text" style={{ cursor: "default" }}>
            &copy; {new Date().getFullYear()} The Dayhoot Project. All Rights
            Reserved.{" "}
            {WebConfig.enviroment !== "prod" ? (
              <span>- {WebConfig.enviroment}</span>
            ) : null}
          </span>
        </div>
        <div className="col-md-4 text-center text-md-right p-0 m-0 d-none d-md-block ">
          <CookiesProvider>
            <NavLink
              className="footer-text"
              key="settings"
              to="/settings/"
              exact
            >
              <FontAwesomeIcon icon={faCog} />
              <span className="pl-1">Settings</span>
            </NavLink>
          </CookiesProvider>
        </div>
      </div>
    </div>
  );
}
