import { ReactElement, ReactNode } from "react";
import {
  Drawer,
  Toolbar,
  Box,
} from "@mui/material";

type SidebarProps = {
  children?: ReactNode;
};

const Sidebar = ({ children }: SidebarProps): ReactElement => {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 240,
        flexShrink: 0,
        zIndex: theme => theme.zIndex.appBar - 1,
        [`& .MuiDrawer-paper`]: {
          width: 240,
          boxSizing: "border-box",
          zIndex: theme => theme.zIndex.appBar - 1,
        },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>{children}</Box>
    </Drawer>
  );
};
Sidebar.displayName = "Sidebar";
export default Sidebar;
