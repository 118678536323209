export async function getValue(key) {
	return localStorage.getItem(key);
}

export async function setValue(key, value) {
	localStorage.setItem(key, value);
}

export async function deleteValue(key) {
	localStorage.removeItem(key);
}

export async function clearAppStorage() {
	localStorage.clear();
}