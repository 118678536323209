import { WebConfig } from "./webconfig";

function determineEnvHost() {
  switch (WebConfig.enviroment) {
    case "prod":
      return "https://api.dayhoot.co.uk";
    case "localstack":
      return "http://localhost:9000";
    case "local":
    case "dev":
    case "test":
    default:
      return "https://api.dayhoot.co.uk/test";
  }
}

export const attractionServiceUrl = `${determineEnvHost()}/attraction-service/v2`;
export const managementServiceUrl = `${determineEnvHost()}/management-service/v1`;
export const marketingServiceUrl = `${determineEnvHost()}/marketing-service/v1`;
export const localInformationUrl =
  "https://api.bigdatacloud.net/data/reverse-geocode-client";
export const postcodesUrl = "http://api.postcodes.io/postcodes/";

export const attractionsUrl = `${attractionServiceUrl}/attractions`;
export const categoriesUrl = `${attractionServiceUrl}/categories`;
export const regionsUrl = `${attractionServiceUrl}/regions`;
export const tilesUrl = `${attractionServiceUrl}/tiles`; // Not currently used in the system
export const attractionGroupsUrl = `${attractionServiceUrl}/attractionGroups`;
export const facilitiesUrl = `${attractionServiceUrl}/facilities`;

export const authenticateAdminUrl = `${managementServiceUrl}/authenticate`;
export const managementcategoriesUrl = `${managementServiceUrl}/categories`;
export const managementregionsUrl = `${managementServiceUrl}/regions`;
export const attractionManageUrl = `${managementServiceUrl}/attractions`;
export const attractionSubmissionsUrl = `${managementServiceUrl}/submissions`;
export const facilityManagementUrl = `${managementServiceUrl}/facilities`;
export const managementfeaturedBannerUrl = `${managementServiceUrl}/banner/featured/`;
export const managementattractionGroupsUrl = `${managementServiceUrl}/attractionGroups`;

export const marketingfeaturedBannerUrl = `${marketingServiceUrl}/banner/featured/`;
export const emailSignupUrl = `${marketingServiceUrl}/email/signup`;

// Returns a single attraction
export function getAttractionUrl(attractionId) {
  return `${attractionsUrl}/${attractionId}`;
}

export function getSubmittedAttractionUrl(attractionId) {
  return getAttractionUrl(attractionId);
}

// Returns a url for the external URL that tracks the click to their website
export function getAttractionTrackableExternalUrl(attractionId) {
  return `${attractionsUrl}/${attractionId}/track`;
}

// Returns a single category record from the category name passed in
export function getSingleCategoryUrl(categoryName) {
  return `${categoriesUrl}/${categoryName}`;
}

// Returns a list of attractions with a single region filter
export function getAttractionRegionUrl(regionName) {
  return `${attractionsUrl}?region=${regionName}`;
}

// Returns a single region record from the region name passed in
export function getSingleRegionUrl(regionName) {
  return `${regionsUrl}/${regionName}`;
}

// Returns a single attraction group from the group Id passed in
export function getSingleAttractionGroupUrl(groupId) {
  return `${attractionGroupsUrl}/${groupId}`;
}

// Returns a list of attractions with a single category filter
export function getAttractionCategoryUrl(categoryName) {
  return `${attractionsUrl}?category=${categoryName}`;
}

export function getSingleFacilityUrl(facilityName) {
  return `${facilityManagementUrl}/${facilityName}`;
}

// Gets information about the local area according to the lat and long passed in
export function getLocalInformationUrl(coordinates) {
  // The coordinates only work to 4 decimal places
  const latitude = coordinates.latitude.toFixed(4);
  const longitude = coordinates.longitude.toFixed(4);

  // For now we'll stick with English but we might want to change this later on
  const localityLanguage = "en";

  return `${localInformationUrl}?latitude=${latitude}&longitude=${longitude}&localityLanguage=${localityLanguage}`;
}

export function getLocationDetailsByPostcodeUrl(postcode) {
  return `${postcodesUrl}${postcode}`;
}

export function validatePostcodeUrl(postcode) {
  return `${postcodesUrl}${postcode}/validate`;
}

export function getGoogleLocationDataUrl(attractionId) {
  return `${managementServiceUrl}/attractions/${attractionId}/fetchGoogleData`;
}
