import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import LazyLoad from "react-lazyload";
import {
  setPriceRatingBadgeContent,
  convertRatingToIcons,
} from "../component-functions/attraction-core";
import { getIconFromCategoryName } from "../component-functions/categories-core";
import {
  checkUrlHasHttp,
  getAttractionItemDistanceAway,
  getUuid,
} from "../scripts/utils";
import { Button } from "../components/common-components";
import { PageTitle } from "../components";
import { DayhootStandardModal } from "../components/modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faDesktop,
  faEnvelope,
  faExclamationCircle,
  faHeart,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import imagePlaceholder from "../img/image-placeholder.jpg";
import { AttractionsList } from ".";

export function AttractionBody({
  attraction,
  attractions,
  cookies,
  isDetailsOverflow,
  showDetailsModal,
  setShowDetailsModal,
  isPreview,
}) {
  return (
    <>
      <PageTitle headingText={attraction.name} />
      <div className="infoBody">
        <div>
          <AttractionDetailsContainer
            attraction={attraction}
            cookies={cookies}
            isVisible={true}
            isDetailsOverflow={isDetailsOverflow}
            showDetailsModal={showDetailsModal}
            setShowDetailsModal={setShowDetailsModal}
          />
          <AttractionFacilities facilities={attraction.facilities} />
          <AttractionMapContainer attraction={attraction} isVisible={true} />
          <AttractionRelatedAttractionsContainer
            attraction={attraction}
            attractions={attractions}
            cookies={cookies}
            isVisible={true}
          />
          <AttractionTagContainer
            attraction={attraction}
            cookies={cookies}
            isPreview={isPreview}
          />
        </div>
      </div>
    </>
  );
}

export function AttractionNav({ data }) {
  const pageButtons = data.buttons.map((button, i) => (
    <PageNavButton key={button.id} data={button} />
  ));

  return <div className="d-flex">{pageButtons}</div>;
}

function PageNavButton({ data }) {
  const { id, index, buttonClick, activeTabClass, displayName, pullRight } =
    data;
  const buttonDto = {
    id: id,
    buttonClass: `pageNav pageNavButton ${activeTabClass}`,
    buttonClick: buttonClick,
    buttonText: displayName,
  };

  return (
    <div
      className={`${pullRight ? "ml-auto " : ""} ${index > 0 ? "pl-2" : "p-0"}`}
    >
      <Button data={buttonDto} />
    </div>
  );
}

export function AttractionMapContainer({ attraction, isVisible }) {
  const displayStyle = isVisible ? "block" : "none";

  return (
    <div id="attractionMapContainer" style={{ display: displayStyle }}>
      <div className="container pt-3">
        <PageTitle
          subHeadingId={attraction.id}
          subHeadingText={`Where to find ${attraction.name}`}
        />
        <div className="row">
          <div className="col-12 pb-3">
            <div id="attractionMap"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function AttractionRelatedAttractionsContainer({
  hideTitle,
  attraction,
  attractions,
  cookies,
  isVisible,
}) {
  const displayStyle = isVisible ? "block" : "none";

  return (
    <div
      id="attractionMoreAttractionsContainer"
      style={{ display: displayStyle }}
    >
      {hideTitle ? null : (
        <PageTitle
          subHeadingId={attraction.id || getUuid()}
          subHeadingText={`Related Dayhoots like ${attraction.name}`}
        />
      )}
      <AttractionsList
        attractions={attractions}
        cookies={cookies}
        isAttractionPage={true}
      />
    </div>
  );
}

export function AttractionImage({ image, alt, url, cleanUrl }) {
  const [imageSrc, setImageSrc] = React.useState(image);
  const [isValid, setIsValid] = React.useState(true);

  useEffect(() => setImageSrc(image), [image]);

  return (
    <div className="row">
      <div className="col-12 mb-3">
        <LazyLoad once={true}>
          <img
            className="attraction-image"
            src={imageSrc}
            alt={alt}
            onLoad={() => {
              if (isValid) setImageSrc(image);
            }}
            onError={() => {
              setIsValid(false);
              setImageSrc(imagePlaceholder);
            }}
          />
          {cleanUrl ? (
            <em className="image-attribution">
              This image is being served from{" "}
              <b>
                <a href={url}>{cleanUrl}</a>
              </b>
            </em>
          ) : null}
        </LazyLoad>
      </div>
    </div>
  );
}

function AttractionDescription({ data }) {
  const { isDetailsOverflow, setShowDetailsModal, description } = data;
  const descriptionClass = `attraction-description-force-overflow ${
    isDetailsOverflow ? "clickable" : ""
  }`;
  return (
    <div className="d-flex flex-column">
      <div className="p-0">
        {description ? (
          <p
            id="description"
            className={descriptionClass}
            onClick={() =>
              isDetailsOverflow ? setShowDetailsModal(true) : null
            }
          >
            {description}
          </p>
        ) : (
          <div className="d-flex flex-row align-items-center">
            <div className="p-0">
              <FontAwesomeIcon
                className="h1 mt-2 missing-description"
                icon={faExclamationCircle}
              />
            </div>
            <div className="pl-2 pb-3">
              <span className="missing-description">
                This attraction doesn't yet have a description. If you would
                like to request or submit one, please{" "}
                <NavLink className="hyperlink" to={"../contact/"}>
                  Contact Us
                </NavLink>{" "}
                and we'll get it sorted!
              </span>
            </div>
          </div>
        )}
      </div>
      {isDetailsOverflow ? (
        <div className="p-0">
          <div className="d-flex flex-row align-items-center">
            <div className="ml-auto mr-auto pb-3">
              <AttractionDescriptionShowMoreButton
                data={{
                  isVisible: isDetailsOverflow,
                  setShowDetailsModal: setShowDetailsModal,
                }}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

function WebsiteLink() {
  return (
    <>
      <FontAwesomeIcon icon={faDesktop}></FontAwesomeIcon>
      <span className="pl-2 hyperlink">Visit the Website</span>
    </>
  );
}

export function AttractionWebsite({ url, isPreview }) {
  if (isPreview) {
    return <WebsiteLink />;
  } else {
    const sanitisedUrl = checkUrlHasHttp(url) ? url : `http://${url}`;
    return (
      <a href={sanitisedUrl} target="_blank" rel="noreferrer">
        <WebsiteLink />
      </a>
    );
  }
}
function PhoneLink({ phoneNumber }) {
  return (
    <>
      <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>
      <span className="pl-2">Call on </span>
      <span className="hyperlink">{phoneNumber}</span>
    </>
  );
}

export function AttractionPhoneNumber({ phoneNumber, isPreview }) {
  if (phoneNumber != null) {
    if (isPreview) {
      return <PhoneLink phoneNumber={phoneNumber} />;
    }
    return (
      <a href={`tel:${phoneNumber}`}>
        <PhoneLink phoneNumber={phoneNumber} />
      </a>
    );
  } else return null;
}

function EmailLink({ emailAddress }) {
  return (
    <>
      <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
      <span className="pl-2">Email at </span>
      <span className="hyperlink">{emailAddress}</span>
    </>
  );
}

export function AttractionEmail({ emailAddress, isPreview }) {
  if (emailAddress != null) {
    if (isPreview) {
      return <EmailLink emailAddress={emailAddress} />;
    }
    return (
      <a href={`mailto: ${emailAddress}`}>
        <EmailLink emailAddress={emailAddress} />
      </a>
    );
  } else return null;
}

export function AttractionFacebook({ facebookAccount, isPreview }) {
  if (facebookAccount != null) {
    if (isPreview) {
      return <FacebookLink facebookAccount={facebookAccount} />;
    }
    return (
      <a
        href={`http://www.facebook.com/${facebookAccount}`}
        target="_blank"
        rel="noreferrer"
      >
        <FacebookLink facebookAccount={facebookAccount} />
      </a>
    );
  } else return null;
}

function FacebookLink({ facebookAccount }) {
  return (
    <>
      <FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon>
      <span className="pl-2 hyperlink">{facebookAccount}</span>
    </>
  );
}

export function AttractionTwitter({ twitterAccount, isPreview }) {
  if (twitterAccount != null) {
    if (isPreview) {
      return <TwitterLink twitterAccount={twitterAccount} />;
    }
    return (
      <a
        href={`http://www.twitter.com/${twitterAccount}`}
        target="_blank"
        rel="noreferrer"
      >
        <TwitterLink twitterAccount={twitterAccount} />
      </a>
    );
  } else return null;
}

function TwitterLink({ twitterAccount }) {
  return (
    <>
      <FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon>
      <span className="pl-2 hyperlink">{twitterAccount}</span>
    </>
  );
}

export function AttractionInstagram({ instagramAccount, isPreview }) {
  if (instagramAccount != null) {
    if (isPreview) {
      return <InstagramLink instagramAccount={instagramAccount} />;
    }
    return (
      <a
        href={`http://www.instagram.com/${instagramAccount}`}
        target="_blank"
        rel="noreferrer"
      >
        <InstagramLink instagramAccount={instagramAccount} />
      </a>
    );
  } else return null;
}

function InstagramLink({ instagramAccount }) {
  return (
    <>
      <FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon>
      <span className="pl-2 hyperlink">{instagramAccount}</span>
    </>
  );
}

export function AttractionRatings({ name, price, rating }) {
  const hasRating = rating != null;
  return (
    <div className="d-flex flex-row align-items-center">
      <div className="p-0">
        <span className="badge badge-success">
          <ul className="rating-list text-nowrap">
            {setPriceRatingBadgeContent(price || 0, name)}
          </ul>
        </span>
      </div>
      {hasRating ? (
        <div className="pl-3">
          <span className="badge badge-danger">
            <ul className="rating-list text-nowrap">
              {convertRatingToIcons(
                rating,
                faHeart,
                "danger",
                `This attraction is rated ${rating} out of 5 by Dayhoot`
              )}
            </ul>
          </span>
        </div>
      ) : null}
    </div>
  );
}

function AttractionDescriptionShowMoreButton({ data }) {
  if (data.isVisible) {
    return (
      <b
        className="clickable hyperlink"
        style={{ fontSize: "0.75rem" }}
        onClick={() => data.setShowDetailsModal(true)}
      >
        <span>See More</span>
        <FontAwesomeIcon className="ml-2" icon={faChevronRight} />
      </b>
    );
  } else return null;
}

function AttractionTag({ id, category, isUsingDarkMode, isPreview }) {
  const { categoryName, displayName, displayColor } = category;
  const icon = getIconFromCategoryName(categoryName, isUsingDarkMode);
  const content = (
    <div
      key={id}
      className="badge mr-2 mb-2 wordbreak"
      style={{
        padding: "0.2rem 0.4rem 0.2rem 0.4rem",
        backgroundColor: displayColor,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "100%",
      }}
    >
      <div className="d-flex flex-row align-items-center">
        <div className="pl-2 pt-1 pb-1">
          <LazyLoad once={true}>
            <img
              className="img-responsive align-self-center m-0"
              src={icon}
              alt={displayName + " icon"}
              style={{
                width: "1.5em",
                marginLeft: "0rem",
                marginTop: "0.55rem",
                zIndex: "2",
                position: "block",
              }}
            ></img>
          </LazyLoad>
        </div>
        <div className="pl-1 pr-2 pt-1 pb-1">
          <span className="wordbreak icon-text text-white pl-1">
            {displayName}
          </span>
        </div>
      </div>
    </div>
  );
  if (isPreview) return content;
  else
    return (
      <NavLink key={categoryName} to={`../categories/${categoryName}`}>
        {content}
      </NavLink>
    );
}

export function AttractionTags({ categories, isUsingDarkMode, isPreview }) {
  return categories.map((category, id) => {
    return (
      <AttractionTag
        key={id}
        id={id}
        category={category}
        isUsingDarkMode={isUsingDarkMode}
        isPreview={isPreview}
      />
    );
  });
}

function AttractionTagContainer({ attraction, cookies, isPreview }) {
  return (
    <div className="mt-2 mb-3 container">
      <div className="row">
        <div className="col-12">
          <b>Related Tags</b>
          <hr className="mt-0 mb-1" />
          <AttractionTags
            categories={attraction.categories}
            isUsingDarkMode={false}
            isPreview={isPreview}
          />
        </div>
      </div>
    </div>
  );
}

function Facility(displayName) {
  return (
    <div className="col-md-6 col-lg-3">
      <div className="d-flex flex-row align-items-center">
        <div className="pl-2 pb-2">
          <p className="mb-0">
            <b className="wordbreak">{displayName}</b>
          </p>
        </div>
      </div>
    </div>
  );
}

function FacilityList(facilities) {
  return facilities.map(({ facilityName, displayName }) => {
    return <Facility key={facilityName} displayName={displayName} />;
  });
}

export function AttractionFacilities({ facilities }) {
  return (
    facilities && (
      <div className="mt-2 mb-3 container">
        <div className="row">
          <div className="col-12">
            <PageTitle subHeadingText="Facilities" />
          </div>
          <div className="col-12">
            <FacilityList facilities={facilities} />
          </div>
        </div>
      </div>
    )
  );
}

export function AttractionDetailsContainer({
  attraction,
  cookies,
  isVisible,
  isDetailsOverflow,
  showDetailsModal,
  setShowDetailsModal,
  isPreview,
}) {
  const {
    price,
    rating,
    links,
    mainCategory,
    location,
    name,
    description,
    contact,
  } = attraction;

  const { mainImage: imageUrl } = links;

  const titleDto = {
    headingText: name,
    subHeadingText: isPreview
      ? null
      : `${location.description} ${getAttractionItemDistanceAway({
          attraction: attraction,
          cookies: cookies,
        })}`,
  };

  const attractionDescriptionDto = {
    isDetailsOverflow: isDetailsOverflow,
    setShowDetailsModal: setShowDetailsModal,
    description: attraction.description,
  };

  const descriptionModalDto = {
    show: showDetailsModal,
    onHide: () => setShowDetailsModal(false),
    headerContent: null,
    bodyContent: <span className="wordbreak">{attraction.description}</span>,
  };

  return (
    <div
      id="attractionDetailsContainer"
      style={{ display: isVisible ? "block" : "none" }}
    >
      <div className="container pt-3">
        <div className="row">
          <div className="col-12">
            <div className="row">
              {!!imageUrl ? (
                <div className="col-md-6">
                  <AttractionImage
                    image={imageUrl}
                    alt={
                      !!imageUrl ? description : mainCategory.categoryPageTitle
                    }
                    url={imageUrl}
                    cleanUrl={!!imageUrl ? imageUrl.split("/")[2] : null}
                  />
                </div>
              ) : null}
              <div className={imageUrl ? "col-md-6" : "col-12"}>
                <div className="row">
                  <div className="col-12">
                    <PageTitle subHeadingText={titleDto.subHeadingText} />
                  </div>
                  {isPreview ? null : (
                    <div className="col-12 mt-2">
                      <AttractionRatings
                        name={attraction.name}
                        price={price}
                        rating={rating}
                      />
                    </div>
                  )}
                  <div className="col-12 mt-2">
                    <b>About {name}</b>
                    <hr className="mt-0 mb-1" />
                    <AttractionDescription data={attractionDescriptionDto} />
                  </div>

                  {attraction.linkUrl ||
                  (contact && contact.phone) ||
                  (contact && contact.email) ? (
                    <div className="col-12 mt-2">
                      <b>Contact Details</b>
                      <hr className="mt-0 mb-1" />
                      <div className="mb-1">
                        <AttractionWebsite
                          url={attraction.linkUrl}
                          isPreview={isPreview}
                        />
                      </div>
                      <div className="mb-1">
                        <AttractionPhoneNumber
                          phoneNumber={
                            contact && contact.phone ? contact.phone : null
                          }
                          isPreview={isPreview}
                        />
                      </div>
                      <div className="mb-1">
                        <AttractionEmail
                          emailAddress={
                            contact && contact.email ? contact.email : null
                          }
                          isPreview={isPreview}
                        />
                      </div>
                      <div className="mb-1">
                        <AttractionFacebook
                          facebookAccount={
                            contact && contact.facebook
                              ? contact.facebook
                              : null
                          }
                          isPreview={isPreview}
                        />
                      </div>
                      <div className="mb-1">
                        <AttractionTwitter
                          twitterAccount={
                            contact && contact.twitter ? contact.twitter : null
                          }
                          isPreview={isPreview}
                        />
                      </div>
                      <div className="mb-1">
                        <AttractionInstagram
                          instagramAccount={
                            contact && contact.instagram
                              ? contact.instagram
                              : null
                          }
                          isPreview={isPreview}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <DayhootStandardModal data={descriptionModalDto} />
      </div>
    </div>
  );
}
