// Dayhoot Functions & Scripts
import {
  attractionGroupsUrl,
  attractionsUrl,
  categoriesUrl,
  facilitiesUrl,
  marketingfeaturedBannerUrl,
  regionsUrl,
} from "../config/apiUrls";
import { appendFilterString, generateStringJoinCharacter } from "./api-utils";
import { convertMilesToKilometers, handleCoordinates } from "./utils";

// Dayhoot Config
import {
  distanceFilterValueList,
  distanceFilterValues,
  attractionListSortTypeEnumList,
  attractionListSortTypeEnums,
} from "../config/enums";
import { cookieDictionary, getCookie } from "../storage/cookies";

export function setUrlAttractionsSelectedCategoryFilterSuffix(
  attractionsFilterString,
  categories
) {
  const selectedCategories = categories.filter((c) => c.isFilterSelected);
  return setUrlAttractionsCategoryFilterSuffix(
    attractionsFilterString,
    selectedCategories
  );
}

export function setUrlAttractionsCategoryFilterSuffix(
  attractionsFilterString,
  categories
) {
  return categories.length > 0
    ? appendAttractionsCategoriesFilters(attractionsFilterString, categories)
    : attractionsFilterString;
}

export function setUrlAttractionsSelectedRegionFilterSuffix(
  attractionsFilterString,
  regions
) {
  const selectedRegions = regions.filter((c) => c.isFilterSelected);
  return setUrlAttractionsRegionFilterSuffix(
    attractionsFilterString,
    selectedRegions
  );
}

export function setUrlAttractionsRegionFilterSuffix(
  attractionsFilterString,
  regions
) {
  const selectedRegions = regions.filter((c) => c.isFilterSelected);
  return selectedRegions.length > 0
    ? appendAttractionsRegionsFilters(attractionsFilterString, selectedRegions)
    : attractionsFilterString;
}

export function setUrlAttractionsSelectedFacilityFilterSuffix(
  attractionsFilterString,
  facilities
) {
  const selectedFacilities = facilities.filter((f) => f.isFilterSelected);
  return setUrlAttractionsFacilityFilterSuffix(
    attractionsFilterString,
    selectedFacilities
  );
}

export function setUrlAttractionsFacilityFilterSuffix(
  attractionsFilterString,
  facilities
) {
  return facilities.length > 0
    ? appendAttractionsFacilitiesFilters(attractionsFilterString, facilities)
    : attractionsFilterString;
}

export function setUrlAttractionsDistanceFilterSuffix(
  attractionsFilterString,
  withinMiles,
  cookies
) {
  const hasWithinMiles = withinMiles != null;
  const hasDefaultSelected =
    withinMiles === distanceFilterValues.AnyDistance.value;

  // Apply a distance filter is the user specifies a distance which isn't the "Any Distance" default
  if (hasWithinMiles && !hasDefaultSelected) {
    const distanceEnum = Number.isInteger(withinMiles)
      ? distanceFilterValueList.find((x) => x.value === withinMiles)
      : withinMiles;

    const filterInKm = convertMilesToKilometers(distanceEnum.milesValue); // The API uses km so we want to convert from miles to km
    const hasValidKm = !isNaN(filterInKm);

    // Here we just want to validate that the conversion has succeeded before doing anything else
    if (hasValidKm) {
      const userLatitude = getCookie(
        cookies,
        cookieDictionary.SettingUserLocationLatitude.key
      );

      const userLongitude = getCookie(
        cookies,
        cookieDictionary.SettingUserLocationLongitude.key
      );
      // Here we want to get the lat and long but if they're not there, set it as London
      const { latitude, longitude } = handleCoordinates({
        latitude: userLatitude,
        longitude: userLongitude,
      });

      // For a distance filter, we need the max distance as well as the user's coordinates to calculate their distance from the attraction
      attractionsFilterString = appendLocationCoordinates(
        attractionsFilterString,
        { latitude: latitude, longitude: longitude }
      );
      attractionsFilterString = appendMaxDistanceFilter(
        attractionsFilterString,
        filterInKm
      );
    } else
      console.log(
        "No distance filter was applied as the conversion from Miles to Kilometers did not return a valid number."
      );
  } else if (hasWithinMiles && hasDefaultSelected)
    console.log(
      "No distance filter was applied as the Miles value is not a valid number."
    );

  return attractionsFilterString;
}

// Determines which url suffix to use when sorting
export function setUrlSortSuffix({ url, sortOptionValue, cookies }) {
  const sortType = attractionListSortTypeEnumList.find(
    (x) => x.value === sortOptionValue
  );
  const isLocationFilter =
    sortType === attractionListSortTypeEnums.DistanceAsc ||
    sortType === attractionListSortTypeEnums.DistanceDesc;

  if (isLocationFilter) {
    const stringHasLatAndLong =
      url.includes("latitude") && url.includes("longitude");
    if (!stringHasLatAndLong) {
      let userLatitude = "";
      let userLongitude = "";
      if (cookies) {
        userLatitude = getCookie(
          cookies,
          cookieDictionary.SettingUserLocationLatitude.key
        );

        userLongitude = getCookie(
          cookies,
          cookieDictionary.SettingUserLocationLongitude.key
        );
      }
      url +=
        generateStringJoinCharacter(url) +
        `latitude=${userLatitude}&longitude=${userLongitude}`;
    }
  }
  if (sortType !== attractionListSortTypeEnums.NoFilter)
    url += `${generateStringJoinCharacter(url)}sort=${sortType.name}`;

  return url;
}

export function appendAttractionsExcludeFilter(url, excludeId) {
  const stringJoinCharacter = generateStringJoinCharacter(url);
  return `${url}${stringJoinCharacter}exclude=${excludeId}`;
}

export function appendUrlAttractionsExcludeAttractionSuffix(
  attractionsFilterString,
  excludeAttractionId
) {
  return appendAttractionsExcludeFilter(
    attractionsFilterString,
    excludeAttractionId
  );
}

export function appendAttractionsAttractionGroupSuffix(url, groupId) {
  const stringJoinCharacter = generateStringJoinCharacter(url);
  return `${url}${stringJoinCharacter}attractionGroupName=${groupId}`;
}

// We pass in the current URL and append categories filters on to it from an array passed in
export function appendAttractionsCategoriesFilters(url, filterCategories) {
  const categoryNames = filterCategories.map((c) => c.categoryName);
  return appendFilterString(url, "category", categoryNames);
}

// We pass in the current URL and append regions filters on to it from an array passed in
export function appendAttractionsRegionsFilters(url, filterRegions) {
  const regionNames = filterRegions.map((r) => r.regionName);
  return appendFilterString(url, "region", regionNames);
}

// We pass in the current URL and append facilities filters on to it from an array passed in
export function appendAttractionsFacilitiesFilters(url, filterFacilities) {
  const facilityNames = filterFacilities.map((f) => f.facilityName);
  return appendFilterString(url, "facility", facilityNames);
}

// We pass in the current URL and append coordinates for sorting by closest
export function appendLocationCoordinates(url, coordinates) {
  const stringJoinCharacter = generateStringJoinCharacter(url);
  return `${url}${stringJoinCharacter}latitude=${coordinates.latitude}&longitude=${coordinates.longitude}`;
}

// We pass in the current URL and append a max distance in KM to filter out any results outside of that range
export function appendMaxDistanceFilter(url, maxDistance) {
  // We want to check that if the url doesn't have a lat and long, we don't apply a filter
  const hasValidLocation =
    url.includes("latitude=") && url.includes("longitude=");
  if (hasValidLocation) {
    return `${url}&maxDistance=${maxDistance}`;
  } else {
    console.log(
      "The max distance filter was not applied as user coordinates were not passed."
    );
    return url;
  }
}

// We pass in the current URL and append the page number to filter the results to that appropriate page
export function tryAppendPaginationFilter({ url, page }) {
  if (page) {
    const stringJoinCharacter = generateStringJoinCharacter(url);
    return `${url}${stringJoinCharacter}page=${page}`;
  } else return url;
}

export function appendIsApprovedFilter({ url, isApproved }) {
  const stringJoinCharacter = generateStringJoinCharacter(url);
  return `${url}${stringJoinCharacter}isApproved=${isApproved}`;
}

export function appendIsRejectedFilter({ url, isRejected }) {
  const stringJoinCharacter = generateStringJoinCharacter(url);
  return `${url}${stringJoinCharacter}isRejected=${isRejected}`;
}

export function appendIsAwaitingApprovalFilter({ url, isAwaitingApproval }) {
  const stringJoinCharacter = generateStringJoinCharacter(url);
  const awaitingApprovalParam = isAwaitingApproval
    ? "isApproved=false&isRejected=false"
    : "";
  return `${url}${stringJoinCharacter}${awaitingApprovalParam}`;
}

export function tryAppendIsExactSearchFilter({ url, isExactSearch }) {
  if (isExactSearch) {
    const stringJoinCharacter = generateStringJoinCharacter(url);
    return `${url}${stringJoinCharacter}isExactSearch=${isExactSearch}`;
  } else return url;
}

// We pass in the current URl and append the search term to filter the results to that appropriate search
export function tryAppendSearchFilter({ url, searchTerm, isExactSearch }) {
  let returnUrl = url;
  if (searchTerm) {
    const stringJoinCharacter = generateStringJoinCharacter(url);
    returnUrl = `${returnUrl}${stringJoinCharacter}search=${searchTerm}`;
    return tryAppendIsExactSearchFilter({ url: returnUrl, isExactSearch });
  }
  return returnUrl;
}

export function setRelatedAttractionListUrl({ categories, excludeId, limit }) {
  let attractionsFilterString = attractionsUrl;

  if (categories) {
    attractionsFilterString = setUrlAttractionsCategoryFilterSuffix(
      attractionsFilterString,
      categories
    );
  }

  if (excludeId) {
    attractionsFilterString = appendUrlAttractionsExcludeAttractionSuffix(
      attractionsFilterString,
      excludeId
    );
  }

  if (limit) {
    attractionsFilterString = tryAppendLimitSuffix({
      url: attractionsFilterString,
      limit,
    });
  }

  return attractionsFilterString;
}

export function tryAppendLimitSuffix({ url, limit }) {
  if (limit && limit > 0) {
    const stringJoinCharacter = generateStringJoinCharacter(url);
    return `${url}${stringJoinCharacter}limit=${limit}`;
  } else return url;
}

export function tryAppendPageSizeSuffix({ url, pageSize }) {
  if (pageSize && pageSize > 0) {
    const stringJoinCharacter = generateStringJoinCharacter(url);
    return `${url}${stringJoinCharacter}pageSize=${pageSize}`;
  } else return url;
}

export function appendCategoryRankSuffix(url, rank) {
  const stringJoinCharacter = generateStringJoinCharacter(url);
  return rank && rank > 0 ? `${url}${stringJoinCharacter}rank=${rank}` : url;
}

export function tryAppendIsAttractionAll({ url, isAll }) {
  let returnUrl = url;
  if (isAll) {
    const stringJoinCharacter = generateStringJoinCharacter(url);
    return `${returnUrl}${stringJoinCharacter}adminFilter=ALL_ATTRACTIONS`;
  }
  return returnUrl;
}

export function tryAppendIsAttractionActive({ url, isActive }) {
  let returnUrl = url;
  if (isActive) {
    const stringJoinCharacter = generateStringJoinCharacter(url);
    return `${returnUrl}${stringJoinCharacter}adminFilter=LIVE_ATTRACTIONS`;
  }
  return returnUrl;
}

export function tryAppendIsAttractionInactive({ url, isInactive }) {
  let returnUrl = url;
  if (isInactive) {
    const stringJoinCharacter = generateStringJoinCharacter(url);
    return `${returnUrl}${stringJoinCharacter}adminFilter=NON_LIVE_ATTRACTIONS`;
  }
  return returnUrl;
}

// The below functions can generate custom urls from multiple parameters passed
// These are mostly useful for filter boxes or customizible features such as tiles etc
// We should continue to use specific functions in the cases where the string won't change
// -to ensure functionality remains stable
export function setAttractionListUrl({
  categories,
  regions,
  facilities,
  page,
  withinMiles,
  cookies,
  sortOptionValue,
  excludeId,
  groupId,
  coordinates,
  pageSize,
  searchTerm,
  isExactSearch,
  isAll,
  isActive,
  isInactive,
}) {
  let attractionsFilterString = attractionsUrl;

  attractionsFilterString = tryAppendIsAttractionAll({
    url: attractionsFilterString,
    isAll,
  });

  attractionsFilterString = tryAppendIsAttractionActive({
    url: attractionsFilterString,
    isActive,
  });

  attractionsFilterString = tryAppendIsAttractionInactive({
    url: attractionsFilterString,
    isInactive,
  });

  if (categories)
    attractionsFilterString = setUrlAttractionsSelectedCategoryFilterSuffix(
      attractionsFilterString,
      categories
    );

  if (regions)
    attractionsFilterString = setUrlAttractionsSelectedRegionFilterSuffix(
      attractionsFilterString,
      regions
    );

  if (facilities)
    attractionsFilterString = setUrlAttractionsSelectedFacilityFilterSuffix(
      attractionsFilterString,
      facilities
    );

  attractionsFilterString = tryAppendPaginationFilter({
    url: attractionsFilterString,
    page: page,
  });

  if (withinMiles)
    attractionsFilterString = setUrlAttractionsDistanceFilterSuffix(
      attractionsFilterString,
      withinMiles,
      cookies
    );

  if (sortOptionValue)
    attractionsFilterString = setUrlSortSuffix({
      url: attractionsFilterString,
      sortOptionValue: sortOptionValue,
      cookies: cookies,
    });

  if (coordinates)
    attractionsFilterString = appendLocationCoordinates(
      attractionsFilterString,
      coordinates
    );

  if (excludeId)
    attractionsFilterString = appendUrlAttractionsExcludeAttractionSuffix(
      attractionsFilterString,
      excludeId
    );

  if (groupId)
    attractionsFilterString = appendAttractionsAttractionGroupSuffix(
      attractionsFilterString,
      groupId
    );

  attractionsFilterString = tryAppendPageSizeSuffix({
    url: attractionsFilterString,
    pageSize: pageSize,
  });

  attractionsFilterString = tryAppendSearchFilter({
    url: attractionsFilterString,
    searchTerm: searchTerm,
    isExactSearch: isExactSearch,
  });

  return attractionsFilterString;
}

export function setFeaturedBannerListUrl({
  sortOptionValue,
  searchTerm,
  isExactSearch,
  page,
  limit,
}) {
  let featuredBannerUrlString = marketingfeaturedBannerUrl;

  featuredBannerUrlString = tryAppendLimitSuffix({
    url: featuredBannerUrlString,
    limit,
  });

  if (sortOptionValue)
    featuredBannerUrlString = setUrlSortSuffix({
      url: featuredBannerUrlString,
      sortOptionValue: sortOptionValue,
    });

  featuredBannerUrlString = tryAppendPaginationFilter({
    url: featuredBannerUrlString,
    page: page,
  });

  featuredBannerUrlString = tryAppendSearchFilter({
    url: featuredBannerUrlString,
    searchTerm: searchTerm,
    isExactSearch: isExactSearch,
  });

  return featuredBannerUrlString;
}

export function setRegionListUrl({
  coordinates,
  limit,
  sortOptionValue,
  page,
  searchTerm,
  isExactSearch,
}) {
  let regionUrlString = regionsUrl;

  regionUrlString = tryAppendLimitSuffix({ url: regionUrlString, limit });

  if (coordinates)
    regionUrlString = appendLocationCoordinates(regionUrlString, coordinates);

  if (sortOptionValue)
    regionUrlString = setUrlSortSuffix({
      url: regionUrlString,
      sortOptionValue: sortOptionValue,
    });

  regionUrlString = tryAppendPaginationFilter({
    url: regionUrlString,
    page: page,
  });

  regionUrlString = tryAppendSearchFilter({
    url: regionUrlString,
    searchTerm: searchTerm,
    isExactSearch: isExactSearch,
  });

  return regionUrlString;
}

export function setAttractionGroupListUrl({
  sortOptionValue,
  limit,
  page,
  searchTerm,
  isExactSearch,
}) {
  let attractionGroupUrlString = attractionGroupsUrl;

  attractionGroupUrlString = tryAppendLimitSuffix({
    url: attractionGroupUrlString,
    limit: limit,
  });

  if (sortOptionValue)
    attractionGroupUrlString = setUrlSortSuffix({
      url: attractionGroupUrlString,
      sortOptionValue: sortOptionValue,
    });

  attractionGroupUrlString = tryAppendPaginationFilter({
    url: attractionGroupUrlString,
    page: page,
  });

  attractionGroupUrlString = tryAppendSearchFilter({
    url: attractionGroupUrlString,
    searchTerm: searchTerm,
    isExactSearch: isExactSearch,
  });

  return attractionGroupUrlString;
}

export function setCategoryListUrl({
  sortOptionValue,
  limit,
  rank,
  page,
  searchTerm,
  isExactSearch,
}) {
  let categoryUrlString = categoriesUrl;

  categoryUrlString = tryAppendLimitSuffix({
    url: categoryUrlString,
    limit: limit,
  });

  if (sortOptionValue)
    categoryUrlString = setUrlSortSuffix({
      url: categoryUrlString,
      sortOptionValue: sortOptionValue,
    });

  if (rank)
    categoryUrlString = appendCategoryRankSuffix(categoryUrlString, rank);

  categoryUrlString = tryAppendPaginationFilter({
    url: categoryUrlString,
    page: page,
  });

  categoryUrlString = tryAppendSearchFilter({
    url: categoryUrlString,
    searchTerm: searchTerm,
    isExactSearch: isExactSearch,
  });

  return categoryUrlString;
}

export function tryAppendIsAttractionSubmissionAll({ url, isAll }) {
  let returnUrl = url;
  if (isAll) {
    const stringJoinCharacter = generateStringJoinCharacter(url);
    return `${returnUrl}${stringJoinCharacter}adminFilter=ALL_SUBMISSIONS`;
  }
  return returnUrl;
}

export function tryAppendIsAttractionSubmissionRejected({ url, isRejected }) {
  let returnUrl = url;
  if (isRejected) {
    const stringJoinCharacter = generateStringJoinCharacter(url);
    return `${returnUrl}${stringJoinCharacter}adminFilter=SUBMISSION_REJECTED`;
  }
  return returnUrl;
}

export function tryAppendIsAttractionSubmissionAwaitingApproval({
  url,
  isAwaitingApproval,
}) {
  let returnUrl = url;
  if (isAwaitingApproval) {
    const stringJoinCharacter = generateStringJoinCharacter(url);
    return `${returnUrl}${stringJoinCharacter}adminFilter=SUBMISSION_AWAITING_REVIEW`;
  }
  return returnUrl;
}

export function setAttractionSubmissionsListUrl({
  sortOptionValue,
  searchTerm,
  isExactSearch,
  page,
  isAll,
  isRejected,
  isAwaitingApproval,
}) {
  let url = attractionsUrl;

  url = tryAppendIsAttractionSubmissionAll({ url, isAll });
  url = tryAppendIsAttractionSubmissionRejected({ url, isRejected });
  url = tryAppendIsAttractionSubmissionAwaitingApproval({
    url,
    isAwaitingApproval,
  });

  if (sortOptionValue) {
    url = setUrlSortSuffix({
      url: url,
      sortOptionValue: sortOptionValue,
    });
  }

  url = tryAppendSearchFilter({
    url: url,
    searchTerm: searchTerm,
    isExactSearch: isExactSearch,
  });

  url = tryAppendPaginationFilter({
    url: url,
    page: page,
  });

  return url;
}

export function getFacilitiesListUrl({
  searchTerm,
  isExactSearch,
  page,
  pageSize,
  limit,
  sortOptionValue,
}) {
  let url = facilitiesUrl;
  url = tryAppendSearchFilter({ url, searchTerm, isExactSearch });
  url = setUrlSortSuffix({ url, sortOptionValue });
  url = tryAppendPaginationFilter({ url, page });
  url = tryAppendPageSizeSuffix({ url, pageSize });
  url = tryAppendLimitSuffix({ url, limit });
  return url;
}
