import { ReactElement } from "react";
import { NavLink } from "react-router-dom";
import { Search as SearchIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from ".";

type SearchButtonProps = {
  searchText?: string;
};

const useStyles = makeStyles((theme) => {
  return {
    icon: {
      fill: theme.palette.primary.contrastText,
    },
  };
});

const SearchButton = ({ searchText }: SearchButtonProps): ReactElement => {
  const hasSearchText = searchText && searchText !== "";
  const classes = useStyles();
  return (
    <NavLink
      to={hasSearchText ? `/search/${searchText}` : "/"}
      aria-label="Search Button"
    >
      <IconButton
        edge="end"
        aria-label="Search Button"
        color="inherit"
      >
        <SearchIcon className={classes.icon} />
      </IconButton>
    </NavLink>
  );
};
export default SearchButton;
