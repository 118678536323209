// Package Imports
import React from "react";
import { NavLink } from "react-router-dom";

export function FacilityTileContainer({ facility, numberOfTiles }) {
  const { facilityName, displayName, colour } = facility;
  const desktopWidth = numberOfTiles ? Math.round(12 / numberOfTiles) : 3;
  return (
    <div className={`col-sm-6 col-md-${desktopWidth} mt-1 mb-1`}>
      <NavLink key={facilityName} to={`/facilities/${facilityName}`}>
        <div className={`tile-container background-${colour || "blue"}`}>
          <div className="tile-text-centered p-2">
            <span className="h6 text-white pt-2 pb-0 wordbreak apply-ellipsis-3">
              {displayName}
            </span>
          </div>
        </div>
      </NavLink>
    </div>
  );
}

export function FacilityTileList({ facilities, numberOfTiles }) {
  const componentsToRender = facilities.map(facility => (
    <FacilityTileContainer
      key={facility.facilityName}
      facility={facility}
      numberOfTiles={numberOfTiles}
    />
  ));
  return <div className="row">{componentsToRender}</div>;
}
