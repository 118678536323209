import { Dispatch, SetStateAction } from "react";
import { LocationButton, MenuButton, SearchBox } from ".";
import SettingsButton from "./SettingsButton";
import { makeStyles } from "@material-ui/core/styles";

type AnchorTypes = "top" | "right" | "bottom" | "left";

type DesktopMenuProps = {
  setLocationModalShow: Dispatch<SetStateAction<boolean>>;
  locationModalShow: boolean;
  isUsingGeolocation: boolean;
  settingsModalShow: boolean;
  setSettingsModalShow: Dispatch<SetStateAction<boolean>>;
  toggleDrawer: (anchor: AnchorTypes, open: boolean) => (event: any) => void;
  drawerState: boolean;
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
};

const useStyles = makeStyles((theme) => {
  return {
    grow: {
      flexGrow: 1,
      marginBottom: theme.spacing(2),
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
  };
});

const DesktopMenu = ({
  setLocationModalShow,
  locationModalShow,
  isUsingGeolocation,
  settingsModalShow,
  setSettingsModalShow,
  toggleDrawer,
  drawerState,
  searchText,
  setSearchText,
}: DesktopMenuProps) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.grow} />
      <div className={classes.sectionDesktop}>
        <SearchBox
          searchText={searchText}
          setSearchText={setSearchText}
        />
        <LocationButton
          modalShow={locationModalShow}
          setModalShow={setLocationModalShow}
          isUsingGeolocation={isUsingGeolocation}
        />
        <SettingsButton
          modalShow={settingsModalShow}
          setModalShow={setSettingsModalShow}
        />
        <MenuButton
          toggleDrawer={toggleDrawer}
          drawerState={drawerState}
          drawerPosition="right"
        />
      </div>
    </>
  );
};
export default DesktopMenu;
