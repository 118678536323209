// import { ClassValue } from "clsx";
import { Dispatch, Fragment, ReactElement, SetStateAction } from "react";
import clsx from "clsx";
import { NavLink, useHistory } from "react-router-dom";
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  TextField,
} from "@material-ui/core";
import {
  Accessible as AccessibleIcon,
  AccountTree as AccountTreeIcon,
  Close as CloseIcon,
  Email as EmailIcon,
  ExitToApp as ExitToAppIcon,
  Gavel as GavelIcon,
  GroupWork as GroupWorkIcon,
  Info as InfoIcon,
  LocalActivity as LocalActivityIcon,
  Lock as LockIcon,
  Map as MapIcon,
  Search as SearchIcon,
  Settings as SettingsIcon,
  ViewQuilt as ViewQuiltIcon,
  Widgets as WidgetsIcon,
} from "@material-ui/icons";

import { shouldCloseDrawer } from "./AppBarHelpers";
import { NavbarLogo } from "../navbar-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHatWizard, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { ExpandedListItem, ExpandedListItemClickable } from ".";
import { makeStyles } from "@material-ui/core/styles";
import { getAdminAuthenticationDetails, unsetAdminAuthenticationDetails } from "../../scripts/admin-auth";

type AnchorState = {
  top: boolean;
  left: boolean;
  bottom: boolean;
  right: boolean;
};

type AnchorTypes = "top" | "right" | "bottom" | "left";

type MenuDrawerProps = {
  state: AnchorState;
  toggleDrawer: (
    anchor: "top" | "right" | "bottom" | "left",
    open: boolean
  ) => (event: any) => void;
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
  cookies: any;
};

const useStyles = makeStyles((theme) => {
  return {
    drawerHeader: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fill: theme.palette.primary.contrastText,
    },
    fullList: {
      width: "auto",
    },
    list: {
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: 330,
      },
    },
    listContainer: {
      paddingTop: 0,
    },
    drawerHeaderLogoContainer: {
      marginRight: "auto",
    },
    drawerHeaderIconContainer: {
      paddingRight: 0,
      marginLeft: "auto",
      minWidth: "unset",
    },
    drawerHeaderCloseIcon: {
      fill: theme.palette.primary.contrastText,
      cursor: "pointer",
    },
  };
});

const MenuDrawer = ({
  state,
  toggleDrawer,
  searchText,
  setSearchText,
  cookies,
}: MenuDrawerProps): ReactElement => {
  let history = useHistory();
  const classes = useStyles();
  const baseAuth = getAdminAuthenticationDetails(cookies);
  const isAdminLoggedIn = !!baseAuth && !!baseAuth.token;
  const list = (anchor: AnchorTypes) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={(val) => {
        const close = shouldCloseDrawer(val);
        if (close) toggleDrawer(anchor, false)(val);
      }}
      onKeyDown={(val) => {
        const close = shouldCloseDrawer(val);
        if (close) toggleDrawer(anchor, false)(val);
      }}
    >
      <List className={classes.listContainer}>
        <ListItem className={classes.drawerHeader}>
          <div
            className={classes.drawerHeaderLogoContainer}
            onClick={toggleDrawer(anchor, false)}
          >
            <NavbarLogo />
          </div>
          <ListItemIcon className={classes.drawerHeaderIconContainer}>
            <CloseIcon
              className={classes.drawerHeaderCloseIcon}
              onClick={toggleDrawer(anchor, false)}
            />
          </ListItemIcon>
        </ListItem>
        <Divider />
        <ListItem id="drawerSearchBox">
          <NavLink
            to={`/search/${searchText || ""}`}
            aria-label="Search Button"
          >
            <ListItemIcon>
              <SearchIcon id="drawerSearchBoxIcon" />
            </ListItemIcon>
          </NavLink>
          <TextField
            id="drawerSearchBoxInput"
            placeholder="Search..."
            variant="outlined"
            fullWidth={true}
            value={searchText}
            onChange={(val) => setSearchText(val.target.value)}
            onKeyDown={(val) => {
              val.persist();
              const close = val.type === "keydown" && val.key === "Enter";
              if (close) {
                history.push(`/search/${searchText}`);
                setSearchText("");
                setTimeout(() => toggleDrawer(anchor, false)(val), 150);
              }
            }}
          />
        </ListItem>
        <Divider />
        <ExpandedListItem
          key="attractionsLink"
          id="attractionsLink"
          to="/attractions/"
          icon={<LocalActivityIcon />}
          text="Attractions"
        />
        <ExpandedListItem
          key="attractionGroupsLink"
          id="attractionGroupsLink"
          to="/groups/"
          icon={<GroupWorkIcon />}
          text="Attraction Groups"
        />
        <ExpandedListItem
          key="categoryLink"
          id="categoryLink"
          to="/categories/"
          icon={<ViewQuiltIcon />}
          text="Categories"
        />
        <ExpandedListItem
          key="facilityLink"
          id="facilityLink"
          to="/facilities/"
          icon={<AccessibleIcon />}
          text="Facilities"
        />
        <ExpandedListItem
          key="regionLink"
          id="regionLink"
          to="/regions/"
          icon={<MapIcon />}
          text="Regions"
        />
      </List>
      <Divider />
      <List>
        <ExpandedListItem
          key="newSubmissionLink"
          id="newSubmissionLink"
          to="/attraction/submit/"
          icon={
            <FontAwesomeIcon
              icon={faHatWizard}
              className="ml-1 hyperlink h4 mb-0"
            />
          }
          text="Submit Attraction"
        />
        <ExpandedListItem
          key="retrieveSubmittedAttractionLink"
          id="retrieveSubmittedAttractionLink"
          to="/retrieveAttraction/"
          icon={
            <FontAwesomeIcon
              icon={faUserEdit}
              className="ml-1 hyperlink h4 mb-0"
            />
          }
          text="Retrieve Submission"
        />
      </List>
      <Divider />
      <List>
        <ExpandedListItem
          key="settingsLink"
          id="settingsLink"
          to="/settings/"
          icon={<SettingsIcon />}
          text="Settings"
        />
        <ExpandedListItem
          key="aboutLink"
          id="aboutLink"
          to="/about/"
          icon={<InfoIcon />}
          text="About"
        />
        <ExpandedListItem
          key="policiesLink"
          id="policiesLink"
          to="/policies/"
          icon={<GavelIcon />}
          text="Policies"
        />
        <ExpandedListItem
          key={"contactLink"}
          id={"contactLink"}
          to={"/contact/"}
          icon={<EmailIcon />}
          text={"Contact"}
        />
        <ExpandedListItem
          key="sitemapLink"
          id="sitemapLink"
          to="/sitemap/"
          icon={<AccountTreeIcon />}
          text="Sitemap"
        />
      </List>
      <Divider />
      <List>
        {isAdminLoggedIn ? (
          <>
            <ExpandedListItem
              key="adminLink"
              id="adminLink"
              to="/admin/"
              icon={<WidgetsIcon />}
              text="Admin Dashboard"
            />
            <ExpandedListItemClickable
              key="logoutLink"
              id="logoutLink"
              onClick={() => {
                unsetAdminAuthenticationDetails(cookies);
                window.location.reload();
              }}
              icon={<ExitToAppIcon />}
              text="Log Out"
            />
          </>
        ) : (
          <ExpandedListItem
            key="adminLink"
            id="adminLink"
            to="/admin/"
            icon={<LockIcon />}
            text="Admin Login"
          />
        )}
      </List>
    </div>
  );

  return (
    <div>
      {(["left", "right", "top", "bottom"] as Array<AnchorTypes>).map(
        (anchor) => (
          <Fragment key={anchor}>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </Fragment>
        )
      )}
    </div>
  );
};

export default MenuDrawer;
