// Imports ///////////////////////////////////////////////////////////////////////////////////////////////////////

import {
  appendLocationCoordinates,
  getFacilitiesListUrl,
  setAttractionGroupListUrl,
  setAttractionListUrl,
  setAttractionSubmissionsListUrl,
  setCategoryListUrl,
  setFeaturedBannerListUrl,
  setRegionListUrl,
  setRelatedAttractionListUrl,
} from "./api-urlBuilder";
import {
  deleteData,
  fetchData,
  fetchDataWithHeaders,
  postData,
  putData,
} from "./api-utils";
import {
  attractionsUrl,
  categoriesUrl,
  managementcategoriesUrl,
  getAttractionUrl,
  getSingleRegionUrl,
  regionsUrl,
  managementregionsUrl,
  getSingleCategoryUrl,
  getLocalInformationUrl,
  emailSignupUrl,
  attractionGroupsUrl,
  managementattractionGroupsUrl,
  getSingleAttractionGroupUrl,
  getSingleFacilityUrl,
  marketingfeaturedBannerUrl,
  managementfeaturedBannerUrl,
  getSubmittedAttractionUrl,
  authenticateAdminUrl,
  facilityManagementUrl,
  getLocationDetailsByPostcodeUrl,
  validatePostcodeUrl,
  attractionManageUrl,
  attractionSubmissionsUrl,
  getGoogleLocationDataUrl,
} from "../config/apiUrls";
import { attractionListSortTypeEnums } from "../config/enums";

// API Data Functions /////////////////////////////////////////////////////////////////////////////////

export async function getEveryAttraction() {
  return await fetchData(
    `${attractionsUrl}?pageSize=1000&sort=${attractionListSortTypeEnums.AlphabeticalAsc.name}`
  );
}

// Get All
export async function getAllAttractions() {
  return await fetchData(attractionsUrl);
}

export async function getAllCategories() {
  return await fetchData(categoriesUrl);
}

export async function getAllRegions() {
  return await fetchData(regionsUrl);
}

export async function getAllFacilities() {
  const url = getFacilitiesListUrl({ pageSize: 1000 });
  return await fetchData(url);
}

export async function getAllAttractionGroups() {
  return await fetchData(attractionGroupsUrl);
}

// Get Single

export async function getAttraction({ id }) {
  const url = getAttractionUrl(id);
  return await fetchData(url);
}

export async function getSubmittedAttraction({ id }) {
  const url = getSubmittedAttractionUrl(id);
  return await fetchData(url);
}

export async function getCategory({ name }) {
  const url = getSingleCategoryUrl(name);
  return await fetchData(url);
}

export async function getRegion({ name }) {
  const url = getSingleRegionUrl(name);
  return await fetchData(url);
}

export async function getAttractionGroup(groupId) {
  const url = getSingleAttractionGroupUrl(groupId);
  return await fetchData(url);
}

export async function getFacility(facilityName, token) {
  return await fetchDataWithHeaders({
    url: getSingleFacilityUrl(facilityName),
    headers: { token: token },
  });
}

// Other Unsorted

export async function getLocalInformation({ coordinates }) {
  const url = getLocalInformationUrl(coordinates);
  return await fetchData(url);
}

export async function getCategoryAttractions({ name, excludeId, limit }) {
  const url = setRelatedAttractionListUrl({
    categories: [{ categoryName: name }],
    excludeId,
    limit,
  });
  return await fetchData(url);
}

export async function getFilteredAttractions(props) {
  const url = setAttractionListUrl(props);
  return await fetchData(url);
}

export async function getLocationOrderedAttractions({ coordinates }) {
  const url = appendLocationCoordinates(attractionsUrl, coordinates);
  return await fetchData(url);
}

export async function getLocationOrderedRegions({ coordinates }) {
  const url = appendLocationCoordinates(regionsUrl, coordinates);
  return await fetchData(url);
}

export async function getFilteredRegions({
  coordinates,
  limit,
  sortOptionValue,
  page,
  searchTerm,
  isExactSearch,
}) {
  const url = setRegionListUrl({
    coordinates,
    limit,
    sortOptionValue,
    page,
    searchTerm,
    isExactSearch,
  });
  return await fetchData(url);
}

export async function getFilteredCategories({
  sortOptionValue,
  limit,
  rank,
  page,
  searchTerm,
  isExactSearch,
}) {
  const url = setCategoryListUrl({
    sortOptionValue,
    limit,
    rank,
    page,
    searchTerm,
    isExactSearch,
  });
  return await fetchData(url);
}

export async function getFilteredAttractionGroups({
  sortOptionValue,
  limit,
  page,
  searchTerm,
  isExactSearch,
}) {
  const url = setAttractionGroupListUrl({
    sortOptionValue,
    limit,
    page,
    searchTerm,
    isExactSearch,
  });
  return await fetchData(url);
}

export async function getAttractionSubmissions(props) {
  const url = setAttractionSubmissionsListUrl(props);
  return await fetchData(url);
}

export async function getFacilities({
  searchTerm,
  isExactSearch,
  page,
  pageSize,
  limit,
  sortOptionValue,
}) {
  const url = getFacilitiesListUrl({
    searchTerm,
    isExactSearch,
    page,
    pageSize,
    limit,
    sortOptionValue,
  });
  return await fetchData(url);
}

export async function getFeaturedBanner() {
  return await fetchData(marketingfeaturedBannerUrl);
}

export async function getFilteredFeaturedBanners({
  sortOptionValue,
  searchTerm,
  isExactSearch,
  page,
  pageSize,
  limit,
}) {
  const url = setFeaturedBannerListUrl({
    sortOptionValue,
    searchTerm,
    isExactSearch,
    page,
    pageSize,
    limit,
  });
  return await fetchData(url);
}

export async function getSingleFeaturedBanner(id) {
  const url = `${marketingfeaturedBannerUrl}${id}`;
  return await fetchData(url);
}

export async function getLocationDetailsByPostcode(postcode) {
  let isValid = false;
  if (postcode) {
    const validateUrl = validatePostcodeUrl(postcode);
    const response = await fetch(validateUrl);

    if (response.ok) {
      const responseJson = await response.json();
      isValid = responseJson.result;
    }

    if (isValid) {
      const url = getLocationDetailsByPostcodeUrl(postcode);
      const response = await fetch(url);
      if (!response.ok) return;
      else return await response.json();
    } else return null;
  } else return null;
}

export async function getGoogleLocationPopulatedAttraction({
  attractionId,
  token,
}) {
  const url = getGoogleLocationDataUrl(attractionId);
  return await fetchDataWithHeaders({
    url: url,
    headers: { token: token },
  });
}

//Post single

export async function postEmailToEmailSignup(email) {
  return await postData({
    url: emailSignupUrl,
    data: {
      email: email,
    },
  });
}

export async function createAttractionGroup(attractionGroup, token) {
  const { response, result } = await postData({
    url: managementattractionGroupsUrl,
    data: attractionGroup,
    token,
  });
  return result === true ? response : null;
}

export async function postNewlySubmittedAttraction(attraction) {
  const { response, result } = await postData({
    url: attractionsUrl,
    data: attraction,
  });
  return result === true ? response : null;
}

export async function authenticateAdminUser(loginDetails) {
  return await postData({ url: authenticateAdminUrl, data: loginDetails });
}

export async function createCategory(category, token) {
  const { response, result } = await postData({
    url: managementcategoriesUrl,
    data: category,
    token,
  });
  return result === true ? response : null;
}

export async function createRegion(region, token) {
  const { response, result } = await postData({
    url: managementregionsUrl,
    data: region,
    token,
  });
  return result === true ? response : null;
}

export async function createFacility(facility, token) {
  const { response, result } = await postData({
    url: facilityManagementUrl,
    data: facility,
    token,
  });
  return result === true ? response : null;
}

export async function createFeaturedBanner(featuredBanner, token) {
  const { response, result } = await postData({
    url: managementfeaturedBannerUrl,
    data: featuredBanner,
    token,
  });
  return result === true ? response : null;
}

export async function createAttraction(attraction, token) {
  const { response, result } = await postData({
    url: attractionManageUrl,
    data: attraction,
    token,
  });
  return result === true ? response : null;
}

export async function createSubmission(submission) {
  const { response, result } = await postData({
    url: attractionSubmissionsUrl,
    data: submission,
  });
  return result === true ? response : null;
}

// PUT

export async function updateCategory(category, token) {
  const url = `${managementcategoriesUrl}/${category.categoryName}`;
  const { response, result } = await putData({
    url,
    data: category,
    token,
  });
  return result === true ? response : null;
}

export async function updateRegion(region, token) {
  const url = `${managementregionsUrl}/${region.regionName}`;
  const { response, result } = await putData({
    url,
    data: region,
    token,
  });
  return result === true ? response : null;
}

export async function updateSubmittedAttraction(attraction) {
  const url = getSubmittedAttractionUrl(attraction.id);
  const { response, result } = await putData({ url, data: attraction });
  return result === true ? response : null;
}

export async function updateAttractionGroup(attractionGroup, token) {
  const url = `${managementattractionGroupsUrl}/${attractionGroup.groupId}`;
  const { response, result } = await putData({
    url,
    data: attractionGroup,
    token,
  });
  return result === true ? response : null;
}

export async function updateFacility(facility, token) {
  const url = getSingleFacilityUrl(facility.facilityName);
  const { response, result } = await putData({ url, data: facility, token });
  return result === true ? response : null;
}

export async function updateFeaturedBanner(featuredBanner, token) {
  const url = managementfeaturedBannerUrl + featuredBanner.id;
  const { response, result } = await putData({
    url,
    data: featuredBanner,
    token,
  });
  return result === true ? response : null;
}
export async function updateAttraction(attraction, token) {
  const url = `${attractionManageUrl}/${attraction.id}`;
  const { response, result } = await putData({
    url,
    data: attraction,
    token,
  });
  return result === true ? response : null;
}

export async function updateSubmission(attraction, token) {
  const url = `${attractionSubmissionsUrl}/${attraction.id}`;
  const { response, result } = await putData({
    url,
    data: attraction,
    token,
  });
  return result === true ? response : null;
}

// DELETE

export async function deleteFacility(facility, token) {
  const url = getSingleFacilityUrl(facility.facilityName);
  const { result } = await deleteData({ url, data: facility, token });
  return result;
}
