// Package Imports
import React, { Component } from "react";

// Dayhoot Functions
import { policyUpdateDate } from "../component-functions/infoPage-core";
import { setPageTitleText } from "../scripts/utils";

// Dayhoot Components
import {
	PoliciesDropDown,
	InfoPageBodyContent,
} from "../components/infoPage-components";
class Cookies extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPageLoaded: false,
		};
	}

	componentDidMount() {
		this.setState({ isPageLoaded: true });
		setPageTitleText("Cookies Policy");
	}

	render() {
		const bodyHtml = (
			<div className="container">
				<div className="page-margin">
					<div className="row">
						<div className="col-md-12 text-left p-3">
							<div className="pb-0">
								<span>
									<PoliciesDropDown page="cookies" />
									<p className="pt-3">
										Last updated: {policyUpdateDate.cookies}
									</p>

									<p className="h5 pt-3 pb-1">1. About cookies</p>
									<p>
										Dayhoot uses cookie technologies to deliver an enhanced
										experience to our site. Cookies may also be stored by the
										third-party providers we use to deliver services on the
										site.
									</p>
									<p>
										A web cookie is a small text file stored by the browser on
										your computer or device when you visit a site. They hold
										information so that your choices between pages are
										remembered but also details such as your settings for when
										you revisit the site.
									</p>
									<p>
										First-party cookies may be set by the site you visited and
										are generally necessary for the site to function properly.
										Third-party cookies may also be set which provide services
										to the site that allow for an enhanced experience,
										optimisation improvements, and user analytics.
									</p>

									<p className="h5 pt-3 pb-1">2. Use of cookies on Dayhoot</p>
									<p>
										<b>Essential Cookies:</b> These are set by Dayhoot and are
										necessary for the site to function. Disabling these cookies
										will likely cause parts of the site to not function
										correctly.
									</p>
									<div className="pb-3">
										<table className="table table-bordered table-responsive">
											<thead>
												<tr>
													<th style={{ width: "20%" }}>Cookie Name</th>
													<th style={{ width: "50%" }}>Cookie Purpose</th>
													<th style={{ width: "30%" }}>Expiration</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td style={{ fontStyle: "italic" }}>
														dayhoot_session
													</td>
													<td>
														Stores details about your session on Dayhoot
														including your location data if enabled.
													</td>
													<td>Deleted when the browser is closed.</td>
												</tr>
											</tbody>
										</table>
									</div>
									<p>
										<b>Functionality Cookies:</b> These cookies provide enhanced
										functionality to Dayhoot and allow for personalisation. You
										must accept these cookies to change the optional site
										settings. Disabling these cookies will probably not affect
										the function of the site but may reduce your user
										experience.
									</p>
									<div className="pb-3">
										<table className="table table-bordered table-responsive">
											<thead>
												<tr>
													<th style={{ width: "20%" }}>Cookie Name</th>
													<th style={{ width: "50%" }}>Cookie Purpose</th>
													<th style={{ width: "30%" }}>Expiration</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td style={{ fontStyle: "italic" }}>
														dayhoot_cookies
													</td>
													<td>Stores your cookie preferences.</td>
													<td>
														Stored for 31 days if personalisation cookies are
														accepted, otherwise deleted when the browser is
														closed.
													</td>
												</tr>
												<tr>
													<td style={{ fontStyle: "italic" }}>
														dayhoot_settings
													</td>
													<td>Stores settings and site preferences.</td>
													<td>
														Stored for 100 days after settings are applied by
														the user.
													</td>
												</tr>
											</tbody>
										</table>
									</div>
									<p>
										<b>Third-Party Cookies:</b> Dayhoot uses third parties that
										may set cookies to provide you with personalised content and
										advertising. We also use third parties for analytic purposes
										that allow us to monitor user traffic and make optimisation
										improvements to the site. These groups offer tools that
										allow you to update your privacy preferences and opt-out of
										the services.
									</p>
									<div className="pb-3">
										<table className="table table-bordered table-responsive">
											<thead>
												<tr>
													<th style={{ width: "20%" }}>Cookie Provider</th>
													<th style={{ width: "50%" }}>Cookie Purpose</th>
													<th style={{ width: "30%" }}>More Details</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Google Analytics</td>
													<td>
														Provides user analytics and performance data that
														allows us to understand how Dayhoot is used so we
														can make improvements.
													</td>
													<td>
														<a
															href="https://tools.google.com/dlpage/gaoptout"
															target="_blank"
															rel="noopener noreferrer"
														>
															<button
																type="submit"
																className="btn btn-bordered bordered btn-sm w-100"
															>
																Opt-out Add-on
															</button>
														</a>
													</td>
												</tr>
												<tr>
													<td>Google Adsense</td>
													<td>
														Provides advertisements that help support Dayhoot.
													</td>
													<td>
														<a
															href="https://adssettings.google.com/"
															target="_blank"
															rel="noopener noreferrer"
														>
															<button
																type="submit"
																className="btn btn-bordered bordered btn-sm w-100"
															>
																Adsense Settings
															</button>
														</a>
													</td>
												</tr>
											</tbody>
										</table>
									</div>

									<p className="h5 pt-3 pb-1">3. Control your cookie data</p>
									<p>
										There are many ways that you can control the information
										collected with most web browsers having the option that
										allows you to view and delete the cookies that are stored on
										your computer. Mobile devices also have additional settings
										that give you extra control over cookies that are stored.
										Specific guidance for popular web browsers and mobile
										operating systems are below.
									</p>
									<div className="pb-3">
										<ul
											className="nav nav-tabs h-100"
											id="myTab"
											role="tablist"
										>
											<li className="nav-item text-center w-50">
												<a
													className="nav-link active"
													id="Web Browser Links"
													data-toggle="tab"
													href="#webbrowser"
													role="tab"
													aria-controls="webbrowser"
													aria-selected="true"
												>
													Web Browser Links
												</a>
											</li>
											<li className="nav-item text-center w-50">
												<a
													className="nav-link"
													id="Mobile Links"
													data-toggle="tab"
													href="#mobile"
													role="tab"
													aria-controls="mobile"
													aria-selected="false"
												>
													Mobile links
												</a>
											</li>
										</ul>
										<div className="tab-content" id="myTabContent">
											<div
												className="tab-pane fade border infoBorder show active"
												id="webbrowser"
												role="tabpanel"
												aria-labelledby="webbrowser"
											>
												<div className="p-2">
													<a
														className="pb-1"
														href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
														target="_blank"
														rel="noopener noreferrer"
													>
														<button
															type="submit"
															className="btn btn-bordered bordered btn-sm w-100"
														>
															Microsoft Edge
														</button>
													</a>
												</div>
												<div className="p-2">
													<a
														className="pb-1"
														href="https://support.google.com/chrome/answer/95647"
														target="_blank"
														rel="noopener noreferrer"
													>
														<button
															type="submit"
															className="btn btn-bordered bordered btn-sm w-100"
														>
															Google Chrome
														</button>
													</a>
												</div>
												<div className="p-2">
													<a
														className="pb-1"
														href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac"
														target="_blank"
														rel="noopener noreferrer"
													>
														<button
															type="submit"
															className="btn btn-bordered bordered btn-sm w-100"
														>
															Apple Safari
														</button>
													</a>
												</div>
												<div className="p-2">
													<a
														className="pb-1"
														href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
														target="_blank"
														rel="noopener noreferrer"
													>
														<button
															type="submit"
															className="btn btn-bordered bordered btn-sm w-100"
														>
															Mozilla Firefox
														</button>
													</a>
												</div>
											</div>
											<div
												className="tab-pane border infoBorder fade"
												id="mobile"
												role="tabpanel"
												aria-labelledby="mobile"
											>
												<div className="p-2">
													<a
														className="pb-1"
														href="https://support.apple.com/en-us/HT202074"
														target="_blank"
														rel="noopener noreferrer"
													>
														<button
															type="submit"
															className="btn btn-bordered bordered btn-sm w-100"
														>
															iOS
														</button>
													</a>
												</div>
												<div className="p-2">
													<a
														className="pb-1"
														href="https://support.google.com/ads/answer/2662922"
														target="_blank"
														rel="noopener noreferrer"
													>
														<button
															type="submit"
															className="btn btn-bordered bordered btn-sm w-100"
														>
															Android
														</button>
													</a>
												</div>
											</div>
										</div>
									</div>

									<p className="h5 pt-3 pb-1">4. Further support</p>
									<p>
										More information about your data privacy options can be
										found in our{" "}
										<a href="/policies/privacy/">
											<b>privacy policy</b>
										</a>
										.
									</p>
									<p>
										You can disable all non-essential cookies on the{" "}
										<a href="/settings/">
											<b>settings page</b>
										</a>
										.
									</p>
									<p>
										For further information about the use of cookies on Dayhoot,
										you can{" "}
										<a href="/contact/">
											<b>contact us</b>
										</a>
										.
									</p>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		);

		return (
			<InfoPageBodyContent
				id={"cookies"}
				title={"Cookies on Dayhoot"}
				bodyHtml={bodyHtml}
			/>
		);
	}
}

export default Cookies;
