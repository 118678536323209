// Package Imports
import React, { Component } from "react";
import { withCookies } from "react-cookie";

// Dayhoot Scripts
import { setPageTitleText } from "../scripts/utils";
import { citiesList } from "../config/citiesList";
import {
	setGeolocationCookies,
	setLocationCookies,
	getValuesFromCookies,
} from "../component-functions/settings-core";

// Dayhoot Components
import {
	ToggleableSetting,
	CitiesDropdownList,
	DropdownSetting,
} from "../components/settings-components";
import { PageLoading } from "../components/common-components";

// Fonts & Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

class ChangeLocation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPageLoaded: false,
			cookies: props.cookies.getAll(),
			citiesList: citiesList,
			values: getValuesFromCookies(props.cookies),
		};

		this.handleFormChange = this.handleFormChange.bind(this);
	}

	componentDidMount() {
		this.setState({ isPageLoaded: true });
		setPageTitleText("Change Location");
	}

	//This is called when one of the inputs is changed, checks to see which one and updates settigns accordingly
	handleFormChange(event) {
		this.setState({ isPageLoaded: false });

		const { id, value } = event.target;
		let { values } = this.state;
		const { cookies } = this.props;

		switch (id) {
			case "useGeolocation":
				values = setGeolocationCookies(values, cookies);
				window.location.reload();
				break;
			case "citySelector":
				values = setLocationCookies(values, cookies, value);
				window.location.reload();
				break;
			default:
				break;
		}

		this.setState({
			values: values
		});
	}

	render() {
		const { values, citiesList, isPageLoaded } = this.state;
		if (isPageLoaded) {
			const { useGeolocationData, userLocation } = values;

			return (
				<div>
					<span style={{ fontSize: "1.2rem" }}>
						Finding Dayhoots for 
						{useGeolocationData ? " your current location, " : " your selected location, "}
						<b>{userLocation}</b>
					</span>
					<div className="card card-body mt-2 mb-2">
						<ToggleableSetting
							data={{
								id: "useGeolocation",
								labelText: `Us${
									useGeolocationData ? "ing" : "e"
								} Current Location`,
								accessibilityText: `Us${
									useGeolocationData ? "ing" : "e"
								} Current Location`,
								value: useGeolocationData,
								onChange: this.handleFormChange,
								defaultChecked: useGeolocationData,
								showHr: !useGeolocationData,
							}}
						/>
						{!useGeolocationData ? (
							<DropdownSetting
								data={{
									titleText: "Select a city",
									dropdownList: (
										<CitiesDropdownList
											data={{
												values: values,
												citiesList: citiesList,
												handleFormChange: this.handleFormChange,
											}}
										/>
									),
								}}
							/>
						) : null}
					</div>
					<div className="alert alert-info">
						<div className="d-flex flex-row align-items-top">
							<div className="p-0">
								<FontAwesomeIcon icon={faInfoCircle} />
							</div>
							<div className="pl-2">
								<span>
									{useGeolocationData
										? "By not selecting to use your current location, you are instead choosing to specify a default location. This default location will be stored as a cookie during your time using Dayhoot."
										: "Selecting to use your current location will store this as a cookie. Please only select this option if you are happy for this to happen."}
								</span>
							</div>
						</div>
					</div>
				</div>
			);
		} else return <PageLoading />;
	}
}

export default withCookies(ChangeLocation);
