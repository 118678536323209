import { cookieDictionary, setCookie } from "../storage/cookies";

export function confirmCookieNotice(event) {
  const { cookies } = event.cookiesNoticeDto;
  const cookieElement = document.getElementById("cookiesNotice");
  if (cookieElement) {
    //Hide the element
    cookieElement.style.display = "none";
    //Change the cookies to say they have been accepted

    setCookie(
      cookies,
      cookieDictionary.CookiePolicyAcknowledged.key,
      true,
      cookieDictionary.CookiePolicyAcknowledged.validForMonths
    );

    setCookie(
      cookies,
      cookieDictionary.ApplicationCookiesAndLocalStorageUseageAccepted.key,
      true,
      cookieDictionary.ApplicationCookiesAndLocalStorageUseageAccepted
        .validForMonths
    );

    setCookie(
      cookies,
      cookieDictionary.AnalyticsCookieUseageAccepted.key,
      true,
      cookieDictionary.AnalyticsCookieUseageAccepted.validForMonths
    );

    setCookie(
      cookies,
      cookieDictionary.AdvertisingCookieUseageAccepted.key,
      true,
      cookieDictionary.AdvertisingCookieUseageAccepted.validForMonths
    );
  }
}

export function acknowledgeAndManageCookies(event) {
  const { cookies } = event.cookiesNoticeDto;
  const cookieElement = document.getElementById("cookiesNotice");
  if (cookieElement) {
    //Hide the element for the session only, not storing any persistent cookies
    cookieElement.style.display = "none";

    setCookie(
      cookies,
      cookieDictionary.CookiePolicyAcknowledged.key,
      true,
      cookieDictionary.CookiePolicyAcknowledged.validForMonths
    );
  }
}
