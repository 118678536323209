/* eslint-disable no-nested-ternary */
import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import { AdminAreaConfigs, AdminPageConfigs } from "../config/adminPaths";

const breadcrumbNameMap = {
	"/admin": "Admin Home",
};

function pushBreadcrumbsToArray(options, mappedBreadcrumbArray) {
	options
		.filter((x) => x !== undefined)
		.forEach((x) => {
			mappedBreadcrumbArray.push({
				path: x.link,
				label: x.label,
				isClickable: x.isBreadcrumbClickable,
			});
		});
}

function getAllMappedBreadcrumbs() {
	let allAdminSections = [];
	pushBreadcrumbsToArray(AdminPageConfigs, allAdminSections);
	pushBreadcrumbsToArray(AdminAreaConfigs, allAdminSections);
	return allAdminSections;
}

function findMatchingAdminPathBreadcrumb(path, allMappedBreadcrumbs) {
	return allMappedBreadcrumbs.find((b) => path === b.path);
}

function ListItemLink(props) {
	const { to, open, ...other } = props;
	const primary = breadcrumbNameMap[to];

	return (
		<li>
			<ListItem button component={RouterLink} to={to} {...other}>
				<ListItemText primary={primary} />
				{open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
			</ListItem>
		</li>
	);
}

ListItemLink.propTypes = {
	open: PropTypes.bool,
	to: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
	},
	lists: {
		backgroundColor: theme.palette.background.paper,
		marginTop: theme.spacing(1),
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));

export default function RouterBreadcrumbs({ pathName, pageItemLabel }) {
	const classes = useStyles();
	const pathnames = pathName.split("/").filter((x) => x);
	const allAdminPathBreadcrumbs = getAllMappedBreadcrumbs();

	return (
		<div className={`${classes.root} mb-2`}>
			<Breadcrumbs aria-label="breadcrumb">
				{pathnames.map((value, index) => {
					const last = index === pathnames.length - 1;
					const to = `/${pathnames.slice(0, index + 1).join("/")}`;
					const matchingBreadcrumb = findMatchingAdminPathBreadcrumb(
						to,
						allAdminPathBreadcrumbs
					);
					const nameMap =
						breadcrumbNameMap[to] ||
						(!!matchingBreadcrumb && matchingBreadcrumb.label) ||
						pageItemLabel ||
						value;

					return last ||
						(!!matchingBreadcrumb && !matchingBreadcrumb.isClickable) ? (
						<Typography className="wordbreak apply-ellipsis-1" color="textPrimary" key={to}>
							{nameMap}
						</Typography>
					) : (
						<NavLink className="wordbreak apply-ellipsis-1" color="inherit" to={to} key={to}>
							{nameMap}
						</NavLink>
					);
				})}
			</Breadcrumbs>
		</div>
	);
}
