// Package Imports
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withCookies } from "react-cookie";
import LazyLoad from "react-lazyload";

// Dayhoot Components
import { PageLoading } from "../components/common-components";
import { InfoPageBodyContent } from "../components/infoPage-components";

// APIs
import { getAllAttractions } from "../scripts/api-core";

// Images
import LogoDark from "../img/logos/owl-tight-dark.png";
import LocationsImg1Dark from "../img/icons/loc1-dark.png";
import LocationsImg2Dark from "../img/icons/loc2-dark.png";
import LocationsImg3Dark from "../img/icons/loc3-dark.png";
import LogoLight from "../img/logos/owl-tight-light.png";
import LocationsImg1Light from "../img/icons/loc1-light.png";
import LocationsImg2Light from "../img/icons/loc2-light.png";
import LocationsImg3Light from "../img/icons/loc3-light.png";

// Dayhoot Functions
import { formatNumberWithCommas, setPageTitleText } from "../scripts/utils";

// Fonts & Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isPageLoaded: false,
      cookies: props.cookies,
    };
  }

  async componentDidMount() {
    const data = await getAllAttractions(); // We only need this to get a count for total number of attractions on Dayhoot
    setPageTitleText("About");
    this.setState({
      data: data.metadata,
      isPageLoaded: true,
    });
  }

  getLocationImages() {
    const { isUsingDarkMode } = this.state;
    return {
      logo: isUsingDarkMode ? LogoLight : LogoDark,
      image1: isUsingDarkMode ? LocationsImg1Light : LocationsImg1Dark,
      image2: isUsingDarkMode ? LocationsImg2Light : LocationsImg2Dark,
      image3: isUsingDarkMode ? LocationsImg3Light : LocationsImg3Dark,
    };
  }

  render() {
    const { logo, image1, image2, image3 } = this.getLocationImages();

    if (this.state.isPageLoaded) {
      const bodyHtml = (
        <div className="container">
          <div className="page-margin">
            <div className="row">
              <div className="col-md-12 text-center p-3">
                <div className="pb-3">
                  <LazyLoad once={true}>
                    <img
                      src={logo}
                      alt="Dayhoot About Logo"
                      style={{ width: "5rem" }}
                    ></img>
                  </LazyLoad>
                </div>
                <div className="pt-3 pb-0">
                  <p>
                    Dayhoot launched in 2021 and is a directory of popular
                    attractions in the U.K.
                  </p>
                  <p>
                    Our search features can be used to find places you may want
                    to visit.
                  </p>
                  <p>
                    You can also use location data to show those that are
                    closest to you.
                  </p>
                  <p>
                    We are independent and curate the lists of attractions
                    ourselves. No reviews, No bias.
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div
                className="col-md-4 col-xs-12 p-3"
                style={{ padding: "0px" }}
              >
                <div className="infoPanel panel panel-default h-100 m-0">
                  <div className="d-flex flex-wrap align-items-center border-dark">
                    <LazyLoad once={true}>
                      <img
                        className="img-responsive center-block d-block mx-auto pt-5"
                        src={image1}
                        alt="Attractions are added by moderators"
                        style={{
                          width: "auto",
                          maxHeight: "12rem",
                          objectFit: "contain",
                        }}
                      ></img>
                    </LazyLoad>
                  </div>
                  <div className="text-center p-3">
                    <p className="h4">Curated Content</p>
                    <div className="px-5">
                      <hr className="infoHr" />
                    </div>
                    <p>
                      Attractions are selected and added by our Dayhoot
                      moderators.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-md-4 col-xs-12 p-3"
                style={{ padding: "0px" }}
              >
                <div className="infoPanel panel panel-default h-100 m-0">
                  <div className="d-flex flex-wrap align-items-center border-dark">
                    <LazyLoad once={true}>
                      <img
                        className="img-responsive center-block d-block mx-auto pt-5"
                        src={image2}
                        alt="Many attractions on Dayhoot"
                        style={{
                          width: "auto",
                          maxHeight: "12rem",
                          objectFit: "contain",
                        }}
                      ></img>
                    </LazyLoad>
                  </div>
                  <div className="text-center p-3">
                    <p className="h4">
                      {formatNumberWithCommas(this.state.data.totalResults)}
                    </p>
                    <div className="px-5">
                      <hr className="infoHr" />
                    </div>
                    <p>
                      U.K. attractions and activities currently on Dayhoot and
                      counting.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-md-4 col-xs-12 p-3"
                style={{ padding: "0px" }}
              >
                <div className="infoPanel panel panel-default h-100 m-0">
                  <div className="d-flex flex-wrap align-items-center border-dark">
                    <LazyLoad once={true}>
                      <img
                        className="img-responsive center-block d-block mx-auto pt-5"
                        src={image3}
                        alt="Search for locations nearby"
                        style={{
                          width: "auto",
                          maxHeight: "12rem",
                          objectFit: "contain",
                        }}
                      ></img>
                    </LazyLoad>
                  </div>
                  <div className="text-center p-3">
                    <p className="h4">Discover Places</p>
                    <div className="px-5">
                      <hr className="infoHr" />
                    </div>
                    <p>
                      Use the search and your location to discover attractions
                      to visit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center p-3">
                <NavLink className="" key="home" to="../">
                  <div className="btn btn-success">
                    <button type="submit" className="btn btn-success center">
                      Find Dayhoots near you
                      <FontAwesomeIcon className="ml-2" icon={faChevronRight} />
                    </button>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      );

      return (
        <div>
          <InfoPageBodyContent
            id={"about"}
            title={`Dayhoot`}
            bodyHtml={bodyHtml}
          />
        </div>
      );
    } else return <PageLoading />;
  }
}

export default withCookies(About);
