import { NumberOfDayhootsText } from ".";
import { PageTitle } from "../../components";
import {
  attractionListSortTypeEnumList,
  sortTypesRequiringDistance,
} from "../../config/enums";
import { cookieDictionary, getCookie } from "../../storage/cookies";

type AttractionHeaderSectionProps = {
  pageHeader: string;
  title: string;
  hasSelectedFilters: boolean;
  numberOfResults: number;
  sortOptionValue: any;
  cookies: any;
};

const AttractionsHeaderSection = ({
  pageHeader,
  title,
  hasSelectedFilters,
  numberOfResults,
  sortOptionValue,
  cookies,
}: AttractionHeaderSectionProps) => {
  let subHeading = "";
  const sortOption = attractionListSortTypeEnumList.find(
    (x) => x.value === sortOptionValue
  );
  if (!!sortOption) {
    let userLocation = "";
    const isDistanceSort = sortTypesRequiringDistance.find(
      (x) => x.value === sortOptionValue
    );
    if (isDistanceSort) {
      let userLocationCookie = getCookie(
        cookies,
        cookieDictionary.SettingDefaultUserLocationName.key
      );
      if (userLocationCookie === undefined) {
        userLocationCookie = getCookie(
          cookies,
          cookieDictionary.SettingDefaultUserLocationName.key
        );
      }

      userLocation = ` ${userLocationCookie}`;
    }
    subHeading = sortOption.subHeadingText + userLocation;
  }
  const hasSubheading = subHeading != null && subHeading !== "";

  return (
    <>
      <div className="row align-items-center">
        <div className="col-sm-9 col-lg-10">
          <PageTitle
            headingId={pageHeader}
            headingText={title}
            subHeadingId={hasSubheading ? "pageSubheading" : undefined}
            subHeadingText={hasSubheading ? subHeading : undefined}
          />
        </div>
        <div className="col-sm-3 col-lg-2">
          {/* This is for any non-phone screen */}
          <span className="d-none d-sm-block">
            <NumberOfDayhootsText
              textAlign="right"
              hasSelectedFilters={hasSelectedFilters}
              numberOfResults={numberOfResults}
            />
          </span>
          {/* This is for phone screens */}
          <span className="d-sm-none">
            <NumberOfDayhootsText
              textAlign={"left"}
              hasSelectedFilters={hasSelectedFilters}
              numberOfResults={numberOfResults}
            />
          </span>
        </div>
      </div>
      <hr className="mt-2" />
    </>
  );
};
export default AttractionsHeaderSection;
