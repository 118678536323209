import {
  getAllRegions,
  getAttractionSubmissions,
  getFacilities,
  getFilteredAttractions,
  getFilteredCategories,
  getFilteredRegions,
  getFilteredAttractionGroups,
} from "../scripts/api-core";
import { attractionListSortTypeEnums } from "../config/enums";
import { AdminLinks } from "../config/adminPaths";
import $ from "jquery";
import { sortByAz } from "../scripts/utils";
import { getAdminAuthenticationDetails } from "../scripts/admin-auth";

async function renderSubmissionsList(props) {
  const { token } = getAdminAuthenticationDetails(props.cookies);

  let submissionProps = { isExactSearch: true };
  $.extend(submissionProps, props);

  // If errors are thrown about these being undefined, try logging out and back in first
  // before troubleshooting. If the API has been updated, the token will expire.
  const { attractions: submissions, metadata } = await getAttractionSubmissions(
    submissionProps
  );

  const { results: regions } = await getAllRegions();

  return {
    createLink: "/",
    results: submissions.map((x) => {
      const region = regions.find((r) => r.regionName === x.location.region);

      const showAdditionalText = x.mainCategory || region;
      const additionalTextMainCategory = x.mainCategory
        ? x.mainCategory.displayName
        : "";
      const additionalTextJoinText = x.mainCategory && region ? "in " : "";
      const additionalTextRegion = region ? region.displayName : "";

      return {
        name: x.name,
        additionalText: showAdditionalText
          ? `${additionalTextMainCategory} ${
              additionalTextJoinText + additionalTextRegion
            }`
          : "",
        viewLink: `${AdminLinks.AttractionSubmissions.View}/${x.id}`,
        editLink: `${AdminLinks.AttractionSubmissions.Edit}/${x.id}`,
        enableEdit: !x.isApproved,
        deactivateLink: `/`,
        deleteLink: `/`,
      };
    }),
    metadata: metadata,
    isAuthenticated: !!token,
    token: token,
  };
}

async function renderAttractionsList({
  cookies,
  searchTerm,
  page,
  isAll,
  isActive,
  isInactive,
}) {
  const { attractions, metadata } = await getFilteredAttractions({
    searchTerm: searchTerm,
    isExactSearch: true,
    page: page,
    sortOptionValue: attractionListSortTypeEnums.AlphabeticalAsc.value,
    isAll,
    isActive,
    isInactive,
  });

  const { results: regions } = await getAllRegions();
  const { token } = getAdminAuthenticationDetails(cookies);

  return {
    createLink: AdminLinks.Attractions.Create,
    results: attractions.map((a) => {
      let region = "";
      if (a.location && a.location.region) {
        const matchingRegion = regions.find(
          (r) => r.regionName === a.location.region
        );
        region = matchingRegion ? ` in ${matchingRegion.displayName}` : "";
      }

      const additionalText = a.mainCategory ? a.mainCategory.displayName : "";
      return {
        name: a.name,
        additionalText: additionalText + region,
        lastPosted:
          a.metadata && a.metadata.lastSocialPost
            ? a.metadata.lastSocialPost
            : "N/A",
        viewLink: `${AdminLinks.Attractions.View}/${a.id}`,
        editLink: `${AdminLinks.Attractions.Edit}/${a.id}`,
        enableEdit: true,
        deactivateLink: `/`,
        deleteLink: `/`,
        manageSocialLink: `${AdminLinks.Attractions.ManageSocial}/${a.id}`,
      };
    }),
    metadata: metadata,
    isAuthenticated: !!token,
    token: token,
  };
}

async function renderFacilitiesList({
  cookies,
  searchTerm,
  page,
  pageSize,
  limit,
}) {
  const { results, metadata } = await getFacilities({
    searchTerm,
    isExactSearch: true,
    page,
    pageSize,
    limit,
    sortOptionValue: attractionListSortTypeEnums.AlphabeticalAsc.value,
  });
  const { token } = getAdminAuthenticationDetails(cookies);

  return {
    createLink: AdminLinks.Facilities.Create,
    results: results
      .sort((a, b) => sortByAz(a.displayName, b.displayName))
      .map(x => ({
        name: x.displayName,
        additionalText: undefined,
        viewLink: `/`,
        editLink: `${AdminLinks.Facilities.Edit}/${x.facilityName}`,
        enableEdit: true,
        deactivateLink: `/`,
        // deleteLink: `${AdminLinks.Facilities.Delete}/${x.facilityName}`,
        deleteLink: "/",
      })),
    metadata: metadata,
    isAuthenticated: !!token,
    token: token,
  };
}

async function renderAttractionGroupList({
  cookies,
  searchTerm,
  page,
  pageSize,
  limit,
}) {
  const { results, metadata } = await getFilteredAttractionGroups({
    searchTerm,
    isExactSearch: true,
    page,
    pageSize,
    limit,
  });

  const { token } = getAdminAuthenticationDetails(cookies);

  return {
    createLink: AdminLinks.AttractionGroups.Create,
    results: results.map((f) => {
      return {
        name: f.displayName,
        viewLink: `/`,
        editLink: `${AdminLinks.AttractionGroups.Edit}/${f.groupId}`,
        enableEdit: true,
        deactivateLink: `/`,
        deleteLink: `/`,
      };
    }),
    metadata: metadata,
    isAuthenticated: !!token,
    token: token,
  };
}

async function renderCategoriesList({
  cookies,
  searchTerm,
  page,
  pageSize,
  limit,
}) {
  const { results, metadata } = await getFilteredCategories({
    searchTerm,
    isExactSearch: true,
    page,
    pageSize,
    limit,
  });

  const { token } = getAdminAuthenticationDetails(cookies);

  return {
    createLink: AdminLinks.Categories.Create,
    results: results.map((f) => {
      return {
        name: f.displayName,
        viewLink: `/`,
        editLink: `${AdminLinks.Categories.Edit}/${f.categoryName}`,
        enableEdit: true,
        deactivateLink: `/`,
        deleteLink: `/`,
      };
    }),
    metadata: metadata,
    isAuthenticated: !!token,
    token: token,
  };
}

async function renderRegionList({
  cookies,
  searchTerm,
  page,
  pageSize,
  limit,
}) {
  const { results, metadata } = await getFilteredRegions({
    searchTerm,
    isExactSearch: true,
    page,
    pageSize,
    limit,
  });

  const { token } = getAdminAuthenticationDetails(cookies);

  return {
    createLink: AdminLinks.Regions.Create,
    results: results.map((f) => {
      return {
        name: f.displayName,
        viewLink: `/`,
        editLink: `${AdminLinks.Regions.Edit}/${f.regionName}`,
        enableEdit: true,
        deactivateLink: `/`,
        deleteLink: `/`,
      };
    }),
    metadata: metadata,
    isAuthenticated: !!token,
    token: token,
  };
}

export async function renderAdminList({ adminListPage, listProps }) {
  const { link } = adminListPage;
  const {
    Attractions,
    AttractionSubmissions,
    Facilities,
    AttractionGroups,
    Categories,
    Regions,
  } = AdminLinks;

  if (link === Attractions.All) {
    let newListProps = { isAll: true };
    $.extend(newListProps, listProps);
    return await renderAttractionsList(newListProps);
  } else if (link === Attractions.Inactive) {
    let newListProps = { isInactive: true };
    $.extend(newListProps, listProps);
    return await renderAttractionsList(newListProps);
  } else if (link === Attractions.Active) {
    let newListProps = { isActive: true };
    $.extend(newListProps, listProps);
    return await renderAttractionsList(newListProps);
  } else if (link === AttractionSubmissions.All) {
    let newListProps = { isAll: true };
    $.extend(newListProps, listProps);
    return await renderSubmissionsList(newListProps);
  } else if (link === AttractionSubmissions.Rejected) {
    let newListProps = { isRejected: true };
    $.extend(newListProps, listProps);
    return await renderSubmissionsList(newListProps);
  } else if (link === AttractionSubmissions.AwaitingApproval) {
    let newListProps = { isAwaitingApproval: true };
    $.extend(newListProps, listProps);
    return await renderSubmissionsList(newListProps);
  } else if (link === Facilities.All) {
    return await renderFacilitiesList(listProps);
  } else if (link === AttractionGroups.All) {
    return await renderAttractionGroupList(listProps);
  } else if (link === Categories.All) {
    return await renderCategoriesList(listProps);
  } else if (link === Regions.All) {
    return await renderRegionList(listProps);
  } else return undefined;
}
