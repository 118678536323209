// Package Imports
import React from "react";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";

// Fonts & Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { postEmailToEmailSignup } from "../scripts/api-core";

// app storage
import { setValue, getValue } from "../storage/appstorage";

const LS_EMAIL_SIGNUP_COMPLETE = "email-signup-complete";

export function EmailBanner() {
  const [showInput, setShowInput] = React.useState(true);

  getValue(LS_EMAIL_SIGNUP_COMPLETE).then((isEmailSignupComplete) => {
    setShowInput(!isEmailSignupComplete);
  });

  return (
    <div className="email-banner-container p-3">
      <div className="row">
        <div className="col-12">
          <p className="h1 text-white">
            {showInput
              ? "Love a great Dayhoot?"
              : "Thanks for signing up! 🥳🥂"}
          </p>
        </div>
      </div>
      {showInput ? EmailInput(setShowInput) : Confirmation()}
    </div>
  );
}

function EmailInput(setShowInput) {
  return (
    <div className="row align-items-end">
      <div className="col-12 col-lg-6">
        <p className="text-white pb-0">
          Sign up to our email list and get notified regularly about new,
          interesting and fantastically discounted UK Dayhoots.
        </p>
      </div>
      <div className="col-12 col-lg-6 pb-3">
        <div id="emailInputGroup" className="input-group">
          <input
            className="form-control border-dark"
            id="emailInputBox"
            type="email"
            placeholder="janet.smith@example.co.uk"
            aria-label="Email Signup"
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                document.getElementById("emailSubmit").click();
                document.getElementById("emailInputBox").value = "";
              }
            }}
          />
          <div className="input-group-append">
            <button
              id="emailSubmit"
              onClick={() => emailInputOnSubmit(setShowInput)}
              className="btn btn-primary btn-outline-dark"
              style={{ borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }}
              aria-label="Submit"
            >
              <FontAwesomeIcon
                className="text-white"
                icon={faArrowCircleRight}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function emailInputOnSubmit(setShowInput) {
  postEmailToEmailSignup(document.getElementById("emailInputBox").value);
  document.getElementById("emailInputBox").value = "";
  setShowInput(false);
  setValue(LS_EMAIL_SIGNUP_COMPLETE, true);
}

function Confirmation() {
  return (
    <div className="row">
      <div className="col-12 col-lg-12">
        <p className="text-white">
          Awesome, you've signed up for our email list and will start receiving
          fantastic Dayhoots straight away! Let's hope this is the start of a
          long friendship between you and us! Don't worry... We'll put in all
          the work.
        </p>
      </div>
    </div>
  );
}
