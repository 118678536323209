import { Dispatch, ReactElement, SetStateAction } from "react";
import { Settings as SettingsIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from ".";

type SettingsButtonProps = {
  modalShow: boolean;
  setModalShow: Dispatch<SetStateAction<boolean>>;
};

const useStyles = makeStyles((theme) => {
  return {
    icon: {
      fill: theme.palette.primary.contrastText,
    },
  };
});

const SettingsButton = ({
  modalShow,
  setModalShow,
}: SettingsButtonProps): ReactElement => {
  const classes = useStyles();
  return (
    <IconButton
      edge="end"
      aria-label="Settings Window"
      aria-controls={"settingsModal"}
      aria-haspopup="true"
      onClick={() => setModalShow(!modalShow)}
      color="inherit"
    >
      <SettingsIcon className={classes.icon} />
    </IconButton>
  );
};
export default SettingsButton;
