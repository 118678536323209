import { ReactElement } from "react";
import { PrimarySearchAppBar } from ".";

type AppBarProps = {
  isUsingGeolocation: boolean;
  cookies: any;
};

const AppBar = ({ isUsingGeolocation, cookies }: AppBarProps): ReactElement => {
  return (
    <>
      <PrimarySearchAppBar
        isUsingGeolocation={isUsingGeolocation}
        cookies={cookies}
      />
    </>
  );
};
export default AppBar;
