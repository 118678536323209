import { policyUpdateDate } from "../component-functions/infoPage-core";

export function getCookie(cookies, key) {
  let cookie = cookies.get(key);
  if (cookie === undefined) {
    return undefined;
  }
  if (cookie === "true") {
    return true;
  }
  if (cookie === "false") {
    return false;
  }
  return cookie;
}

export function setCookie(cookies, key, value, monthsValid) {
  let expiry = monthsValid ? calculateExpiryDate(monthsValid) : undefined;
  cookies.set(key, value, {
    path: "/",
    expires: expiry,
  });
}

export function deleteCookie(cookies, key) {
  cookies.remove(key, { path: "/" });
}

function calculateExpiryDate(monthsValid) {
  var date = new Date(Date.now());
  date.setMonth(date.getMonth() + monthsValid);
  return date;
}

// This checks to see if the policies have been updated since the cookie was set,
//   and deletes them if they were
export function removeCookiesIfOutdatedByPolicy(cookies) {
  let cookiesList = [
    getCookie(cookies, cookieDictionary.CookiePolicyAcknowledged.key),
    getCookie(
      cookies,
      cookieDictionary.ApplicationCookiesAndLocalStorageUseageAccepted.key
    ),
    getCookie(cookies, cookieDictionary.AnalyticsCookieUseageAccepted.key),
    getCookie(cookies, cookieDictionary.AdvertisingCookieUseageAccepted.key),
    getCookie(cookies, cookieDictionary.SettingShouldUseGeolocation.key),
    getCookie(cookies, cookieDictionary.SettingDefaultUserLocationName.key),
    getCookie(cookies, cookieDictionary.SettingUserLocationName.key),
    getCookie(cookies, cookieDictionary.SettingUserLocationLatitude.key),
    getCookie(cookies, cookieDictionary.SettingUserLocationLongitude.key),
    getCookie(cookies, cookieDictionary.SettingUserDistanceUnitKm.key),
    getCookie(cookies, cookieDictionary.AdminToken.key),
    getCookie(cookies, cookieDictionary.AdminTokenExpires.key),
  ];

  cookiesList.forEach((cookieDto) => {
    if (cookieDto && policyUpdateDate.updatedDate >= cookieDto.setDate)
      deleteCookie(cookies, cookieDto.name);
  });
}

export let cookieDictionary = {
  //Main policy cookies
  CookiePolicyAcknowledged: {
    key: "CookiePolicyAcknowledged",
    validForMonths: 6,
  },
  ApplicationCookiesAndLocalStorageUseageAccepted: {
    key: "ApplicationCookiesAndLocalStorageUseageAccepted",
    validForMonths: 6,
  },
  AnalyticsCookieUseageAccepted: {
    key: "AnalyticsCookieUseageAccepted",
    validForMonths: 6,
  },
  AdvertisingCookieUseageAccepted: {
    key: "AdvertisingCookieUseageAccepted",
    validForMonths: 6,
  },

  //Settings Cookies
  SettingShouldUseGeolocation: {
    key: "SettingShouldUseGeolocation",
    validForMonths: 6,
  },
  SettingDefaultUserLocationName: {
    key: "SettingDefaultUserLocationName",
    validForMonths: 6,
  },
  SettingUserLocationName: {
    key: "SettingUserLocationName",
    validForMonths: 6,
  },
  SettingUserLocationLatitude: {
    key: "SettingUserLocationLatitude",
    validForMonths: 6,
  },
  SettingUserLocationLongitude: {
    key: "SettingUserLocationLongitude",
    validForMonths: 6,
  },
  SettingUserDistanceUnitKm: {
    key: "SettingUserDistanceUnitKm",
    validForMonths: 6,
  },

  //Admin
  AdminToken: {
    key: "AdminToken",
    validForMonths: 6,
  },
  AdminTokenExpires: {
    key: "AdminTokenExpires",
    validForMonths: 6,
  },
};
