import {
  faCheckCircle,
  faInfoCircle,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import { PageTitle } from "../components";
import { getAttraction, updateAttraction } from "../scripts/api-core";
import { format } from "date-fns";
import { makeStyles, TextField } from "@material-ui/core";
import { withCookies } from "react-cookie";
import { Redirect } from "react-router";
import { getAdminAuthenticationDetails } from "../scripts/admin-auth";

function setDateTime(dateTime) {
  return format(dateTime, "yyyy-MM-dd'T'HH:mm:ss");
}

function getCurrentDateTime() {
  return setDateTime(new Date());
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

function DayhootDateTimePicker({ value, handleDateChange }) {
  const classes = useStyles();
  return (
    <form className={classes.container} noValidate>
      <TextField
        id="datetime-local"
        label="Social Post Date & Time"
        type="datetime-local"
        defaultValue={getCurrentDateTime()}
        value={value}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => handleDateChange(e.target.value)}
        style={{ width: "100%" }}
      />
    </form>
  );
}

function RecentPostAlert({ attraction }) {
  const hasLastSocialPost =
    attraction.metadata && attraction.metadata.lastSocialPost;
  const lastSocialPost = hasLastSocialPost
    ? format(new Date(attraction.metadata.lastSocialPost), "MM/dd/yyyy")
    : undefined;
  const socialPostMessage = hasLastSocialPost
    ? `The most recent social media post for ${attraction.name} was on ${lastSocialPost}`
    : `Our records do not yet show a recent social media post for ${attraction.name}.`;

  return (
    <div
      className={`alert ${hasLastSocialPost ? "alert-success" : "alert-info"}`}
    >
      <div className="d-flex flex-row">
        <div className="p-0">
          <FontAwesomeIcon
            icon={hasLastSocialPost ? faCheckCircle : faInfoCircle}
          />
        </div>
        <div className="pl-2" style={{ fontSize: "0.9em" }}>
          {socialPostMessage}
        </div>
      </div>
    </div>
  );
}

class ManageSocial extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPageLoaded: false,
      attraction: null,
      selectedDate: getCurrentDateTime(),
      hasErrors: false,
      token: "",
      isAuthenticated: false,
    };
  }

  handleDateChange(date) {
    this.setState({ selectedDate: date });
  }

  async renderData() {
    const { token } = getAdminAuthenticationDetails(this.props.cookies);
    return {
      attraction: await getAttraction({
        id: this.props.match.params.attractionId,
      }),
      token: token,
      isAuthenticated: !!token,
    };
  }

  async save() {
    const { attraction, selectedDate, token } = this.state;

    this.setState({ isPageLoaded: false });

    let attractionToUpdate = attraction;
    attractionToUpdate.metadata.lastSocialPost = selectedDate;
    attractionToUpdate.mainCategory = attraction.mainCategory.categoryName;
    attractionToUpdate.categories = attraction.categories.map(
      (c) => c.categoryName
    );
    attractionToUpdate.facilities = attraction.facilities.map((f) => ({
      name: f.facilityName,
      types: f.types,
    }));

    const updatedAttraction = await updateAttraction(attractionToUpdate, token);

    if (updatedAttraction) this.props.history.goBack();
  }

  async componentDidMount() {
    const { attraction, token, isAuthenticated } = await this.renderData();
    this.setState({
      isPageLoaded: true,
      attraction,
      selectedDate:
        attraction.metadata && attraction.metadata.lastSocialPost
          ? attraction.metadata.lastSocialPost
          : getCurrentDateTime(),
      token,
      isAuthenticated,
    });
  }

  render() {
    const { isPageLoaded, attraction, selectedDate, isAuthenticated } =
      this.state;
    if (!isPageLoaded) return null;
    else {
      if (isAuthenticated) {
        return (
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="h3 wordbreak text-grey">
                  <span>Managing Social Media Record for</span>
                </div>
                <PageTitle
                  headingId="attractionName"
                  headingText={attraction.name}
                />
              </div>
              <div className="col-12">
                <div className="card card-body">
                  <div className="row pb-2">
                    <div className="col-12 d-lg-none">
                      <RecentPostAlert attraction={attraction} />
                    </div>
                    <div className="col-12 col-lg-4">
                      <DayhootDateTimePicker
                        value={selectedDate}
                        handleDateChange={this.handleDateChange.bind(this)}
                      />
                    </div>
                    <div className="d-none d-lg-block col-lg-8">
                      <RecentPostAlert attraction={attraction} />
                    </div>
                    <div className="col-12">
                      <div className="d-flex flex-row align-items-center">
                        <div className="ml-auto mr-auto p-0">
                          <button
                            className={`btn btn-primary`}
                            onClick={() => this.save()}
                          >
                            <FontAwesomeIcon icon={faSave} />
                            <span className="ml-1">Save Changes</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else return <Redirect to="/admin" />;
    }
  }
}

export default withCookies(ManageSocial);
