// Package Imports
import React from "react";
import { NavLink } from "react-router-dom";

// Dayhoot Functions & Scripts
import { getTileFromName } from "../scripts/utils";

// Dayhoot Components
import { TileContainer } from "../components/common-components";

// Dayhoot Config
import { categoryTileList } from "../config/categoryTiles";

export function CategoryTileContainer({ category, numberOfTiles }) {
	const { categoryName, categoryPageTitle } = category;
	const desktopWidth = numberOfTiles ? Math.round(12/numberOfTiles) : 3;
	return (
		<div className={`col-md-6 col-lg-${desktopWidth} mt-1 mb-1`}>
			<NavLink key={categoryName} to={`/categories/${categoryName}`}>
				<TileContainer
					imageSource={getTileFromName(categoryTileList, category.categoryName)}
					displayName={categoryPageTitle}
				/>
			</NavLink>
		</div>
	);
}

export function CategoryTileList({ categories, numberOfTiles}) {
	const componentsToRender = categories.map((category) => (
		<CategoryTileContainer key={category.categoryName} category={category} numberOfTiles={numberOfTiles} />
	));
	return <div className="row">{componentsToRender}</div>;
}
