import { ReactElement } from "react";
import {
  LocationOn as LocationOnIcon,
  LocationOff as LocationOffIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

type LocationSettingIconProps = {
  isUsingGeolocation: boolean;
};

const useStyles = makeStyles((theme) => {
  return {
    icon: {
      fill: theme.palette.primary.contrastText,
    },
  };
});

// This is to give a more visual representation that the user's geolocation is being used
const LocationSettingIcon = ({
  isUsingGeolocation,
}: LocationSettingIconProps): ReactElement => {
  const classes = useStyles();
  return isUsingGeolocation ? (
    <LocationOnIcon className={classes.icon} />
  ) : (
    <LocationOffIcon className={classes.icon} />
  );
};
export default LocationSettingIcon;
