import { createElement } from "react";

type SelectBoxItemProps = {
  defaultItem?: boolean;
  label?: string;
};

const SelectBoxItem = ({
  defaultItem,
  label,
}: SelectBoxItemProps) => {
  return (
    <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
      {createElement(
        defaultItem ? "em" : "span",
        defaultItem ? { className: "default-select-menuitem" } : null,
        defaultItem ? "Any" : label || "Unknown"
      )}
    </div>
  );
};
export default SelectBoxItem;
